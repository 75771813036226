import React from 'react';

type Props = {
  color?: string;
} & typeof defaultProps;

const defaultProps = {
  color: '#000000',
};

const CompaniesSvg = function CompaniesSvg(props: Props) {
  const { color } = props;
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="SideNav-SalesApp" transform="translate(-31.000000, -397.000000)" fill={color}>
          <g id="Group" transform="translate(9.000000, 378.000000)">
            <g id="noun_company_3654353" transform="translate(22.000000, 19.000000)">
              <path d="M12,24 C11.832,23.9906667 11.6653333,23.9573333 11.5093333,23.8946667 C11.1693333,23.7573333 10.9,23.484 10.7666667,23.1426667 C10.7066667,22.9906667 10.676,22.8293333 10.6666667,22.6666667 L10.6666667,18.6666667 L8,18.6666667 L8,22.6666667 C7.992,22.824 7.96266667,22.98 7.90666667,23.128 C7.78666667,23.444 7.552,23.7053333 7.248,23.8546667 C7.10666667,23.9253333 6.95466667,23.9693333 6.79866667,23.9933333 L6.66666667,24 L1.33333333,24 C0.98,24 0.64,23.86 0.390666667,23.6093333 C0.14,23.36 0,23.02 0,22.6666667 C0,18.4653333 0,5.53466667 0,1.33333333 C0,0.597333333 0.597333333,0 1.33333333,0 C4.76933333,0 13.8973333,0 17.3333333,0 C18.0693333,0 18.6666667,0.597333333 18.6666667,1.33333333 L18.6666667,8 L22.6666667,8 C23.02,8 23.36,8.14 23.6093333,8.39066667 C23.86,8.64 24,8.98 24,9.33333333 C24,12.3466667 24,19.6533333 24,22.6666667 C24,23.02 23.86,23.36 23.6093333,23.6093333 C23.36,23.86 23.02,24 22.6666667,24 L12,24 Z M16,21.3333333 L16,2.66666667 L2.66666667,2.66666667 L2.66666667,21.3333333 L5.33333333,21.3333333 C5.33333333,21.3333333 5.33333333,18.9133333 5.33333333,17.3333333 C5.33333333,16.98 5.47333333,16.64 5.724,16.3906667 C5.97333333,16.14 6.31333333,16 6.66666667,16 C8.14666667,16 10.52,16 12,16 C12.3533333,16 12.6933333,16.14 12.9426667,16.3906667 C13.1933333,16.64 13.3333333,16.98 13.3333333,17.3333333 C13.3333333,18.9133333 13.3333333,21.3333333 13.3333333,21.3333333 L16,21.3333333 Z M21.3333333,10.6666667 L18.6666667,10.6666667 L18.6666667,21.3333333 L21.3333333,21.3333333 L21.3333333,10.6666667 Z" id="Shape" />
              <circle id="Oval" cx="6.85714286" cy="6.85714286" r="1.14285714" />
              <circle id="Oval" cx="6.85714286" cy="12.5714286" r="1.14285714" />
              <circle id="Oval" cx="12.5714286" cy="6.85714286" r="1.14285714" />
              <circle id="Oval" cx="12.5714286" cy="12.5714286" r="1.14285714" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

CompaniesSvg.defaultProps = defaultProps;
export default CompaniesSvg;
