import React, { lazy, Suspense } from 'react';

const SocialEvanoteIcon = lazy(() => import('Components/common/assets/svgIcons/SocialEvanoteIcon'));

type Props = {
  url: string
  title: string
};

const EverNote = function EverNote(props: Props) {
  const { title, url } = props;
  return (
    <span style={{ marginRight: '5px' }} className="evanote-icon">
      <a
        href={
          `https://www.evernote.com/clip.action?title=${encodeURIComponent(title)
          } - via @xiqinc`
          + `&url=${encodeURIComponent(url)}`
        }
        target="_blank"
        rel="noopener noreferrer"
        data-share-type="evernote_share"
      >
        <Suspense
          fallback={<div />}
        >
          <SocialEvanoteIcon />
        </Suspense>
      </a>
    </span>
  );
};

export default EverNote;
