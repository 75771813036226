/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable no-param-reassign */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MarketingCompaignRequest } from 'Types/RequestBodies';

export const initialState = {
  id: 0,
};

const marketingCompaignSlice = createSlice({
  name: 'marketingCompaign',
  initialState,
  reducers: {
    updatePopup(state, action: PayloadAction<MarketingCompaignRequest>) {
    },
  },

});

export const { actions, reducer, name: sliceKey } = marketingCompaignSlice;
