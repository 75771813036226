import { createGlobalStyle } from 'styled-components';
import { secondaryFont, maxWidth, retina } from '../../../Utils/utilsStyle';

const GlobalStyle = createGlobalStyle`
body, html {
  margin:0;
  padding:0;
  font-family: 'roboto_slabregular';
}
svg{
  max-width:100%;
}
.anchor-link {
  color: #1ec252;
  text-decoration: none;
  margin: 0 5px;
}
.anchor-link:hover {
  text-decoration: none;
}
.saveBtnSuccess {
  border-radius: 27px;
  background-color: #1ec252;
  color: #ffffff;
  font-size: 16px;
  font-weight: 900;
  padding: 9px 22px;
  line-height: 1.2;
  border: solid 1px #1ec252;
  font-family: "Roboto";
  transition: 0.3s;
  &:active,
  &:focus {
    box-shadow: 0 0 0 0.2rem rgb(40 167 69 / 50%);
  }
  &.outline {
    background-color: #ffffff;
    color: #1ec252;
    &:active,
    &:focus,
    &:hover {
      background-color: #1ec252;
      color: #ffffff;
    }
  }
  &:disabled {
    cursor: default;
    opacity: 0.6;
  } 
}
.ant-popover-inner-content{
  padding: 0;
}
.ant-icon-button{
  font-family: ${secondaryFont};
  font-size: 10px;
  font-weight: 400;
  color: #697882;
  padding-bottom: 10px;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    font-size: 10px;
  }
  svg {
    width: 12px;
  }
  span{
    padding-left: 5px;
  }
}
.schedule-meeting-modal {
  .ant-modal-close {
    display: none;
  }
}
  .ant-btn{
    &.borderedButton {
    border-radius: 50px !important;
    }
  }
  .mt-2{
    margin-top:8px;
  }
  .mt-1{
    margin-top:4px;
  }
  .mb-2{
    margin-bottom:8px;
  }
  .mb-1{
    margin-bottom:4px;
  }
  .mb-50{
    margin-bottom: 50px;
  }
  .ml-2{
    margin-left:8px;
  }
  .ml-1{
    margin-left:4px;
  }
  .mr-2{
    margin-right:8px;
  }
  .mr-1{
    margin-right:4px;
  }
  
`;

export default GlobalStyle;
