/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  all,
  call, put, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'Utils/Axios';

import { actions } from 'Slice/NotesSlice';
import { actions as personProfileActions } from 'Slice/PersonProfileSlice';

import { API } from 'Utils/Constants';
import {
  AddNoteRequestPayload,
  EditNoteRequestPayload,
  GetPersonNotesRequest,
} from 'Types/RequestBodies';
import { actions as helperActions } from 'Slice/HelperSlice';
import { AddNoteResponse, EditNoteResponse, PersonNotesResponse } from 'Types/NotesTypes';
import { createNotesPayloadObj, getCurrentTime, valueToObject } from 'Utils/UtilityFunctions';
import { actions as BuyerGroupsPageSlice } from 'Slice/BuyerGroupsPageSlice';
import { actions as GlobalSearchResultsSlice } from 'Slice/GlobalSearchResultsSlice';
import { actions as PeopleSlice } from 'Slice/PeopleSlice';
import { actions as CompaniesSlice } from 'Slice/CompaniesSlice';
import { actions as TopCompanyEntitySlice } from 'Slice/TopCompanyEntitySlice';

export function* getPersonNotes({ payload }: PayloadAction<GetPersonNotesRequest>) {
  try {
    const startTime = getCurrentTime();
    const response: PersonNotesResponse = yield call(
      axios.post,
      payload.type === 'notes' ? API.GET_PERSON_NOTES : API.BUYER_GROUPS_LIST_REASONS,
      payload,
    );
    if (response.status === true) {
      const { response: { notesResult, notes, currentUser } } = response;
      if (response?.response) {
        yield put(actions.setPersonNotes(
          notesResult || notes || [],
        ));
        yield put(actions.setCurrentUser(currentUser || null));
      }
      // flurry event call start
      const {
        personId, groupId, type, companyId,
      } = payload;
      if (personId) {
        yield put(helperActions.callFlurryEvent(
          valueToObject(
            'person_note_open',
            startTime,
            {
              personId, groupId, type,
            },
          ),
        ));
        yield put(personProfileActions.resetNewNotes());
      }
      // flurry event call end
      if (companyId) {
        yield put(CompaniesSlice.resetNewNotes());
      }
    } else {
      yield put(actions.getPersonNotesFailed());
    }
  } catch (err) {
    yield put(actions.getPersonNotesFailed());
    console.log(err);
  }
}

export function* addPersonNote(dispatched: PayloadAction<AddNoteRequestPayload>) {
  const { payload } = dispatched;
  const startTime = getCurrentTime();
  const obj = createNotesPayloadObj(payload);
  try {
    const response: AddNoteResponse = yield call(
      axios.post,
      API.ADD_PERSON_NOTES,
      obj,
    );
    const notification = {
      message: response?.message,
      proTip: false,
      rightPlacement: false,
      groupId: 0,
      classnameFlag: !!response?.status,
    };
    if (response?.status === true) {
      const { response: { noteId } } = response;
      yield put(actions.personNoteAdded({ ...payload, noteId: noteId || 0 }));
      const { personId, note, type } = payload;
      if (personId) {
        yield put(helperActions.callFlurryEvent(
          valueToObject(
            'person_write_note',
            startTime,
            {
              personId, note, type, noteId,
            },
          ),
        ));
      }
    } else {
      yield put(actions.addPersonNoteFailed());
    }
    yield put(helperActions.setNotification(notification));
  } catch (err) {
    yield put(actions.addPersonNoteFailed());
    console.log(err);
  }
}

export function* editPersonNote({ payload }: PayloadAction<EditNoteRequestPayload>) {
  const startTime = getCurrentTime();
  const data = payload.type === 'notes'
    ? {
      noteId: payload.noteId,
      note: payload.note,
      type: payload.type,
      ...(!!payload.taggedPersons?.length
        && { meta: { entities: { personMentions: payload.taggedPersons } } }),
    }
    : {
      reasonId: payload.reasonId,
      reasonText: payload.reasonText,
    };
  try {
    const response: EditNoteResponse = yield call(
      axios.post,
      payload.type === 'notes' ? API.EDIT_PERSON_NOTES : API.BUYER_GROUPS_EDIT_REASON,
      data,
    );
    const notification = {
      message: response?.message,
      proTip: false,
      rightPlacement: false,
      groupId: 0,
      classnameFlag: !!response?.status,
    };
    if (response?.status === true) {
      yield put(actions.personNoteEdited(payload));
      // flurry event call start
      const {
        note, type, noteId, reasonId, reasonText,
      } = payload;
      if (response.message.toLowerCase().includes('person')) {
        yield put(helperActions.callFlurryEvent(
          valueToObject(
            'person_note_edit',
            startTime,
            {
              type,
              noteId: type !== 'reason' ? noteId : reasonId,
              note: type !== 'reason' ? note : reasonText,
            },
          ),
        ));
      }
      // flurry event call end
    } else {
      yield put(actions.editPersonNoteFailed());
    }
    yield put(helperActions.setNotification(notification));
  } catch (err) {
    yield put(actions.editPersonNoteFailed());
    console.log(err);
  }
}

export function* deletePersonNote(dispatched: PayloadAction<EditNoteRequestPayload>) {
  const { payload } = dispatched;
  const startTime = getCurrentTime();
  try {
    const response: EditNoteResponse = yield call(
      axios.post,
      API.DELETE_PERSON_NOTES,
      payload,
    );
    const notification = {
      message: response?.message,
      proTip: false,
      rightPlacement: false,
      groupId: 0,
      classnameFlag: !!response?.status,
    };
    if (response?.status === true) {
      yield put(actions.personNoteDeleted(payload.noteId || 0));

      // flurry event call start
      const { personId, noteId } = payload;
      if (personId) {
        yield put(helperActions.callFlurryEvent(
          valueToObject(
            'person_note_delete',
            startTime,
            {
              personId, noteId,
            },
          ),
        ));
      }

      // flurry event call end
      if (!payload.notesLength) {
        yield all([
          put(BuyerGroupsPageSlice.handlePersonNotes(payload.personId || 0)),
          put(GlobalSearchResultsSlice.handlePersonNotes(payload.personId || 0)),
          put(PeopleSlice.handlePersonNotes(payload.personId || 0)),
          put(CompaniesSlice.handlePersonNotes(payload.personId || 0)),
          put(TopCompanyEntitySlice.handlePersonNotes(payload.personId || 0)),
        ]);
      }
    } else {
      yield put(actions.deletePersonNoteFailed());
    }
    yield put(helperActions.setNotification(notification));
  } catch (err) {
    yield put(actions.deletePersonNoteFailed());
    console.log(err);
  }
}

export function* NotesSaga() {
  yield takeLatest(actions.getPersonNotes.type, getPersonNotes);
  yield takeLatest(actions.addPersonNote.type, addPersonNote);
  yield takeLatest(actions.editPersonNote.type, editPersonNote);
  yield takeLatest(actions.deletePersonNote.type, deletePersonNote);
}
