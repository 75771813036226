import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'Store/InjectorsTypes';
import { initialState } from 'Slice/PeopleSlice';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state?.people || initialState;
export const selectRecommendedPeopleLoading = createSelector(
  [selectDomain],
  (state) => state.recommendedPeopleLoading,
);

export const selectRecommendedPeople = createSelector(
  [selectDomain],
  (state) => state.recommendedPeople,
);

export const selectRecommendedTitle = createSelector(
  [selectDomain],
  (state) => state.recommendedTitle,
);
export const selectPeopleCount = createSelector(
  [selectDomain],
  (state) => state.peopleCount,
);
export const selectFollowedPeople = createSelector(
  [selectDomain],
  (state) => state.followedPeople,
);
export const selectFilters = createSelector(
  [selectDomain],
  (state) => state.filters,
);
export const selectFollowedPeopleLoading = createSelector(
  [selectDomain],
  (state) => state.followedPeopleLoading,
);
export const selectMorePeopleLoading = createSelector(
  [selectDomain],
  (state) => state.followedPeopleMoreLoading,
);
export const selectExportFollowLoading = createSelector(
  [selectDomain],
  (state) => state.exportFollowedLoading,
);
export const selectFollowedPeopleEnded = createSelector(
  [selectDomain],
  (state) => state.followedPeopleEnded,
);
export const selectExportPeopleUrl = createSelector(
  [selectDomain],
  (state) => state.exportPeopleUrl,
);
export const selectPeopleRemoved = createSelector(
  [selectDomain],
  (state) => state.removed,
);
export const selectRecommendedPeopleLoaded = createSelector(
  [selectDomain],
  (state) => state.recommendedPeopleLoaded,
);
