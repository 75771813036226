import { createGlobalStyle } from 'styled-components';
import { maxMobile, maxTablet } from 'Utils/utilsStyle';

const SafariStyle = createGlobalStyle`
  @media not all and (min-resolution:.001dpcm) { 
    @supports (-webkit-appearance:none) {
      .company-holo-placeholder {
        margin-bottom: 30px;
      } 
      [role=button] {
        outline: none !important;
        &:focus {
          outline: none !important;
        }
      }
      .detail-drawer-scroll {
        @media all and (max-width: ${maxMobile}) {
          height: calc(100vh - 100px) !important;
        }
      }
      .articles-wrapper{ 
        @media all and (max-width: ${maxMobile}) {
          height: calc(100vh - 200px) !important;
        } 
      }
      .smart-account-drawer {
        .smart-account-plan-slider {
          .slider-for-smart-components {
            height: calc(-320px + 100vh);
          }
        }
      }
      .chat-bot-styled-wrapper {
        .chat-container {
          .body-content {
            @media all and (max-width: ${maxTablet}) {
              height: calc(100vh - 300px);
            }
          }
        }
      }
      .container {
        .pulse-leader-div {
          margin-top: 30px;
        }
      } 
    }
  }
`;

export default SafariStyle;
