/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  CancelPopupType,
  CustomUploadFile, Document, DocumentDeletedType,
  DocumentUploaded,
  HubCompany, SearchList, SetDocuments, SetDownloadURL, SetSearchListWithIndex,
  SortType, UploadDocumentStatus, SetSelectToggle,
  AddRemoveDocInSearchList,
} from 'Types/DocumentHubTypes';
import { DocumentHubState } from 'Types/GlobalTypes';
import {
  DocumentDeleteRequest, DocumentPreviewRequest, DocumentsRequest,
  GetDocumentDownloadRequest, RenameDocumentRequest, UploadRequest,
  ValidateFileNameRequest,
} from 'Types/RequestBodies';
import _ from 'lodash';

export const initialState: DocumentHubState = {
  documents: [],
  uploadedDocuments: [],
  documentsEnded: false,
  documentsLoaded: false,
  documentsLoading: false,
  sort: 'des',
  sortType: 'date',
  companyId: 0,
  companyName: '',
  companyLogo: '',
  chatgptAllowed: false,
  isAdmin: false,
  uploaded: false,
  uploading: false,
  drawerOpen: false,
  deleteing: false,
  deleteLoading: false,
  downloading: false,
  downloadUrl: '',
  drawerState: 'file',
  searchTerm: '',
  uploadDocuments: [],
  nextUploadDocuments: [],
  failedDocuments: [],
  successDocuments: [],
  batchSize: 0,
  batchUploading: false,
  uploadLink: '',
  cancelPopup: {} as CancelPopupType,
  uploadCancelled: false,
  downloadLoadingIds: [],
  fileDownloadURL: '',
  fileViewUrl: '',
  documentCount: 0,
  searchCountDelete: 0,
  sortFlurryEvent: '',
  fileTypeFlurryEvent: [],
  flurryFlag: true,
  userDocumentCount: 0,
  previewDrawerOpen: false,
  isSelectAll: false,
  isSearchSelectAll: false,
  selectedDocuments: [],
  unSelectedDocuments: [],
  searchList: [],
  uploadedByMe: false,
  selectedDocumentCount: 0,
  unselectedDocumentCount: 0,
  uploadDocumentStatus: false,
  uploadingFileError: false,
  validateLoading: false,
  showSelected: false,
  sapSelectedDocOffset: 0,
};

const documentHubSlice = createSlice({
  name: 'documentHub',
  initialState,
  reducers: {
    getDocuments(state, action: PayloadAction<DocumentsRequest>) {
      state.documentsLoading = true;
      state.documentsEnded = false;
      if (action.payload.offset === 0) {
        state.documents = [];
        state.documentsLoaded = false;
      }
    },
    setDocuments(state, action: PayloadAction<SetDocuments>) {
      const { payload, inSmartDrawer } = action.payload;
      state.uploadedDocuments = state.uploadedDocuments.map((uploadedDoc) => ({
        ...uploadedDoc,
      }));
      state.uploadedDocuments = state.uploadedDocuments.filter(
        (uploadedDoc) => !payload.some((doc) => uploadedDoc.id === doc.id),
      );
      let newDocs = payload;
      newDocs = newDocs?.filter((doc) => !state.documents.some((d) => d.id === doc.id));
      state.documents = state.documents.concat(newDocs);
      state.sapSelectedDocOffset += payload.length;
      state.documentsLoading = false;
      if (!payload.length) {
        state.documentsEnded = true;
      }
      state.documentsLoaded = true;
    },
    setHubCompany(state, action: PayloadAction<HubCompany>) {
      const {
        companyLogo, companyName, companyId, chatgptAllowed, documentCount,
        isAdmin, userDocumentCount, selectedDocumentCount, unselectedDocumentCount, searchTerm,
      } = action.payload;
      state.companyId = companyId;
      state.companyLogo = companyLogo;
      state.companyName = companyName;
      state.chatgptAllowed = chatgptAllowed;
      state.documentCount = documentCount;
      state.isAdmin = isAdmin;
      state.userDocumentCount = userDocumentCount;
      state.selectedDocumentCount = selectedDocumentCount;
      state.unselectedDocumentCount = unselectedDocumentCount;

      const inSearchList = state.searchList?.some((item) => {
        if (item.searchTerm === searchTerm) {
          return true;
        }
        return false;
      });
      if (searchTerm?.length && !inSearchList) {
        state.isSearchSelectAll = !unselectedDocumentCount;
      }
    },
    toggleSort(state, action: PayloadAction<SortType>) {
      if (action.payload === state.sortType) {
        if (state.sort === 'asc') state.sort = 'des';
        else state.sort = 'asc';
      } else {
        state.sortType = action.payload;
        state.sort = 'des';
      }
      state.documentsLoaded = false;
      state.documents = [];
      state.uploadedDocuments = [];
      state.sapSelectedDocOffset = 0;
      if (state.sortType === 'date') {
        state.sortFlurryEvent = `date_${state.sort === 'asc' ? 'ascending' : 'descending'}`;
      } else if (state.sortType === 'name') {
        state.sortFlurryEvent = `name_${state.sort === 'asc' ? 'ascending' : 'descending'}`;
      } else if (state.sortType === 'size') {
        state.sortFlurryEvent = `filesize_${state.sort === 'asc' ? 'ascending' : 'descending'}`;
      } else if (state.sortType === 'status') {
        state.sortFlurryEvent = `status_${state.sort === 'asc' ? 'ascending' : 'descending'}`;
      } else if (state.sortType === 'uploadedBy') {
        state.sortFlurryEvent = `uploadedBy_${state.sort === 'asc' ? 'ascending' : 'descending'}`;
      }
    },
    uploadDocument(state, action: PayloadAction<UploadRequest>) {
      state.flurryFlag = false;
      state.uploaded = false;
      if (action.payload) {
        state.uploading = true;
      }
      if (action.payload.fileUrl) {
        state.batchUploading = true;
      }
    },
    updateFileName(state, action: PayloadAction<File>) {
      state.uploadDocuments[0] = action.payload;
    },
    validateFileName(state, action: PayloadAction<ValidateFileNameRequest>) {
      state.validateLoading = true;
    },
    setUploadDocumentStatus(state, action: PayloadAction<UploadDocumentStatus>) {
      const { filenameAllowed } = action.payload;
      state.uploadDocumentStatus = filenameAllowed;
      state.validateLoading = false;
    },
    setUploadDocumentError(state, action: PayloadAction<boolean>) {
      state.uploadingFileError = action.payload;
    },
    documentUploaded(state, action: PayloadAction<DocumentUploaded>) {
      const { file, inSmartDrawer } = action.payload;
      state.uploaded = true;
      state.uploading = false;
      if (!_.isEmpty(action.payload) && state.documentsLoaded) {
        state.uploadedDocuments = [{
          ...file, showBlip: true, showPopover: true, isSelected: inSmartDrawer,
        },
        ...state.uploadedDocuments];
        state.documentCount += 1;
        state.userDocumentCount += 1;
      }
      if (inSmartDrawer) {
        state.selectedDocumentCount += 1;
        if (!state.isSelectAll) {
          state.selectedDocuments = [...state.selectedDocuments, file.id];
        }
      } else {
        state.unselectedDocumentCount += 1;
      }
    },
    setDrawerOpen(state, action: PayloadAction<boolean>) {
      state.drawerOpen = action.payload;
      if (!action.payload) {
        state.drawerState = 'file';
      }
    },
    deleteDocument(state, action: PayloadAction<DocumentDeleteRequest>) {
      const { selectedDocuments } = action.payload;
      const id = selectedDocuments?.split(',').map((docId) => parseInt(docId.trim(), 10));
      if (id) {
        if (state.isSelectAll) {
          state.documents = state.documents.map((doc) => ({
            ...doc,
            deleting: !id.includes(doc.id),
          }));
          state.uploadedDocuments = state.uploadedDocuments.map((doc) => ({
            ...doc,
            deleting: !id.includes(doc.id),
          }));
        } else {
          state.documents = state.documents.map((doc) => ({
            ...doc,
            deleting: id.includes(doc.id),
          }));

          state.uploadedDocuments = state.uploadedDocuments.map((doc) => ({
            ...doc,
            deleting: id.includes(doc.id),
          }));
        }
        state.deleteing = true;
        state.deleteLoading = true;
        state.isSelectAll = false;
        state.selectedDocuments = [];
        state.unSelectedDocuments = [];
        state.searchList = [];
      }
    },
    documentDeleted(state, action: PayloadAction<DocumentDeletedType>) {
      const {
        deletedDocumentIds, searchCountDelete,
      } = action.payload;

      state.documents = state.documents.map((doc) => ({
        ...doc,
        deleting: false,
      })).filter((doc) => !deletedDocumentIds.includes(doc.id));
      state.uploadedDocuments = state.uploadedDocuments.map((doc) => ({
        ...doc,
        deleting: false,
      })).filter((doc) => !deletedDocumentIds.includes(doc.id));
      state.documentCount = searchCountDelete ?? (state.documentCount - deletedDocumentIds.length);
      // state.selectedDocumentsDelete = [];
      state.deleteing = false;
      state.deleteLoading = false;
      state.userDocumentCount -= deletedDocumentIds.length;
    },
    documentDeleteFail(state, action: PayloadAction<number[]>) {
      state.deleteing = false;
      state.deleteLoading = false;
      const id = action.payload;
      if (id) {
        state.documents = state.documents.map((doc) => ({
          ...doc,
          deleting: false,
        }));

        state.uploadedDocuments = state.uploadedDocuments.map((doc) => ({
          ...doc,
          deleting: false,
        }));
      }
    },
    downloadDocument(state, action: PayloadAction<string>) {
      const link = action.payload;
      if (link) {
        state.documents = state.documents.map((doc) => ({
          ...doc,
          downloading: doc.fileUrl === link,
        }));
        state.uploadedDocuments = state.uploadedDocuments.map((doc) => ({
          ...doc,
          downloading: doc.fileUrl === link,
        }));
        state.downloading = true;
        state.downloadUrl = link;
      }
    },
    getPreviewDocument(state, action: PayloadAction<DocumentPreviewRequest>) {
    },
    clearPreviewDocument(state) {
      state.fileViewUrl = '';
    },
    documentDownloaded(state) {
      state.documents = state.documents.map((doc) => ({
        ...doc,
        downloading: false,
      }));
      state.uploadedDocuments = state.uploadedDocuments.map((doc) => ({
        ...doc,
        downloading: false,
      }));
      state.downloading = false;
      state.downloadUrl = '';
      state.fileDownloadURL = '';
    },
    renameDocument(state, action: PayloadAction<RenameDocumentRequest>) {
      const { id } = action.payload;
      state.documents = state.documents.map((doc) => ({
        ...doc,
        deleting: doc.id === id,
      }));
      state.uploadedDocuments = state.uploadedDocuments.map((doc) => ({
        ...doc,
        deleting: doc.id === id,
      }));
    },
    documentRenamed(state, action: PayloadAction<Document>) {
      const { id } = action.payload;
      if (id) {
        state.documents = state.documents
          .map((doc) => (doc.id === id ? { ...action.payload, showPopover: true } : doc));
        state.uploadedDocuments = state.uploadedDocuments
          .map((doc) => (doc.id === id ? { ...action.payload, showPopover: true } : doc));
      }
    },
    getDocumentDownloadURL(state, action: PayloadAction<GetDocumentDownloadRequest>) {
      state.downloadLoadingIds = [...state.downloadLoadingIds, action.payload.id];
    },
    setDocumentDownloadURL(state, action: PayloadAction<SetDownloadURL>) {
      const { fileURL, documentId, fileViewUrl } = action.payload;
      state.fileDownloadURL = fileURL;
      state.downloadLoadingIds = state.downloadLoadingIds.filter((docId) => docId !== documentId);
    },
    setDocumentPreviewURL(state, action: PayloadAction<SetDownloadURL>) {
      const { fileViewUrl } = action.payload;
      state.fileViewUrl = fileViewUrl;
    },
    setDrawerState(state, action: PayloadAction<string>) {
      state.drawerState = action.payload;
    },
    setSearchText(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
      state.documentsLoaded = false;
      state.documents = [];
      state.uploadedDocuments = [];
    },
    setUploadDocument(state, action: PayloadAction<File>) {
      state.uploadDocuments.push(action.payload);
      if (state.uploadDocuments.length) {
        state.batchUploading = true;
      }
    },
    setNextUploadDocument(state, action: PayloadAction<File>) {
      state.nextUploadDocuments.push(action.payload);
    },
    resetNextUploadDocuments(state) {
      state.nextUploadDocuments = [];
    },
    removeUploadDocument(state) {
      state.uploadDocuments = state.uploadDocuments?.slice(1);
      if (!state?.uploadDocuments?.length) {
        state.batchUploading = false;
      }
    },
    modifyUploadDocument(state, action: PayloadAction<File>) {
      if (state.uploadDocuments.length === 1) {
        state.uploadDocuments[0] = action.payload;
      }
    },
    setFailDocuments(state, action: PayloadAction<CustomUploadFile>) {
      state.failedDocuments.push(action.payload);
    },
    setSuccessDocuments(state, action: PayloadAction<Document>) {
      state.successDocuments.push(action.payload);
    },
    resetSuccessDocuments(state) {
      state.successDocuments = [];
    },
    setBatchSize(state, action: PayloadAction<number>) {
      state.batchSize = action.payload;
    },
    setBatchUploading(state, action: PayloadAction<boolean>) {
      state.batchUploading = action.payload;
    },
    setUploadLink(state, action: PayloadAction<string>) {
      state.uploadLink = action.payload;
    },
    setCancelPopup(state, action: PayloadAction<CancelPopupType>) {
      state.cancelPopup = action.payload;
    },
    toggleDocumentPopover(state, action: PayloadAction<number>) {
      state.documents = state.documents.map((doc) => ({
        ...doc,
        showPopover: doc.id === action.payload
          ? !doc.showPopover : true,
      }));
      state.uploadedDocuments = state.uploadedDocuments.map((doc) => ({
        ...doc,
        showPopover: doc.id === action.payload
          ? !doc.showPopover : true,
      }));
    },
    cancelUpload(state) {
      state.uploadDocuments = [];
      state.uploadCancelled = true;
    },
    resetBatch(state) {
      state.batchUploading = false;
      state.batchSize = 0;
      state.uploadDocuments = [];
      state.successDocuments = [];
      state.failedDocuments = [];
      state.uploadLink = '';
      state.fileTypeFlurryEvent = [];
      state.flurryFlag = true;
    },
    setSearchCount(state, action: PayloadAction<number>) {
      state.searchCountDelete = action.payload;
    },
    resetUploadCancel(state) {
      state.uploadCancelled = false;
    },
    pushFileTypeFlurry(state, action: PayloadAction<string>) {
      state.fileTypeFlurryEvent.push(action.payload);
    },
    setPreviewDrawerOpen(state, action: PayloadAction<boolean>) {
      state.previewDrawerOpen = action.payload;
    },
    setSelectDocument(state, action: PayloadAction<number>) {
      const documentIndex = state.documents.findIndex(
        (item) => item.id === action.payload,
      );
      if (documentIndex !== -1) {
        state.documents[documentIndex].isSelected = !state.documents[documentIndex].isSelected;
      } else {
        const index = state.uploadedDocuments.findIndex(
          (item) => item.id === action.payload,
        );
        state.uploadedDocuments[index].isSelected = !state.uploadedDocuments[index].isSelected;
      }
    },
    setSelectAllDocuments(state, action: PayloadAction<boolean>) {
      state.documents = state.documents?.map((doc) => ({
        ...doc,
        isSelected: doc.deleteAllowed ? action.payload : false,
      }));
      state.uploadedDocuments = state.uploadedDocuments?.map((doc) => ({
        ...doc,
        isSelected: doc.deleteAllowed ? action.payload : false,
      }));
    },
    setSelectAllSapDocuments(state, action: PayloadAction<boolean>) {
      state.documents = state.documents?.map((doc) => ({
        ...doc,
        isSelected: action.payload,
      }));
      state.uploadedDocuments = state.uploadedDocuments?.map((doc) => ({
        ...doc,
        isSelected: action.payload,
      }));
    },
    setIsSelectAll(state, action: PayloadAction<boolean>) {
      state.isSelectAll = action.payload;
    },
    setIsSearchSelectAll(state, action: PayloadAction<boolean>) {
      state.isSearchSelectAll = action.payload;
    },
    setSelectedDocuments(state, action: PayloadAction<number[]>) {
      state.selectedDocuments = action.payload;
    },
    setUnselectedDocuments(state, action: PayloadAction<number[]>) {
      state.unSelectedDocuments = action.payload;
    },
    setSearchList(state, action: PayloadAction<SearchList[]>) {
      state.searchList = action.payload;
    },
    setUploadedByMe(state, action: PayloadAction<boolean>) {
      state.uploadedByMe = action.payload;
    },

    updateSearchList(state, action: PayloadAction<SearchList>) {
      const newSearch = action.payload;
      const {
        searchTerm, isSelectAll, ids, uploadedByMe, isPrevSelected,
      } = newSearch;
      const index = state.searchList.findIndex((item) => item.searchTerm === searchTerm
        && item.uploadedByMe === uploadedByMe);
      if (index === -1) {
        if (!state.isSearchSelectAll && !isSelectAll && isPrevSelected && state.isSelectAll) {
          state.unSelectedDocuments = [...state.unSelectedDocuments, ...ids];
        } else if (!state.isSearchSelectAll && !isSelectAll
          && !searchTerm?.length && uploadedByMe && !isPrevSelected) {
          state.selectedDocuments = [...state.selectedDocuments, ...ids];
        } else if (!state.isSearchSelectAll && !isSelectAll
          && !searchTerm?.length && uploadedByMe && isPrevSelected) {
          const obj = newSearch;
          obj.ids = [];
          state.searchList = [...state.searchList, obj];
        } else {
          state.searchList = [...state.searchList, newSearch];
        }
      } else if (state.searchList[index].isSelectAll === isSelectAll) {
        if ((isSelectAll && isPrevSelected) || (!isSelectAll && !isPrevSelected)) {
          const updatedSearchList = { ...state.searchList[index] };
          updatedSearchList.ids = Array.from(
            new Set([...state.searchList[index].ids, ...ids]),
          );
          state.searchList.splice(index, 1);
          state.searchList = [...state.searchList, updatedSearchList];
        } else {
          state.searchList[index].ids = state.searchList[index].ids?.filter(
            (searchId) => searchId !== ids[0],
          );
        }
      } else {
        state.searchList[index].isSelectAll = isSelectAll;
        state.searchList[index].ids = ids;
      }
    },

    updateSearchListWhenIdinOtherObj(state, action: PayloadAction<SetSearchListWithIndex>) {
      const { search, index } = action.payload;
      const {
        searchTerm, ids, uploadedByMe, isPrevSelected,
      } = search;
      const { isSelectAll } = state.searchList[index];
      if (!isSelectAll && !isPrevSelected) {
        state.searchList[index].ids = Array.from(
          new Set([...state.searchList[index].ids, ...ids]),
        );
      } else if (!isSelectAll && isPrevSelected) {
        state.searchList[index].ids = state.searchList[index].ids
          .filter(
            (id) => id !== ids[0],
          );
        state.selectedDocumentCount -= 1;
        state.unselectedDocumentCount += 1;
      } else if (isSelectAll && !isPrevSelected) {
        state.searchList[index].ids = state.searchList[index].ids
          .filter(
            (id) => id !== ids[0],
          );
        state.selectedDocumentCount += 1;
        state.unselectedDocumentCount -= 1;
      } else if (isSelectAll && isPrevSelected) {
        state.searchList[index].ids = Array.from(
          new Set([...state.searchList[index].ids, ...ids]),
        );
      }
    },

    updateSelectedDocCountWithFilter(state, action: PayloadAction<SearchList>) {
      const newSearch = action.payload;
      const {
        searchTerm, isSelectAll, ids, uploadedByMe, isPrevSelected,
      } = newSearch;
      const index = state.searchList.findIndex((item) => item.searchTerm === searchTerm
        && item.uploadedByMe === uploadedByMe);
      if (index === -1) {
        if (isPrevSelected) {
          state.selectedDocumentCount -= ids.length;
          state.unselectedDocumentCount += ids.length;
        } else {
          state.selectedDocumentCount += ids.length;
          state.unselectedDocumentCount -= ids.length;
        }
      } else if (state.searchList[index]?.isSelectAll === isSelectAll) {
        if (isPrevSelected) {
          state.selectedDocumentCount -= ids.length;
          state.unselectedDocumentCount += ids.length;
        } else {
          state.selectedDocumentCount += ids.length;
          state.unselectedDocumentCount -= ids.length;
        }
      } else {
        const idsLength = ids.length - state.searchList[index].ids.length;
        state.selectedDocumentCount += idsLength;
        state.unselectedDocumentCount -= idsLength;
      }
    },

    setSearchSelectionToggle(state, action: PayloadAction<SearchList>) {
      const data = action.payload;
      const { isSap, ...newSearch } = data;
      const {
        searchTerm, isSelectAll, ids, uploadedByMe,
      } = newSearch;

      const index = state.searchList.findIndex((item) => item.searchTerm === searchTerm
        && item.uploadedByMe === uploadedByMe);
      if (isSelectAll) {
        const docIds = state.documents?.map((doc) => doc.isSelected && doc.id);
        state.selectedDocuments = state.selectedDocuments.filter(
          (id) => !docIds.includes(id),
        );
      } else {
        const docIds = state.documents?.map((doc) => doc.isSelected && doc.id);
        state.selectedDocuments = state.selectedDocuments.filter(
          (id) => !docIds.includes(id),
        );
      }
      let prevSelectAll = false;
      let prevIds = [];
      if (index === -1) {
        state.searchList = [...state.searchList, newSearch];
      } else {
        prevSelectAll = state.searchList[index].isSelectAll;
        prevIds = state.searchList[index].ids;
        const updatedSearchList = { ...state.searchList[index] };
        updatedSearchList.isSelectAll = isSelectAll;
        updatedSearchList.ids = ids;
        state.searchList.splice(index, 1);
        state.searchList = [...state.searchList, updatedSearchList];
      }

      // for selection count
      const count = state.isAdmin || isSap ? state.documentCount : state.userDocumentCount;
      const selectedDocumentsCount = count - state.unselectedDocumentCount;
      const newlyUploaded = state.uploadedDocuments?.filter((doc) => doc.showBlip);

      if (!isSelectAll) {
        state.selectedDocumentCount -= (count - state.unselectedDocumentCount);
        state.unselectedDocumentCount += (count - state.unselectedDocumentCount);
      } else if (prevSelectAll && prevIds?.length) {
        state.selectedDocumentCount += (prevIds.length + newlyUploaded.length);
        state.unselectedDocumentCount -= (prevIds.length + newlyUploaded.length);
      } else if (state.unselectedDocumentCount) {
        state.selectedDocumentCount += (count - selectedDocumentsCount);
        state.unselectedDocumentCount -= (count - selectedDocumentsCount);
      } else {
        state.selectedDocumentCount += (count - state.unselectedDocumentCount);
        state.unselectedDocumentCount -= (count - state.unselectedDocumentCount);
      }
    },

    setSelectionToggle(state, action: PayloadAction<SetSelectToggle>) {
      const { isSelectAll, isSap } = action.payload;
      state.isSelectAll = !isSelectAll;
      state.selectedDocuments = [];
      state.unSelectedDocuments = [];
      state.searchList = [];
      const count = state.isAdmin || isSap ? state.documentCount : state.userDocumentCount;
      if (isSelectAll) {
        state.selectedDocumentCount -= count;
      } else {
        state.selectedDocumentCount = count;
      }
      state.unselectedDocumentCount = 0;
    },

    setSelectedDocumentsCount(state, action: PayloadAction<number>) {
      state.selectedDocumentCount = action.payload;
    },

    setUnselectedDocumentsCount(state, action: PayloadAction<number>) {
      state.unselectedDocumentCount = action.payload;
    },

    setSapSelectedDocOffset(state, action: PayloadAction<number>) {
      state.sapSelectedDocOffset = action.payload;
    },

    setShowSelected(state, action: PayloadAction<boolean>) {
      state.showSelected = action.payload;
      state.documentsLoaded = false;
      state.documents = [];
      state.uploadedDocuments = [];
    },

    setSapDocuments(state, action: PayloadAction<Document[]>) {
      state.documents = action.payload;
      if (!action.payload?.length) {
        state.documentCount = 0;
      } else {
        state.documentCount -= 1;
        state.unselectedDocumentCount = 0;
      }
    },

    addRemoveDocInWholeSearchList(state, action: PayloadAction<AddRemoveDocInSearchList>) {
      const { isAdd, id } = action.payload;
      if (isAdd) {
        state.searchList = state.searchList?.map((item) => {
          if (!item.isSelectAll) {
            return item;
          }
          return {
            ...item,
            ids: item.ids?.filter((itemId) => itemId !== id),
          };
        });
      } else {
        state.searchList = state.searchList?.map((item) => {
          if (!item.isSelectAll) {
            return item;
          }
          return {
            ...item,
            ids: item.ids?.includes(id) ? item?.ids : [...item.ids, id],
          };
        });
      }
    },

    resetSelectedDocuments(state, action: PayloadAction<boolean>) {
      const isSap = action.payload;
      state.isSelectAll = false;
      state.isSearchSelectAll = false;
      state.selectedDocuments = [];
      state.unSelectedDocuments = [];
      state.searchList = [];
      state.selectedDocumentCount = 0;
      state.showSelected = false;
      state.unselectedDocumentCount = state.isAdmin || isSap
        ? state.documentCount : state.userDocumentCount;
      state.documents = state.documents?.map((doc) => ({
        ...doc,
        isSelected: false,
      }));
      state.uploadedDocuments = state.uploadedDocuments?.map((doc) => ({
        ...doc,
        isSelected: false,
      }));
    },
    reset: (state) => ({
      ...initialState,
      uploaded: state.uploaded,
      uploading: state.uploading,
    }),
  },
});

export const { actions, reducer, name: sliceKey } = documentHubSlice;
