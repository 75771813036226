import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'Store/InjectorsTypes';
import { initialState } from 'Slice/PeopleFollowSlice';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state?.peopleFollow || initialState;

export const selectFollowLoading = createSelector(
  [selectDomain],
  (state) => state.addFollowLoading,
);
export const selectFollowFailed = createSelector([selectDomain], (state) => state.addFollowFailed);
export const selectMessage = createSelector([selectDomain], (state) => state.message);
