import React from 'react';

const PencilIcon = function PencilIcon() {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.54224 17.25H0.104736V13.8125L10.2431 3.67413L13.6806 7.11163L3.54224 17.25ZM10.2431 6.2683L1.93807 14.5733V15.4166H2.7814L11.0864 7.11163L10.2431 6.2683Z" fill="#7E7E7E" />
      <path d="M14.1939 1.01581C13.8364 0.658306 13.2589 0.658306 12.9014 1.01581L11.2239 2.69331L14.6614 6.13081L16.3389 4.45331C16.6964 4.09581 16.6964 3.51831 16.3389 3.16081L14.1939 1.01581Z" fill="#7E7E7E" />
    </svg>

  );
};

export default PencilIcon;
