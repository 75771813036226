import React from 'react';

const defaultProps = {
  onClick: () => { },
};
type Props = {
  onClick?: (e?: React.MouseEvent) => void
} & typeof defaultProps;
const BackIcon = function BackIcon(props: Props) {
  const { onClick } = props;
  return (
    <svg
      width="25px"
      height="25px"
      viewBox="0 0 28 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className="c-pointer back-icon-svg"
      id="main-app-back-btn-am"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Alert-Filter" transform="translate(-970.000000, -92.000000)">
          <g id="Group-25" transform="translate(886.000000, 0.000000)">
            <g id="Group-20" transform="translate(84.000000, 92.000000)">
              <circle id="Oval" fill="#424B50" cx="14" cy="14" r="14" />
              <polygon id="Path" fill="#FFFFFF" fillRule="nonzero" transform="translate(13.000000, 14.000000) rotate(-270.000000) translate(-13.000000, -14.000000) " points="7 12.1358025 9.17263158 10 13 13.7407407 16.8273684 10 19 12.1358025 13 18" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

BackIcon.defaultProps = defaultProps;
export default BackIcon;
