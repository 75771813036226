/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { actions } from 'Slice/FeedIntentDataSlice';
import { PayloadAction } from '@reduxjs/toolkit';

import axios from 'Utils/Axios';

import { API } from 'Utils/Constants';
import { FeedIntentDataRequest } from 'Types/RequestBodies';
import { FeedIntentDataResponse } from 'Types/FeedIntentData';

export function* getFeedIntentData(dispatched: PayloadAction<FeedIntentDataRequest>) {
  try {
    const payload = dispatched?.payload;
    const response: FeedIntentDataResponse = yield call(
      axios.post,
      API.GET_FEED_INTENT_DATA,
      payload,
    );

    if (response?.status === true) {
      yield put(actions.setFeedIntentData(response));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* feedIntentDataSaga() {
  yield takeLatest(actions.getFeedIntentData.type, getFeedIntentData);
}
