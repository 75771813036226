/* eslint-disable react/no-unknown-property */
import React from 'react';

const CloseIcon = function CloseIcon() {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1000003616">
        <circle id="Oval" opacity="0.543816" cx="14.5" cy="14.5" r="14.5" fill="#606060" fill-opacity="0.620013" />
        <g id="Group 3">
          <path id="Line" d="M9.5094 19.7311L19.7324 9.50815" stroke="white" stroke-width="2.63636" stroke-linecap="square" />
          <path id="Line_2" d="M9.51008 9.50794L19.7331 19.7309" stroke="white" stroke-width="2.63636" stroke-linecap="square" />
        </g>
      </g>
    </svg>
  );
};

export default CloseIcon;
