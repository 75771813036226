import {
  lazy, Suspense, useEffect, useState,
} from 'react';

import AccountPopOver from 'Components/common/components/AccountPopOver';
import CompaniesSectionSkeleton from 'Components/company/components/FullComponentSkeleton/CompaniesSection';
import DrawerPeopleGroup from 'Components/people/components/DrawerPeopleGroup';
import { MinWidthApp, IsTablet, scrollHandlerToggleClass } from 'Utils/UtilityFunctions';
import styled from 'styled-components';
import {
  boxShadow,
  maxMobile, maxTablet, maxWidth, maxWidth1170, maxWidth1300,
  primaryHeadingColor, retina, secondaryFont, smMobile, wMedium,
} from 'Utils/utilsStyle';
import { useSelector, useDispatch } from 'react-redux';
import { selectCompanyComparisonData, selectComparisonRows, selectisComparisonLoading } from 'Selector/CompaniesSelector';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { selectShowGlobalGilroy } from 'Selector/GlobalSearchContentSelector';
import { actions as UiAction } from 'Slice/UISlice';
import BackIcon from '../../Components/common/assets/svgIcons/BackIcon';
import NavBarPrimary, { chatActions, chatStates } from '../../Components/layout/headers/NavBarPrimary';
import GlobalSearch from '../../Components/globalSearch/components/GlobalSearch';
import DrawerAlerts from '../../Components/alerts/components/DrawerAlerts';
import { actions as companiesActions } from '../../Slice/CompaniesSlice';
import { Gilroy } from 'gilroy-global';
import { useGilroy } from 'Utils/hooks/useGilroy';

const CompanyComparisonTable = lazy(() => import('./CompanyComparisonTable'));

const Wrapper = styled.div`
  .header-wrapper {
    .left-side-wrap {
      &.smMob-NavBar-Wrapper {
        @media all and (max-width: ${maxMobile}){
          display: flex;
        }
        .bottom-tag {
          z-index: -1;
          position: absolute;
          @media all and (min-width: ${maxWidth1300}){
            height: 0px;
          }
          @media all and (max-width: ${maxMobile}){
            display: none;
          }
        }
      }
      display: block;
      height: 40px;
      @media all and (max-width: ${maxTablet}){
        width: 100%;
        padding-left: 10px;
      }
      .items-data {
        display: flex;
        align-items: center;
        z-index: 1;
      }
      .bottom-tag {
        font-family: ${secondaryFont};
        color:${primaryHeadingColor};
        font-size: 16px;
        letter-spacing: -0.4px;
        font-weight: ${wMedium};
        margin-top: -20px;
        display: block;
        @media all and (max-width: ${maxWidth1300}){
          font-size: 14px;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          word-wrap: break-word;
        }
      }
    }
  }
  &.companyComparePage {
    .sticky-area {
      max-width: 200px;
      @media all and (max-width: ${smMobile}) {
        max-width: 170px;
      }
    }
   .boxDoor {
     @media all and (max-width: ${maxWidth1170}) {
      top: 100px;
    }
    @media all and (max-width: ${maxTablet}) {
      top: 70px;
    }
   }
  }
  &.sticky-header {
    .swot-sticky {
     border-right: 0px;
     border-bottom: 0px;
      .second-area {
        border-left: none;
      }
      .table-item {
        /* border-right: none; */
        border-bottom: none;
      }
      .sticky-area {
        max-width: 200px;
          @media all and (max-width: ${smMobile}) {
            max-width: 170px;
          }
        &.table-item {
          padding: 0px;
          .swotHeadSticky {
            position: sticky;
            //these two for safari
            position: -webkit-sticky;
            display: block;
            top: 180px;
            /* z-index: 2; */
          }
        }
      }
    }
    .header-table {
      position: sticky;
      top: 80px;
      width: 100%;
      z-index: 1;
      background-color: #fff;
      border-radius: 0px !important;
      border-top: none;
      box-shadow: ${boxShadow};
      border-right: 0px !important;
      .table-item {
        height: 100px;
        border-top: 1px solid rgb(218, 218, 218);
        .heading-item {
          display: flex;
          flex-direction: row-reverse;
          .gif-count-div {
            padding: 0px;
            .count-div {
              height: 30px;
              width: 30px;
              font-size: 14px;
              margin-left: 4px;
            }
          }
          .select-company-modal {
            .graph-animation {
              display: none;
            }
            .add-company-btn-compare {
              margin-top: 0px;
            }
          }
        }
        &:first-child {
          padding: 20px 16px;
          justify-content: center;
          @media all and (max-width: ${maxTablet}) {
            justify-content: end;
            padding: 8px 16px;
          }
        }
        &:last-child {
          border-right: 1px solid rgb(218, 218, 218);
          border-radius: 0px 16px 0px 0px !important;
        }
      }
    }
    .add-company-btn-compare {
      margin-top: 10px;
    }
    .isCompanyTableItem {
      .company-image-parent {
        width: 73px;
        height: 55px;
        border-radius: 10px;
        padding: 2px;
        margin-bottom: 0px;
        @media all and (max-width: ${maxMobile}){
          width: 70px;
          height: 40px;
          border-radius: 4px;
          padding: 2px;
        }
      }
      .logo-image-wrapper {
        @media all and (max-width: ${maxMobile}){
          border-radius: 4px;
        }
      }
      .progress-wrapper {
        width: 95%;
        bottom: 10px;
        div {
          height: 2px;
        }
      }
      .check_label {
        width: auto;
        display: flex;
        flex-direction: column;
      }
      .exceptButtonContent {
        display: flex;
        align-items: center;
        flex-direction: row;
        .company-share  {
          display: none;
        }
        a {
          transform: translateX(-5px);
        }
        .companyName {
        text-align: left;
        font-size: 14px;
          @media all and (max-width: ${maxWidth}) , ${retina} {
          font-size: 12px;
          }
        }
        .company-stats  {
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .company-naves {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding-left: 10px;
        }
      }
      .small-button-chinese {
        @media all and (max-width: ${maxMobile}){
          padding: 5px 10px;
          font-size: 8px;
        }
      }
      .actionTableBtn {
        justify-content: flex-start;
        display: none;
        button {
          margin: 0px;
          @media all and (max-width: ${maxWidth}) , ${retina} {
            margin: 10px 0px 0px 0px;
          }
        }
      }
    }
    .header-main-wrapper {
      box-shadow: none !important;
    }
    .heading-item {
      box-shadow: none;
    }
    .animation-item {
      .animation-gif {
        display: none;
      }
    }
  }
  .companies-length-4 {
    .table-width-decide {
      .header-table {
        .heading-item {
          .select-company-modal {
            .graph-animation {
              bottom: 74px;
              @media all and (max-width: ${maxWidth}), ${retina} {
                bottom: 57px;
              }
              @media all and (max-width: ${maxWidth1300}) {
                bottom: 40px;
              }
              @media all and (max-width: ${maxMobile}) {
                bottom: 25px;
              }
            }
          }
          @media all and (max-width: ${maxWidth1300}) {
            margin-top: 30px;
          }
          @media all and (max-width: ${maxMobile}) {
            margin-top: 40px;
          }
        }
        .animation-div {
          .animation-item{
            .animation-gif {
              top: 12px;
              @media all and (max-width: ${maxWidth}), ${retina} {
                top: 0px;
              }
            }
          }
        }
      }
      .body-table {
        .table-item {
          &:first-child {
            padding-left: 14px;
          }
        }
        .sticky-area {
          &:first-child {
            padding: 0px;
            padding-left: 0px;
          }
        }
      }
    }
  }
`;

const CompanyComparison = function CompanyComparison() {
  const { gilroyStates, gilroyActions } = useGilroy();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { saveUrl: savedUrlV2 } = chatStates;
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const companyId = searchParams.get('companyId');
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const minWidthApp = MinWidthApp();
  const companyComparisonData = useSelector(selectCompanyComparisonData);
  const comparisonRows = useSelector(selectComparisonRows);
  const isComparisonLoading = useSelector(selectisComparisonLoading);
  const showGlobalGilroy = useSelector(selectShowGlobalGilroy);
  const currentUrl = window.location.href;

  useEffect(() => {
    if (!minWidthApp && !isComparisonLoading) {
      scrollHandlerToggleClass('.companyComparePage', 'sticky-header');
    }
  });

  useEffect(() => {
    if (savedUrlV2 !== currentUrl) {
      dispatch(UiAction.isShowGilroyGlobalToggle(false));
      chatActions.setShowLibrary(true);
      chatActions.setShowExamplePrompts(false);
      chatActions.resetChatHistory();
      chatActions.setSelectedConversationId(-1);
      chatActions.setHistoryPanelOpened(false);
    } else if (!companyComparisonData?.length) {
      chatActions.setSaveUrl('');
      chatActions.setSaveProfileName('');
    }
  }, []);

  const toggle = (flag: boolean) => {
    setOpenCreateModal(flag);
  };

  useEffect(() => {
    dispatch(companiesActions.resetCompanyComparison());
    if (companyId) {
      const data = {
        companyIds: companyId,
      };
      dispatch(companiesActions.compareCompanies(data));
    } else {
      dispatch(companiesActions.setIsComparisonRemoved(false));
    }
  }, [companyId, location]);

  return (
    <Wrapper className="companyComparePage">
      <NavBarPrimary headerClassName="headers-space box-shadow  no-overflow-on-header">
        <div className="left-side-wrap smMob-NavBar-Wrapper no-overflow-on-header">
          <div className="items-data">
            <BackIcon onClick={() => { navigate(-1); }} />
            <h1 className="heading">Compare Companies</h1>
          </div>
        </div>
        <div className="right-side-wrap">
          <GlobalSearch iconMode={IsTablet()} />
          <DrawerAlerts />
          <AccountPopOver />
        </div>
      </NavBarPrimary>
      <div className="container">
        <Suspense
          fallback={(
            <CompaniesSectionSkeleton />
          )}
        >
          <CompanyComparisonTable
            companiesData={companyComparisonData}
            comparisonRows={comparisonRows}
            savedUrlV2={savedUrlV2}
          />
        </Suspense>
        <DrawerPeopleGroup
          openNewCreate={openCreateModal}
          setOpenNew={toggle}
          showNew={showNew}
          toggleShowNew={(bool: boolean) => setShowNew(bool)}
          disableDrop
          noButton
        />
      </div>
      {showGlobalGilroy
        ? (
          <>
            <Gilroy gilroyStates={gilroyStates} gilroyActions={gilroyActions} />
          </>
        ) : null}
    </Wrapper>
  );
};

export default CompanyComparison;
