import React, { useEffect } from 'react';
import { Layout as AntLayout } from 'antd';
import {
  isOnboardingRoute, isGuest, isSalesforce, IsTablet, skipOnboarding,
  isGuestfromEmail,
} from 'Utils/UtilityFunctions';
import BottomAccordion from 'Components/certification/components/BottomAccordion';
import OverlayComponent from 'Components/common/components/OverlayComponent';
import { useDispatch, useSelector } from 'react-redux';
import { selectHighlight } from 'Selector/CertificationSelector';
import { HIGHLIGHT_TIME } from 'Utils/Constants';
import styled from 'styled-components';
import FreshDeskAppearanceCheck from 'Components/common/components/FreshDeskAppearanceCheck';
import {
  maxMobile, maxTablet, maxWidth, retina,
} from 'Utils/utilsStyle';
import { selectShowTranslationWidget } from 'Selector/GlobalSearchContentSelector';
import Aside from './asider/Aside';
import { actions as certificationActions } from '../../Slice/CertificationSlice';

type Props = {
  children: object,
};

const Span = styled.span`
  cursor: pointer;
  @media all and (max-width: ${maxMobile}) {
    display: none;
  }
  svg {
   display: flex;
   position: fixed;
   right: 90px;
   top: 28px;
   z-index: 4;
   @media all and (max-width: ${maxWidth}) , ${retina} {
    top: 27px;
   }
    @media all and (max-width: 1440px) and (min-width: 1414px) {
      right: 95px;
    }
    @media all and (max-width: 1414px) and (min-width: 1387px) {
      right: 85px;
    }
    @media all and (max-width: 1387px) and (min-width: 1366px) {
      right: 65px;
    }
    @media all and (max-width: 1366px) and (min-width: 1360px) {
      right: 60px;
    }
    @media all and (max-width: 1360px) and (min-width: 1350px) {
      right: 65px;
    }
    @media all and (max-width: 1350px) and (min-width: 1336px) {
      right: 60px;
    }
     @media all and (max-width: 1336px) and (min-width: 1330px) {
      right: 45px;
    }
    @media all and (max-width: 1335px) and (min-width: 1300px) {
      right: 35px;
    }
    @media all and (max-width: 1300px) and (min-width: 1290px) {
      right: 37px;
    }
    @media all and (max-width: 1291px) and (min-width: 1280px) {
      right: 35px;
    }
    @media all and (max-width: 1280px) and (min-width: 1278px) {
      right: 30px;
    }
    @media all and (max-width: 1278px) and (min-width: 1121px) {
      right: 40px;
    }
    @media all and (max-width: 1121px) and (min-width: ${maxTablet}) {
      right: 35px;
    }
    @media all and (max-width: ${maxTablet}) {
      top: 20px;
      width: 24px;
      right: 30px;
    }
    @media all and (max-width: ${maxMobile}) {
      right: 10px;
    }
  }
`;

const Layout = function Layout(props: Props) {
  const {
    Sider, Content,
  } = AntLayout;
  const { children } = props;
  const dispatch = useDispatch();
  const showOverlay = useSelector(selectHighlight);
  const isGuestUser = isGuest() || isGuestfromEmail();
  useEffect(() => {
    if (showOverlay) {
      setTimeout(() => {
        dispatch(certificationActions.setHighlight(false));
        dispatch(certificationActions.setIsHighlighted(true));
      }, HIGHLIGHT_TIME);
    }
  }, [showOverlay]);

  const showTranslationWidget = useSelector(selectShowTranslationWidget);

  if (!showTranslationWidget && isGuestUser) {
    document.cookie = 'xiqtrans=; expires=Thu, 01 Jan 2000 00:00:00 UTC; path=/;';
  }
  return (
    <AntLayout>
      {IsTablet() || isSalesforce() || isOnboardingRoute() ? null
        : <Sider width={87}><Aside /></Sider>}
      <AntLayout>
        <Content className="content-custom-layout">{children}</Content>
        {skipOnboarding() && !isGuestfromEmail() ? (
          <Span className="freshDeskHandle">
            <FreshDeskAppearanceCheck />
          </Span>
        ) : null}
        <BottomAccordion />
        <OverlayComponent
          overlayWrapper={showOverlay}
        />
      </AntLayout>
    </AntLayout>
  );
};

export default Layout;
