/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */

import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { User } from 'Types/LoginTypes';

import axios from 'Utils/Axios';
import axiosWithoutBaseURL from 'Utils/AxiosWithoutBaseUrl';
import {
  SignUpRequest, TellMeMoreRequest,
  SignUpFormRequest,
  SendVerificationCodeEmailRequest,
  SubmitVerificationCodeRequest,
} from 'Types/RequestBodies';
import { API } from 'Utils/Constants';
import {
  SignUpEmailVerificationResponse, TellMeMoreResponse,
  SendVerificationCodeEmailResponse,
  SubmitVerificationCodeResponse,
} from 'Types/SignUpTypes';
// import { saveUserDetails, getCurrentTime, valueToObject } from 'Utils/UtilityFunctions';
import { saveUserDetails } from 'Utils/UtilityFunctions';
// import { actions as flurryActions } from 'Slice/FlurryEventSlice';
import { actions } from '../Slice/SignUpSlice';

/**
 * User request/response handler
 */
export function* emailVerification(dispatched: PayloadAction<SignUpRequest>) {
  try {
    const response: SignUpEmailVerificationResponse = yield call(
      axios.post,
      API.EMAIL_VERIFICATION,
      dispatched?.payload,
    );
    yield put(actions.emailVerificationResponseRecieved(response));
  } catch (err: unknown) {
    console.log(err);
  }
}

export function* signUpFormReqLCallback(dispatched: PayloadAction<SignUpFormRequest>) {
  try {
    const response: SignUpEmailVerificationResponse = yield call(
      axios.post,
      API.SIGN_UP,
      dispatched?.payload,
    );
    yield put(actions.signUpFormRes(response));
    const user: User = response.response;
    saveUserDetails(user, false);
  } catch (err: unknown) {
    console.log(err);
  }
}

export function* tellMeMore(dispatched: PayloadAction<TellMeMoreRequest>) {
  try {
    const response: TellMeMoreResponse = yield call(
      axiosWithoutBaseURL.post,
      'https://dev-publish.xiqonline.com/publish/send_email_support/',
      dispatched?.payload,
    );
    yield put(actions.tellMeMoreResponse(response));
  } catch (err: unknown) {
    console.log(err);
  }
}

export function* submitVerificationCode(dispatched: PayloadAction<SubmitVerificationCodeRequest>) {
  try {
    const response: SubmitVerificationCodeResponse = yield call(
      axios.post,
      API.SUBMIT_VERIFICATION_CODE,
      dispatched.payload,
    );
    yield put(actions.submitVerificationCodeResponse(response));
  } catch (err: unknown) {
    console.log(err);
  }
}

export function* sendVerificationCodeEmail(
  dispatched: PayloadAction<SendVerificationCodeEmailRequest>,
) {
  try {
    const response: SendVerificationCodeEmailResponse = yield call(
      axios.post,
      API.SEND_EMAIL_VERIFICATION_CODE,
      dispatched.payload,
    );
    yield put(actions.sendVerificationCodeEmailResponse(response));
  } catch (error: unknown) {
    console.log(error);
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* userAuthSaga() {
  // Watches for loadUser actions and calls getUser when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.emailVerification.type, emailVerification);
  yield takeLatest(actions.signUpFormReq.type, signUpFormReqLCallback);
  yield takeLatest(actions.tellMeMore.type, tellMeMore);
  yield takeLatest(actions.sendVerificationCodeEmail, sendVerificationCodeEmail);
  yield takeLatest(actions.submitVerificationCode, submitVerificationCode);
}
