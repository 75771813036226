import React from 'react';
import styled from 'styled-components';
import {
  maxWidth, primaryFont, retina,
} from 'Utils/utilsStyle';

type Props = {
  title?: string,
  padding?: string,
  margin?: string,
  bgColor?: string,
  fontSize?: string,
  fontSizeSm?: string,
} & typeof defaultProps;

const defaultProps = {
  title: 'Influencer',
  padding: '10px 20px',
  margin: '0px 10px',
  bgColor: '#E79540',
  fontSize: '26px',
  fontSizeSm: '19px',
};

const Span = styled.span<{ padding: string, margin: string, bgColor: string, fontSize: string, fontSizeSm: string }>`
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  background-color: ${(props) => props.bgColor};
  font-size: ${(props) => props.fontSize};
  font-family: none;
  color: #fff;
  border-radius: 25px;
  display: inline-block;
  font-family: ${primaryFont};
  @media all and (max-width: ${maxWidth}) , ${retina} {
    font-size: ${(props) => props.fontSizeSm};
  }
`;
const PersonalityPills = function PersonalityPills(props: Props) {
  const {
    title, padding, margin, bgColor, fontSize, fontSizeSm,
  } = props;
  return (
    <Span
      padding={padding}
      margin={margin}
      bgColor={bgColor}
      fontSize={fontSize}
      fontSizeSm={fontSizeSm}
    >
      {title}
    </Span>
  );
};
PersonalityPills.defaultProps = defaultProps;
export default PersonalityPills;
