/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  call, put, takeEvery,
} from 'redux-saga/effects';
import { actions } from 'Slice/HelperSlice';
import { PayloadAction } from '@reduxjs/toolkit';

import axios from 'Utils/Axios';
import { actions as LogoutActions } from 'Slice/LogoutSlice';
import { API } from 'Utils/Constants';
import { FlurryEventRequest } from 'Types/RequestBodies';
import { GlobalResponse } from 'Types/ResponseBodies';

export function* callFlurryEvent(dispatched: PayloadAction<FlurryEventRequest>) {
  try {
    const payload = dispatched?.payload;
    const response :GlobalResponse = yield call(
      axios.post,
      API.FLURRY_EVENTS_API,
      payload,
    );
    if (response.status) {
      if (dispatched.payload.eventname === 'user_logout') {
        yield put(LogoutActions.setUserLogout(response?.message));
      }
    }
  } catch (err) {
    console.log(err);
  }
}
export function* flurryEventSaga() {
  yield takeEvery(actions.callFlurryEvent.type, callFlurryEvent);
}
