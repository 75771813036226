/* eslint-disable func-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/indent */
import React, {
    createContext, useContext, useEffect, useState,
} from 'react';
import {
    PublicClientApplication,
    AuthenticationResult,
    AccountInfo,
    EventType,
} from '@azure/msal-browser';
import { msalConfig } from '../config/authConfig';

interface MsalContextType {
    instance: PublicClientApplication | null;
    accounts: AccountInfo[];
    inProgress: boolean;
}

const MsalContext = createContext<MsalContextType>({
    instance: null,
    accounts: [],
    inProgress: false,
});

export const useMsal = () => useContext(MsalContext);

export const MsalProvider: React.FC<{ children: React.ReactNode }> = function ({
    children,
}) {
    const [instance, setInstance] = useState<PublicClientApplication | null>(
        null,
    );
    const [accounts, setAccounts] = useState<AccountInfo[]>([]);
    const [inProgress, setInProgress] = useState<boolean>(false);

    useEffect(() => {
        const msalInstance = new PublicClientApplication(msalConfig);
        setInstance(msalInstance);

        msalInstance.initialize().then(() => {
            // Account selection logic is app dependent. Adjust as needed for different use cases.
            const accounts = msalInstance.getAllAccounts();
            setAccounts(accounts);

            msalInstance.addEventCallback(async (event) => {
                if (event.eventType === EventType.LOGIN_SUCCESS) {
                    const result = await event.payload as AuthenticationResult;
                    console.log('Res', result);
                    setAccounts(msalInstance.getAllAccounts());
                }
            });
        });
    }, []);

    return (
        <MsalContext.Provider value={{ instance, accounts, inProgress }}>
            {children}
        </MsalContext.Provider>
    );
};
