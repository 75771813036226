/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable no-param-reassign */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { HelperState } from 'Types/GlobalTypes';
import { FlurryEventRequest, ToasterNotification } from 'Types/RequestBodies';

export const initialState: HelperState = {
  message: '',
  proTip: false,
  rightPlacement: false,
  groupId: 0,
  classnameFlag: false,
  certification: false,
  showWarning: false,
  type: '',
  companyId: 0,
  getContainerEle: '',
  accountPlanFlag: false,
  className: '',
  meetingLink: '',
};

const helperSlice = createSlice({
  name: 'helper',
  initialState,
  reducers: {

    callFlurryEvent(state, action: PayloadAction<FlurryEventRequest>) {
    },
    setNotification(state, action: PayloadAction<ToasterNotification>) {
      const {
        message, proTip, rightPlacement, groupId, classnameFlag, certification,
        type, companyId, getContainerEle, showWarning, accountPlanFlag, className, meetingLink,
      } = action.payload;
      state.message = message;
      state.proTip = proTip;
      state.rightPlacement = rightPlacement;
      state.groupId = groupId;
      state.classnameFlag = classnameFlag;
      state.showWarning = showWarning;
      state.certification = certification ?? false;
      state.accountPlanFlag = accountPlanFlag ?? false;
      state.type = type ?? '';
      state.companyId = companyId ?? 0;
      state.getContainerEle = getContainerEle;
      state.accountPlanFlag = accountPlanFlag ?? false;
      state.className = className ?? '';
      state.meetingLink = meetingLink ?? '';
    },
  },

});

export const { actions, reducer, name: sliceKey } = helperSlice;
