import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  primaryFont, wMedium, maxWidth, retina, maxMobile,
  maxTablet,
  minExtraLargeScreen,
  secondaryFont,
  primaryHeadingColor,
} from '../../../Utils/utilsStyle';

const HeadingWrapper = styled.span`
  &.global-search-heading {
    color: #001760;
    font-family: ${primaryFont};
    font-size: 18px;
    font-weight: ${wMedium};
    line-height: 32px;
    letter-spacing: -0.7px;
    @media all and (min-width: ${minExtraLargeScreen}) {
      font-size: 22px;
      line-height: 24px;
      letter-spacing: -1.005px;
    }
    @media all and (max-width: ${maxWidth}) , ${retina} {
      font-size: 18px;
      line-height: 17px;
      letter-spacing: -0.7px;
    }
    @media all and (max-width: ${maxTablet}) {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.548px;
    }
    @media all and (max-width: ${maxMobile}) {
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.391px;
      padding-left: 0px;
    }
  }
  &.similar-people-heading {
    font-size: 24px;
    font-weight: ${wMedium};
    font-family: ${secondaryFont};
    letter-spacing: -0.55px;
    color: ${primaryHeadingColor};
    @media all and (max-width: ${maxTablet}){
      font-size: 16px;
    }
  }
  font-family: ${primaryFont};
  font-size: 18px;
  font-weight: ${wMedium};
  line-height: 32px;
  color: #001760;
  letter-spacing: -0.7px;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    font-size: 20px;
    line-height: 34px;
  }
  @media all and (max-width: ${maxMobile}){
    font-size: 16px;
    line-height: 20px;
    padding-left: 10px;
  }
  `;

type Props = {
  children: PropTypes.ReactNodeLike,
  customClassName?: string,
} & typeof defaultProps;

const defaultProps = {
  customClassName: '',
};

const SectionHeading = function SectionHeading(props: Props) {
  const { children, customClassName } = props;
  return (
    <HeadingWrapper className={classNames(`${customClassName}`)}>{children}</HeadingWrapper>
  );
};
SectionHeading.defaultProps = defaultProps;
export default SectionHeading;
