/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SearchResultSection, SearchResultsRequest } from 'Types/GlobalSearchResultsTypes';
import { ProfileConfirmationState } from 'Types/GlobalTypes';
import { Executive } from 'Types/PersonTypes';

export const initialState: ProfileConfirmationState = {
  popupVisible: false,
  peopleLoading: false,
  results: [],
  searchError: '',
  page: 1,
  searchEnded: false,
  confirmedPerson: {} as Executive,
  confirmedPersonCount: 0,
  confirmedPersonLinkedinCount: 0,
  confirmSuccess: false,
  confirmFail: false,
  confirming: false,
  linkedinPeopleLoading: false,
  linkedinSearchError: '',
  linkedinSearchEnded: false,
  mappedPersonId: 0,
};

const ProfileConfirmationSlice = createSlice({
  name: 'profileConfirmation',
  initialState,
  reducers: {
    setPopupVisible(state, action: PayloadAction<boolean>) {
      const { payload } = action;
      state.popupVisible = payload;
    },
    getSearchPeople(state, action: PayloadAction<SearchResultsRequest>) {
      state.peopleLoading = true;
      state.linkedinPeopleLoading = true;
      const { page } = action.payload;
      if (page === 1) {
        state.results = [];
        state.page = 1;
      }
    },
    setSearchPeople(state, action: PayloadAction<SearchResultSection[]>) {
      if (state.page === 1) {
        state.results = action.payload[0]?.persons ?? [];
      } else {
        state.results = [...state.results, ...action.payload[0]?.persons ?? []];
      }
      state.page += 1;
      state.searchEnded = !action.payload.length;
      state.searchError = '';
      state.peopleLoading = false;
      state.confirmedPersonCount = action.payload[0]?.count;
    },
    setLinkedinSearchPeople(state, action: PayloadAction<Executive[]>) {
      if (action.payload.length) {
        state.results = [...state.results, ...action.payload];
      } else {
        state.linkedinSearchEnded = true;
      }
      state.linkedinSearchError = '';
      state.linkedinPeopleLoading = false;
      state.confirmedPersonLinkedinCount = action.payload.length;
    },
    setSearchError(state, action: PayloadAction<string>) {
      state.peopleLoading = false;
      state.searchError = action.payload;
    },
    setLinkedinSearchError(state, action: PayloadAction<string>) {
      state.linkedinPeopleLoading = false;
      state.linkedinSearchError = action.payload;
    },
    setConfirmedPerson(state, action: PayloadAction<Executive>) {
      state.confirming = true;
      state.confirmedPerson = action.payload;
      state.confirmFail = false;
      state.confirmSuccess = false;
    },
    setConfirmSuccess(state, action: PayloadAction<boolean>) {
      state.confirming = false;
      state.confirmSuccess = action.payload;
    },
    setConfirmFail(state, action: PayloadAction<boolean>) {
      state.confirming = false;
      state.confirmFail = action.payload;
    },
    resetSearchResults(state) {
      state.results = [];
      state.page = 1;
      state.confirmFail = false;
      state.confirmSuccess = false;
      state.confirmedPerson = {} as Executive;
    },
    setMappedPersonId(state, action: PayloadAction<number>) {
      state.mappedPersonId = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = ProfileConfirmationSlice;
