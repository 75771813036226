import type { InputRef } from 'antd';
import React, { useRef } from 'react';

export type FocusRefObj = {
  htmlElementRef: React.RefObject<InputRef>,
  setFocus: () => void
};

export const useFocus = () => {
  const htmlElementRef = useRef<InputRef | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const setFocus = () => {
    if (htmlElementRef && htmlElementRef.current) { htmlElementRef?.current?.focus(); }
  };

  return { htmlElementRef, setFocus };
};
