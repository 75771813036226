/* eslint-disable @typescript-eslint/no-unused-vars */
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Pulse } from 'Types/PulseTypes';
import classNames from 'classnames';
import { Radio } from 'antd';
import { parseColorGradientMixture } from '../../../Utils/UtilityFunctions';
import { CompanyPersonality } from '../../../Types/CompaniesTypes';
import HeatMapProgress from './HeatMapProgress';
import { maxWidth, retina } from '../../../Utils/utilsStyle';

type Props = {
  persona: CompanyPersonality[],
  logo: string,
  triggerColor: Pulse[]
  checked?: boolean
  selection?: (a: boolean, b: string) => void
  companyId?: string;
  checkBox?: boolean
  isRadio?: boolean
  wrapperClassName?: string
  imgClassName?: string
  backgroundSize?: string
  backgroundPosition?: string
  addCompanyWidget?: boolean
  selectedCount?: number
  isRadioChecked?: boolean
} & typeof defaultProps;

const defaultProps = {
  checkBox: true,
  checked: false,
  isRadio: false,
  wrapperClassName: '',
  imgClassName: '',
  companyId: '',
  selection(a: boolean, b: string) { },
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  addCompanyWidget: false,
  selectedCount: -1,
  isRadioChecked: false,
};

const Wrapper = styled.div<{ color: string }>`
 width: 100%;
 height: 90px;
 border-radius: 10px;
 background:conic-gradient(from -70deg, ${(props) => props.color});
 background-color: ${(props) => props.color};
 position: relative;
 margin: 0px;
 margin-bottom: 15px;

  @media all and (max-width: ${maxWidth}) , ${retina} {
    border-radius: 8px;
  }
  .progress-wrapper{
    padding: 0px 10px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    div {
      height:4px;
    }
  }
  .ant-checkbox{
    position: absolute;
    left: -5px;
    top: -5px;
  }
  .ant-radio-wrapper {
    position: absolute;
    left: -30px;
    top: 18px;
    .ant-radio {
      height: 20px;
      width: 20px;
      .ant-radio-inner {
        &::after {
          transform: scale(.6);
        }
      }
    }
  }
`;

const Image = styled.div<{ image: string, backgroundSize: string, backgroundPosition: string, }>`
 width: 93%;
 height: 90%;
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 border-radius: 7px;
 background-image: url(${(props) => props.image});
 background-position: ${(props) => props.backgroundPosition};
 background-size: ${(props) => props.backgroundSize};
 background-repeat: no-repeat;
 background-color: #fff;
 
  @media all and (max-width: ${maxWidth}) , ${retina} {
    width: 93%;
    height: 90%;
    border-radius:6px;
  }
`;

const CompanyWidgetSmall = function CompanyWidgetSmall(props: Props) {
  const [colors, setColors] = useState<string>('');
  const {
    persona,
    logo,
    triggerColor,
    checked,
    selection,
    companyId,
    checkBox,
    wrapperClassName,
    imgClassName,
    backgroundSize,
    backgroundPosition,
    addCompanyWidget,
    selectedCount,
    isRadio,
    isRadioChecked,
  } = props;

  useEffect(() => {
    if (persona?.length > 0) {
      setColors(parseColorGradientMixture(persona) || '');
    }
  }, [persona]);

  return (
    <Wrapper color={colors} className={classNames(`${wrapperClassName}`, { addCompanyWidgetItem: addCompanyWidget })}>
      <Image className={`image-placeholder ${imgClassName}`} image={logo} backgroundSize={backgroundSize} backgroundPosition={backgroundPosition} />
      {triggerColor.length ? <HeatMapProgress pulse={triggerColor} /> : null}
      {checkBox && !isRadio ? (
        <Checkbox
          checked={checked}
          onChange={(e) => (selection(e.target.checked, companyId))}
        />
      ) : null}
      {isRadio ? (
        <Radio checked={isRadioChecked} />
      ) : null}
    </Wrapper>
  );
};

CompanyWidgetSmall.defaultProps = defaultProps;

export default CompanyWidgetSmall;
