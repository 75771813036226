/* eslint-disable @typescript-eslint/indent */
/* eslint-disable max-len */
import React from 'react';

const FavIcon = function FavIcon() {
  return (
    // <svg width="14px" height="13px" viewBox="0 0 14 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    //   <title>Shape</title>
    //   <g id="Client-Prototype" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    //     <g id="The-Feed-(homepage)-News" transform="translate(-1079.000000, -398.000000)" fill="#7E7E7E" fillRule="nonzero">
    //       <g id="Group-18-Copy" transform="translate(1054.000000, 83.778171)">
    //         <g id="Group-18" transform="translate(0.000000, 183.221829)">
    //           <g id="favorite_border_black_24dp-(1)" transform="translate(23.000000, 129.000000)">
    //             <path d="M12.15,2 C10.932,2 9.763,2.57384196 9,3.48065395 C8.237,2.57384196 7.068,2 5.85,2 C3.694,2 2,3.71444142 2,5.89645777 C2,8.57438692 4.38,10.7564033 7.985,14.0719346 L9,15 L10.015,14.0648501 C13.62,10.7564033 16,8.57438692 16,5.89645777 C16,3.71444142 14.306,2 12.15,2 Z M9.07,13.0163488 L9,13.0871935 L8.93,13.0163488 C5.598,9.96294278 3.4,7.94386921 3.4,5.89645777 C3.4,4.47956403 4.45,3.41689373 5.85,3.41689373 C6.928,3.41689373 7.978,4.11825613 8.349,5.08882834 L9.658,5.08882834 C10.022,4.11825613 11.072,3.41689373 12.15,3.41689373 C13.55,3.41689373 14.6,4.47956403 14.6,5.89645777 C14.6,7.94386921 12.402,9.96294278 9.07,13.0163488 Z" id="Shape" />
    //           </g>
    //         </g>
    //       </g>
    //     </g>
    //   </g>
    // </svg>

    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 217.929 217.929"
      xmlSpace="preserve"
      className="favIcon"
    >
      <g>
        <path
          fill="#7E7E7E"
          d="M212.39,101.703c5.023-4.897,6.797-12.083,4.629-18.755s-7.827-11.443-14.769-12.452l-52.969-7.697
		c-0.097-0.014-0.18-0.075-0.223-0.162L125.371,14.64C122.267,8.349,115.98,4.44,108.964,4.44S95.662,8.349,92.558,14.64
		L68.87,62.637c-0.043,0.087-0.126,0.147-0.223,0.162l-52.968,7.697c-6.942,1.009-12.601,5.78-14.769,12.452
		s-0.394,13.858,4.629,18.755l38.328,37.361c0.07,0.068,0.102,0.166,0.085,0.262l-9.048,52.755
		c-1.186,6.914,1.604,13.771,7.279,17.894c5.676,4.125,13.059,4.657,19.268,1.393l47.376-24.907c0.086-0.046,0.19-0.045,0.276,0
		l47.376,24.907c2.701,1.42,5.623,2.121,8.531,2.121c3.777,0,7.53-1.184,10.736-3.514c5.675-4.123,8.464-10.98,7.279-17.895
		l-9.048-52.754c-0.017-0.096,0.016-0.194,0.085-0.262L212.39,101.703z M156.235,142.368l9.048,52.754
		c0.024,0.14,0.031,0.182-0.118,0.29c-0.149,0.108-0.187,0.088-0.312,0.022l-47.377-24.908c-5.33-2.801-11.695-2.801-17.027,0
		l-47.376,24.907c-0.125,0.065-0.163,0.086-0.312-0.022c-0.149-0.108-0.142-0.15-0.118-0.289l9.048-52.755
		c1.018-5.936-0.949-11.989-5.262-16.194L18.103,88.813c-0.101-0.099-0.132-0.128-0.075-0.303c0.057-0.175,0.099-0.181,0.239-0.202
		l52.968-7.697c5.961-0.866,11.111-4.607,13.776-10.008l23.688-47.998c0.063-0.126,0.081-0.165,0.265-0.165s0.203,0.039,0.265,0.165
		l23.688,47.998c2.666,5.401,7.815,9.143,13.776,10.008l52.968,7.697c0.14,0.021,0.182,0.027,0.239,0.202
		c0.057,0.175,0.026,0.205-0.075,0.303l-38.328,37.361C157.185,130.378,155.218,136.432,156.235,142.368z"
        />
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
};

export default FavIcon;
