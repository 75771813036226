/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  call, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { actions } from 'Slice/ArticlesSlice';
import { actions as AlertAction } from 'Slice/AlertsSlice';

import { PayloadAction } from '@reduxjs/toolkit';

import axios from 'Utils/Axios';

import { API } from 'Utils/Constants';
import { ArticleDetailRequest, ArticleSummaryRequest, SimilarArticleRequest } from 'Types/RequestBodies';
import { ArticleDetailResponse, DeepLinkResponse, SimilarArticleResponse } from 'Types/ArticleTypes';
import { getCurrentTime, valueToObject } from 'Utils/UtilityFunctions';
import { actions as branchActions } from 'Slice/BranchSlice';
import { actions as flurryActions } from 'Slice/HelperSlice';
import _ from 'lodash';
import { ArticleSummaryResponse } from 'Types/ResponseBodies';

export function* getArticleDetail(dispatched: PayloadAction<ArticleDetailRequest>) {
  const startTime = getCurrentTime();
  try {
    const { payload } = dispatched;
    const { isSimilarArticle, ...parameters } = { ...payload };
    if ('source' in parameters) {
      _.unset(parameters, 'source');
    }
    const response: ArticleDetailResponse = yield call(
      axios.post,
      API.ARTICLE_DETAIL,
      parameters,
    );

    if (response?.status === true && parameters.articleid) {
      if (isSimilarArticle) {
        yield put(actions.setSimilarArticleDetail(response));
      } else {
        yield put(actions.setArticleDetail(response));
      }

      yield put(AlertAction.isReadAlert(response.response.articleId));
      yield put(actions.isRead(response.response.articleId));
      yield put(flurryActions.callFlurryEvent(
        valueToObject(
          'article_page_load',
          startTime,
          {
            article_id: response.response.articleId,
            article_title: response.response.title,
            alert_id: response.response.alerts[0].alertId,
            article_link: response.response.url,
            article_entity_name: payload.isPushNotification ? 'PushNotification' : 'SALES TRIGGERS',
            ...(payload?.isPushNotification && !payload?.source && {
              source: 'PushNotification',
            }),
            ...(payload?.source && {
              source: payload?.source,
            }),
          },
        ),
      ));
      if (payload?.source) { yield put(branchActions.reset()); }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getSimilarArticle(dispatched: PayloadAction<SimilarArticleRequest>) {
  try {
    const { payload } = dispatched;
    const { isSimilarArticle, ...parameters } = { ...payload };
    const response: SimilarArticleResponse = yield call(
      axios.post,
      API.SIMILAR_ARTICLES,
      parameters,
    );

    if (response?.status === true && payload.article_id) {
      const data = {
        ...response.response,
        offset: payload?.offset || 0,
      };
      if (isSimilarArticle) {
        yield put(actions.setSimilarArticlesTwo(data));
      } else {
        yield put(actions.setSimilarArticles(data));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getArticleDeeplink(dispatched: PayloadAction<number>) {
  const startTime = getCurrentTime();
  try {
    const data = {
      articleid: dispatched?.payload,
    };
    const response: DeepLinkResponse = yield call(
      axios.post,
      API.GET_ARTICLE_DEEPLINK,
      data,
    );

    if (response?.status === true) {
      yield put(actions.setArticleDeepLink(response));
      yield put(flurryActions.callFlurryEvent(
        valueToObject(
          'share_options',
          startTime,
          {},
        ),
      ));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getArticleSummary(dispatched: PayloadAction<ArticleSummaryRequest>) {
  try {
    const { isArticleItem, isSimilarArticle, ...payload } = dispatched.payload;
    const response: ArticleSummaryResponse = yield call(
      axios.post,
      API.ARTICLE_SUMMARY,
      payload,
    );

    if (response?.status === true) {
      const data = {
        articleId: payload.articleId || 0,
        isArticleItem,
        isSimilarArticle,
        summary: response?.response?.summary,
      };
      yield put(actions.setArticleSummary(data));
    } else {
      yield put(actions.setArticleSummaryLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* ArticleDetailSaga() {
  yield takeLatest(actions.getArticle.type, getArticleDetail);
  yield takeLatest(actions.getArticleDeepLink.type, getArticleDeeplink);
  yield takeLatest(actions.getSimilarArticle.type, getSimilarArticle);
  yield takeEvery(actions.getArticleSummary.type, getArticleSummary);
}
