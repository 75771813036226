import React from 'react';
import styled from 'styled-components';
import { maxTablet } from 'Utils/utilsStyle';
import XiqSkeletonSvg from '../assets/svgIcons/XiqSkeletonSvg';

const Wrapper = styled.div<{ articleHeight: string }>`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* background-color:#fff; */
  box-shadow: 0px 0px 24px rgb(0 0 0 / 10%);
  border-radius:8px;
  margin-bottom:13px;
  min-height: calc(100% - 13px);
  height: 100%;
   .title-box {
     height: 20px;
     border-radius: 25px;
     width: 85%;
     margin-bottom: 10px;
     margin-top: 15px;
     margin-left:15px;
   }
   .title-box-short{
     width: 50%;
     height: 20px;
     border-radius: 25px;
     margin-bottom: 12px;
     margin-left:15px;
   }
   span.image-box { 
      min-height: ${(props) => props.articleHeight};
      width: 100%;
      border-radius: 10px 10px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction:row;
      @media all and (max-width: ${maxTablet}) {
        min-height: 150px;
      }
      svg {
        opacity: 0.3;
      }
   }
`;

type Props = {
  articleHeight?: string,
} & typeof defaultProps;

const defaultProps = {
  articleHeight: '188px',
};

const ArticleHeroSkeleton = function ArticleHeroSkeleton(props: Props) {
  const { articleHeight } = props;
  return (
    <Wrapper articleHeight={articleHeight}>
      <span className="skeleton-box image-box">
        <XiqSkeletonSvg />
      </span>
      <span className="skeleton-box title-box" />
      <span className="skeleton-box title-box-short" />
    </Wrapper>
  );
};
ArticleHeroSkeleton.defaultProps = defaultProps;
export default ArticleHeroSkeleton;
