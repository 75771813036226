import {
  useState, useEffect, SetStateAction, Dispatch,
} from 'react';
import Cookies from 'js-cookie';

type SetValue = Dispatch<SetStateAction<string>>;

export const useCookie = (key: string, initialValue: string): [string, SetValue] => {
  const [value, setValue] = useState(() => {
    const cookieValue = Cookies.get(key);
    return cookieValue !== undefined ? cookieValue : initialValue;
  });

  const { hostname } = window.location;

  const date = new Date(new Date().getTime());

  const opts = {
    expires: new Date(date.setMonth(date.getMonth() + 6)), // 6 months
    domain: '.xiqonline.com',
    secure: true,
    sameSite: 'None' as const,
  };

  useEffect(() => {
    if (hostname === 'localhost') {
      Cookies.set(key, value);
    } else {
      Cookies.set(key, value, opts);
    }
  }, [key, value]);

  return [value, setValue];
};
