import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'Store/InjectorsTypes';
import { initialState } from 'Slice/AlertsSlice';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state?.alerts || initialState;
export const selectShowAlertsSetting = createSelector([selectDomain], (state) => state.toggle);
export const selectArticles = createSelector([selectDomain], (state) => state.articles);
export const selectLoading = createSelector([selectDomain], (state) => state.articlesLoading);
export const selectNotificationPulse = createSelector(
  [selectDomain],
  (state) => state.notificationPulse,
);
export const selectOpenDrawer = createSelector(
  [selectDomain],
  (state) => state.openDrawer,
);
export const selectSelectedTriggers = createSelector(
  [selectDomain],
  (state) => state.selectedFilters,
);
export const selectSelectedPeopleTriggers = createSelector(
  [selectDomain],
  (state) => state.selectedPeopleFilters,
);
export const selectCompaniesSelectionToggle = createSelector(
  [selectDomain],
  (state) => state.selectAllCompanies,
);
export const selectUnSelectedCompanies = createSelector(
  [selectDomain],
  (state) => state.unSelectedCompanies,
);
export const selectSelectedCompanies = createSelector(
  [selectDomain],
  (state) => state.selectedCompanies,
);
export const selectPage = createSelector([selectDomain], (state) => state.articlesPage);
export const selectArticlesEnded = createSelector([selectDomain], (state) => state.articlesEnded);

export const selectPeopleNotification = createSelector(
  [selectDomain],
  (state) => state.peopleNotifications,
);
export const selectPeopleMessage = createSelector([selectDomain], (state) => state.peopleMessage);
export const selectnNotificationLoading = createSelector(
  [selectDomain],
  (state) => state.notificationsLoading,
);
export const selectNotificationPage = createSelector(
  [selectDomain],
  (state) => state.notificationsPage,
);
export const selectNotificationsEnded = createSelector(
  [selectDomain],
  (state) => state.notificationsEnded,
);
