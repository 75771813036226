import {
  getAppName, resetOnlogout, setLocalStorageValue,
} from 'Utils/UtilityFunctions';
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getLocalStorageValue, objToFormData } from './UtilityFunctions';
import { API } from './Constants';

const instance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 900 * 1000,
  withCredentials: true,
});

instance.interceptors.request.use(
  async (req: AxiosRequestConfig | any) => {
    req.headers['X-CSRFToken'] = getLocalStorageValue('csrfToken');
    const username = getLocalStorageValue('email');
    if (username && getLocalStorageValue('csrfToken')) {
      if (!req.data) {
        req.data = {};
      }
      if (!req.data.username) {
        req.data.username = getLocalStorageValue('email');
      }
    }
    req.data.appName = getAppName(); // will add a util function to change the source/app name LATER

    // Only convert to FormData if the method is not GET
    if (req.method !== 'get') {
      req.data = objToFormData(req.data);
    }

    return req;
  },
  async (err: any) => Promise.reject(err),
);

instance.interceptors.response.use(
  async (resp: AxiosResponse) => resp?.data,
  async (error: any) => {
    if (error.response.status === 403) {
      if (error.response.config.url === API.USER_PROFILE) {
        resetOnlogout(window.location.pathname + window.location.search, false, true);
      } else {
        resetOnlogout(window.location.pathname + window.location.search);
      }
    }
    if (error.response.status === 504
      && (error.response.config.url === API.GENERATE_EMAIL
      || error.response.config.url === API.COMPANY_CHATBOT
      || error.response.config.url === API.DOCUMENT_HUB_CHATBOT
      || error.response.config.url === API.BUYER_GROUP_CHATBOT)) {
      setLocalStorageValue('timeout', 'true');
      window.dispatchEvent(new Event('storage'));
    }
    return Promise.reject(error.response.data);
  },
);

export default instance;
