import { Col, Row } from 'antd';
import { SlicerResultPage } from 'Utils/UtilityFunctions';
import PersonSkeleton from './PersonSkeleton';

type Props = {
  count?: number;
  sliceFlag?: boolean;
  inGlobalSearch?: boolean
};

const defaultProps: Props = {
  count: 4,
  sliceFlag: false,
  inGlobalSearch: false,
};

const SearchPersonSkeletonBlock = function SearchPersonSkeletonBlock(props: Props) {
  const {
    count,
    sliceFlag,
    inGlobalSearch,
  } = props;
  const slicerGroupNumber = SlicerResultPage();

  const skeletonArray = Array.from({ length: 12 }, (_, index) => index + 1);
  const arrayLength = !sliceFlag ? 12 : slicerGroupNumber;
  return (
    <Row gutter={[32, 8]} className="search-page-people-skeleton Grid-width-row gridUpdated col-six-fix-md">
      {count === 4 ? skeletonArray.slice(0, arrayLength).map((item) => (
        <Col span={4} xs={6} sm={6} md={4} className="gutter-row" key={item}>
          <PersonSkeleton inGlobalSearch={inGlobalSearch} />
        </Col>
      )) : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].slice(0, count).map(() => (
        <Col span={4} xs={6} sm={6} md={4} className="gutter-row">
          <PersonSkeleton />
        </Col>
      ))}
    </Row>
  );
};

export default SearchPersonSkeletonBlock;
SearchPersonSkeletonBlock.defaultProps = defaultProps;
