/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { actions } from 'Slice/AlertsSlice';
import { actions as SearchContentActions } from 'Slice/GlobalSearchContentSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'Utils/Axios';
import { API } from 'Utils/Constants';
import {
  AlertsArticlesRequest, AlertsArticlesResponse,
  NotificationAlertRequest, NotificationAlertsResponse, NotificationOpendResponse,
  NotificationOpenedRequest,
} from 'Types/CompaniesTypes';

export function* getAlertArticles(dispatched: PayloadAction<AlertsArticlesRequest>) {
  try {
    const payload = dispatched?.payload;
    const data: object = {
      page: payload.page,
      ...payload.filter.length > 0 && { filter: payload.filter.map((it) => it.filterId).join(',') },
      ...payload.company.length > 0 && { companyids: payload.company.map((c) => c.companyId).join(',') },
      resetcount: 'True',
    };
    const response: AlertsArticlesResponse = yield call(
      axios.post,
      API.GET_NOTIFICATIONS_ALERTS,
      data,
    );
    if (response?.status === true) {
      if (dispatched?.payload?.page !== 1) {
        yield put(actions.setMoreAlertArticles(response?.response));
      } else {
        yield put(actions.setAlertArticles(response?.response));
        yield put(SearchContentActions.updateArticleCount(response?.response?.notificationCount));
      }
      if (response?.response?.articles?.length < 1) {
        yield put(actions.setArticlesEnded());
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getNotificationAlerts(dispatched: PayloadAction<NotificationAlertRequest>) {
  try {
    const payload = dispatched?.payload;
    const data: object = {
      username: payload.username,
      page: payload.page,
      ...payload.filter.length > 0 && { filter: payload.filter.map((it) => it.filterId).join(',') },
    };
    const response: NotificationAlertsResponse = yield call(
      axios.post,
      API.GET_PERSON_DESIGNATION_ALERT,
      data,
    );
    if (response?.status === true) {
      if (dispatched?.payload?.page !== 1) {
        yield put(actions.setMoreNotificationAlerts(response?.response));
      } else if (response.response.notifications.length) {
        yield put(actions.setNotificationAlerts(response?.response));
        yield put(SearchContentActions.updatePeopleCount(response?.response?.notificationCount));
      } else {
        yield put(actions.setNotificationMessage(response?.message));
      }
      if (response?.response?.notifications?.length < 1) {
        yield put(actions.setNotificationAlertsEnded());
      }
    } else {
      yield put(actions.notificationAlertsError());
    }
  } catch (error) {
    yield put(actions.notificationAlertsError());
    console.log('error', error);
  }
}

export function* getNotificationOpened(dispatched: PayloadAction<NotificationOpenedRequest>) {
  try {
    const payload = dispatched?.payload;
    const response: NotificationOpendResponse = yield call(
      axios.post,
      API.GET_NOTIFICATION_OPENED,
      payload,
    );
    if (response?.status === true) {
      yield put(actions.setNotificationOpened(payload.notificationID));
    }
  } catch (error) {
    console.log('error', error);
  }
}

export function* alertsSaga() {
  yield takeLatest(actions.getAlertArticles.type, getAlertArticles);
  yield takeLatest(actions.getNotificationAlerts.type, getNotificationAlerts);
  yield takeLatest(actions.getNotificationOpened.type, getNotificationOpened);
}
