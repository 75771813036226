import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Modal, Popover, Spin, notification,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'Slice/LogoutSlice';
import { isActive, isGuest } from 'Utils/UtilityFunctions';
import CrossCircle from 'Components/common/assets/svgIcons/CrossCircle';

import { actions as helperActions } from 'Slice/HelperSlice';
import { certificationFlag } from 'Selector/HelperSelector';
import { selectHighlight } from 'Selector/CertificationSelector';
import { ONBOARDING_ROUTE } from 'Utils/Constants';
import {
  secondaryFont, wMedium, primaryColor, primaryFont, primaryHeadingColor, wBold, wLight, maxMobile,
} from '../../../Utils/utilsStyle';
import Logo from '../assets/Logo';
import Logout from '../assets/Logout';
import NavigationLinks from './NavigationLinks';

const Wrapper = styled.aside`
&.documentHighlight {
  z-index: 5;
  .logo-app {
    opacity: 0.4;
  }
  .nav-list {
    .item {
      z-index: 0;
      &.active {
        opacity: 0.4;        
      }
      &.highlightedComponent {
        z-index: 999;
      }
    }
  }
  .nav-footer {
    .sign-out {
      .app-name {
        opacity: 0.4;
      }
    }
    .ant-popover-inner{
      width: max-content;
      .ant-popover-inner-content{
        span{
          white-space: nowrap;
        }
      }
    }
  }
}
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100%;
  .logo-app {
    text-align: center;
    margin-top: 26px;
    svg {
      margin-top: 0px;
      height: 28px;
      width: auto;
    }
  }
  .app-name {
    font-size:17px;
    font-weight:${wMedium};
    font-family:${secondaryFont};
    color:${primaryColor};
    min-width: 140px;
    display: block;
    position: relative;
    transform: rotate(-90deg);
    word-break: break-all;
    
  }
  .nav-footer {
   text-align:center;
    svg{
      width: 18px;
      height: auto;
      margin: 10px 0px;
      cursor: pointer;
    }
    &.guestFooterNav {
      margin-bottom: 30px;
    }
    .ant-popover-inner{
      width: max-content;
      .ant-popover-inner-content{
        span{
          white-space: nowrap;
        }
      }
    }
  }
  .sign-out{
    margin: 50px 0px;
  }
  .sales-sigma strong svg{
    height: 9px;
    width: 7px;
    padding-left: 2px;
    margin-top: 0;
  }
`;

const LogOutAction = styled.span`
  font-weight:${wMedium};
  font-family:${secondaryFont};
  font-size:12px;
  color: rgba(0, 0, 0, 0.85);
  display: block;
  padding-bottom: 2px;
  cursor: pointer;
  transition: 0.5s ease all;
  &:hover{ 
    color: ${primaryColor};
  }
`;

const LogoutModal = styled(Modal)`
  .ant-modal-content{
    border-radius: 18px;
    text-align: center;

    .ant-modal-body{
      padding: 30px 0 0 0;
      padding-top: 50px !important;

      h4.heading_logout_popup{
        font-family: ${secondaryFont};
        font-size: 32px;
        letter-spacing: -0.8;
        line-height: 1.469;
        color: ${primaryHeadingColor};
        font-weight: ${wBold};
        margin: 30px 0;
        @media all and (max-width: ${maxMobile}) {
          font-size: 22px;
          line-height: 24px;
          margin: 20px 0;
        }
      }
      .text_logout_popup{
        font-family: ${primaryFont};
        font-size: 20px;
        font-weight: ${wLight};
        letter-spacing: -0.56;
      }
    }
    .ant-modal-footer{
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;
    }
  }
`;

const Aside = function Aside() {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [showNavs, setShowNavs] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();
  const { pathname } = window.location;
  const certification = useSelector(certificationFlag);
  const showOverlay = useSelector(selectHighlight);

  const logout = () => {
    setSpinner(true);
    dispatch(actions.logoutUser({
      deviceToken: '',
    }));
  };

  useEffect(() => {
    if (!certification) {
      notification.destroy();
      dispatch(helperActions.setNotification({
        message: '', proTip: false, rightPlacement: false, groupId: 0, classnameFlag: false,
      }));
    }
    if (!isActive()) setShowNavs(false);
    if (pathname === ONBOARDING_ROUTE && showNavs) setShowNavs(false);
    else if (pathname !== ONBOARDING_ROUTE && !showNavs) setShowNavs(true);
  }, [pathname]);

  const content = (
    <LogOutAction onClick={() => setIsVisible(true)}>Logout</LogOutAction>
  );

  return (
    <Wrapper className={classNames('aside-wrapper', { documentHighlight: showOverlay })}>
      {isGuest() ? <div className="logo-app"><Logo /></div>
        : (
          <span
            className="c-pointer logo-app"
            onClick={() => navigate('/')}
            onKeyPress={() => navigate('/')}
            role="button"
            tabIndex={0}
            aria-label="Back Icon"
          >
            <Logo />
          </span>
        )}

      {showNavs && !isGuest()
        && (
          <div className="nav-list">
            <NavigationLinks />
          </div>
        )}

      <div className={classNames('nav-footer', { guestFooterNav: isGuest() || !isActive() })}>
        <div className="sign-out">
          <span className="app-name">
            Sales Xelerator™
          </span>
        </div>
        {isActive() && !isGuest() && (
          <>
            <LogoutModal
              visible={isVisible}
              onOk={logout}
              onCancel={() => setIsVisible(false)}
              centered
              cancelText="No"
              okText="Yes"
              closeIcon={<CrossCircle />}
              width="530px"
              className="logout-modal-c"
            >
              {spinner
                && (
                  <div className="spinner-layer">
                    <Spin />
                  </div>
                )}
              <h4 className="heading_logout_popup">Log Out</h4>
              <p className="text_logout_popup">Are you sure you want to log out?</p>
            </LogoutModal>
            <Popover
              content={content}
              placement="rightTop"
              trigger="click"
              overlayClassName="logout-tip"
              getPopupContainer={(e) => e}
            >
              <Logout />
              <span className="d-none">logout popover</span>
            </Popover>
          </>
        )}
      </div>

    </Wrapper>

  );
};

export default Aside;
