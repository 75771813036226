import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LogoutState } from 'Types/GlobalTypes';
import { LogoutRequest } from 'Types/RequestBodies';

export const initialState: LogoutState = {
  loading: false,
  errorLogout: '',
  success: false,
  errorReset: '',
};

const userLogoutSlice = createSlice({
  name: 'logout',
  initialState,
  reducers: {
    logoutUser(state, action: PayloadAction<LogoutRequest>) {
      if (action) {
        state.loading = true;
        state.errorLogout = '';
      }
    },
    setUserLogout(state, action: PayloadAction<string>) {
      if (action) {
        state.loading = false;
        state.errorLogout = '';
      }
    },
    logoutError(state, action: PayloadAction<string>) {
      state.errorLogout = action.payload;
      state.loading = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = userLogoutSlice;
