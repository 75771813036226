/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { actions } from 'Slice/FeedActivitiesSlice';
import { PayloadAction } from '@reduxjs/toolkit';

import axios from 'Utils/Axios';

import { API } from 'Utils/Constants';
import {
  FeedActivitiesAllResponse,
  SocialActivitiesResponse,
  NewsActivitiesResponse,
  CompaniesPulseResponse,
} from 'Types/FeedActivitiesTypes';
import { FeedAllTabRequest, FeedNewsTabRequest, FeedSocialTabRequest } from 'Types/RequestBodies/Feed/AllTab';
import { actions as flurryActions } from 'Slice/HelperSlice';
import { getCurrentTime, valueToObject } from 'Utils/UtilityFunctions';
import _ from 'lodash';

export function* getAllActivitiesFeed(dispatched: PayloadAction<FeedAllTabRequest>) {
  const startTime = getCurrentTime();
  try {
    const payload = dispatched?.payload;
    const response: FeedActivitiesAllResponse = yield call(
      axios.post,
      API.GET_FEED_ALL_ACTIVITIES,
      payload,
    );
    if (response?.status === true) {
      if (payload?.page !== 1) {
        yield put(actions.setMoreAllFeedArticles(response));
      } else yield put(actions.setAllActivities(response));
      if (response?.response?.articles?.length < 1) {
        yield put(actions.setArticlesEnded());
      }
      if (payload.page > 1) {
        yield put(actions.setAllActivitiesFlurry({
          ...payload,
          widget_name: 'All Activities',
          loading_time: _.round((getCurrentTime() - startTime)),
        }));
        yield put(flurryActions.callFlurryEvent(
          valueToObject(
            'digest_widget_page_load',
            startTime,
            { page: payload.page },
          ),
        ));
      }
    }
  } catch (err) {
    console.log(err);
  }
}
export function* getSocialActivities(dispatched: PayloadAction<FeedSocialTabRequest>) {
  const startTime = getCurrentTime();
  try {
    const payload = dispatched?.payload;
    const obj = {
      page: payload.page,
      ...payload.filters && {
        filters: payload.filters.filter(
          (item) => item.isSelected,
        ).map((item) => item.subFilterId).join(','),
      },
    };
    const response: SocialActivitiesResponse = yield call(
      axios.post,
      API.GET_SOCIAL_ACTIVITIES,
      obj,
    );

    if (response?.status === true) {
      if (payload?.page !== 1) {
        yield put(actions.setMoreAllSocialArticles(response));
      } else yield put(actions.setSocialActivities(response));

      if (payload?.page !== 1) {
        yield put(flurryActions.callFlurryEvent(
          valueToObject(
            'digest_widget_page_load',
            startTime,
            { page: payload.page },
          ),
        ));
      } else {
        yield put(actions.setCxoTweetsFlurry({
          ...obj,
          widget_name: 'CxO TWEETS',
          loading_time: _.round((getCurrentTime() - startTime), 2),
        }));
      }
    }
  } catch (err) {
    console.log(err);
  }
}
export function* getNewsActivities(dispatched: PayloadAction<FeedNewsTabRequest>) {
  const startTime = getCurrentTime();
  try {
    const payload = dispatched?.payload;
    const obj = {
      page: payload.page,
      ...payload.filters && {
        filters: payload.filters.filter(
          (item) => item.isSelected,
        ).map((item) => item.filterId).join(','),
      },
    };
    const response: NewsActivitiesResponse = yield call(
      axios.post,
      API.GET_NEWS_ACTIVITIES,
      obj,
    );

    if (response?.status === true) {
      if (payload?.page !== 1) {
        yield put(actions.setMoreAllNewsArticles(response));
      } else yield put(actions.setNewsActivities(response));

      if (response?.response?.articles?.length < 1) {
        yield put(actions.setArticlesEnded());
      }
      delete payload.isFilters;

      if (payload?.page !== 1) {
        yield put(flurryActions.callFlurryEvent(
          valueToObject(
            'digest_widget_page_load',
            startTime,
            { page: payload.page },
          ),
        ));
      } else {
        yield put(actions.setSalesTriggersFlurry({
          ...obj,
          widget_name: 'Sales Triggers',
          loading_time: _.round((getCurrentTime() - startTime), 2),
        }));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getFeedPulseBoard(dispatched: PayloadAction<FeedNewsTabRequest>) {
  try {
    const payload = dispatched?.payload;
    const response: CompaniesPulseResponse = yield call(
      axios.post,
      API.GET_FEED_PULSE_BOARD,
      payload,
    );

    if (response?.status === true) yield put(actions.setFeedPulseBoard(response.response));
  } catch (err) {
    console.log(err);
  }
}

export function* feedActivitiesSaga() {
  yield takeLatest(actions.getAllActivities.type, getAllActivitiesFeed);
  yield takeLatest(actions.getSocialActivities.type, getSocialActivities);
  yield takeLatest(actions.getNewsActivites.type, getNewsActivities);
  yield takeLatest(actions.getFeedPulseBoard.type, getFeedPulseBoard);
}
