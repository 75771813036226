import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

type Props = {
  overlayWrapper: boolean,
} & typeof defaultProps;

const defaultProps = {
  overlayWrapper: true,
};

const Wrapper = styled.div`
  display: none;
  &.overlayComponentWrapper {
    position: absolute;
    width: calc(100% + 90px); 
    height: 100%; 
    top: 0;
    left: -90px;
    background-color: rgba(0,0,0,0.5); 
    z-index: 4; 
    display: block;
  }
`;

const OverlayComponent = function OverlayComponent(props: Props) {
  const { overlayWrapper } = props;

  return (
    <Wrapper
      className={classNames('layer-certification', { overlayComponentWrapper: overlayWrapper })}
    />
  );
};

export default OverlayComponent;
