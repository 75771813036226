import styled from 'styled-components';

const Wrapper = styled.div`
  overflow: hidden;
  .skeleton-box{
    height: 18px;
    border-radius: 20px;
    width:80%;
    margin-bottom:10px;
  }
`;

const LineSkeleton = function LineSkeleton() {
  return (
    <Wrapper>
      <span className="skeleton-box" />
    </Wrapper>
  );
};

export default LineSkeleton;
