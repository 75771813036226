import { Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';
import {
  loadingGifV6, primaryFont, primaryHeadingColor, wMedium,
} from 'Utils/utilsStyle';

import CrossCircle from '../assets/svgIcons/CrossCircle';

type Props = {
  loaderGif?: object,
} & typeof defaultProps;

const defaultProps = {
  loaderGif: <span />,
};

const CustomModal = styled(Modal)`
.ant-modal-content {
  .ant-modal-close {
    display: none;
  }
  .ant-modal-body {
    height: 255px;
    padding: 0px;
    padding-top: 0px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .img-loader-popup {
      height: 141px;
      width: 141px;
    }
    .popup-text {
      width: 198px;
      margin-top: 6px;
      color: ${primaryHeadingColor};
      text-align: center;
      font-family: ${primaryFont};
      font-size: 12px;
      font-weight: ${wMedium};
      line-height: 16px;
    }
  }
  .ant-modal-footer {
    display: none;
  }
}  
`;

const CustomLoaderPopup = function CustomLoaderPopup(props: Props) {
  const {
    loaderGif,
  } = props;
  // const [showPopup, setShowPopup] = useState(true);
  console.log(loaderGif);
  // const onClickCancel = () => {
  //   setShowPopup(!showPopup);
  // };
  return (
    <CustomModal
      open
      centered
      width={406}
      closeIcon={<div className="cross-icon-x"><CrossCircle /></div>}
      getContainer=""
      wrapClassName="loader-popups"
      // onCancel={onClickCancel}
      closable
    >
      <img className="img-loader-popup" src={loadingGifV6} alt="img-marketing" />
      <span className="popup-text">Please wait while we summarize the article for sharing</span>
    </CustomModal>
  );
};
CustomLoaderPopup.defaultProps = defaultProps;
export default CustomLoaderPopup;
