/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Checkbox } from 'antd';
import styled from 'styled-components';
import { Params, useParams } from 'react-router-dom';
import {
  primaryFont, wMedium, retina, maxWidth,
} from '../../../Utils/utilsStyle';

type Props = {
  id?: string
  title: string,
  color: string,
  checked?: boolean
  onCheckedChange?: (a: boolean, b: string) => void
} & typeof defaultProps;
const defaultProps = {
  onCheckedChange(a: boolean, b: string) { },
  checked: false,
  id: '',
};
const Span = styled.span<{ bgColor: string }>`
  background-color: ${(props) => props.bgColor};
  height:16px;
  width:16px;
  border-radius:50%;
  display: inline-block;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    height:12px;
    width:12px;
    min-width: 12px;
  }
`;
const Title = styled.span`
  color:#7E7E7E;
  font-size:16px;
  line-height:20px;
  font-family: ${primaryFont};
  font-weight: ${wMedium};
  margin-left:8px;
  display: inline-block;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    font-size: 12px;
  }
`;
const AlertsTriggersCheckBox = function AlertsTriggersCheckBox(props: Props) {
  const {
    id, title, color, checked, onCheckedChange,
  } = props;
  const { section } = useParams<Params>();

  return (
    <Checkbox checked={checked} onChange={(e) => onCheckedChange(e.target.checked, id)}>
      {section !== 'social' && <Span bgColor={color} />}
      <Title className="title-name">{title}</Title>
    </Checkbox>
  );
};
AlertsTriggersCheckBox.defaultProps = defaultProps;
export default AlertsTriggersCheckBox;
