/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { PayloadAction } from '@reduxjs/toolkit';
import {
  call, put, fork, delay, takeLatest,
} from 'redux-saga/effects';
// import { Company } from 'Types/CompaniesTypes';
import { actions as flurryActions } from 'Slice/HelperSlice';
import { getCurrentTime, valueToObject } from 'Utils/UtilityFunctions';
import { AffinitySearchResults, SearchResultSection, SearchResultsRequest } from 'Types/AffinitySearchTypes';
import { PersonLinkedinSearchResponse } from 'Types/GlobalSearchResultsTypes';
import { API } from '../Utils/Constants';
import axios from '../Utils/Axios';
import { actions } from '../Slice/AffinitySearchResultsSlice';

export function* affinitySearchResultsLoading(dispatched: PayloadAction<SearchResultsRequest>) {
  const startTime = getCurrentTime();
  const host = window.location.href;
  try {
    const {
      query, page, screenName, from, sortCompanyId, group_id: groupId, defaultLanguage, inSap,
    } = dispatched.payload;
    const refinedQuery: SearchResultsRequest = {
      query, page, screenName, sortCompanyId, defaultLanguage, inSap: inSap || false,
    };
    if (from || !sortCompanyId) {
      delete refinedQuery.sortCompanyId;
    }
    if (groupId) {
      refinedQuery.group_id = groupId;
    }
    refinedQuery.query = `person:${refinedQuery.query}`;
    const response: AffinitySearchResults = yield call(
      axios.post,
      API.GLOBAL_SEARCH_RESULTS,
      refinedQuery,
    );
    if (response?.status === true) {
      const results: SearchResultSection[] = response?.response?.searchResult;
      if (from === 'buyerSearch') {
        yield put(actions.buyerAffinitySearchResultsLoaded(results));
      } else {
        yield put(actions.affinitySearchResultsLoaded(results));
      }

      yield put(flurryActions.callFlurryEvent(
        valueToObject(
          'salesAlignment_search_person',
          startTime,
          {
            search_query: query,
            screen_name: screenName,
            ...page && { page },
            host,
          },
        ),
      ));
    } else {
      yield put(actions.affinitySearchResultsError(response?.message));
    }
  } catch (err) {
    console.log(err);
    yield put(actions.affinitySearchResultsError('API Error'));
  }
}

export function* affinityPersonLinkedInSearch(dispatched: PayloadAction<SearchResultsRequest>) {
  const abortController = new AbortController();
  const { query, from } = dispatched.payload;
  const refinedPayload = { ...dispatched.payload };
  delete refinedPayload.sortCompanyId;
  const searchTerm = query.includes(':') ? query.split(':')[1].trim() : query;
  refinedPayload.query = searchTerm;
  if (!query) {
    abortController.abort();
  } else {
    const response: PersonLinkedinSearchResponse = yield call(
      axios.post,
      API.GET_PERSON_EXTENDED_SEARCH,
      refinedPayload,
    );
    if (response?.status === true) {
      if (from === 'buyerSearch') {
        yield put(actions.affinityBuyerSearchResultsPersonExtendedLoaded(response?.response));
      } else {
        yield put(actions.affinitySearchResultsPersonExtendedLoaded(response?.response));
      }
    } else {
      yield put(actions.affinitySearchResultsError(response?.message));
    }
  }
}

export function* affinitySearchResultsFlow(dispatched: PayloadAction<SearchResultsRequest>) {
  yield fork(affinitySearchResultsLoading, dispatched);
  delay(3000);
  yield fork(affinityPersonLinkedInSearch, dispatched);
}

export function* useAffinitySearchResultsSaga() {
  yield takeLatest(actions.affinitySearchResultsLoading.type, affinitySearchResultsFlow);
}
