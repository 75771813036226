/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { isEmpty, unset } from 'lodash';

import {
  call, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { actions } from 'Slice/SRISlice';
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'Utils/Axios';

import { API, FILE_DOWNLOAD_SUCCESS } from 'Utils/Constants';
import {
  FollowCompanyRequest, GlobalRequest, UpdatePersonProfileFollow,
  SRITabFilters, SRIOverviewAllRequest,
  GetComplianceRequest,
  ExportComplianceRequest,
} from 'Types/RequestBodies';
import {
  SRIuserOverviewCardResponse,
  SRIUserScoreContainer,
  SRIUserScoreResponse,
  UserOverviewSRIResponse,
  SRIMyEngagementResponse,
  SRIOverviewContainerOverAllResponse,
  TeamLeaderBoardResponse,
  LeaderBoardSummaryResponse,
  TeamReadinessTeamDetailsResponse,
  TeamReadinessGraphResponse,
  TeamReadinessAvgGraphResponse,
  TeamReadinessDetailsResponse,
  TeamReadinessDetailsObject,
  SRIUserScoreRequest,
  SRIuserOverviewCardData,
  SalesSigmaResponse,
} from 'Types/SRITypes';
import { PersonDataResponse } from 'Types/PersonTypes';
import { FollowCompanyResponse } from 'Types/CompaniesTypes';
import { actions as flurryActions } from 'Slice/HelperSlice';
import { getCurrentTime, valueToObject } from 'Utils/UtilityFunctions';
import { ComplianceDashboardResponse, ComplianceExportResponse } from 'Types/ResponseBodies';

export function* getSRICardDetails(dispatched: PayloadAction<SRIUserScoreRequest>) {
  const startTime = getCurrentTime();

  try {
    const payload = dispatched?.payload;
    const response: SRIuserOverviewCardResponse = yield call(
      axios.post,
      API.GET_SRI_USER_OVERVIEW,
      payload,
    );
    if (response?.status === true) {
      const resp: SRIuserOverviewCardData = response.response;
      resp.user_id = dispatched?.payload.user_id;
      yield put(actions.setSRICardDetails(resp));
      const object = { ...payload };
      if (payload.user_id) {
        unset(object, 'user_id');
        object.checked_user = payload.user_id;
      }
      yield put(flurryActions.callFlurryEvent(
        valueToObject('sri_page_load', startTime, object),
      ));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getSRIUserScore(dispatched: PayloadAction<SRIUserScoreRequest>) {
  try {
    const payload = dispatched?.payload;
    const response: SRIUserScoreResponse = yield call(
      axios.post,
      API.GET_SRI_USER_SCORE,
      payload,
    );
    if (response?.status === true) {
      const resp: SRIUserScoreContainer = response.response;
      resp.user_id = dispatched?.payload?.user_id;
      yield put(actions.setSRIUserScore(resp));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getSRIMyEngagement(dispatched: PayloadAction<GlobalRequest>) {
  try {
    const payload = dispatched?.payload;
    const response: SRIMyEngagementResponse = yield call(
      axios.post,
      API.GET_SRI_MY_ENGAGEMENT,
      payload,
    );
    if (response?.status === true) {
      console.log(response);
      yield put(actions.setSRIMyEngagement(response.response));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getSRIOverviewEngagement(dispatched: PayloadAction<SRIUserScoreRequest>) {
  try {
    const payload = dispatched?.payload;
    const response: UserOverviewSRIResponse = yield call(
      axios.post,
      API.GET_SRI_OVERVIEW_ENGAGEMENT,
      payload,
    );
    if (response?.status === true) {
      const { response: { containers } } = response;
      if (containers[0]) {
        const container = containers[0];
        container.user_id = dispatched?.payload?.user_id;
        yield put(actions.setSRIOverviewEngagement(container));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getTeamLeaderBoardGraph(dispatched: PayloadAction<SRITabFilters>) {
  try {
    const payload = dispatched?.payload;
    const response: TeamLeaderBoardResponse = yield call(
      axios.post,
      API.GET_SRI_LEADER_BOARD,
      payload,
    );
    if (response?.status === true) {
      if (!isEmpty(response.response)) {
        const { response: { top_performers_details: { results } } } = response;
        yield put(actions.setTeamLeaderBoardGraph(results || []));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getLeaderBoardSummaryGraph(dispatched: PayloadAction<SRITabFilters>) {
  try {
    const payload = dispatched?.payload;
    const response: LeaderBoardSummaryResponse = yield call(
      axios.post,
      API.GET_LEADER_BOARD_SUMMARY_GRAPH,
      payload,
    );
    if (response?.status === true) {
      if (!isEmpty(response.response)) {
        const { response: { leaderboard_summary: leaderboardSummary } } = response;
        yield put(actions.setLeaderBoardSummaryGraph(leaderboardSummary));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* addPersonToNetwork(dispatched: PayloadAction<UpdatePersonProfileFollow>) {
  const startTime = getCurrentTime();
  try {
    const response: PersonDataResponse = yield call(
      axios.post,
      API.UPDATE_PERSON_PROFILE_FOLLOW,
      dispatched?.payload,
    );
    if (response.status === true) {
      yield put(actions.addPersonToNetworkSuccess(dispatched?.payload));
      if (dispatched?.payload.person_data && dispatched?.payload.person_data.length) {
        yield put(flurryActions.callFlurryEvent(
          valueToObject('person_follow_succeeded', startTime, dispatched?.payload),
        ));
      } else {
        yield put(flurryActions.callFlurryEvent(
          valueToObject('person_unfollow_succeeded', startTime, dispatched?.payload),
        ));
      }
    } else {
      yield put(actions.addPersonToNetworkFailure(dispatched?.payload));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* addCompany(dispatched: PayloadAction<FollowCompanyRequest>) {
  const startTime = getCurrentTime();
  try {
    const response: FollowCompanyResponse = yield call(
      axios.post,
      API.FOLLOW_COMPANIES,
      dispatched?.payload,
    );

    if (response?.status === true) {
      yield put(actions.addCompanySuccess(response?.response));
      yield put(flurryActions.callFlurryEvent(
        valueToObject('add_company_succeeded', startTime, dispatched?.payload),
      ));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getTeamReadinessFiltersDetails(dispatched: PayloadAction<GlobalRequest>) {
  try {
    const payload = dispatched?.payload;
    const response: TeamReadinessTeamDetailsResponse = yield call(
      axios.post,
      API.GET_SRI_USER_TEAM_READINESS_DETAILS,
      payload,
    );
    if (response && response.status === true) {
      if (!isEmpty(response.response)) {
        const { response: resppnseData } = response;
        yield put(actions.setTeamReadinessFiltersData(resppnseData));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getTeamReadinessBeltsGraph(dispatched: PayloadAction<SRITabFilters>) {
  try {
    const payload = dispatched?.payload;
    const response: TeamReadinessGraphResponse = yield call(
      axios.post,
      API.GET_SRI_TEAM_READINESS_BELT_GRAPH,
      payload,
    );
    if (response && response?.status === true) {
      if (!isEmpty(response.response)) {
        const { response: { teamReadinessBelts: leaderboardSummary } } = response;
        yield put(actions.setTeamReadinessBeltsGraph(leaderboardSummary));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getTeamReadinessAvgGraph(dispatched: PayloadAction<SRITabFilters>) {
  try {
    const payload = dispatched?.payload;
    const response: TeamReadinessAvgGraphResponse = yield call(
      axios.post,
      API.GET_SRI_TEAM_READINESS_TEAMS_GRAPH,
      payload,
    );
    if (response?.status === true) {
      if (!isEmpty(response.response)) {
        const { response: result } = response;
        yield put(actions.setTeamReadinessAvgGraph(result));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getTeamReadinessTeamDetails(dispatched: PayloadAction<SRITabFilters>) {
  const startTime = getCurrentTime();

  try {
    const payload = dispatched?.payload;
    const response: TeamReadinessDetailsResponse = yield call(
      axios.post,
      API.GET_SRI_TEAM_READINESS_DETAILS,
      payload,
    );
    if (response?.status === true) {
      if (!isEmpty(response.response)) {
        const responseData: TeamReadinessDetailsObject = response?.response;
        yield put(actions.setTeamReadinessTeamDetails(responseData));
        yield put(flurryActions.callFlurryEvent(
          valueToObject('sri_team_readiness_team_details_loaded', startTime, payload),
        ));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getSRIOverviewCompanyViews(dispatched: PayloadAction<SRIOverviewAllRequest>) {
  try {
    const payload = dispatched?.payload;
    const response: SRIOverviewContainerOverAllResponse = yield call(
      axios.post,
      API.GET_SRI_OVERVIEW_COMPANY_VIEW,
      payload,
    );
    if (response?.status === true) {
      const { response: { containers } } = response;
      if (containers[0]) {
        const container = containers[0];
        container.user_id = payload?.user_id;
        yield put(actions.setSRICompanyGraph(container));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getSRIOverviewPeopleViews(dispatched: PayloadAction<SRIOverviewAllRequest>) {
  try {
    const payload = dispatched?.payload;
    const response: SRIOverviewContainerOverAllResponse = yield call(
      axios.post,
      API.GET_SRI_OVERVIEW_PEOPLE_VIEW,
      payload,
    );
    if (response?.status === true) {
      const { response: { containers } } = response;
      if (containers[0]) {
        const container = containers[0];
        container.user_id = payload?.user_id;
        yield put(actions.setSRIPeopleGraph(container));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getSRIOverviewArticlsRead(dispatched: PayloadAction<SRIOverviewAllRequest>) {
  try {
    const payload = dispatched?.payload;
    const response: SRIOverviewContainerOverAllResponse = yield call(
      axios.post,
      API.GET_SRI_OVERVIEW_ARTICLES_READ,
      payload,
    );
    if (response?.status === true) {
      const { response: { containers } } = response;
      if (containers[0]) {
        const container = containers[0];
        container.user_id = payload?.user_id;
        yield put(actions.setSRIArticleGraph(container));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getSRIOverviewPlatformSessions(dispatched: PayloadAction<SRIOverviewAllRequest>) {
  try {
    const payload = dispatched?.payload;
    const response: SRIOverviewContainerOverAllResponse = yield call(
      axios.post,
      API.GET_SRI_OVERVIEW_PLATFORM_SESSION,
      payload,
    );
    if (response?.status === true) {
      const { response: { containers } } = response;
      if (containers[0]) {
        const container = containers[0];
        container.user_id = payload?.user_id;
        yield put(actions.setSRISessionGraph(container));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getSRIOverviewEmailsOpened(dispatched: PayloadAction<SRIOverviewAllRequest>) {
  try {
    const payload = dispatched?.payload;
    const response: SRIOverviewContainerOverAllResponse = yield call(
      axios.post,
      API.GET_SRI_OVERVIEW_EMAIL_OPENED,
      payload,
    );
    if (response?.status === true) {
      const { response: { containers } } = response;
      if (containers[0]) {
        const container = containers[0];
        container.user_id = payload?.user_id;
        yield put(actions.setSRIEmailOpenedGraph(container));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getSalesSigmaForFeed(dispatched: PayloadAction<SRIUserScoreRequest>) {
  try {
    const payload = dispatched?.payload;
    const response: SalesSigmaResponse = yield call(
      axios.post,
      API.GET_SALES_SIGMA_LEADERBOARD,
      payload,
    );
    if (response?.status === true) {
      const { response: salesSigmaData } = response;
      if (salesSigmaData) {
        yield put(actions.setSalesSigmaForFeed(salesSigmaData));
      }
    } else {
      yield put(actions.getSalesSigmaForFeedFailed());
    }
  } catch (err) {
    console.log(err);
    yield put(actions.getSalesSigmaForFeedFailed());
  }
}

export function* exportComplianceData(dispatched: PayloadAction<ExportComplianceRequest>) {
  try {
    const response: ComplianceExportResponse = yield call(
      axios.post,
      API.EXPORT_COMPLIANCE_RECORD,
      dispatched?.payload,
    );
    if (response?.status === true) {
      yield put(actions.setExportComplianceData(response?.response?.url));
      yield put(flurryActions.setNotification({
        message: FILE_DOWNLOAD_SUCCESS,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: true,
      }));
    } else {
      yield put(actions.setComplianceExportLoading(false));
      yield put(flurryActions.setNotification({
        message: 'File download failed',
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: false,
      }));
    }
  } catch (err) {
    console.log(err);
    yield put(actions.setComplianceExportLoading(false));
  }
}

export function* getComplianceData(dispatched: PayloadAction<GetComplianceRequest>) {
  try {
    const payload = dispatched?.payload;
    const response: ComplianceDashboardResponse = yield call(
      axios.post,
      API.GET_COMPLIANCE_RECORD,
      payload,
    );
    if (response?.status === true) {
      yield put(actions.setComplianceData(response?.response));
    } else {
      yield put(actions.setComplianceDataLoading(false));
    }
  } catch (err) {
    console.log(err);
    yield put(actions.setComplianceDataLoading(false));
  }
}

export function* SRISaga() {
  yield takeLatest(actions.getSRICardDetails.type, getSRICardDetails);
  yield takeLatest(actions.getSRIUserScore.type, getSRIUserScore);
  yield takeEvery(actions.getSRIMyEngagement.type, getSRIMyEngagement);
  yield takeEvery(actions.getSRIOverviewEngagement.type, getSRIOverviewEngagement);
  yield takeLatest(actions.addPersonToNetwork.type, addPersonToNetwork);
  yield takeLatest(actions.addCompany.type, addCompany);
  yield takeLatest(actions.getTeamLeaderBoardGraph.type, getTeamLeaderBoardGraph);
  yield takeLatest(actions.getLeaderBoardSummaryGraph.type, getLeaderBoardSummaryGraph);
  yield takeLatest(actions.getTeamReadinessFiltersDetails.type, getTeamReadinessFiltersDetails);
  yield takeLatest(actions.getTeamReadinessBeltsGraph.type, getTeamReadinessBeltsGraph);
  yield takeLatest(actions.getTeamReadinessAvgGraph.type, getTeamReadinessAvgGraph);
  yield takeLatest(actions.getTeamReadinessTeamDetails.type, getTeamReadinessTeamDetails);
  yield takeLatest(actions.getSRIOverviewCompanyViews.type, getSRIOverviewCompanyViews);
  yield takeLatest(actions.getSRIOverviewPeopleViews.type, getSRIOverviewPeopleViews);
  yield takeLatest(actions.getSRIOverviewArticlsRead.type, getSRIOverviewArticlsRead);
  yield takeLatest(actions.getSRIOverviewPlatformSessions.type, getSRIOverviewPlatformSessions);
  yield takeLatest(actions.getSRIOverviewEmailsOpened.type, getSRIOverviewEmailsOpened);
  yield takeLatest(actions.getSalesSigmaForFeed.type, getSalesSigmaForFeed);
  yield takeLatest(actions.getComplianceData.type, getComplianceData);
  yield takeLatest(actions.exportComplianceData.type, exportComplianceData);
}
