import React from 'react';

type Props = {
  color?: string,
  onClick?: () => void,
} & typeof defaultProps;

const defaultProps = {
  color: '#000',
  onClick: () => { },
};

const SearchGlobalIcon = function SearchGlobalIcon(props: Props) {
  const { color, onClick } = props;
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" className="search-global-icon-svg" onClick={onClick}>
      <path fillRule="evenodd" clipRule="evenodd" d="M20.3631 17.3903L25.3843 22.4116C26.2052 23.2325 26.2052 24.5634 25.3843 25.3843C24.5634 26.2052 23.2325 26.2052 22.4116 25.3843L17.3903 20.3631C12.6355 23.5853 6.21026 22.6643 2.55197 18.2362C-1.10632 13.8081 -0.798411 7.32453 3.26306 3.26306C7.32453 -0.798411 13.8081 -1.10632 18.2362 2.55197C22.6643 6.21026 23.5853 12.6355 20.3631 17.3903ZM4.26385 13.9824C5.41581 16.762 8.12892 18.5737 11.1377 18.5725C15.2438 18.5725 18.5725 15.2438 18.5725 11.1377C18.5737 8.12892 16.762 5.41581 13.9824 4.26385C11.2029 3.1119 8.00312 3.74805 5.87558 5.87558C3.74805 8.00312 3.1119 11.2029 4.26385 13.9824Z" fill={color} />
    </svg>
  );
};

SearchGlobalIcon.defaultProps = defaultProps;
export default SearchGlobalIcon;
