import React from 'react';
import styled from 'styled-components';
import { smMobile } from 'Utils/utilsStyle';

const Wrapper = styled.div<{ imageRadius: string }>`
.article-item {
    display:flex;
    padding: 5px 0px;
    border-radius: 8px;
    margin-bottom:13px;
    background-color: #eff5fb52;
      .loading-image {
        height: 72px;
        width: 72px;
        border-radius: ${(props) => props.imageRadius};
        @media all and (max-width: ${smMobile}) {
          height: 50px;
          width: 50px;
        }
      };
    }
    .profile-area {
     min-width: 72px;
    }
    .text-area{
      min-width: 70%;
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .heading-progress{
      height:10px;
      margin-bottom:4px;
      width:100%;
      @media all and (max-width: ${smMobile}) {
        margin-left: 10px;
      }
    }
`;

type Props = {
  imageRadius?: string,
} & typeof defaultProps;

const defaultProps = {
  imageRadius: '10px',
};

const ArticleTileSkeleton = function ArticleTileSkeleton(props: Props) {
  const { imageRadius } = props;
  return (
    <Wrapper imageRadius={imageRadius}>
      <div className="article-item">
        <div className="profile-area">
          <span className="loading-image skeleton-box" />
        </div>
        <div className="text-area">
          <span className="skeleton-box heading-progress" />
          <span className="skeleton-box heading-progress" />
        </div>
      </div>
    </Wrapper>
  );
};

ArticleTileSkeleton.defaultProps = defaultProps;
export default ArticleTileSkeleton;
