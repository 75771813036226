/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { actions } from 'Slice/IndustriesSlice';
import _ from 'lodash';
import axios from 'Utils/Axios';
import { API } from 'Utils/Constants';
import { getCurrentTime, valueToObject } from 'Utils/UtilityFunctions';
import { actions as helperActions } from 'Slice/HelperSlice';
import {
  IndustriesApiResponse, Industry, IndustryNewsResponse, IndustryRequest,
} from 'Types/IndustryTypes';
import { PayloadAction } from '@reduxjs/toolkit';
import { actions as globalSearchResultsActions } from 'Slice/GlobalSearchResultsSlice';

export function* getIndustries() {
  const host = window.location.href;
  const startTime = getCurrentTime();
  try {
    const apiResponse: IndustriesApiResponse = yield call(
      axios.post,
      API.GET_INDUSTRIES,
      {},
    );
    if (apiResponse?.status === true) {
      const { response } = apiResponse;
      yield put(actions.setIndustries(response));
      yield put(helperActions.callFlurryEvent(
        valueToObject(
          'industries_page_load',
          startTime,
          {
            number_of_industries:
            response.followedIndustries.length + response.recommendedIndustries.length,
            host,
          },
        ),
      ));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* removingIndustries(dispatched: PayloadAction<Industry[]>) {
  const startTime = getCurrentTime();
  const data = dispatched.payload.map((v) => v.id);
  const industryName = dispatched.payload.map((v) => v.name);
  try {
    const apiResponse: IndustriesApiResponse = yield call(
      axios.post,
      API.REMOVE_INDUSTRIES,
      { industryIds: data },
    );
    if (apiResponse?.status === true) {
      yield put(helperActions.setNotification({
        message: apiResponse?.message,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: true,
      }));
      yield put(actions.removedIndustries(dispatched.payload));
      yield put(helperActions.callFlurryEvent(
        valueToObject(
          'industries_industry_remove',
          startTime,
          {
            industry_name: industryName.join(','),
            ...dispatched.payload.length > 1 && {
              industry_ids: data.join(','),
            },
            ...dispatched.payload.length === 1 && {
              industry_id: data.join(','),
            },
          },
        ),
      ));
      yield put(globalSearchResultsActions.searchIndustriesUnfollowHandling(
        data,
      ));
    } else {
      yield put(helperActions.setNotification({
        message: apiResponse?.message,
        proTip: false,
        rightPlacement: true,
        groupId: 0,
        classnameFlag: false,
      }));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* addingIndustry(dispatched: PayloadAction<Industry>) {
  const startTime = getCurrentTime();
  try {
    const apiResponse: IndustriesApiResponse = yield call(
      axios.post,
      API.ADD_INDUSTRIES,
      { industryId: dispatched.payload.id },
    );
    if (apiResponse?.status === true) {
      yield put(helperActions.setNotification({
        message: apiResponse?.message,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: true,
      }));
      if (dispatched.payload.type === '') {
        yield put(actions.addedIndustryDetail(dispatched.payload));
      } else {
        yield put(actions.addedIndustry(dispatched.payload));
      }
      yield put(globalSearchResultsActions.searchIndustryFollowLoadingSuccess(
        dispatched.payload.id,
      ));
      yield put(helperActions.callFlurryEvent(
        valueToObject(
          'industries_industry_added',
          startTime,
          {
            industry_id: dispatched.payload.id,
            industry_name: dispatched.payload.name,
          },
        ),
      ));
    }
    if (apiResponse?.status === false) {
      yield put(helperActions.setNotification({
        message: apiResponse?.message,
        proTip: false,
        rightPlacement: true,
        groupId: 0,
        classnameFlag: false,
      }));
      yield put(actions.addedIndustryError(dispatched.payload));
      yield put(globalSearchResultsActions.searchIndustryFollowLoadingFail(
        dispatched.payload.id,
      ));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* getNewsActivities(dispatched: PayloadAction<IndustryRequest>) {
  const startTime = getCurrentTime();
  const obj = {
    industryId: dispatched.payload.industryId,
    page: dispatched.payload.page,
    ...dispatched.payload.filters && {
      filters: dispatched.payload.filters.filter(
        (item) => item.isSelected,
      ).map((item) => item.filterId).join(','),
    },
  };
  try {
    const response: IndustryNewsResponse = yield call(
      axios.post,
      API.GET_INDUSTRY_NEWS,
      obj,
    );

    if (response?.status === true) {
      yield put(actions.setNewsActivities(response));

      if (response?.response?.news?.length < 1) {
        yield put(actions.setNewsArticlesEnded());
      }
      const filterRes = dispatched.payload.filters;
      _.unset(dispatched.payload, 'filters');
      if (dispatched.payload.page === 1) {
        yield put(actions.setNewsFlurryApi({
          ...dispatched.payload,
          industry_name: response.response.industryName,
          loading_time: _.round((getCurrentTime() - startTime), 2),
          ...filterRes?.length
          && {
            number_of_filters_applied: filterRes.filter(
              (item) => item.isSelected,
            ).length,
            filter_applied: filterRes.filter(
              (item) => item.isSelected,
            ).map((item) => item.filterId).join(','),
          },
        }));
      } else {
        yield put(helperActions.callFlurryEvent(
          valueToObject('industry_news', startTime, {
            ...dispatched.payload,
            industry_name: response.response.industryName,
            ...filterRes?.length
          && {
            number_of_filters_applied: filterRes.filter(
              (item) => item.isSelected,
            ).length,
            filter_applied: filterRes.filter(
              (item) => item.isSelected,
            ).map((item) => item.filterId).join(','),
          },

          }),
        ));
      }
    }
  } catch (err) {
    console.log(err);
  }
}
export function* industriesSaga() {
  yield takeLatest(actions.getIndustries.type, getIndustries);
  yield takeLatest(actions.removingIndustries.type, removingIndustries);
  yield takeLatest(actions.removeIndustry.type, removingIndustries);
  yield takeLatest(actions.addingIndustry.type, addingIndustry);
  yield takeLatest(actions.getNewsActivites.type, getNewsActivities);
}
