import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isSamsungEra } from 'Utils/UtilityFunctions';

const ConditionalRendering = function () {
  const navigate = useNavigate();
  const isSamsung = isSamsungEra()
  useEffect(() => {
    if (isSamsung) {
      navigate('/people');
    } else {
      navigate('/feed');
    }
  }, [isSamsung, navigate]);

  return <div />;
};

export default ConditionalRendering;