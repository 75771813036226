import classNames from 'classnames';
import styled from 'styled-components';
import { ReactNode } from 'react';
import {
  primaryHeadingColor,
  wLight, greyColorSecondary, primaryColor,
  primaryFont, wMedium,
  retina, maxMobile, minExtraLargeScreen,
  minStandardScreen, maxTablet,
  creator,
  textBlack,
  midMobile,
} from 'Utils/utilsStyle';

const AritclesGridWrapper = styled.div`
  display: flex;
  gap:16px;
  margin-bottom: 20px;
  height: 100%;
  &:last-child {
    margin-bottom: 0px;
  }
  @media all and (min-width: ${minExtraLargeScreen}) {
    gap: 24px;
    margin-bottom: 24px;
  }
  @media all and (${retina}) {
    gap: 16px;
    margin-bottom: 16px;
  }
  @media all and (max-width: ${minStandardScreen}) {
    gap: 12px;
    margin-bottom: 12px;
  }
  @media all and (max-width: ${maxTablet}) {
    gap: 8px;
    margin-bottom: 8px;
  }
  @media all and (max-width: ${maxMobile}) {
    flex-direction: column;
    gap: 8px;
    margin-bottom: 8px;
  }
  .articleMainTileWrapper,
  .articleGridTilesWrapper{ 
    display: flex;
    flex: 1;
    width: 50%;
    .gridTile{
      overflow: hidden !important;
      margin: 0 !important;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 0px 8px -1px rgba(0, 0, 0, 0.08);
      .videoPlayIframe{
        .layer {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0px;
          left: 0px;
          border-radius: 6px 6px 0px 0px;
          transition: 0.5s ease all;
          &:hover {
            background-color: rgba(0,0,0,0.2);
          }
        }
        .youtube-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-height: 39px;
          max-width: 52px;
        }
        .thumbnail-image {
        height: 100%;
        width: 100%;
        border-radius: 8px 8px 0px 0px;
        }
      }
      .text-area{
        &::before {
          left: 0;
          transform: unset;
          width: 7px;
          @media all and (min-width: ${minExtraLargeScreen}) {
            width: 9px;
          }
          @media all and (max-width: ${minStandardScreen}) {
            width: 6px;
          }
          @media all and (max-width: ${maxTablet}) {
            width: 4px;
          }
          @media all and (max-width: ${maxMobile}) {
            width: 4px;
          }
        }
      }
      .footer-data-article{
        margin-top: auto;
        .share-actions-with-fav{
          margin: 0;
          display: flex;
          flex: 1;
          justify-content: space-between;
          align-items: center;
          gap: 12px;
          width: 100%;
          .iconsWrapper{
            margin-left: auto;
            display: flex;
            align-items: center;
            gap: 12px;
            @media all and (min-width: ${minExtraLargeScreen}) {
              gap: 12px;
            }
            @media all and (${retina}) {
              gap: 8px;
            }
            @media all and (max-width: ${minStandardScreen}) {
              gap: 8px;
            }
            @media all and (max-width: ${maxTablet}) {
              gap: 6px;
            }
            @media all and (max-width: ${maxMobile}) {
              gap: 8px;
            }
            .favorite-icon-custom{
              width: 16px;
              height: 15.206px;
              display: inline-flex;
              margin-right: 0;
              @media all and (min-width: ${minExtraLargeScreen}) {
                width: 19.6px;
                height: 18.628px;
              }
              @media all and (${retina}) {
                width: 12px;
                height: 12px;
              }
              @media all and (max-width: ${minStandardScreen}) {
                width: 11.574px;
                height: 11px;
              }
              svg{
                height: 100%;
                width: 100%;
              }
            }
            .article-share-icon{
              width: 12.511px;
              height: 15px;
              display: inline-flex;
              @media all and (min-width: ${minExtraLargeScreen}) {
                width: 15.301px;
                height: 18.346px;
              }
              @media all and (${retina}) {
                width: 12px;
                height: 12px;
              }
              @media all and (max-width: ${minStandardScreen}) {
                width: 9.175px;
                height: 11px;
              }
              svg{
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }
      &.notImage{
        .text-area{
          .textAreaContentWrapper{
            .person-activity-text-detail{
              .tagAndIndustryBox{
                order: 2;
                flex-direction: column-reverse;
                align-items: flex-start;
                .tagArticle {
                  margin-left: unset !important;
                }
              }
              .heading {
                order: 1;
              }
              .article_summary {
                order: 3;
                margin-bottom: auto !important;
              }
              .footer-data-article{
                order: 4;
              }
            }
          }
        }
      }
    }
  }
  .articleMainTileWrapper{
    padding-left: 16px;
    margin-left: -16px;
    min-height: 741px;
    max-width: calc(50% - 26px);
    @media all and (min-width: ${minExtraLargeScreen}) {
      min-height: 990px;
    }
    @media all and (${retina}) {
      min-height: 696px;
    }
    @media all and (max-width: ${minStandardScreen}) {
      min-height: 529px;
    }
    @media all and (max-width: ${maxTablet}) {
      min-height: 396px;
    }
    @media all and (max-width: ${maxMobile}) {
      padding: 0;
      margin: 0;
      min-height: unset;
      max-width: 100%;
      width: 100%;
    }
    &.heightUnset{
      height: unset;
      min-height: unset;
    }
    .gridTile{
      width: 100%;
      .newsVideoFrame{
        height: 550px;
        @media all and (min-width: ${minExtraLargeScreen}) {
          height: 747px;
        }
        @media all and (${retina}) {
          height: 526px;
        }
        @media all and (max-width: ${minStandardScreen}) {
          height: 378px;
        }
        @media all and (max-width: ${maxTablet}) {
          height: 264;
        }
        @media all and (max-width: ${maxMobile}) {
          height: 349px;
        }
        iframe{
          height: 100% !important;
        }
      }
      .profile-area{
        margin-right: 0;
        max-height: 550px;
        min-height: 550px;
        @media all and (min-width: ${minExtraLargeScreen}) {
          max-height: 747px;
          min-height: 747px;
        }
        @media all and (${retina}) {
          max-height: 526px;
          min-height: 526px;
        }
        @media all and (max-width: ${minStandardScreen}) {
          max-height: 378px;
          min-height: 378px;
        }
        @media all and (max-width: ${maxTablet}) {
          max-height: 264px;
          min-height: 264px;
        }
        @media all and (max-width: ${maxMobile}) {
          max-height: 349px;
          min-height: 349px;
        }
      }
      .text-area{
        width: 100%;
        min-height: 172px;
        box-shadow: 0px 0px 17px -2px rgba(0, 0, 0, 0.08);;
        border-top: none;
        flex: 1;
        @media all and (min-width: ${minExtraLargeScreen}) {
          min-height: 242px;
        }
        @media all and (${retina}) {
          min-height: 173px;
        }
        @media all and (max-width: ${minStandardScreen}) {
          min-height: 148px;
        }
        @media all and (max-width: ${maxTablet}) {
          min-height: 116px;
        }
        @media all and (max-width: ${maxMobile}) {
          min-height: unset;
        }
        .textAreaContentWrapper{
          height: 100%;
          display: flex;
          .tagAndIndustryBox{
            display: flex;
            align-items: center;
            gap: 12px;
            width: 100%;
            padding: 12px 12px 0px 15px;
            @media all and (min-width: ${minExtraLargeScreen}) {
              padding: 16.15px 16.07px 0px 21px;
            }
            @media all and (${retina}) {
              padding: 12px 12.78px 0 15px;
            }
            @media all and (max-width: ${minStandardScreen}) {
              padding: 10px 8px 0px 12px;
            }
            @media all and (max-width: ${maxTablet}) {
              padding: 8px 8px 0px 10px;
            }
            @media all and (max-width: ${maxMobile}) {
              padding: 8px 8px 0px 11px;
            }
            .industryName{
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 100%;
              color: ${creator};
              font-family: ${primaryFont};
              font-size: 12px;
              font-style: normal;
              font-weight: ${wMedium};
              line-height: 14px;
              @media all and (min-width: ${minExtraLargeScreen}) {
                font-size: 14px;
                line-height: 16px;
              }
              @media all and (${retina}) {
                font-size: 10px;
              }
              @media all and (max-width: ${minStandardScreen}){
                font-size: 10px;
                line-height: 10.889px;
              }
              @media all and (max-width: ${maxTablet}){
                font-size: 7.995px;
                line-height: 8.706px;
              }
              @media all and (max-width: ${maxMobile}){
                font-size: 10px;
                line-height: 12px;
              }
            }
            .tagArticle {
              border: none;
              color: #FFF;
              font-size: 12px;
              font-style: normal;
              line-height: 14px;
              letter-spacing: -0.361px;
              border-radius: 4px;
              padding: 4px 8px;
              position: unset;
              height: 18px;
              display: flex;
              align-items: center;
              justify-content: center;
              white-space: nowrap;
              margin-left: auto;
              @media all and (min-width: ${minExtraLargeScreen}) {
                font-size: 14px;
                line-height: 16px;
                padding: 5.114px 10px;
                border-radius: 6px;
                height: 24px;
              }
              @media all and (${retina}) {
                font-size: 10px;
                line-height: 12.444px;
                padding: 3.556px 7.111px;
                border-radius: 4px;
                height: 16px;
              }
              @media all and (max-width: ${minStandardScreen}){
                font-size: 10px;
                line-height: 14px;
                padding: 3.111px 6px;
                border-radius: 3px;
                height: 14px;
              }
              @media all and (max-width: ${maxTablet}){
                font-size: 7.995px;
                line-height: 8.706px;
                padding: 2.487px 4px;
                border-radius: 2px;
                height: 11px;
              }
              @media all and (max-width: ${maxMobile}){
                font-size: 10px;
                line-height: 12px;
                padding: 3.556px 6px;
                border-radius: 2px;
                height: 14px;
              }
            }
          }
          .sourcesBox{
            display: flex;
            align-items: center;
            width: calc(100% - 52px);
            @media all and (max-width: ${maxMobile}){
              width: calc(100% - 42px);
            }
            .separatorBothSide,
            .separatorLeftOnly{
              padding-left: 13px;
              position: relative;
              @media all and (min-width: ${minExtraLargeScreen}) {
                padding-left: 17px;
              }
              @media all and (max-width: ${minStandardScreen}) {
                padding-left: 9px;
              }
              @media all and (max-width: ${maxTablet}){
                padding-left: 7px;
              }
              @media all and (max-width: ${maxMobile}){
                padding-left: 9px;
              }
              &::after,
              &::before{
                content: "";
                height: 10px;
                width: 1px;
                background-color: #CFCFCF;
                position: absolute;
                top: 50%;
                left: 6px;
                transform: translateY(-50%);
                @media all and (min-width: ${minExtraLargeScreen}) {
                  left: 8px;
                }
                @media all and (max-width: ${minStandardScreen}){
                  left: 4px;
                  height: 10px;
                }
                @media all and (max-width: ${maxTablet}){
                  left: 3px;
                  height: 8px;
                }
                @media all and (max-width: ${maxMobile}){
                  left: 4px;
                  height: 9px;
                }
              }
              &::before{
                right: 6px;
                left: unset;
                @media all and (min-width: ${minExtraLargeScreen}) {
                  right: 8px;
                }
                @media all and (max-width: ${minStandardScreen}){
                  right: 4px;
                }
                @media all and (max-width: ${maxTablet}){
                  right: 3px;
                }
                @media all and (max-width: ${maxMobile}){
                  right: 4px;
                }
              }
            }
            .separatorBothSide{
              padding: 0 13px;
              @media all and (min-width: ${minExtraLargeScreen}) {
                padding: 0 17px;
              }
              @media all and (${retina}) {
              }
              @media all and (max-width: ${minStandardScreen}){
                padding: 0 9px;
              }
              @media all and (max-width: ${maxTablet}){
                padding: 0 7px;
              }
              @media all and (max-width: ${maxMobile}){
                padding: 0 9px;
              }
            }
            .separatorLeftOnly{
              &::before{
                display: none !important;
              }
            }
            .source{
              color: ${greyColorSecondary};
              text-align: center;
              font-family: ${primaryFont};
              font-size: 12px;
              font-style: normal;
              font-weight: ${wMedium};
              line-height: 16px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              @media all and (min-width: ${minExtraLargeScreen}) {
                font-size: 14px;
              }
              @media all and (${retina}) {
                font-size: 10px;
              }
              @media all and (max-width: ${minStandardScreen}){
                font-size: 10px;
                line-height: 16px;
              }
              @media all and (max-width: ${maxTablet}){
                font-size: 8px;
                line-height: 12.793px;
              }
              @media all and (max-width: ${maxMobile}){
                font-size: 10px;
                line-height: 12.793px;
              }
            }
            .similarArticles,
            .date{
              overflow: visible;
            }
            .similarArticles{
              color: ${primaryColor};
            }
          }
          .footer-data-article{
            padding: 0px 12px 12px 15px;
            @media all and (min-width: ${minExtraLargeScreen}) {
              padding: 0px 16px 15px 21px;
            }
            @media all and (${retina}) {
              padding: 0px 12px 10px 15px;
            }
            @media all and (max-width: ${minStandardScreen}) {
              padding: 0px 8px 10px 12px;
            }
            @media all and (max-width: ${maxTablet}) {
              padding: 0px 8px 8px 10px;
            }
            @media all and (max-width: ${maxMobile}) {
              padding: 0px 8px 7px 11px;
            }
          }
        }
        .person-activity-text-detail{
          margin-left: 0;
          flex: 1;
          display: flex;
          flex-direction: column;
          width: 100%;
          .heading{
            padding: 0 14px 0 15px;
            margin-top: 4px;
            margin-bottom: 4px;
            min-height: unset;
            color: ${textBlack}; 
            font-size: 18px;
            font-style: normal;
            font-weight: ${wMedium};
            line-height: 22px;
            letter-spacing: -0.299px;
            @media all and (min-width: ${minExtraLargeScreen}) {
              font-size: 20px;
              line-height: 24px;
              letter-spacing: -0.641px;
              margin-top: 11px;
              margin-bottom: 8px;
              padding: 0 44px 0 21px;
            }
            @media all and (${retina}) {
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.299px;
              margin-bottom: 10px;
              margin-bottom: 6px;
              padding: 0 14px 0 15px;
              min-height: unset;
            }
            @media all and (max-width: ${minStandardScreen}) {
              font-size: 14px;
              line-height: 16px;
              letter-spacing: -0.351px;
              margin-top: 6px;
              margin-bottom: 4px;
              padding: 0 11px 0 12px;
            }
            @media all and (max-width: ${maxTablet}) {
              font-size: 12px;
              line-height: 14px;
              letter-spacing: -0.256px;
              margin-top: 4px;
              margin-bottom: 2px;
              padding: 0 9px 0 10px;
              min-height: unset;
            }
            @media all and (max-width: ${maxMobile}) {
              margin-top: 6px;
              margin-bottom: 4px;
              padding: 0 10px 0 11px;
            }
          }
          .article_summary{
            color: ${primaryHeadingColor};
            font-size: 14px;
            font-style: normal;
            font-weight: ${wLight};
            line-height: 16px;
            letter-spacing: -0.494px;
            padding: 0 14px 0 15px;
            margin-bottom: 27px;
            @media all and (min-width: ${minExtraLargeScreen}) {
              font-size: 16px;
              line-height: 20px;
              letter-spacing: -0.783px;
              padding: 0 21px 0 21px;
              margin-bottom: 36px;
            }
            @media all and (${retina}) {
              font-size: 12px;
              line-height: 16px;
              letter-spacing: -0.494px;
              padding: 0 14px 0 15px;
              margin-bottom: 14px;
            }
            @media all and (max-width: ${minStandardScreen}) {
              font-size: 12px;
              line-height: 16px;
              letter-spacing: -0.429px;
              padding: 0 10px 0 12px;
              margin-bottom: 14px;
            }
            @media all and (max-width: ${maxTablet}) {
              font-size: 12px;
              line-height: 16px;
              letter-spacing: -0.312px;
              padding: 0 8px 0 10px;
              margin-bottom: 10px;
            }
            @media all and (max-width: ${maxMobile}) {
              font-size: 10px;
              line-height: 14px;
              padding: 0 8px 0 11px;
              margin-bottom: 7px;
            }
          }
          .text-truncate-two-line{
            -webkit-line-clamp: 2;
          }
        }
      }
      &.notImage{
        .text-area{
          .person-activity-text-detail{
            .tagAndIndustryBox{
              gap: 10px;
              padding-top: 8px;
              padding-bottom: 12px;
              @media all and (min-width: ${minExtraLargeScreen}) {
                gap: 11px;
                padding-top: 12px;
                padding-bottom: 12px;
              }
              @media all and (${retina}) {
                gap: 10px;
                padding-top: 12px;
                padding-bottom: 6px;
              }
              @media all and (max-width: ${minStandardScreen}) {
                gap: 6px;
                padding-top: 8px;
                padding-bottom:8px;
              }
              @media all and (max-width: ${maxTablet}) {
                gap: 4px;
                padding-top: 8px;
                padding-bottom: 8px;
              }
              @media all and (max-width: ${maxMobile}) {
                gap: 10px;
                padding-top: 6px;
                padding-bottom: 8px;
              }
            }
            .heading{
              margin-bottom: 0;
              margin-top: 14px;
              min-height: 54px;
              @media all and (min-width: ${minExtraLargeScreen}) {
                margin-top: 16px;
                min-height: 72px;
              }
              @media all and (${retina}) {
                margin-top: 12px;
                min-height: 54px;
              }
              @media all and (max-width: ${minStandardScreen}) {
                margin-top: 10px;
                min-height: 48px;
              }
              @media all and (max-width: ${maxTablet}) {
                margin-top: 8px;
                min-height: 46px;
              }
              @media all and (max-width: ${maxMobile}) {
                margin-top: 8px;
                -webkit-line-clamp: 3;
              }
            }
            .article_summary{
              font-weight: ${wLight};
              color: ${primaryHeadingColor};
              font-family: ${primaryFont};
              font-size: 16px;
              line-height: 18.75px;
              font-style: normal;
              letter-spacing: -0.556px;
              display: -webkit-box;
              max-height: 513px;
              height: 100%;
              padding: 0 31px 0 15px;
              -webkit-line-clamp: 27;
              @media all and (min-width: ${minExtraLargeScreen}) {
                padding: 0 6px 0 21px;
                letter-spacing: -0.783px;
                font-size: 16px;
                line-height: 19px;
                -webkit-line-clamp: 39;
                max-height: 741px;
              }
              @media all and (${retina}) {
                padding: 0px 14px 0px 15px;
                font-size: 12px;
                line-height: 16px;
                -webkit-line-clamp: 29;
                max-height: 463px;
              }
              @media all and (max-width: ${minStandardScreen}) {
                padding: 0px 13px 0px 12px;
                font-size: 12px;
                line-height: 15.4px;
                max-height: 355px;
                -webkit-line-clamp: 23;
              }
              @media all and (max-width: ${maxTablet}) {
                padding: 0px 7px 0px 10px;
                font-size: 12px;
                line-height: 14px;
                max-height: 238px;
                -webkit-line-clamp: 17;
              }
              @media all and (max-width: ${maxMobile}) {
                padding: 0px 6px 0px 11px;
                font-size: 12px;
                line-height: 16px;
                -webkit-line-clamp: 21;
              }
            }
            .footer-data-article{
              @media all and (max-width: ${maxMobile}) {
                padding-top: 10px;
                padding-bottom: 10px;
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
  }
  .articleGridTilesWrapper{
    flex-wrap: wrap;
    gap: 16px;
    padding-right: 16px;
    margin-right: -16px;
    @media all and (min-width: ${minExtraLargeScreen}) {
      gap: 24px ;
    }
    @media all and (${retina}) {
      gap: 16px;
    }
    @media all and (max-width: ${minStandardScreen}) {
      gap: 11px 12px ;
    }
    @media all and (max-width: ${maxTablet}) {
      gap: 8px ;
    }
    @media all and (max-width: ${maxMobile}) {
      padding: 0;
      margin: 0;
      width: 100%;
    }
    .gridTile{
      width: calc(50% - 8px);
      min-height: 362px;
      max-height: calc(50% - 8px);
      height: 100%;
      @media all and (min-width: ${minExtraLargeScreen}) {
        width: calc(50% - 12px);
        min-height: 483px;
        max-height: calc(50% - 12px);
      }
      @media all and (${retina}) {
        width: calc(50% - 8px);
        max-height: calc(50% - 8px);
      }
      @media all and (max-width: ${minStandardScreen}) {
        width: calc(50% - 6px);
        min-height: 258px;
        max-height: calc(50% - 6px);
      }
      @media all and (max-width: ${maxTablet}) {
        width: calc(50% - 4px);
        min-height: 194px;
        max-height: calc(50% - 4px);
      }
      @media all and (max-width: ${maxMobile}) {
        width: calc(50% - 4px);
        max-height: calc(50%);
        height: auto;
      }
      .newsVideoFrame{
        height: 252px;
        @media all and (min-width: ${minExtraLargeScreen}) {
          height: 341px;
        }
        @media all and (${retina}) {
          height: 236px;
        }
        @media all and (max-width: ${minStandardScreen}) {
          height: 172px;
        }
        @media all and (max-width: ${maxTablet}) {
          height: 119px;
        }
        @media all and (max-width: ${maxMobile}) {
          height: 136px;
        }
        iframe{
          height: 100% !important;
        }
      }
      .profile-area{
        max-height: 252px;
        min-height: 252px;
        margin-right: 0;
        @media all and (min-width: ${minExtraLargeScreen}) {
          max-height: 341px;
          min-height: 341px;
        }
        @media all and (${retina}) {
          max-height: 236px;
          min-height: 236px;
        }
        @media all and (max-width: ${minStandardScreen}) {
          max-height: 172px;
          min-height: 172px;
        }
        @media all and (max-width: ${maxTablet}) {
          max-height: 119px;
          min-height: 119px;
        }
        @media all and (max-width: ${maxMobile}) {
          max-height: 136px;
          min-height: 136px;
        }
      }
      .text-area{
        width: 100%;
        min-height: 94px;
        box-shadow: 0px 0px 17px -1.981px rgba(0, 0, 0, 0.08);;
        border-top: none;
        flex: 1;
        @media all and (min-width: ${minExtraLargeScreen}) {
          min-height: 132px;
        }
        @media all and (${retina}) {
          min-height: 92px;
        }
        @media all and (max-width: ${minStandardScreen}) {
          min-height: 80px;
        }
        @media all and (max-width: ${maxTablet}) {
          min-height: 63px;
        }
        @media all and (max-width: ${maxMobile}) {
          min-height: 65px;
        }
        .textAreaContentWrapper{
          height: 100%;
          display: flex;
          .headingSpaceFromTop{
            margin-top: 6px;
          }
          .tagAndIndustryBox{
            display: flex;
            align-items: center;
            gap: 12px;
            width: 100%;
            padding: 10px 12px 0px 15px;
            @media all and (min-width: ${minExtraLargeScreen}) {
              padding: 12px 12px 0px 21px;
            }
            @media all and (${retina}) {
              padding: 6px 12.78px 0 15px;
            }
            @media all and (max-width: ${minStandardScreen}) {
              padding: 6px 8px 0px 12px;
            }
            @media all and (max-width: ${maxTablet}) {
              padding: 6px 6px 0px 10px;
            }
            @media all and (max-width: ${maxMobile}) {
              padding: 6px 6px 0px 11px;
            }
            .industryName{
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 100%;
              color: ${creator};
              font-family: ${primaryFont};
              font-size: 12px;
              font-style: normal;
              font-weight: ${wMedium};
              line-height: 14px;
              @media all and (min-width: ${minExtraLargeScreen}) {
                font-size: 14px;
                line-height: 16px;
              }
              @media all and (${retina}) {
                font-size: 10px;
              }
              @media all and (max-width: ${minStandardScreen}){
                font-size: 10px;
                line-height: 10.889px;
              }
              @media all and (max-width: ${maxTablet}){
                font-size: 7.995px;
                line-height: 8.706px;
              }
              @media all and (max-width: ${maxMobile}){
                font-size: 10px;
                line-height: 12px;
              }
            }
            .tagArticle {
              border: none;
              color: #FFF;
              font-size: 12px;
              font-style: normal;
              line-height: 14px;
              letter-spacing: -0.361px;
              border-radius: 4px;
              padding: 4px 8px;
              position: unset;
              height: 18px;
              display: flex;
              align-items: center;
              justify-content: center;
              white-space: nowrap;
              margin-left: auto;
              @media all and (min-width: ${minExtraLargeScreen}) {
                font-size: 14px;
                line-height: 16px;
                padding: 5.114px 10px;
                border-radius: 6px;
                height: 24px;
              }
              @media all and (${retina}) {
                font-size: 10px;
                line-height: 12.444px;
                padding: 3.556px 7.111px;
                border-radius: 4px;
                height: 16px;
              }
              @media all and (max-width: ${minStandardScreen}){
                font-size: 10px;
                line-height: 10.889px;
                padding: 3.111px 6px;
                border-radius: 3px;
                height: 14px;
              }
              @media all and (max-width: ${maxTablet}){
                font-size: 7.995px;
                line-height: 8.706px;
                padding: 2.487px 4px;
                border-radius: 2px;
                height: 11px;
              }
              @media all and (max-width: ${maxMobile}){
                font-size: 10px;
                line-height: 12px;
                padding: 3.556px 6px;
                border-radius: 2px;
                height: 14px;
              }
            }
          }
          .sourcesBox{
            display: flex;
            align-items: center;
            width: calc(100% - 52px);
            @media all and (max-width: ${maxMobile}){
              width: calc(100% - 42px);
            }
            .separatorBothSide,
            .separatorLeftOnly{
              padding-left: 13px;
              position: relative;
              @media all and (min-width: ${minExtraLargeScreen}) {
                padding-left: 17px;
              }
              @media all and (max-width: ${minStandardScreen}) {
                padding-left: 9px;
              }
              @media all and (max-width: ${maxTablet}){
                padding-left: 7px;
              }
              @media all and (max-width: ${maxMobile}){
                padding-left: 9px;
              }
              &::after,
              &::before{
                content: "";
                height: 10px;
                width: 1px;
                background-color: #CFCFCF;
                position: absolute;
                top: 50%;
                left: 6px;
                transform: translateY(-50%);
                @media all and (min-width: ${minExtraLargeScreen}) {
                  left: 8px;
                }
                @media all and (max-width: ${minStandardScreen}){
                  left: 4px;
                  height: 10px;
                }
                @media all and (max-width: ${maxTablet}){
                  left: 3px;
                  height: 8px;
                }
                @media all and (max-width: ${maxMobile}){
                  left: 4px;
                  height: 9px;
                }
              }
              &::before{
                right: 6px;
                left: unset;
                @media all and (min-width: ${minExtraLargeScreen}) {
                  right: 8px;
                }
                @media all and (max-width: ${minStandardScreen}){
                  right: 4px;
                }
                @media all and (max-width: ${maxTablet}){
                  right: 3px;
                }
                @media all and (max-width: ${maxMobile}){
                  right: 4px;
                }
              }
            }
            .separatorBothSide{
              padding: 0 13px;
              @media all and (min-width: ${minExtraLargeScreen}) {
                padding: 0 17px;
              }
              @media all and (${retina}) {
              }
              @media all and (max-width: ${minStandardScreen}){
                padding: 0 9px;
              }
              @media all and (max-width: ${maxTablet}){
                padding: 0 7px;
              }
              @media all and (max-width: ${maxMobile}){
                padding: 0 9px;
              }
            }
            .separatorLeftOnly{
              &::before{
                display: none !important;
              }
            }
            .source{
              color: ${greyColorSecondary};
              text-align: center;
              font-family: ${primaryFont};
              font-size: 12px;
              font-style: normal;
              font-weight: ${wMedium};
              line-height: 16px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
               @media all and (min-width: ${minExtraLargeScreen}) {
                font-size: 14px;
              }
              @media all and (${retina}) {
                font-size: 10px;
              }
              @media all and (max-width: ${minStandardScreen}){
                font-size: 10px;
                line-height: 12.486px;
              }
              @media all and (max-width: ${maxTablet}){
                font-size: 8px;
                line-height: 10.268px
              }
              @media all and (max-width: ${maxMobile}){
                font-size: 10px;
                line-height: 10.264px;
                letter-spacing: -0.6px;
              }
            }
            .similarArticles,
            .date{
              overflow: visible;
            }
            .similarArticles{
              color: ${primaryColor};
            }
          }
        }
        .person-activity-text-detail{
          margin-left: 0;
          flex: 1;
          display: flex;
          flex-direction: column;
          width: 100%;
          .heading{
            padding: 0 13px 0 15px;
            min-height: unset;
            color: ${textBlack}; 
            font-size: 16px;
            font-style: normal;
            font-weight: ${wMedium};
            line-height: 20px;
            letter-spacing: -0.299px;
            margin-top: 4px;
            @media all and (min-width: ${minExtraLargeScreen}) {
              font-size: 18px;
              line-height: 22px;
              letter-spacing: -0.641px;
              padding: 0 19px 0 21px;
              margin-top: 8px;
            }
            @media all and (${retina}) {
              font-size: 12px;
              line-height: normal;
              letter-spacing: -0.299px;
              padding: 0 15px 0 13px;
              margin-top: 4px;
            }
            @media all and (max-width: ${minStandardScreen}) {
              font-size: 12px;
              line-height: 14px;
              letter-spacing: -0.234px;
              padding: 0 8px 0 12px;
              margin-top: 6px;
            }
            @media all and (max-width: ${maxTablet}) {
              font-size: 10px;
              line-height: 12px;
              letter-spacing: -0.192px;
              padding: 0 8px 0 10px;
              margin-top: 2px;
              min-height: unset;
            }
            @media all and (max-width: ${maxMobile}) {
              font-size: 12px;
              line-height: 14px;
              letter-spacing: -0.192px;
              padding: 0 5.48px 0 11px;
              margin-top: 4px;
            }
            &.text-truncate-three-line{
              -webkit-line-clamp: 2;
            }
          }
          .article_summary{
            display: none;
          }
        }
        .footer-data-article{
          padding: 10px 10px 12px 15px;
          display: flex;
          @media all and (min-width: ${minExtraLargeScreen}) {
            padding: 20px 12px 14px 21px;
          }
          @media all and (${retina}) {
            padding: 6px 12px 8px 15px;
          }
          @media all and (max-width: ${minStandardScreen}) {
            padding: 9px 8px 8px 12px;
          }
          @media all and (max-width: ${maxTablet}) {
            padding: 8px 6px 8px 10px;
          }
          @media all and (max-width: ${maxMobile}) {
            padding: 8px 6px 8px 11px;
          }
        }
      }
      &.notImage{
        .text-area{
          .person-activity-text-detail{
            .tagAndIndustryBox{
              gap: 8px;
              padding-top: 12px;
              padding-bottom: 8px;
              @media all and (min-width: ${minExtraLargeScreen}) {
                gap: 11px;
                padding-top: 8px;
                padding-bottom: 12px;
              }
              @media all and (${retina}) {
                gap: 10px;
                padding-top: 12px;
                padding-bottom: 6px;
              }
              @media all and (max-width: ${minStandardScreen}) {
                gap: 6px;
                padding-top: 8px;
                padding-bottom: 5px;
              }
              @media all and (max-width: ${maxTablet}) {
                gap: 4px;
                padding-top: 6px;
                padding-bottom: 4px;
              }
            }
            .heading{
              margin-top: 12px;
              min-height: 36px;
              line-height: 18px;
              -webkit-line-clamp: 2;
              @media all and (min-width: ${minExtraLargeScreen}) {
                margin-top: 16px;
                line-height: 22px;
                min-height: 44px;
              }
              @media all and (${retina}) {
                margin-top: 12px;
                min-height: 51px;
              }

              @media all and (max-width: ${minStandardScreen}) {
                margin-top: 6px;
                line-height: 14.047px;
                min-height: 30px;
              }
              @media all and (max-width: ${maxTablet}) {
                line-height: 14px;
                min-height: 29px;
              }
            }
            .article_summary{
              font-weight: ${wLight};
              color: ${primaryHeadingColor};
              font-family: ${primaryFont};
              font-size: 12px;
              line-height: 14.06px;
              font-style: normal;
              line-height: normal;
              letter-spacing: -0.556px;
              display: -webkit-box;
              max-height: 182px;
              height: 100%;
              padding: 0 12px 0 15px;
              -webkit-line-clamp: 13;
              @media all and (min-width: ${minExtraLargeScreen}) {
                padding: 0px 11px 0px 21px;
                font-size: 16px;
                line-height: 18.75px;
                max-height: 266px;
                -webkit-line-clamp: 14;
              }
              @media all and (${retina}) {
                padding: 0px 14px 0px 15px;
                font-size: 12px;
                line-height: 14px;
                max-height: 196px;
                -webkit-line-clamp: 14;
              }
              @media all and (max-width: ${minStandardScreen}) {
                padding: 0px 09px 0px 12px;
                font-size: 10px;
                line-height: 12px;
                max-height: 120px;
                -webkit-line-clamp: 10;
              }
              @media all and (max-width: ${maxTablet}) {
                padding: 0px 7px 0px 10px;
                font-size: 8px;
                line-height: 10px;
                max-height: 90px;
                -webkit-line-clamp: 9;
              }
              @media all and (max-width: ${maxMobile}) {
                padding: 0px 6px 0px 11px;
                font-size: 10px;
                line-height: 11.72px;
                -webkit-line-clamp: 9;
                max-height: 108px;
              }
            }
            .footer-data-article{
              @media all and (max-width: ${maxMobile}) {
                padding-top: 10px;
                padding-bottom: 10px;
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
  }

  ////////////////////////////////////////////
  ///////////////////////////////////////////
  /////// Video Global Tiles

  &.videoGridWrapper{
    .articleMainTileWrapper,
    .articleGridTilesWrapper{
      .video-frame-area{
        position: relative;
        .thumbnail-image{
          object-fit: cover;
        }
      }
      .text-area{
        .heading{
          margin-bottom: 0;
        }
      }
    }
    .articleMainTileWrapper{
      min-height: 652px;
      @media all and (min-width: ${minExtraLargeScreen}) {
        min-height: 906px;
      }
      @media all and (${retina}) {
        min-height: 573px;
      }
      @media all and (max-width: ${minStandardScreen}) {
        min-height: 475px;
      }
      @media all and (max-width: ${maxTablet}) {
        min-height: 350px;
      }
      @media all and (max-width: ${maxMobile}) {
        min-height: unset;
      }
      .video-frame-area{
        max-height: 550px;
        min-height: 550px;
        @media all and (min-width: ${minExtraLargeScreen}) {
          max-height: 747px;
          min-height: 747px;
          height: 747px;
        }
        @media all and (${retina}) {
          max-height: 526px;
          min-height: 526px;
          height: 526px;
        }
        @media all and (max-width: ${minStandardScreen}) {
          max-height: 378px;
          min-height: 378px;
          height: 378px;
        }
        @media all and (max-width: ${maxTablet}) {
          max-height: 264px;
          min-height: 264px;
          height: 264px;
        }
        @media all and (max-width: ${maxMobile}) {
          max-height: 349px;
          min-height: 349px;
          height: 349px;
        }
        .youtube-icon {
          @media all and (max-width: ${maxMobile}) {
            height: 70px;
          }
        }
      }
      .text-area{
        min-height: unset !important;
        display: flex;
        flex-direction: column;
        padding: 12px 12px 12px 15px;
        gap: 8px;
        @media all and (min-width: ${minExtraLargeScreen}) {
          padding: 16px 16px 14px 21px;
        }
        @media all and (${retina}) {
          padding: 7px 36px 7px 20px;
        }
        @media all and (max-width: ${minStandardScreen}) {
          padding: 10px 8px 12px 12px;
        }
        @media all and (max-width: ${maxTablet}) {
          padding: 8px 8px 8px 10px;
        }
        @media all and (max-width: ${maxMobile}) {
          min-height: unset;
          padding: 8px 8px 8px 11px;
        }
        .heading{
          margin-top: 8px;
          margin-bottom: 6px;
          min-height: unset;
          color: ${textBlack}; 
          font-size: 16px;
          font-style: normal;
          font-weight: ${wMedium};
          line-height: 18px;
          letter-spacing: -0.299px;
          @media all and (min-width: ${minExtraLargeScreen}) {
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.641px;
            margin-top: 11px;
            margin-bottom: 8px;
          }
          @media all and (${retina}) {
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.299px;
            margin-bottom: 10px;
            margin-bottom: 6px;
            min-height: unset;
          }
          @media all and (max-width: ${minStandardScreen}) {
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.351px;
            margin-top: 6px;
            margin-bottom: 4px;
          }
          @media all and (max-width: ${maxTablet}) {
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.256px;
            margin-top: 4px;
            margin-bottom: 2px;
            min-height: unset;
          }
          @media all and (max-width: ${maxMobile}) {
            margin-top: 6px;
            margin-bottom: 4px;
          }
          }
        .sourcesBox{
          display: flex;
          align-items: center;
          width: 100%;
          margin-top: auto;
          .separatorLeftOnly{
            padding-left: 17px;
            position: relative;
            &::after{
              content: "";
              height: 10px;
              width: 1px;
              background-color: #CFCFCF;
              position: absolute;
              top: 50%;
              left: 8px;
              transform: translateY(-50%);
            }
          }
          .source{
            color: ${greyColorSecondary};
            text-align: center;
            font-family: ${primaryFont};
            font-size: 12px;
            font-style: normal;
            font-weight: ${wMedium};
            line-height: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @media all and (min-width: ${minExtraLargeScreen}) {
              font-size: 14px;
            }
            @media all and (${retina}) {
              font-size: 10px;
            }
            @media all and (max-width: ${minStandardScreen}){
              font-size: 10px;
              line-height: 16px;
            }
            @media all and (max-width: ${maxTablet}){
              font-size: 8px;
              line-height: 12.793px;
            }
            @media all and (max-width: ${maxMobile}){
              font-size: 10px;
              line-height: 12.793px;
            }
          }
          .similarArticles,
          .date{
            overflow: visible;
          }
        }
      }
    }
    .articleGridTilesWrapper{
      .video-frame-area{
        max-height: 252px;
        min-height: 252px;
        height: 252px;
        @media all and (min-width: ${minExtraLargeScreen}) {
          max-height: 341px;
          min-height: 341px;
          height: 341px;
        }
        @media all and (${retina}) {
          max-height: 236px;
          min-height: 236px;
          height: 236px;
        }
        @media all and (max-width: ${minStandardScreen}) {
          max-height: 172px;
          min-height: 172px;
          height: 172px;
        }
        @media all and (max-width: ${maxTablet}) {
          max-height: 119px;
          min-height: 119px;
          height: 119px;
        }
        @media all and (max-width: ${maxMobile}) {
          max-height: 136px;
          min-height: 136px;
          height: 136px;
        }
        .youtube-icon {
          @media all and (max-width: ${maxMobile}) {
            height: 50px;
          }
        }
      }
      .text-area{
        min-height: unset !important;
        display: flex;
        flex-direction: column;
        padding: 8px 15px 8px;
        gap: 8px;
        @media all and (min-width: ${minExtraLargeScreen}) {
          padding: 9px 25px;
        }
        @media all and (${retina}) {
          padding: 7px 15px;
        }
        @media all and (max-width: ${minStandardScreen}) {
          padding: 6px 13px;
        }
        @media all and (max-width: ${maxTablet}) {
          padding: 5px 10px;
        }
        @media all and (max-width: ${maxMobile}) {
          padding: 6px 9px;
        }
        .heading{
          min-height: unset;
          color: ${textBlack}; 
          font-size: 14px;
          font-style: normal;
          font-weight: ${wMedium};
          line-height: 18px;
          letter-spacing: -0.299px;

          @media all and (min-width: ${minExtraLargeScreen}) {
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.641px;
          }
          @media all and (${retina}) {
            font-size: 12px;
            line-height: normal;
            letter-spacing: -0.299px;
          }
          @media all and (max-width: ${minStandardScreen}) {
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.234px;
          }
          @media all and (max-width: ${maxTablet}) {
            font-size: 10px;
            line-height: 12px;
            letter-spacing: -0.192px;
            min-height: unset;
          }
          @media all and (max-width: ${maxMobile}) {
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.192px;
          }
          &.text-truncate-three-line{
            -webkit-line-clamp: 2;
          }
        }
        .sourcesBox{
          display: flex;
          align-items: center;
          width: 100%;
          margin-top: auto;
          .separatorLeftOnly{
            padding-left: 17px;
            position: relative;
            &::after{
              content: "";
              height: 10px;
              width: 1px;
              background-color: #CFCFCF;
              position: absolute;
              top: 50%;
              left: 8px;
              transform: translateY(-50%);
            }
          }
          .source{
            color: ${greyColorSecondary};
            text-align: center;
            font-family: ${primaryFont};
            font-size: 12px;
            font-style: normal;
            font-weight: ${wMedium};
            line-height: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @media all and (min-width: ${minExtraLargeScreen}) {
              font-size: 14px;
            }
            @media all and (${retina}) {
              font-size: 10px;
            }
            @media all and (max-width: ${minStandardScreen}){
              font-size: 10px;
              line-height: 12.486px;
            }
            @media all and (max-width: ${maxTablet}){
              font-size: 8px;
              line-height: 10.268px
            }
            @media all and (max-width: ${maxMobile}){
              font-size: 10px;
              line-height: 10.264px;
              letter-spacing: -0.6px;
            }
          }
          .similarArticles,
          .date{
            overflow: visible;
          }
        }
      }
    }
  }

  ////////////////////////////////////////////
  ///////////////////////////////////////////
  /////// Conditions

  &.listView{
    flex-direction: column;
    gap: 0;
    padding: 0;
    margin: 0;
    height: auto;
    .articleMainTileWrapper,
    .articleGridTilesWrapper{ 
      display: flex;
      width: 100%;
      min-width: 100%;
      padding: 0;
      margin: 0;
      .tileListView {
        width: 100%;
        .video-frame-area{
          height: 85px;
          min-height: 85px;
          max-height: 85px;
        }
      }
    }
    .articleMainTileWrapper{
      min-height: auto;
    }
    .articleGridTilesWrapper{ 
      gap:0;
      min-height: auto;
    }
  }
  &.isReversed{
    flex-direction: row-reverse;
    .articleMainTileWrapper{
      padding-left: 0;
      padding-right: 16px;
      margin-left: 0;
      margin-right: -16px;
    }
    .articleGridTilesWrapper{ 
      padding-right: 0;
      padding-left: 16px;
      margin-right: 0;
      margin-left: -16px;
    }
    &.listView{
      flex-direction: column-reverse;
      .articleMainTileWrapper{
        padding: 0;
        margin: 0;
      }
      .articleGridTilesWrapper{
        padding: 0;
        margin: 0;
      }
    }
  }
  &.videoGridWrapper{
    &.listView{
      .youtube-icon {
        @media all and (max-width: ${maxMobile}) {
          height: 50px !important;
          width: 50px;
        }
      }
    }
  }
`;

type Props = {
  className?: string,
  children: ReactNode,
  listView?: boolean,
  isReversed?: boolean
} & typeof defaultProps;

const defaultProps = {
  className: '',
  isReversed: false,
  listView: false,
};
const ArticleGridGlobal = function ArticleGridGlobal(props: Props) {
  const {
    className, listView, isReversed, children,
  } = props;
  return (
    <AritclesGridWrapper
      className={classNames(className, {
        listView,
        isReversed,
      })}
    >
      {children}
    </AritclesGridWrapper>
  );
};

ArticleGridGlobal.defaultProps = defaultProps;
export default ArticleGridGlobal;
