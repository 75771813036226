/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from 'Slice/LoginSlice';
import { PayloadAction } from '@reduxjs/toolkit';

import axios from 'Utils/Axios';
import {
  LoginRequest,
  ForgetPasswordRequest,
  ResetPasswordRequest,
  UserProfileRequest,
} from 'Types/RequestBodies';
import { API } from 'Utils/Constants';
import {
  saveUserDetails,
  getCurrentTime,
  valueToObject,
} from 'Utils/UtilityFunctions';
import { ForgetPasswordResponse, LoginResponse, User } from 'Types/LoginTypes';
import { GlobalResponse } from 'Types/ResponseBodies';
import { actions as flurryActions } from 'Slice/HelperSlice';

/**
 * User request/response handlera
 */

export function* loginUser(dispatched: PayloadAction<LoginRequest>) {
  const startTime = getCurrentTime();

  try {
    const response: LoginResponse = yield call(
      axios.post,
      API.LOGIN,
      dispatched?.payload,
    );
    if (response?.status === true) {
      const userInfo: User = response?.response;
      yield put(actions.loginUserLoaded(userInfo));
      saveUserDetails(userInfo, dispatched.payload.rememberMe);
      yield put(
        flurryActions.callFlurryEvent(
          valueToObject('log_in', startTime, dispatched?.payload),
        ),
      );
    } else {
      yield put(actions.loginError(response?.message));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getUserProfile(dispatched: PayloadAction<UserProfileRequest>) {
  const startTime = getCurrentTime();
  yield put(
    flurryActions.callFlurryEvent(
      valueToObject('log_in', startTime, dispatched?.payload),
    ),
  );
  try {
    const response: LoginResponse = yield call(
      axios.post,
      API.USER_PROFILE,
      dispatched?.payload,
    );
    if (response?.status === true) {
      const userInfo: User = response?.response;
      yield put(actions.loginUserLoaded(userInfo));
      saveUserDetails(userInfo);
    }
  } catch (err) {
    console.log(err);
  }
}

// forget password
export function* forgetPassword(
  dispatched: PayloadAction<ForgetPasswordRequest>,
) {
  const startTime = getCurrentTime();

  try {
    const response: ForgetPasswordResponse = yield call(
      axios.post,
      API.FORGET_PASSWORD,
      dispatched?.payload,
    );
    if (response?.status) {
      yield put(actions.forgetPasswordLoaded(true));
      yield put(
        flurryActions.callFlurryEvent(
          valueToObject(
            'forget_password_succeeded',
            startTime,
            dispatched?.payload,
          ),
        ),
      );
    } else if (!response?.status) {
      yield put(actions.loginError(response?.message));
    }
  } catch (err: unknown) {
    console.log(err);
  }
}

// reset password
export function* resetPassword(
  dispatched: PayloadAction<ResetPasswordRequest>,
) {
  const startTime = getCurrentTime();

  try {
    const response: GlobalResponse = yield call(
      axios.post,
      API.RESET_PASSWORD,
      dispatched?.payload,
    );
    if (response?.status) {
      yield put(actions.resetPasswordLoaded(true));
      yield put(
        flurryActions.callFlurryEvent(
          valueToObject(
            'reset_password_succeeded',
            startTime,
            dispatched?.payload,
          ),
        ),
      );
    } else if (!response?.status) {
      yield put(actions.resetPasswordError(response?.message));
    }
  } catch (error: unknown) {
    console.log(error);
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* userAuthSaga() {
  // Watches for loadUser actions and calls getUser when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.loginUser.type, loginUser);
  yield takeLatest(actions.forgetPassword.type, forgetPassword);
  yield takeLatest(actions.resetPassword.type, resetPassword);
  yield takeLatest(actions.getUserProfile.type, getUserProfile);
}
