import { Modal } from 'antd';
import CrossCircle from 'Components/common/assets/svgIcons/CrossCircle';
import styled from 'styled-components';

const PopupModal = styled(Modal)`
  .ant-modal-content{
    .ant-modal-footer{
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;
    }
  }
`;

type Props = {
  text: string;
  visible: boolean;
  handleOk: () => void;
  handlePopupCancel: () => void;
};

const DeletePopup = function DeletePopup(props: Props) {
  const {
    text, visible, handleOk, handlePopupCancel,
  } = props;

  return (
    <PopupModal
      visible={visible}
      onOk={handleOk}
      onCancel={handlePopupCancel}
      centered
      cancelText="No"
      okText="Yes"
      closeIcon={<CrossCircle />}
      width="530px"
    >
      <p className="text_logout_popup main-message">{text}</p>
    </PopupModal>
  );
};
export default DeletePopup;
