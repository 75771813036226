/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { NotesState } from 'Types/GlobalTypes';
import { EntityNotes } from 'Types/NotesTypes';
import { Executive } from 'Types/PersonTypes';
import {
  AddNoteRequestPayload,
  EditNoteRequestPayload,
  GetPersonNotesRequest,
} from 'Types/RequestBodies';

export const initialState: NotesState = {
  currentUser: null,
  personNotes: {
    notesLoading: false,
    notes: [],
    notesEnded: false,
    notesPageNumber: 1,
  },
  addNoteLoading: false,
  noteAdded: false,
  deleteNoteLoading: false,
  noteDeleted: false,
  editNoteLoading: false,
  noteEdited: false,
  notesDrawerOpen: false,
  alertsNofiticationId: 0,
};

const PersonProfileSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    getPersonNotes(state, action: PayloadAction<GetPersonNotesRequest>) {
      const { payload } = action;
      state.personNotes.notesLoading = true;
      if (payload.page === 1) {
        state.personNotes.notesEnded = false;
        state.personNotes.notes = [];
        state.personNotes.notesPageNumber = 1;
      }
      state.currentUser = null;
    },
    setPersonNotes(state, action: PayloadAction<EntityNotes[]>) {
      const notes = action.payload;
      state.personNotes.notes = [...state.personNotes.notes, ...notes];
      state.personNotes.notesPageNumber += 1;
      state.personNotes.notesLoading = false;
      state.personNotes.notesEnded = !notes?.length;
    },
    setCurrentUser(state, action: PayloadAction<Executive | null>) {
      state.currentUser = action.payload;
    },
    getPersonNotesFailed(state) {
      state.personNotes.notesLoading = false;
      state.personNotes.notesEnded = false;
    },
    resetPersonNotes(state) {
      state.personNotes.notesLoading = false;
      state.personNotes.notesEnded = false;
      state.personNotes.notes = [];
      state.personNotes.notesPageNumber = 1;
    },
    addPersonNote(state, action: PayloadAction<AddNoteRequestPayload>) {
      state.addNoteLoading = true;
      state.noteAdded = false;
    },
    personNoteAdded(state, action: PayloadAction<AddNoteRequestPayload>) {
      const { payload } = action;
      const obj: EntityNotes = {
        comment: {
          changed: false,
          dateTimeStamp: 'Just now',
          editable: !!payload.noteId,
          noteId: payload.noteId || 0,
          text: payload.note,
          person: payload.person,
          entities: {
            personMentions: payload.taggedPersons,
          },
        },
      };
      state.personNotes.notes = [obj, ...state.personNotes.notes];
      state.addNoteLoading = false;
      state.noteAdded = true;
    },
    addPersonNoteFailed(state) {
      state.addNoteLoading = false;
      state.noteAdded = false;
    },
    editPersonNote(state, action: PayloadAction<EditNoteRequestPayload>) {
      state.editNoteLoading = true;
      state.noteEdited = false;
    },
    personNoteEdited(state, action: PayloadAction<EditNoteRequestPayload>) {
      const { payload } = action;
      state.editNoteLoading = false;
      const editedNote = state.personNotes.notes
        .find((note) => (payload.type !== 'notes'
          ? note.comment?.reasonId === payload.reasonId
          : note.comment?.noteId === payload.noteId));
      if (editedNote && editedNote.comment) {
        editedNote.comment.dateTimeStamp = 'Just now';
        editedNote.comment.changed = true;
        editedNote.comment.text = payload.note || payload.reasonText || '';
        editedNote.comment.editable = true;
        if (editedNote.comment.entities) {
          editedNote.comment.entities.personMentions = payload.taggedPersons;
        }
      }
      state.noteEdited = true;
    },
    editPersonNoteFailed(state) {
      state.editNoteLoading = false;
      state.noteEdited = false;
    },
    deletePersonNote(state, action: PayloadAction<EditNoteRequestPayload>) {
      state.deleteNoteLoading = true;
      state.noteDeleted = false;
    },
    personNoteDeleted(state, action: PayloadAction<number>) {
      const { payload } = action;
      state.deleteNoteLoading = false;
      state.personNotes.notes = state.personNotes.notes
        .filter((note) => note.comment?.noteId !== payload);
      state.personNotes.notesEnded = !state.personNotes.notes.length;
      state.noteDeleted = true;
    },
    deletePersonNoteFailed(state) {
      state.deleteNoteLoading = false;
      state.noteDeleted = false;
    },
    setNotesDrawerOpen(state, action: PayloadAction<boolean>) {
      state.notesDrawerOpen = action.payload;
    },
    setAlertsNotificationId(state, action: PayloadAction<number>) {
      state.alertsNofiticationId = action.payload;
    },
    reset: () => initialState,
  },
});

export const { actions, reducer, name: sliceKey } = PersonProfileSlice;
