import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Article } from 'Types/ArticleTypes';
import { CustomWidget, CustomWidgetNewsResponse } from 'Types/CustomWidgetTypes';
import { CustomWidgetState } from 'Types/GlobalTypes';
import { AddToFavoriteRequest, CustomWidgetNewsRequest } from 'Types/RequestBodies';
import { mergeFilters } from 'Utils/UtilityFunctions';

export const initialState: CustomWidgetState = {
  widgetLoading: false,
  customWidgetData: [],
  newsLoading: false,
  paginationLoader: false,
  newsData: {
    articles: [], widgetTitle: '',
  },
  newsPageNumber: 1,
  newsEnded: false,
  newsFilter: [],
  selectedNewsFilter: [],
};

const CustomWidgetSlice = createSlice({
  name: 'customWidget',
  initialState,
  reducers: {
    getCustomWidgetData(state) {
      state.widgetLoading = true;
    },
    setCustomWidgetData(state, action: PayloadAction<CustomWidget[]>) {
      state.widgetLoading = false;
      state.customWidgetData = action.payload;
    },
    getCustomWidgetNewsData(state, action: PayloadAction<CustomWidgetNewsRequest>) {
      const { payload } = action;
      if (payload.page === 1) {
        state.newsLoading = true;
        state.newsPageNumber = 1;
        state.newsData.articles = [];
        if (!payload.filters) {
          state.newsFilter = [];
          state.selectedNewsFilter = [];
        } else {
          state.selectedNewsFilter = payload.filters;
        }
      } else {
        state.paginationLoader = true;
      }
    },
    setCustomWidgetNewsData(state, action: PayloadAction<CustomWidgetNewsResponse>) {
      if (state.newsPageNumber === 1) {
        state.newsLoading = false;
        state.newsData = action.payload?.response;
        state.newsFilter = state.newsFilter?.length ? state.newsFilter
          : mergeFilters(action.payload?.response?.filter || []);
      } else if (action.payload) {
        state.newsData.articles = [...state.newsData.articles as Article[],
          ...action.payload.response.articles];
      }
      state.paginationLoader = false;
      state.newsPageNumber += 1;
      state.newsEnded = !action.payload?.response.articles.length;
    },
    setWidgetLoading(state, action:PayloadAction<boolean>) {
      state.widgetLoading = action.payload;
    },
    setNewsLoading(state, action:PayloadAction<boolean>) {
      state.newsLoading = action.payload;
    },
    addToFavoriteSuccess(state, action: PayloadAction<AddToFavoriteRequest>) {
      const { articleId, favoriteFlag } = action.payload;
      if (articleId) {
        const { articles } = state.newsData;
        if (articles && articles.length) {
          const matchedArticles = articles.filter(
            (article: Article) => article.articleId === articleId,
          );
          if (matchedArticles.length > 0) {
            matchedArticles.forEach((arrayItem: Article) => {
              const article = arrayItem;
              article.isFavorite = favoriteFlag === 1;
            });
          }
        }
      }
    },
    resetNewsData(state) {
      state.newsLoading = false;
      state.newsData = { articles: [], filter: [], widgetTitle: '' };
      state.newsPageNumber = 1;
      state.newsEnded = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = CustomWidgetSlice;
