/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BranchState } from 'Types/GlobalTypes';

export const initialState: BranchState = {
  loading: true,
  data: null,
};

const branchIOSlice = createSlice({
  name: 'branchIO',
  initialState,
  reducers: {
    getBranchinit(state, action: PayloadAction<any>) {
      state.loading = false;
      state.data = action.payload;
    },
    reset(state) {
      state.data = null;
    },
  },
});

export const { actions, reducer, name: sliceKey } = branchIOSlice;
