/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable import/extensions */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ProfileShareState } from 'Types/GlobalTypes';
import { BranchIORequest } from 'Types/RequestBodies';

export const initialState: ProfileShareState = {
  loading: false,
  link: '',
  copyModalOpen: false,
};

const ProfileShareSlice = createSlice({
  name: 'profileShare',
  initialState,
  reducers: {
    getProfileShare(state, action: PayloadAction<BranchIORequest>) {
      state.loading = true;
      state.link = '';
    },

    setProfileShare(state, action: PayloadAction<string>) {
      state.loading = false;
      state.link = action.payload;
    },

    failProfileShare(state, action: PayloadAction<string>) {
      state.loading = false;
      state.link = '';
    },
    setCopyModalOpen(state, action: PayloadAction<boolean>) {
      state.copyModalOpen = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = ProfileShareSlice;
