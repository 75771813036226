import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'Store/InjectorsTypes';
import { initialState } from 'Slice/ArticlesSlice';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state?.articleDetail || initialState;
export const selectLoading = createSelector([selectDomain], (state) => state.loading);
export const selectArticle = createSelector([selectDomain], (state) => state.article);
export const selectArticleId = createSelector([selectDomain], (state) => state.articleId);

export const selectDeepLoading = createSelector([selectDomain], (state) => state.deepLinkLoading);
export const selectDeepLink = createSelector([selectDomain], (state) => state.deepLink);
export const selectSimilarArticles = createSelector(
  [selectDomain],
  (state) => state.similarArticle.similarArticles,
);
export const selectArticleDrawerOpen = createSelector(
  [selectDomain],
  (state) => state.articleDrawerOpen,
);

export const selectSimilarArticleLoading = createSelector(
  [selectDomain],
  (state) => state.similarArticleLoading,
);

export const selectSimilarArticleTwoLoading = createSelector(
  [selectDomain],
  (state) => state.similarArticleTwoLoading,
);

export const selectSimilarArticleCount = createSelector(
  [selectDomain],
  (state) => state.similarArticle.similarArticlesCount,
);

export const selectSimilarArticleTwo = createSelector(
  [selectDomain],
  (state) => state.similarArticleTwo.similarArticles,
);

export const selectSimilarArticleCountTwo = createSelector(
  [selectDomain],
  (state) => state.similarArticleTwo.similarArticlesCount,
);

export const selectSimilarArticleDetail = createSelector(
  [selectDomain],
  (state) => state.similarArticleDetail,
);

export const selectSimilarArticleDetailLoading = createSelector(
  [selectDomain],
  (state) => state.similarArticleDetailLoading,
);

export const selectSimilarArticleEnded = createSelector(
  [selectDomain],
  (state) => state.similarArticleEnded,
);

export const selectSimilarArticleTwoEnded = createSelector(
  [selectDomain],
  (state) => state.similarArticleTwoEnded,
);

export const selectArticleSummary = createSelector(
  [selectDomain],
  (state) => state.articleSummary,
);

export const selectArticleSummaryLoading = createSelector(
  [selectDomain],
  (state) => state.articleSummaryLoading,
);

export const selectArticleSummaryLoaded = createSelector(
  [selectDomain],
  (state) => state.articleSummaryLoaded,
);

export const selectSimilarArticleSummary = createSelector(
  [selectDomain],
  (state) => state.similarArticleSummary,
);

export const selectSimilarArticleSummaryLoading = createSelector(
  [selectDomain],
  (state) => state.similarArticleSummaryLoading,
);

export const selectSimilarArticleSummaryLoaded = createSelector(
  [selectDomain],
  (state) => state.similarArticleSummaryLoaded,
);

export const selectArticleItemSummary = createSelector(
  [selectDomain],
  (state) => state.articleItemSummary,
);

export const selectArticleItemSummaryLoading = createSelector(
  [selectDomain],
  (state) => state.articleItemSummaryLoading,
);

export const selectArticleItemSummaryLoaded = createSelector(
  [selectDomain],
  (state) => state.articleItemSummaryLoaded,
);

export const selectArticleItemId = createSelector(
  [selectDomain],
  (state) => state.articleItemId,
);

export const selectAlertDrawerVisible = createSelector(
  [selectDomain],
  (state) => state.alertDrawerVisible,
);

export const selectSimilarDrawerVisible = createSelector(
  [selectDomain],
  (state) => state.similarDrawerVisible,
);
