import React from 'react';

type Props = {
  className?: string
  onClick?: () => void,
} & typeof defaultProps;

const defaultProps = {
  className: '',
  onClick: () => { },
};

const SmartInputRemoveIcon = function SmartInputRemoveIcon(props: Props) {
  const { className, onClick } = props;
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} className={className}>
      <rect width="18" height="18" rx="9" fill="#F5222D" fillOpacity="0.22" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3 10H15V8H3V10Z" fill="#E3433F" />
    </svg>
  );
};

SmartInputRemoveIcon.defaultProps = defaultProps;
export default SmartInputRemoveIcon;
