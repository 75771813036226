import { createGlobalStyle } from 'styled-components';
import { isSalesforce } from 'Utils/UtilityFunctions';
import { gillRoyDesk } from 'Utils/utilsStyle';

const FreshBotStyle = createGlobalStyle`
  .freshDesk-Bot {
   /* display: ${isSalesforce() ? 'none' : 'block'}; */
   display: block;
   position: fixed;
   right: 80px;
   bottom: 80px;
   z-index: 21474836001;
   cursor: pointer;
  }

  .freshDesk-Bot::after {
   content: '';
   padding: 0px;
   background-image: url(${gillRoyDesk});
   background-position: center;
   background-size: cover;
   width: 60px;
   height: 60px;
   top: 0px;
   left: 0px;
   position: absolute;
   @media all and (max-width: 992px ) {
    width: 50px;
    height: 50px;
    left: 10px;
    top: 9px;
   }
  }
`;

export default FreshBotStyle;
