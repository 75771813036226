import React from 'react';

type Props = {
  color?: string;
};

const defaultProps = {
  color: '#7E7E7E',
};
const ListViewIcon: React.FC<Props> = function ListViewIcon({ color }) {
  return (
    <svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="2.12285" cy="16.9309" r="2.12285" transform="rotate(-90 2.12285 16.9309)" fill={color} />
      <circle cx="2.12285" cy="9.85469" r="2.12285" transform="rotate(-90 2.12285 9.85469)" fill={color} />
      <circle cx="2.12285" cy="2.77803" r="2.12285" transform="rotate(-90 2.12285 2.77803)" fill={color} />
      <rect x="6.36841" y="1.36279" width="19.8132" height="2.83046" rx="1.41523" fill={color} />
      <rect x="6.36841" y="8.43896" width="19.8132" height="2.83046" rx="1.41523" fill={color} />
      <rect x="6.36841" y="15.5151" width="19.8132" height="2.83046" rx="1.41523" fill={color} />
    </svg>
  );
};
ListViewIcon.defaultProps = defaultProps;
export default ListViewIcon;
