/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */

import axios from 'Utils/Axios';
import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { actions } from 'Slice/CustomWidgetSlice';
import { CustomWidgetNewsResponse, CustomWidgetResponse } from 'Types/CustomWidgetTypes';
import { API } from 'Utils/Constants';
import { getCurrentTime, valueToObject } from 'Utils/UtilityFunctions';
import { CustomWidgetNewsRequest } from 'Types/RequestBodies';
import { PayloadAction } from '@reduxjs/toolkit';
import { actions as helperActions } from 'Slice/HelperSlice';

export function* getCustomWidgetData() {
  try {
    const response: CustomWidgetResponse = yield call(
      axios.post,
      API.GET_CUSTOM_WIDGET_DATA,
      {},
    );
    if (response?.status === true) {
      const widgets = response?.response.widgets;
      yield put(actions.setCustomWidgetData(widgets));
    } else {
      yield put(actions.setWidgetLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* getCustomWidgetNewsData(dispatched: PayloadAction<CustomWidgetNewsRequest>) {
  const startTime = getCurrentTime();
  const { payload } = dispatched;
  const AppliedFilters = payload?.filters?.filter(
    (item) => item.isSelected,
  ).map((item) => ({
    id: item.filterId,
    type: item.filterTypeName,
  }));
  const obj = {
    widget: payload?.widget,
    page: payload?.page,
    ...payload.filters && {
      filters: AppliedFilters,
    },
  };
  try {
    const response: CustomWidgetNewsResponse = yield call(
      axios.post,
      API.GET_WIDGET_NEWS,
      obj,
    );
    const { widgetTitle } = response.response;
    if (response?.status === true) {
      yield put(actions.setCustomWidgetNewsData(response));
      yield put(helperActions.callFlurryEvent(
        valueToObject('digest_widget_page_load', startTime, {
          ...payload,
          ...payload.page && { current_page: payload?.page },
          widget_name: widgetTitle,
        }),
      ));
    } else {
      yield put(actions.setNewsLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* CustomWidgetSaga() {
  yield takeLatest(actions.getCustomWidgetData.type, getCustomWidgetData);
  yield takeLatest(actions.getCustomWidgetNewsData.type, getCustomWidgetNewsData);
}
