import { useState } from 'react';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import {
  maxMobile,
  maxTablet,
  maxWidth, midWidthApp, minTablet, primaryColor,
  primaryFont, retina, scrollbar, secondaryFont, wLight, wMBold, wMedium,
} from 'Utils/utilsStyle';
import HTMLReactParser from 'html-react-parser';
import { TooltipPlacement } from 'antd/lib/tooltip';
import classNames from 'classnames';
import { valueToObject } from 'Utils/UtilityFunctions';
import { actions as flurryActions } from 'Slice/HelperSlice';
import { useDispatch } from 'react-redux';
import VideoPopup from '../modal/VideoPopup';
import PlayIcon from '../assets/svgIcons/PlayIcon';
import InfoIconTwo from '../assets/svgIcons/InfoIconTwo';

export const Wrapper = styled.span<{
  midTabletFlag: boolean,
  widthIcon?: string,
  widthIconSm?: string,
  widthIconXSm?: string,
  heightIcon?: string,
  heightIconSm?: string,
  heightIconXSm?: string,
  customWidth?: string,
}>`
&.smartTooltip {
  .ant-tooltip {
    /* left: 0px !important; */
    .ant-tooltip-content {
      .ant-tooltip-arrow {
        display: none;
      }
      .ant-tooltip-inner {
        padding: 10px;
        .tooltip_wrapper {
          padding: 10px 10px 10px 0px;
          max-height: 220px;
          ${scrollbar};
          .tooltip-text-tour {
            font-size: 14px;
            line-height: 18px;
            margin-left: 10px;
            &.green-text {
              margin: 0px;
            }
            ul {
              li {
                margin-bottom: 10px;
                margin-left: -20px;
                &:last-child {
                  margin-bottom: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
} 
  .ant-tooltip {
    @media all and (max-width: ${maxMobile}) {
      padding-top: 0px !important;
      padding-left: 10px;
    }
    &.right-tip {
      .ant-tooltip-content { 
        @media all and (max-width: ${maxWidth}) and (min-width: ${maxMobile}){
         min-width: 250px;
         width: 250px;
        }
      }
    }
  }
  .tooltip_wrapper{
    padding: 10px;
    display: flex;
    flex-direction: column;
    .layer{
      margin-left: auto;
    }
    .tooltip-text-tour {
      font-family: ${primaryFont};
      font-size: 14px;
      line-height: 16px;
      font-weight: ${wLight};
      color: #000000;
      white-space: pre-wrap;
      &.green-text {
        font-family: ${secondaryFont};
        color: ${primaryColor};
        font-weight: ${wMBold};
      }
      .tooltip-email-link {
        color: ${primaryColor};
        text-decoration: underline;
      }
      &.example-text {
        margin-left: 0px !important;
      }
      &.htmlTextStyles {
        font-weight: ${wMedium};
        color: #5E7079;
      }
    }
    .tooltip-text-tour-video {
      cursor: pointer;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #616161;
      display: flex;
      align-items: center;
      margin-top: 16px;
      svg {
        margin-right: 5px;
      }
    }
  }
  .tooltip-text {
    color: black;
    font-family: ${primaryFont};
    font-size: 13px;
    line-height: 13px;
    font-weight: ${wLight};
    white-space: pre-wrap;
    .bold {
      font-weight: 900;
      margin-right: 5px;
    }
    .light {
      margin-left:5px;
    }
    svg {
      max-width: 17px;
      background-color: lightgray;
      height: 15px;
      width: 17px;
      max-height: 15px;
      border: 3px solid lightgray;
      border-radius: 10px;    
      margin: 0px;
      margin: -4px 5px;  
      g, path {
          fill: gray;
      }
    }
  }
  .ant-tooltip-content {
    .ant-tooltip-arrow {
      @media all and (max-width: ${maxMobile}) {
        display: none;
      }
    }
      width: ${(props) => (props.customWidth ? props.customWidth : '350px')};
      min-width: ${(props) => (props.customWidth ? props.customWidth : '350px')};
      border-radius: 10px;
      @media all and (max-width: ${maxWidth}), ${retina} {
        width: ${(props) => (props.customWidth ? props.customWidth : '455px')};
        min-width:  ${(props) => (props.customWidth ? props.customWidth : '285px')};
      }
      @media all and (max-width: ${midWidthApp}) {
         width: ${(props) => (props.midTabletFlag ? '300px' : '455px')};
      }
      @media all and (max-width: ${minTablet}) {
        width: ${(props) => (props.midTabletFlag ? '285px' : '455px')};
      }
      @media all and (max-width: ${maxMobile}) {
        width: ${(props) => (props.midTabletFlag ? '200px' : '250px')};
        min-width: ${(props) => (props.midTabletFlag ? '200px' : '250px')};
      }
   }
  .ant-tooltip-inner {
    border-radius: 10px;
   }
   .popup_wrapper{
    z-index: 1000;
   }
  .icon-area{
    display: flex;
    margin-left: 8px;
    height: ${(props) => props.heightIcon};
    width: ${(props) => props.widthIcon};
    @media all and (max-width: ${maxWidth}) , ${retina} {
      height: ${(props) => props.heightIconSm};
      width: ${(props) => props.widthIconSm};
    }
    @media all and (max-width: ${maxTablet}) {
      height: ${(props) => props.heightIconXSm};
      width: ${(props) => props.widthIconXSm};
    }
  }
`;

type Props = {
  widthIcon?: string,
  widthIconSm?: string,
  widthIconXSm?: string,
  heightIcon?: string,
  heightIconSm?: string,
  heightIconXSm?: string,
  toolTipText?: string,
  exampleText?: string,
  toolPlacement?: TooltipPlacement,
  videoLink?: string,
  classNameOverlay?: string,
  midTabletFlag?: boolean,
  htmlFlag?: boolean,
  customWidth?: string,
  pageType?: string
} & typeof defaultProps;

const defaultProps = {
  widthIcon: '20px',
  widthIconSm: '16px',
  widthIconXSm: '12px',
  heightIcon: '19px',
  heightIconSm: '18px',
  heightIconXSm: '20px',
  toolTipText: '',
  exampleText: '',
  toolPlacement: 'bottomLeft',
  videoLink: '',
  classNameOverlay: '',
  midTabletFlag: false,
  htmlFlag: false,
  customWidth: '',
  pageType: '',
};

const HeadingWithToolTip = function HeadingWithToolTip(props: Props) {
  const {
    toolTipText, videoLink, classNameOverlay, midTabletFlag, widthIcon,
    widthIconSm, heightIcon, heightIconSm, heightIconXSm, widthIconXSm,
    customWidth, toolPlacement, htmlFlag, exampleText, pageType,
  } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const dispatch = useDispatch();
  const host = window.location.href;

  const isVisible = () => {
    if (!visible && pageType === 'people') {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('peopleDashboard_buyerGroup_tooltip_video_intent', 0, {
          host,
        }),
      ));
    }
    setVisible(!visible);
  };

  const tooltipText = (
    <div className="tooltip_wrapper">
      <span className={classNames('tooltip-text-tour', { htmlTextStyles: htmlFlag })}>
        {HTMLReactParser(toolTipText)}
        {exampleText !== '' ? (
          <div className="d-flex flex-column mt-c-10">
            <span className={classNames('tooltip-text-tour green-text')}>
              Example
            </span>
            <span className={classNames('tooltip-text-tour mt-c-10 example-text', { htmlTextStyles: htmlFlag })}>{HTMLReactParser(exampleText)}</span>
          </div>
        ) : null}
      </span>
      {videoLink ? (
        <div
          className="layer tooltip-text-tour-video"
          onClick={isVisible}
          onKeyDown={isVisible}
          tabIndex={0}
          role="button"
          aria-label="Play on youtube"
        >
          <PlayIcon />
          Watch Video
        </div>
      ) : null}
    </div>
  );

  return (
    <Wrapper
      className={classNames('global-tooltip-element', { smartTooltip: htmlFlag })}
      midTabletFlag={midTabletFlag}
      widthIcon={widthIcon}
      widthIconSm={widthIconSm}
      widthIconXSm={widthIconXSm}
      heightIcon={heightIcon}
      heightIconSm={heightIconSm}
      heightIconXSm={heightIconXSm}
      customWidth={customWidth}
    >
      <Tooltip
        placement={toolPlacement || 'bottomLeft'}
        title={tooltipText}
        getPopupContainer={(e: HTMLElement) => e}
        className="tooltip section-detail-tip"
        color="#fff"
        zIndex={1}
        overlayClassName={classNameOverlay}
      >
        <span className="icon-area">
          <InfoIconTwo />
        </span>
      </Tooltip>
      <VideoPopup videoLink={videoLink} visible={visible} setVisible={isVisible} />
    </Wrapper>
  );
};
HeadingWithToolTip.defaultProps = defaultProps;
export default HeadingWithToolTip;
