import { createGlobalStyle } from 'styled-components';
import { maxMobile, maxTablet, primaryBgColor } from 'Utils/utilsStyle';

const ResponsiveStyle = createGlobalStyle`
  @media all and (max-width: ${maxTablet}){
    .responsive-logo {
      &.dailyIqNoHam {
        display: none;
        ~div {
          width: 100%;
        }
      }
      padding: 5px 25px 0px 25px;
      background-color: ${primaryBgColor};
      margin-top: -5px;
      svg {
        width: 35px;
        font-size: 20px;
        color: #444B4F;
      }
    }
    .ant-layout-content {
      &.content-custom-layout {
        /* margin-top: 112px;
        @media all and (max-width: ${maxMobile}){
          margin-top: 50px;
        } */
      }
    }
    .header-wrapper {
      width: calc(100% - 60px);
      &.onboarding-header {
        width: calc(100% - 0px);
      }
      .left-side-wrap {
        .heading {
          font-size: 18px !important;
          line-height: 20px !important;
        }
        svg {
          font-size: 16px;
        }
      }
    }
  }
  @media all and (max-width: ${maxMobile}){
    .responsive-logo {
     padding: 5px 10px 0px 10px; 
    }
    .change-view-sm {
      flex-direction: column;
      .left-side, .right-side {
        width: 100%;
      }
    }
  }
  .menu-drawer {
    .ant-drawer-header {
      display: none;
    }
    .ant-drawer-content-wrapper {
      height: 403px;
      top: 65px; 
    }
    .ant-drawer-content {
      border-radius: 0px 8px 8px 0px;
    }
  }
`;

export default ResponsiveStyle;
