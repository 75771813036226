/* eslint-disable react/no-unknown-property */
import React from 'react';

const Logo = function Logo() {
  return (
    <svg width="50" height="30" viewBox="0 0 50 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="50" height="30" fill="none" />
      <path fillRule="evenodd" clipRule="evenodd" d="M36.2122 21.0733C32.3377 21.0733 29.1852 17.9208 29.1852 14.0462C29.1852 10.1714 32.3377 7.01936 36.2122 7.01936C40.0868 7.01936 43.2393 10.1714 43.2393 14.0462C43.2393 14.9816 43.052 15.8726 42.7191 16.6894L46.4394 20.4101C47.5911 18.563 48.2587 16.3829 48.2587 14.0462C48.2587 7.39338 42.8651 2 36.2122 2C29.5591 2 24.166 7.39338 24.166 14.0462C24.166 20.6993 29.5591 26.0926 36.2122 26.0926C38.4623 26.0926 40.5671 25.4738 42.3685 24.4L38.6111 20.6427C37.8614 20.916 37.0559 21.0733 36.2122 21.0733Z" fill="#001760" />
      <path fillRule="evenodd" clipRule="evenodd" d="M47.0141 21.5894L43.2431 17.8184L40.7418 15.3172L40.3975 14.973L39.8817 15.4888L37.1123 18.2583L39.9553 21.1014L43.7298 24.8759L45.3578 26.5034L45.8736 27.0193L46.2177 26.6751L49.1586 23.7339L47.0141 21.5894Z" fill="#001760" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.9228 2C22.6855 2 24.1662 3.48036 24.1662 5.24334C24.1662 7.00564 22.6855 8.48648 20.9228 8.48648C19.1606 8.48648 17.6797 7.00564 17.6797 5.24334C17.6797 3.48036 19.1606 2 20.9228 2Z" fill="#27CE0A" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.62272 23.0401C9.04637 22.3552 9.30718 21.6715 9.3396 21.6025C9.3727 21.7052 9.69838 22.441 10.122 23.0572L12.5951 27.0193H17.6795L12.2085 18.5725L17.4899 10.3398H12.3717L10.0896 13.8494C9.69838 14.4655 9.3396 15.3646 9.3396 15.3646C9.3396 15.3646 8.9484 14.5121 8.55752 13.8621L6.30819 10.3398H1.18998L6.43827 18.5725L1 27.0193H6.08545L8.62272 23.0401Z" fill="#27CE0A" />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="17" y="10" width="6" height="17"><path fillRule="evenodd" clipRule="evenodd" d="M17.8535 10.515H22.2426V26.8113H17.8535V10.515Z" fill="white" /></mask>
      <g mask="url(#mask0)"><path fillRule="evenodd" clipRule="evenodd" d="M17.8535 23.7646L19.7654 26.8113H22.2426V10.515H19.5468L17.8537 13.1965L17.8535 23.7646Z" fill="#001760" /></g>
    </svg>
  );
};
export default Logo;
