/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AffinitySearchResultsState, SearchResultSection, SearchResultsRequest } from 'Types/AffinitySearchTypes';
import { PersonCardId } from 'Types/ChatBotV2Types';
import { PersonLinkedinSearch } from 'Types/GlobalSearchResultsTypes';

export const initialState: AffinitySearchResultsState = {
  searchText: '',
  searchResultsPage: 0,
  isAffinitySearchLoading: false,
  isAffinitySearchLoaded: false,
  isPersonExtendedLoaded: false,
  isPersonExtendedLoading: false,
  buyerSearchText: '',
  buyersearchResultsPage: 0,
  isBuyerAffinitySearchLoading: false,
  isbuyerAffinitySearchLoaded: false,
  isbuyerPersonExtendedLoading: false,
  isbuyerPersonExtendedLoaded: false,
  BuyererrorText: '',
  errorText: '',
  peopleSection: {
    title: '',
    type: '',
    count: 0,
    firstCount: 0,
    linkedInCount: 0,
    persons: [],
    followedPersons: [],
  },
  buyerPeopleSection: {
    title: '',
    type: '',
    count: 0,
    firstCount: 0,
    linkedInCount: 0,
    persons: [],
    followedPersons: [],
  },
  isPagination: false,
  selectedPersonId: 0,
};

const useAffinitySearchResultsSlice = createSlice({
  name: 'affinitySearchResults',
  initialState,
  reducers: {
    affinitySearchResultsLoading(state, action: PayloadAction<SearchResultsRequest>) {
      const { payload } = action;
      const { from } = payload;
      state.searchText = payload.query;
      state.searchResultsPage = payload.page || 1;
      if (from === 'buyerSearch') {
        state.isbuyerPersonExtendedLoading = true;
        state.isBuyerAffinitySearchLoading = true;
      } else {
        state.isPersonExtendedLoading = true;
        state.isAffinitySearchLoading = true;
        state.isAffinitySearchLoaded = false;
      }
      state.isPersonExtendedLoaded = false;
      state.errorText = '';
    },
    affinitySearchResultsLoaded(state, action: PayloadAction<SearchResultSection[]>) {
      state.isAffinitySearchLoading = false;
      state.isAffinitySearchLoaded = true;
      state.isPagination = false;
      const { payload } = action;
      if (payload.length > 0) {
        const people = payload[0];
        if (people.type === 'Executives') {
          if (state.peopleSection.persons && people.persons) {
            state.peopleSection = {
              ...people,
              persons: [...state.peopleSection.persons, ...people.persons],
            };
          }
        }
      }
    },
    buyerAffinitySearchResultsLoaded(state, action: PayloadAction<SearchResultSection[]>) {
      state.isAffinitySearchLoading = false;
      state.isAffinitySearchLoaded = true;
      state.isPagination = false;
      state.isbuyerAffinitySearchLoaded = false;
      state.isbuyerPersonExtendedLoaded = true;
      const { payload } = action;
      if (payload.length > 0) {
        const people = payload[0];
        if (people.type === 'Executives') {
          if (state.buyerPeopleSection.persons && people.persons) {
            state.buyerPeopleSection = {
              ...people,
              persons: [...state.buyerPeopleSection.persons, ...people.persons],
            };
          }
        }
      }
    },
    buyerAffinitySearchResultReset(state) {
      state.buyerSearchText = '';
      state.buyersearchResultsPage = 0;
      state.isBuyerAffinitySearchLoading = false;
      state.isbuyerAffinitySearchLoaded = false;
      state.isbuyerPersonExtendedLoaded = false;
      state.isbuyerPersonExtendedLoading = false;
      state.BuyererrorText = '';
      state.buyerPeopleSection = {
        title: '',
        type: '',
        count: 0,
        firstCount: 0,
        linkedInCount: 0,
        persons: [],
        followedPersons: [],
      };
    },
    affinitySearchResultsError(state, action: PayloadAction<string>) {
      state.isAffinitySearchLoading = false;
      state.isAffinitySearchLoaded = false;
      state.isPersonExtendedLoaded = false;
      state.isPersonExtendedLoading = false;
      state.errorText = action.payload;
      state.isPagination = false;
    },

    affinitySearchResultsPersonExtendedLoaded(state, action: PayloadAction<PersonLinkedinSearch>) {
      const { payload } = action;
      state.isPersonExtendedLoaded = true;
      state.isPersonExtendedLoading = false;

      state.peopleSection.persons = [
        ...(state.peopleSection.persons ?? []),
        ...payload.results,
      ];

      state.peopleSection.linkedInCount = payload.count ?? 0;
    },
    affinityBuyerSearchResultsPersonExtendedLoaded(
      state,
      action: PayloadAction<PersonLinkedinSearch>,
    ) {
      const { payload } = action;
      state.isPersonExtendedLoaded = true;
      state.isbuyerPersonExtendedLoading = false;
      state.isBuyerAffinitySearchLoading = false;
      state.buyerPeopleSection.persons = [
        ...(state.buyerPeopleSection.persons ?? []),
        ...payload.results,
      ];

      state.buyerPeopleSection.linkedInCount = payload.count ?? 0;
    },

    affinitySetSelectedPersonId(state, action: PayloadAction<number>) {
      state.selectedPersonId = action.payload;
    },
    personAddedToGroup(state, action: PayloadAction<number>) {
      state.peopleSection.persons?.map((person) => {
        const p = person;

        if (p.personId === action.payload) p.personAdded = !person.personAdded;

        return p;
      });
    },
    setLiveToExecutiveIdAccountPlan(state, action: PayloadAction<PersonCardId>) {
      const { livesearchId, executiveId } = action.payload;
      const updatedSearch = state.peopleSection.persons?.map(
        (person) => (
          person.personId === livesearchId ? { ...person, personId: executiveId } : person),
      );
      state.peopleSection.persons = updatedSearch;
    },
    affinitySearchResultReset: () => initialState,
  },
});

export const { actions, reducer, name: sliceKey } = useAffinitySearchResultsSlice;
