import React from 'react';
import styled from 'styled-components';
import { wMBold, primaryHeadingColor, secondaryFont } from 'Utils/utilsStyle';

const Wrapper = styled.div`
  height: calc(100vh - 250px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    font-size: 22px;
    font-family: ${secondaryFont};
    font-weight: ${wMBold};
    color: ${primaryHeadingColor};
  }
`;

type Props = {
  title?: string,
} & typeof defaultProps;

const defaultProps = {
  title: '',
};

const NoDataFound = function NoDataFound(props: Props) {
  const { title } = props;
  return (
    <Wrapper className="no_data_found">
      <h3>{title}</h3>
    </Wrapper>
  );
};

NoDataFound.defaultProps = defaultProps;
export default NoDataFound;
