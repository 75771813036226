import { useInjectReducer, useInjectSaga } from 'Utils/ReduxInjectors';

// slices
import { reducer as TopCompanyReducer, sliceKey as TopCompanySliceKey } from 'Slice/TopCompanyEntitySlice';
import {
  reducer as CompaniesReducer,
  sliceKey as CompaniesSliceKey,
} from 'Slice/CompaniesSlice';
import { sliceKey as GroupFollowSliceKey, reducer as PeopleFollowReducer } from 'Slice/PeopleFollowSlice';
import { sliceKey as GroupSliceKey, reducer as GroupReducer } from 'Slice/GroupsSlice';
import { reducer as LoginReducer, sliceKey as LoginSliceKey } from 'Slice/LoginSlice';
import { reducer as SignUpReducers, sliceKey as SignUpSliceKey } from 'Slice/SignUpSlice';
import {
  reducer as ArticlesReducer, sliceKey as ArticlesSliceKey,
} from 'Slice/ArticlesSlice';
import { reducer as IndustriesReducer, sliceKey as IndustriesSliceKey } from 'Slice/IndustriesSlice';
import { reducer as OnboardingReducer, sliceKey as OnboardingSliceKey } from 'Slice/OnboardingSlice';
import { reducer as SearchReducer, sliceKey as SearchSliceKey } from 'Slice/GlobalSearchContentSlice';
import { reducer as BuyerGroupsPageReducer, sliceKey as BuyerGroupsPageSliceKey } from 'Slice/BuyerGroupsPageSlice';
import { sliceKey as PeopleSliceKey, reducer as PeopleReducer } from 'Slice/PeopleSlice';
import { reducer as PersonProfileReducer, sliceKey as PersonProfileSliceKey } from 'Slice/PersonProfileSlice';
import { reducer as AffinitySearchReducer, sliceKey as AffinitySearchSliceKey } from 'Slice/AffinitySearchResultsSlice';
import { reducer as GlobalSearchResultsReducer, sliceKey as GlobalSearchResultsSliceKey } from 'Slice/GlobalSearchResultsSlice';
import { reducer as SRIReducer, sliceKey as SRISliceKey } from 'Slice/SRISlice';
import { reducer as AlertReducer, sliceKey as AlertSliceKey } from 'Slice/AlertsSlice';
import { reducer as TriggersReducer, sliceKey as TriggersSliceKey } from 'Slice/TriggersSlice';
import { reducer as profileShareReducer, sliceKey as profileShareSliceKey } from 'Slice/ProfileShareSlice';
import { reducer as UiReducer, sliceKey as UiSliceKey } from 'Slice/UISlice';
import {
  reducer as FeedActivitiesReducer, sliceKey as FeedActivitiesSliceKey,
} from 'Slice/FeedActivitiesSlice';
import { reducer as SpotlightReducer, sliceKey as SpotlightSliceKey } from 'Slice/SpotlightSlice';
import { reducer as FeedIntentDataReducer, sliceKey as FeedIntentDataSliceKey } from 'Slice/FeedIntentDataSlice';
import { reducer as FavouriteArticleReducer, sliceKey as FavouriteArticleSliceKey } from 'Slice/FavoriteArticlesSlice';
import { reducer as LogoutReducer, sliceKey as LogoutSliceKey } from 'Slice/LogoutSlice';
import { sliceKey as BranchSliceKey, reducer as BranchReducer } from 'Slice/BranchSlice';
import { sliceKey as FlurrySliceKey, reducer as FlurryReducer } from 'Slice/HelperSlice';
import { reducer as settingsReducer, sliceKey as settingsSliceKey } from 'Slice/SettingsSlice';
import { reducer as customWidgetReducer, sliceKey as customWidgetSliceKey } from 'Slice/CustomWidgetSlice';
import { reducer as guestSliceReducer, sliceKey as guestSliceKey } from 'Slice/GuestSlice';
import { reducer as servicelineSliceReducer, sliceKey as servicelineSliceKey } from 'Slice/ServiceLinesSlice';
import { reducer as marketingCompaignReducer, sliceKey as marketingCompaignSliceKey } from 'Slice/MarketingCompaignSlice';
import { reducer as certificationReducer, sliceKey as certificationSliceKey } from 'Slice/CertificationSlice';
import { reducer as notesReducer, sliceKey as notesSliceKey } from 'Slice/NotesSlice';
import { reducer as profileConfirmationReducer, sliceKey as profileConfirmationSliceKey } from 'Slice/ProfileConfirmationSlice';
import { reducer as documentHubReducer, sliceKey as documentHubSliceKey } from 'Slice/DocumentHubSlice';
// Sagas
import { companiesSaga } from 'Saga/CompaniesSaga';
import { peopleFollowSaga } from 'Saga/PeopleFollowSaga';
import { groupsSaga } from 'Saga/GroupsSaga';
import { userAuthSaga as loginSaga } from 'Saga/LoginSaga';
import { userAuthSaga as signUpSaga } from 'Saga/SignUpSaga';
import { ArticleDetailSaga } from 'Saga/ArticlesSaga';
import { industriesSaga } from 'Saga/IndustriesSaga';
import { onboardingSaga } from 'Saga/OnboardingSaga';
import { useGlobalSearchContentSaga } from 'Saga/GlobalSearchContentSaga';
import { BuyerGroupsPageSaga } from 'Saga/BuyerGroupsPageSaga';
import { peopleSaga } from 'Saga/PeopleSaga';
import { personProfileSaga } from 'Saga/PersonProfileSaga';
import { useAffinitySearchResultsSaga } from 'Saga/AffinitySearchSaga';
import { useGlobalSearchResultsSaga } from 'Saga/GlobalSearchResultsSaga';
import { SRISaga } from 'Saga/SRISaga';
import { alertsSaga } from 'Saga/AlertsSaga';
import { triggersSaga } from 'Saga/TriggersSaga';
import { ProfileShareSaga } from 'Saga/ProfileShareSaga';
import { feedActivitiesSaga } from 'Saga/FeedActivitiesSaga';
import { spotlightSaga } from 'Saga/SpotlightSaga';
import { feedIntentDataSaga } from 'Saga/FeedIntentDataSaga';
import { topEntitiesSaga } from 'Saga/TopEntitiesSaga';
import { FavouriteArticleSaga } from 'Saga/FavoriteArticlesSaga';
import { logoutSaga } from 'Saga/LogoutSaga';
import { flurryEventSaga } from 'Saga/FlurryEventSaga';
import { SettingsSaga } from 'Saga/SettingsSaga';
import { guestSaga } from 'Saga/GuestSaga';
import { servicelineSaga } from 'Saga/ServiceLinesSaga';
import { CustomWidgetSaga } from 'Saga/CustomWidgetSaga';
import { MarketingCompaignSaga } from 'Saga/MarketingCompaignSaga';
import { certificationSaga } from 'Saga/CertificationSaga';
import { NotesSaga } from 'Saga/NotesSaga';
import { ProfileConfirmationSaga } from 'Saga/ProfileConfirmationSaga';
import { DocumentHubSaga } from 'Saga/DocumentHubSaga';

const GlobalInjector = function GlobalInjector() {
  // Injecting Reducers
  useInjectReducer({ key: CompaniesSliceKey, reducer: CompaniesReducer });
  useInjectReducer({ key: TopCompanySliceKey, reducer: TopCompanyReducer });
  useInjectReducer({ key: GroupFollowSliceKey, reducer: PeopleFollowReducer });
  useInjectReducer({ key: GroupSliceKey, reducer: GroupReducer });
  useInjectReducer({ key: LoginSliceKey, reducer: LoginReducer });
  useInjectReducer({ key: SignUpSliceKey, reducer: SignUpReducers });
  useInjectReducer({ key: ArticlesSliceKey, reducer: ArticlesReducer });
  useInjectReducer({ key: IndustriesSliceKey, reducer: IndustriesReducer });
  useInjectReducer({ key: OnboardingSliceKey, reducer: OnboardingReducer });
  useInjectReducer({ key: SearchSliceKey, reducer: SearchReducer });
  useInjectReducer({ key: BuyerGroupsPageSliceKey, reducer: BuyerGroupsPageReducer });
  useInjectReducer({ key: PeopleSliceKey, reducer: PeopleReducer });
  useInjectReducer({ key: PersonProfileSliceKey, reducer: PersonProfileReducer });
  useInjectReducer({ key: AffinitySearchSliceKey, reducer: AffinitySearchReducer });
  useInjectReducer({ key: GlobalSearchResultsSliceKey, reducer: GlobalSearchResultsReducer });
  useInjectReducer({ key: SRISliceKey, reducer: SRIReducer });
  useInjectReducer({ key: AlertSliceKey, reducer: AlertReducer });
  useInjectReducer({ key: TriggersSliceKey, reducer: TriggersReducer });
  useInjectReducer({ key: profileShareSliceKey, reducer: profileShareReducer });
  useInjectReducer({ key: UiSliceKey, reducer: UiReducer });
  useInjectReducer({ key: FeedActivitiesSliceKey, reducer: FeedActivitiesReducer });
  useInjectReducer({ key: SpotlightSliceKey, reducer: SpotlightReducer });
  useInjectReducer({ key: FeedIntentDataSliceKey, reducer: FeedIntentDataReducer });
  useInjectReducer({ key: FavouriteArticleSliceKey, reducer: FavouriteArticleReducer });
  useInjectReducer({ key: LogoutSliceKey, reducer: LogoutReducer });
  useInjectReducer({ key: BranchSliceKey, reducer: BranchReducer });
  useInjectReducer({ key: settingsSliceKey, reducer: settingsReducer });
  useInjectReducer({ key: customWidgetSliceKey, reducer: customWidgetReducer });
  useInjectReducer({ key: FlurrySliceKey, reducer: FlurryReducer });
  useInjectReducer({ key: guestSliceKey, reducer: guestSliceReducer });
  useInjectReducer({ key: servicelineSliceKey, reducer: servicelineSliceReducer });
  useInjectReducer({ key: marketingCompaignSliceKey, reducer: marketingCompaignReducer });
  useInjectReducer({ key: certificationSliceKey, reducer: certificationReducer });
  useInjectReducer({ key: notesSliceKey, reducer: notesReducer });
  useInjectReducer({ key: profileConfirmationSliceKey, reducer: profileConfirmationReducer });
  useInjectReducer({ key: documentHubSliceKey, reducer: documentHubReducer });

  // Injecting Sagas
  useInjectSaga({ key: CompaniesSliceKey, saga: companiesSaga });
  useInjectSaga({ key: TopCompanySliceKey, saga: topEntitiesSaga });
  useInjectSaga({ key: GroupFollowSliceKey, saga: peopleFollowSaga });
  useInjectSaga({ key: GroupSliceKey, saga: groupsSaga });
  useInjectSaga({ key: LoginSliceKey, saga: loginSaga });
  useInjectSaga({ key: SignUpSliceKey, saga: signUpSaga });
  useInjectSaga({ key: ArticlesSliceKey, saga: ArticleDetailSaga });
  useInjectSaga({ key: IndustriesSliceKey, saga: industriesSaga });
  useInjectSaga({ key: OnboardingSliceKey, saga: onboardingSaga });
  useInjectSaga({ key: SearchSliceKey, saga: useGlobalSearchContentSaga });
  useInjectSaga({ key: BuyerGroupsPageSliceKey, saga: BuyerGroupsPageSaga });
  useInjectSaga({ key: PeopleSliceKey, saga: peopleSaga });
  useInjectSaga({ key: PersonProfileSliceKey, saga: personProfileSaga });
  useInjectSaga({ key: AffinitySearchSliceKey, saga: useAffinitySearchResultsSaga });
  useInjectSaga({ key: GlobalSearchResultsSliceKey, saga: useGlobalSearchResultsSaga });
  useInjectSaga({ key: SRISliceKey, saga: SRISaga });
  useInjectSaga({ key: AlertSliceKey, saga: alertsSaga });
  useInjectSaga({ key: TriggersSliceKey, saga: triggersSaga });
  useInjectSaga({ key: profileShareSliceKey, saga: ProfileShareSaga });
  // useInjectSaga({ key: UiSliceKey, saga:  });
  useInjectSaga({ key: FeedActivitiesSliceKey, saga: feedActivitiesSaga });
  useInjectSaga({ key: SpotlightSliceKey, saga: spotlightSaga });
  useInjectSaga({ key: FeedIntentDataSliceKey, saga: feedIntentDataSaga });
  useInjectSaga({ key: FavouriteArticleSliceKey, saga: FavouriteArticleSaga });
  useInjectSaga({ key: LogoutSliceKey, saga: logoutSaga });
  useInjectSaga({ key: settingsSliceKey, saga: SettingsSaga });
  // useInjectSaga({ key: BranchSliceKey, saga:  });
  useInjectSaga({ key: FlurrySliceKey, saga: flurryEventSaga });
  useInjectSaga({ key: guestSliceKey, saga: guestSaga });
  useInjectSaga({ key: servicelineSliceKey, saga: servicelineSaga });
  useInjectSaga({ key: customWidgetSliceKey, saga: CustomWidgetSaga });
  useInjectSaga({ key: marketingCompaignSliceKey, saga: MarketingCompaignSaga });
  useInjectSaga({ key: certificationSliceKey, saga: certificationSaga });
  useInjectSaga({ key: notesSliceKey, saga: NotesSaga });
  useInjectSaga({ key: profileConfirmationSliceKey, saga: ProfileConfirmationSaga });
  useInjectSaga({ key: documentHubSliceKey, saga: DocumentHubSaga });
};

export default GlobalInjector;
