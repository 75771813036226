/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable import/extensions */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PeopleFollowState } from 'Types/GlobalTypes';
import { UpdatePersonProfileFollow } from 'Types/RequestBodies';

export const initialState: PeopleFollowState = {
  addFollowLoading: false,
  addFollowFailed: false,
  message: '',
};

const PersonProfileSlice = createSlice({
  name: 'peopleFollow',
  initialState,
  reducers: {
    updateProfileFollow(state, action: PayloadAction<UpdatePersonProfileFollow>) {
      if (!state.addFollowLoading) {
        state.addFollowLoading = true;
      }
    },

    setProfileFollowSuccess(state) {
      state.addFollowLoading = false;
      state.addFollowFailed = false;
    },

    setProfileFollowFailure(state) {
      state.addFollowLoading = false;
      state.addFollowFailed = !state.addFollowFailed;
    },
  },
});

export const { actions, reducer, name: sliceKey } = PersonProfileSlice;
