/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'Store/InjectorsTypes';
import { initialState } from 'Slice/TriggersSlice';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state?.triggers || initialState;

export const selectTriggers = createSelector([selectDomain], (state) => state.triggers);
export const selectPeopleTriggers = createSelector([selectDomain], (state) => state.peopleTriggers);
export const selectTriggersLoading = createSelector(
  [selectDomain],
  (state) => state.triggersLoading,
);
export const selectPeopleTriggersLoading = createSelector(
  [selectDomain],
  (state) => state.peopleTriggersLoading,
);
