import { Tooltip } from 'antd';
import classNames from 'classnames';
import CompanyWidgetSmall from 'Components/common/components/CompanyWidgetSmall';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { CompanyPersonality } from 'Types/CompaniesTypes';
import { Pulse } from 'Types/PulseTypes';
import {
  primaryColor, primaryFont, primaryHeadingColor, secondaryFont, wMedium,
} from 'Utils/utilsStyle';

type Props = {
  className?: string;
  onClick?: () => void;
  companyLogo: string;
  companyPersonality: CompanyPersonality[];
  companyPulse: Pulse[];
  isChecked?: boolean;
  companyId: number;
  companyName: string;
  companyWebsite: string;
  selectedCount?: number,
  tooltip?: boolean,
  showRadioBtn?: boolean,
  isRadioChecked?: boolean,
};
const defaultProps = {
  className: '',
  isChecked: false,
  isRadioChecked: false,
  onClick: () => { },
  selectedCount: -1,
  tooltip: false,
  showRadioBtn: false,
};

const StyleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
  &.blocked{
    cursor: not-allowed;
  }
  .addCompanyWidgetItem {
    width: 70px;
    height: 57px;
    position: relative;
    margin-left: 30px;
    margin-bottom: 0px;
    .progress-wrapper {
      display: none;
    }
    .ant-checkbox-wrapper {
      position: absolute;
      left: -25px;
      top: 25px;
    }
  }
  .text-area {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    width: calc(100% - 112px);
    .heading {
      font-size: 18px;
      font-weight: ${wMedium};
      font-family: ${secondaryFont};
      color: ${primaryHeadingColor};
      line-height: 20px;
    }
    .domain {
      font-size: 10px;
      font-family: ${primaryFont};
      color: ${primaryColor};
      line-height: 12px; 
    }
  }
`;
const AddCompanyItem = function AddCompanyItem(props: Props) {
  const {
    className, onClick, companyLogo, companyPersonality,
    companyPulse, companyId, companyName, companyWebsite, isChecked, selectedCount,
    tooltip, showRadioBtn, isRadioChecked,
  } = props;
  const isTab = useMediaQuery({
    query: '(max-width: 1024px)',
  });
  return (
    <StyleWrapper
      className={classNames('add-company-item-wrapper', className)}
      onClick={onClick}
    >
      <CompanyWidgetSmall
        logo={companyLogo}
        persona={companyPersonality}
        triggerColor={companyPulse}
        checked={isChecked}
        companyId={companyId.toString()}
        backgroundPosition="center 5px"
        backgroundSize="50%"
        addCompanyWidget
        selectedCount={selectedCount}
        isRadio={showRadioBtn}
        isRadioChecked={!!isRadioChecked}
      />
      <div className="text-area">
        {tooltip && !isTab ? (
          <Tooltip
            placement="topLeft"
            getPopupContainer={(e: HTMLElement) => e}
            className="triggers-bullet-tooltip tooltip-item"
            color="#fff"
            title={companyName}
            zIndex={3}
          >
            <span className="heading text-truncate">{companyName}</span>
          </Tooltip>
        ) : (
          <span className="heading text-truncate-one-line">{companyName}</span>
        )}
        <span className="domain text-truncate-one-line">{companyWebsite}</span>
      </div>
    </StyleWrapper>
  );
};

AddCompanyItem.defaultProps = defaultProps;
export default AddCompanyItem;
