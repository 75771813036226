/* eslint-disable @typescript-eslint/indent */
import { Configuration, PopupRequest } from '@azure/msal-browser';

export const msalConfig: Configuration = {
    auth: {
        clientId: '5d1bdec2-9f9c-480c-9695-933a5479ad5f',
        authority: 'https://login.microsoftonline.com/common',
        // redirectUri: 'http://localhost:3000/test',
        redirectUri: 'https://qasalesapp.xiqonline.com/person',
        //redirectUri: 'http://localhost:3000/person',
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
};

export const loginRequest: PopupRequest = {
    scopes: ['User.Read', 'Calendars.ReadWrite'],
};

export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
