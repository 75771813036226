import React from 'react';

type Props = {
  className: string,
  onClick?: () => void,
} & typeof defaultProps;
const defaultProps = {
  onClick: () => { },
};
const SmartInputAddIcon = function SmartInputAddIcon(props: Props) {
  const { onClick, className } = props;
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} className={className}>
      <rect width="18" height="18" rx="9" fill="#1EC252" />
      <path d="M14 8.02748H9.97252V4H8.02748V8.02748H4V9.97252H8.02748V14H9.97252V9.97252H14V8.02748Z" fill="white" />
    </svg>
  );
};
SmartInputAddIcon.defaultProps = defaultProps;
export default SmartInputAddIcon;
