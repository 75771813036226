import { useState, useEffect } from 'react';
import FreshDeskIcon from '../assets/svgIcons/FreshDeskIcon';

const FreshDeskAppearanceCheck = function () {
  const [show, setShow] = useState(false);

  // On componentDidMount set the timer
  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 5 seconds set the show value to false
      setShow(true);
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  // If show is false the component will return null and stop here
  if (!show) {
    return null;
  }

  const showData = () => (
    show ? <FreshDeskIcon /> : null
  );

  // If show is true this will be returned
  return (
    showData()
  );
};

export default FreshDeskAppearanceCheck;
