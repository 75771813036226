import classNames from 'classnames';
import styled from 'styled-components';
import { smMobile } from 'Utils/utilsStyle';

const Wrapper = styled.div`
  overflow: hidden;
  margin-bottom: 8px;
  .buyer-skeleton-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    border-radius: 16px;
    width: 100%;
    padding: 40px 20px;
    @media all and (max-width: ${smMobile}) {
      padding: 40px 10px;
    }
    .left-side {
      margin-left: 20px;
      @media all and (max-width: ${smMobile}) {
        margin-left: 0px;
      }
    }
    &.border {
      border: 5px solid #e9e9e9; 
    }
    .company-logo{
      height: 26px;
      width:40px;
      border-radius:0;
    }
    .company-logo:nth-of-type(2){
      width:50px;
      margin:0 10px;
    }
    .company-logo:nth-of-type(3){
      width:40px;
    }
   .title-box {
     height: 25px;
     border-radius: 25px;
     width: 120px;
     @media all and (max-width: ${smMobile}) {
      margin-left: 5px;
      width: 70px;
     }
   }
  }
  .round-logos{
    .user-logo{
      border-radius:50%;
      width: 30px;
      height: 30px;
      margin-left:-7px;
    }
  }
  .skeleton-box {
    height: 20px;
    border-radius: 25px;
    width: 50px;
  }
`;

type Props = {
  border?: boolean
} & typeof defaultProps;

const defaultProps = {
  border: false,
};

const GroupsDisplaySkeleton = function GroupsDisplaySkeleton(props: Props) {
  const { border } = props;
  return (
    <Wrapper>
      <div className={classNames('buyer-skeleton-wrapper', { border })}>
        <div className="left-side">
          <div className="d-flex justify-content-center round-logos">
            <span className="skeleton-box user-logo" />
            <span className="skeleton-box user-logo" />
            <span className="skeleton-box user-logo" />
            <span className="skeleton-box user-logo" />
          </div>
        </div>
        <div className="right-side">
          <span className="skeleton-box title-box" />
        </div>
      </div>
    </Wrapper>
  );
};
GroupsDisplaySkeleton.defaultProps = defaultProps;
export default GroupsDisplaySkeleton;
