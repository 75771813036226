import styled from 'styled-components';
import { Row, Col } from 'antd';
import CompanySkeleton from 'Components/common/skeleton/CompanySkeleton';
import {
  maxMobile,
  maxWidth, retina, retinaOne,
} from 'Utils/utilsStyle';

const StyleWrapper = styled.div`
  .fancy-box {
    border-radius: 22px;
    background: rgb(255, 255, 255);
    padding: 40px 50px 40px 50px;
    margin-top: 50px;
    margin-bottom: 10px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
     padding: 17px 25px 25px 25px;
    }
    @media all and (max-width: ${maxWidth}) , ${retinaOne} {
     padding: 17px 25px 25px 25px;
    }
    @media all and (max-width: ${maxMobile}) {
     padding: 17px 16px 16px 16px;
    }
    .companiesSection {
      margin-top: 13px;
    }
  }
`;

const CompaniesSectionSkeleton = function CompaniesSectionSkeleton() {
  const getCompaniesLoadingSkeleton = () => {
    const skeletons = [];
    for (let i = 0; i < 12; i += 1) {
      skeletons.push(
        <Col span={4} className="gutter-row"><CompanySkeleton /></Col>,
      );
    }
    return (
      <Row gutter={[24, 24]} className="Grid-width-row lastTwoRemove">
        {skeletons}
      </Row>
    );
  };

  return (
    <StyleWrapper>
      <div className="fancy-box">
        <div className="companiesSection">
          {
            getCompaniesLoadingSkeleton()
          }
        </div>
      </div>
    </StyleWrapper>
  );
};

export default CompaniesSectionSkeleton;
