import React from 'react';

export const SearchAreaIcon = function SearchAreaIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path d="M1.63406 1.63715C2.68952 0.581695 4.09347 0 5.58577 0C7.07877 0 8.48191 0.580971 9.53748 1.63715C11.6303 3.72993 11.7122 7.08246 9.7845 9.27538L9.98009 9.47097C10.1337 9.39491 10.3032 9.35362 10.4792 9.35362C10.7806 9.35362 11.0638 9.47097 11.2775 9.68394L13.6659 12.0731C13.8796 12.286 13.9969 12.57 13.9969 12.8714C13.9969 13.1727 13.8796 13.456 13.6666 13.6689C13.4537 13.8826 13.1697 14 12.8683 14C12.567 14 12.283 13.8826 12.07 13.6689L9.68165 11.2806C9.33104 10.9299 9.26077 10.4055 9.46868 9.98314L9.27309 9.78755C8.25312 10.6858 6.95796 11.1777 5.58657 11.1777C4.09356 11.1777 2.69043 10.5967 1.63486 9.54053C-0.544896 7.36152 -0.54497 3.81617 1.63406 1.63715ZM10.1929 10.7682L12.5812 13.1566C12.7341 13.3095 12.9999 13.3102 13.1535 13.1559C13.2296 13.0798 13.2716 12.9784 13.2716 12.8705C13.2716 12.7625 13.2296 12.6611 13.1528 12.5851L10.7644 10.1959C10.6876 10.1199 10.5862 10.0778 10.4783 10.0778C10.3703 10.0778 10.2689 10.1199 10.1928 10.1959C10.0356 10.3538 10.0357 10.6103 10.1929 10.7682ZM2.14608 9.02822C3.06462 9.94676 4.28671 10.4531 5.58559 10.4531C6.88517 10.4531 8.10656 9.94748 9.02509 9.02894C9.02509 9.02822 9.02509 9.02822 9.02581 9.02822C10.9224 7.13108 10.9216 4.04579 9.02581 2.14921C8.10727 1.23067 6.88519 0.724303 5.58631 0.724303C4.28673 0.724303 3.06533 1.22995 2.14681 2.14921C0.249675 4.04579 0.249675 7.13164 2.14608 9.02822Z" fill="#667982" />
      <path d="M2.74806 2.75115C3.50578 1.99343 4.51344 1.57617 5.5856 1.57617C6.65699 1.57617 7.6654 1.99343 8.42314 2.75115C9.98786 4.31587 9.98786 6.86142 8.42314 8.42549C7.66542 9.18321 6.65777 9.60047 5.5856 9.60047C4.51421 9.60047 3.50655 9.18321 2.74881 8.42549C1.18409 6.86149 1.18407 4.31596 2.74806 2.75115ZM3.26022 7.91328C3.88104 8.5341 4.70686 8.87602 5.58481 8.87602C6.46351 8.87602 7.28933 8.53409 7.91014 7.91328C9.19162 6.63107 9.19162 4.54555 7.91014 3.26336C7.28931 2.64253 6.46349 2.30062 5.58481 2.30062C4.70612 2.30062 3.881 2.64255 3.25947 3.26336C1.97873 4.54556 1.97875 6.63183 3.26022 7.91328Z" fill="#667982" />
    </svg>
  );
};

export default SearchAreaIcon;
