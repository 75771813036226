import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLocalStorageObject } from 'Utils/UtilityFunctions';
import { ViewInterface } from 'Types/GlobalTypes';
import { selectViewTab } from 'Selector/UISelector';
import GridViewIcon from '../assets/svgIcons/GridViewIcon';
import ListViewIcon from '../assets/svgIcons/ListViewIcon';
import { actions } from '../../../Slice/UISlice';

type Props = {
  listView?: boolean;
  disabled?: boolean;
} & typeof defaultProps;

const defaultProps = {
  listView: false,
  disabled: true,
};

const ViewTabs = function ViewTabs(props: Props) {
  const {
    listView, disabled,
  } = props;
  const tabView: ViewInterface = useSelector(selectViewTab);
  const dispatch = useDispatch();

  const makeView = (viewPath: string) => {
    setLocalStorageObject('ViewTab', { view: viewPath });
    dispatch(actions.isTabView({
      view: viewPath,
    }));
  };

  useEffect(() => {
    setLocalStorageObject('ViewTab', { view: tabView.view });
  }, []);

  return (
    <div className="tabs-wrapper layout-controller c-pointer">
      <span
        role="button"
        tabIndex={0}
        className={classNames('tabs-item', { active: (listView && !disabled) }, { disable: disabled })}
        onKeyPress={() => makeView('listView')}
        onClick={() => makeView('listView')}
      >
        <ListViewIcon color={(listView && !disabled) ? '#09A43A' : '#7E7E7E'} />
      </span>
      <span
        role="button"
        tabIndex={0}
        className={classNames('tabs-item', { active: (!listView && !disabled) }, { disable: disabled })}
        onKeyPress={() => makeView('tileView')}
        onClick={() => makeView('tileView')}
      >
        <GridViewIcon color={(!listView && !disabled) ? '#09A43A' : '#7E7E7E'} />
      </span>
    </div>
  );
};

ViewTabs.defaultProps = defaultProps;
export default ViewTabs;
