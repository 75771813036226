import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  selectAlertDrawerVisible, selectArticleItemId, selectArticleItemSummary,
  selectSimilarDrawerVisible,
} from 'Selector/ArticleSector';
import { actions as articleActions } from 'Slice/ArticlesSlice';
import { Article, ArticleDetailResponse } from 'Types/ArticleTypes';

interface UseArticleDetailProps {
  setVisible?: (value: boolean) => void;
  visible?: boolean
}

export const useArticleDetail = ({
  setVisible,
  visible,
}: UseArticleDetailProps) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const alertDrawerVisible = useSelector(selectAlertDrawerVisible);
  const similarDrawerVisible = useSelector(selectSimilarDrawerVisible);
  const articleItemSummary = useSelector(selectArticleItemSummary);
  const articleItemId = useSelector(selectArticleItemId);
  const articleIdDeepLink: number = parseInt(searchParams.get('articleId') || '0', 10);

  const toggleVisible = (article: Article) => {
    const articleDetailResponse = { response: article };

    dispatch(articleActions.setArticleDetail(articleDetailResponse as ArticleDetailResponse));
    if (setVisible) {
      setVisible(!visible);
    }

    if (alertDrawerVisible) {
      dispatch(articleActions.setSimilarDrawerVisible(!similarDrawerVisible));
    } else {
      dispatch(articleActions.getArticle({
        articleid: article?.articleId,
        ...(article?.companyId && article?.companyId > 0 && { companyId: article?.companyId }),
        ...(articleIdDeepLink > 0 && { isPushNotification: true }),
        ...(article?.alerts[0]?.alertId > 0 && { alertId: article.alerts[0].alertId }),
      }));
      dispatch(articleActions.getSimilarArticle({
        article_id: article?.articleId,
      }));
      if (articleItemId === article?.articleId) {
        dispatch(articleActions.setArticleSummary({
          articleId: article?.articleId,
          isSimilarArticle: false,
          summary: articleItemSummary,
        }));
      } else {
        dispatch(articleActions.getArticleSummary({
          articleId: article?.articleId,
          isSimilarArticle: false,
        }));
      }
      dispatch(articleActions.setAlertDrawerVisible(!alertDrawerVisible));
    }
  };
  return {
    toggleVisible,
  };
};
