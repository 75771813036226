import React from 'react';

type Props = {
  color?: string;
} & typeof defaultProps;

const defaultProps = {
  color: '#000000',
};

const DocumentSvg = function DocumentSvg(props: Props) {
  const { color } = props;
  return (
    <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg" className="document-svg">
      <path d="M5.1361 14.4997C5.05576 14.4997 4.97622 14.4838 4.90205 14.4529C4.82788 14.4221 4.76054 14.3769 4.70391 14.3199C4.64728 14.2629 4.60248 14.1953 4.57208 14.1209C4.54168 14.0466 4.52629 13.9669 4.52678 13.8866V5.09294C4.52017 5.00865 4.53106 4.9239 4.55877 4.84401C4.58648 4.76413 4.63042 4.69084 4.68782 4.62876C4.74522 4.56668 4.81485 4.51714 4.89231 4.48326C4.96978 4.44938 5.05342 4.43188 5.13798 4.43188C5.22253 4.43188 5.30617 4.44938 5.38364 4.48326C5.46111 4.51714 5.53073 4.56668 5.58813 4.62876C5.64553 4.69084 5.68947 4.76413 5.71718 4.84401C5.7449 4.9239 5.75579 5.00865 5.74917 5.09294V13.8866C5.74917 14.0492 5.68458 14.2051 5.5696 14.3201C5.45463 14.4351 5.29869 14.4997 5.1361 14.4997Z" fill={color} stroke={color} strokeWidth="1.2" />
      <path d="M18.8643 14.4996C18.7017 14.4996 18.5457 14.435 18.4307 14.3201C18.3158 14.2051 18.2512 14.0492 18.2512 13.8866V2.22689H8.61503C8.45243 2.22689 8.29649 2.1623 8.18152 2.04733C8.06654 1.93235 8.00195 1.77641 8.00195 1.61382C8.00195 1.45122 8.06654 1.29528 8.18152 1.18031C8.29649 1.06534 8.45243 1.00074 8.61503 1.00074H18.8643C18.9446 1.00025 19.0242 1.01564 19.0986 1.04604C19.1729 1.07644 19.2406 1.12124 19.2975 1.17787C19.3545 1.2345 19.3997 1.30184 19.4306 1.37601C19.4614 1.45018 19.4773 1.52972 19.4773 1.61006V13.8866C19.4773 14.0492 19.4127 14.2051 19.2978 14.3201C19.1828 14.435 19.0269 14.4996 18.8643 14.4996Z" fill={color} stroke={color} strokeWidth="1.2" />
      <path d="M8.61507 5.70603H5.13973C5.01848 5.70652 4.89981 5.67105 4.79872 5.6041C4.69763 5.53716 4.61866 5.44173 4.57179 5.32991C4.52572 5.21804 4.51399 5.09498 4.5381 4.97642C4.56222 4.85786 4.62108 4.74916 4.7072 4.66418L8.18629 1.18132C8.27128 1.0952 8.37998 1.03634 8.49854 1.01223C8.6171 0.988111 8.74015 0.999839 8.85202 1.04592C8.96356 1.09319 9.0587 1.17226 9.12559 1.27326C9.19248 1.37426 9.22814 1.49272 9.22814 1.61385V5.09295C9.22864 5.1736 9.21313 5.25355 9.18249 5.32815C9.15186 5.40276 9.10672 5.47054 9.04969 5.52757C8.99266 5.5846 8.92488 5.62974 8.85027 5.66037C8.77566 5.69101 8.69572 5.70652 8.61507 5.70603ZM6.61412 4.47988H8.00576V3.092L6.61412 4.47988Z" fill={color} stroke={color} strokeWidth="1.2" />
      <path d="M15.4986 8.99847H8.42865C8.31197 8.98862 8.20304 8.93183 8.12358 8.83944C8.04413 8.74704 8 8.62583 8 8.5C8 8.37417 8.04413 8.25296 8.12358 8.16056C8.20304 8.06817 8.31197 8.01138 8.42865 8.00153H15.4986C15.5625 7.99614 15.6268 8.00502 15.6874 8.02762C15.748 8.05022 15.8036 8.08606 15.8507 8.13287C15.8978 8.17969 15.9353 8.23647 15.961 8.29965C15.9867 8.36283 16 8.43104 16 8.5C16 8.56896 15.9867 8.63717 15.961 8.70035C15.9353 8.76353 15.8978 8.82031 15.8507 8.86713C15.8036 8.91394 15.748 8.94978 15.6874 8.97238C15.6268 8.99498 15.5625 9.00386 15.4986 8.99847Z" fill={color} stroke={color} strokeWidth="0.5" />
      <path d="M15.4986 10.9985H8.42865C8.31197 10.9886 8.20304 10.9318 8.12358 10.8394C8.04413 10.747 8 10.6258 8 10.5C8 10.3742 8.04413 10.253 8.12358 10.1606C8.20304 10.0682 8.31197 10.0114 8.42865 10.0015H15.4986C15.5625 9.99614 15.6268 10.005 15.6874 10.0276C15.748 10.0502 15.8036 10.0861 15.8507 10.1329C15.8978 10.1797 15.9353 10.2365 15.961 10.2996C15.9867 10.3628 16 10.431 16 10.5C16 10.569 15.9867 10.6372 15.961 10.7004C15.9353 10.7635 15.8978 10.8203 15.8507 10.8671C15.8036 10.9139 15.748 10.9498 15.6874 10.9724C15.6268 10.995 15.5625 11.0039 15.4986 10.9985Z" fill={color} stroke={color} strokeWidth="0.5" />
      <path d="M1.6302 23.8575C1.46825 23.8575 1.31288 23.7935 1.19802 23.6793C1.08315 23.5651 1.01812 23.4102 1.01713 23.2482V10.4602C1.01167 10.2474 1.04879 10.0356 1.1263 9.83731C1.2038 9.63903 1.32013 9.45822 1.46847 9.30551C1.62015 9.15136 1.80108 9.02905 2.00065 8.94573C2.20022 8.86241 2.41441 8.81978 2.63067 8.82032H5.1281C5.2907 8.82032 5.44664 8.88491 5.56161 8.99988C5.67658 9.11486 5.74118 9.27079 5.74118 9.43339C5.74118 9.59599 5.67658 9.75193 5.56161 9.8669C5.44664 9.98187 5.2907 10.0465 5.1281 10.0465H2.6382C2.58391 10.045 2.52987 10.0543 2.47928 10.0741C2.42869 10.0938 2.38257 10.1235 2.34364 10.1614C2.30472 10.1992 2.27378 10.2445 2.25267 10.2946C2.23155 10.3446 2.22068 10.3984 2.22071 10.4527V23.2407C2.22071 23.4033 2.15611 23.5592 2.04114 23.6742C1.92617 23.7892 1.77023 23.8538 1.60763 23.8538L1.6302 23.8575Z" fill={color} stroke={color} strokeWidth="1.2" />
      <path d="M19.1462 25.0724H2.63464C2.39409 25.0722 2.15655 25.019 1.93893 24.9164C1.72132 24.8139 1.52898 24.6647 1.37562 24.4794C1.22226 24.2941 1.11165 24.0772 1.05168 23.8443C0.991697 23.6113 0.983823 23.368 1.02861 23.1317C1.0265 23.1179 1.0265 23.104 1.02861 23.0903L3.28533 14.5825C3.36083 14.2135 3.56161 13.8819 3.85363 13.6441C4.14566 13.4062 4.51095 13.2766 4.88759 13.2773H21.3653C21.6057 13.277 21.8432 13.3297 22.0608 13.4318C22.2785 13.5339 22.4709 13.6828 22.6243 13.8678C22.7777 14.0529 22.8884 14.2695 22.9484 14.5023C23.0084 14.7351 23.0162 14.9782 22.9714 15.2144C22.9735 15.2281 22.9735 15.242 22.9714 15.2557L20.7523 23.7635C20.6768 24.1335 20.4756 24.466 20.1828 24.7046C19.8901 24.9431 19.5239 25.0731 19.1462 25.0724ZM2.22091 23.3761C2.20987 23.4334 2.21228 23.4924 2.22794 23.5485C2.24361 23.6046 2.2721 23.6564 2.31118 23.6996C2.34893 23.747 2.39701 23.7853 2.45176 23.8113C2.50651 23.8374 2.56648 23.8506 2.62712 23.85H19.1462C19.2428 23.8499 19.3363 23.816 19.4105 23.7541C19.4847 23.6923 19.5349 23.6065 19.5525 23.5115C19.5507 23.4991 19.5507 23.4864 19.5525 23.4739L21.7791 14.9736C21.7901 14.9164 21.7877 14.8574 21.7721 14.8013C21.7564 14.7451 21.7279 14.6934 21.6888 14.6502C21.6511 14.6027 21.603 14.5645 21.5482 14.5384C21.4935 14.5124 21.4335 14.4991 21.3729 14.4997H4.88383C4.78779 14.4997 4.69474 14.5331 4.62063 14.5942C4.54651 14.6553 4.49596 14.7402 4.47762 14.8345C4.47762 14.8345 4.47762 14.8646 4.47762 14.8759L2.22091 23.3761Z" fill={color} stroke={color} strokeWidth="1.2" />
    </svg>

  );
};

DocumentSvg.defaultProps = defaultProps;
export default DocumentSvg;
