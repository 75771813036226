import ArrowActionIcon from 'Components/common/assets/svgIcons/ArrowActionIcon';
import {
  primaryColor, primaryFont, primaryHeadingColor, wMedium,
} from 'Utils/utilsStyle';
import classNames from 'classnames';
import HTMLReactParser from 'html-react-parser';
import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
border-radius: 8px;
background: #fff;
box-shadow: 0px 0px 17px -2px rgba(0, 0, 0, 0.08);
border: 1.6px solid #fff;
padding: 9px 16px;
cursor: pointer;
&:hover {
  border: 1.6px solid ${primaryColor};
  .arrow-action-icon {
    path {
      fill: ${primaryColor};
    }
  }
}
&.notHover {
  border: 1.6px solid #fff;
  cursor: auto;
}
.prompt-title {
  color: ${primaryHeadingColor};
  font-family: ${primaryFont};
  font-size: 12px;
  font-weight: ${wMedium};
  line-height: 18px; 
  letter-spacing: -0.364px;
  .prompt-input-style {
    &.personAppend , &.companyAppend, &.groupAppend, &.documentAppend {
      padding: 1px 7px 1px 7px !important;
      font-size: 12px;
    }
  }
}
.arrow-action-icon {
  width: 10.5px;
  height: 10.5px;
  flex-shrink: 0;
  align-self: center;
  margin: 0px;
  path {
    fill: #667982;
  }
}
`;
type Props = {
  promptTitle: string,
  eventType: string,
  source: string,
  isDrawer?: boolean,
  onClick?: () => void,
};
const defaultProps = {
  isDrawer: false,
  onClick: () => { },
};

const PromptCopyCmp = function PromptCopyCmp(props: Props) {
  const {
    promptTitle, eventType, source, onClick, isDrawer,
  } = props;
  console.log('unused', eventType, source);

  return (
    <StyledWrapper
      className={classNames('prompt-copy-cert-cmp', { notHover: isDrawer })}
      onClick={onClick}
    >
      <span className="prompt-title">{HTMLReactParser(promptTitle)}</span>
      {!isDrawer ? <ArrowActionIcon /> : null}
    </StyledWrapper>
  );
};
PromptCopyCmp.defaultProps = defaultProps;
export default PromptCopyCmp;
