import React from 'react';

type Props = {
  color?: string,
} & typeof defaultProps;

const defaultProps = {
  color: '#fff',
};
const DownAngleIcon = function DownAngleIcon(props: Props) {
  const { color } = props;
  return (
    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" className="down-angle-icon">
      <path fillRule="evenodd" clipRule="evenodd" d="M5 8L0 0L10 0L5 8Z" fill={color} />
    </svg>
  );
};
DownAngleIcon.defaultProps = defaultProps;
export default DownAngleIcon;
