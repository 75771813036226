import { Popover } from 'antd';
import CongratulationBadgeIcon from 'Components/alerts/assets/svg/CongratulationBadgeIcon';
import EyeIcon from 'Components/alerts/assets/svg/EyeIcon';
import ShareIcon from 'Components/common/assets/svgIcons/ShareIcon';
import SkeletonCustom from 'Components/common/skeleton/SkeletonCustom';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectCompletionDate, selectPDFLoading, selectPDFLink,
  selectImgLink,
  selectOpenGilroyDrawer,
  selectAiPDFLoading,
  selectAiPDFLink,
  selectAiImgLink,
  selectAiCompletionDate,
} from 'Selector/CertificationSelector';
import styled from 'styled-components';
import {
  maxWidth, primaryColor, primaryFont, retina, secondaryFont, wMedium,
} from 'Utils/utilsStyle';
import { actions } from 'Slice/CertificationSlice';
import SocialShareIcons from 'Components/share/SocialShareIcons';
import ViewCertificatePopup from 'Components/common/modal/ViewCertificatePopup';
import classNames from 'classnames';

const Wrapper = styled.div`
&.gilroyCongratsDiv {
  width: 465px;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    width: 398px;
  }
}
  width: 503px;
  height: 223px;
  background-color: #E9FFF0;
  padding: 30px;
  border-radius: 8px;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    width: 360px;
    height: 176px;
    padding: 20px;
  }
`;

const Display = styled.div`
  display: flex;
`;
const Header = styled.div`
  display: flex;
  margin-left: -9px;
  .header{
    margin-top: 1px;
  }
  svg {
    width: 60px;
    height: 54px;
  }
  @media all and (max-width: ${maxWidth}) , ${retina} {
    margin-left: 0px;
    .header{
      margin-top: 2px;
    }
    svg {
      width: 30px;
      height: 40px;
    }
  }
`;

const Text = styled.h2`
  font-family: ${secondaryFont};
  font-weight: ${wMedium};
  font-size: 27px;
  line-height: 20px;
  letter-spacing: -0.36px;
  color: #424B50;
  &.second-text {
    font-size: 18px;
  }
  &.third-text {
    font-size: 15px;
  }
  &.green-text {
    color: ${primaryColor};
    margin-left: 3px;
  }
@media all and (max-width: ${maxWidth}) , ${retina} {
  font-size: 24px;
  line-height: 12px;
  &.second-text {
    font-size: 16px;
  }
  &.third-text {
    font-size: 12px;
  }
}
`;

const Button = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  min-width: 100px;
  height: 39px;
  border-radius: 8px;
  background-color: ${primaryColor};
  color: #FFFFFF;
  font-family: ${primaryFont};
  font-weight: ${wMedium};
  font-size: 17px;
  line-height: 20px;
  cursor: pointer;
  svg {
    margin-right: 8px;
  }
  @media all and (max-width: ${maxWidth}) , ${retina} { 
    width: 80px;
    height: 36px;
    border-radius: 6px;
    font-size: 14px;
    svg {
     margin-right: 5px;
    }
  }
`;

const ViewCertification = styled.span`
    display: flex;
    align-items: flex-start;
    margin-left: 19px;
    margin-top: 11px;
    cursor: pointer;
    svg {
      width: 21px;
      height: 21px;
    }
    @media all and (max-width: ${maxWidth}) , ${retina} {
      svg {
        width: 15px;
        height: 15px;
      }
    }
`;

const ContentWrapper = styled.div`
`;

const CongratulationDiv = function CongratulationDiv() {
  const dispatch = useDispatch();
  const salesXDate = useSelector(selectCompletionDate);
  const aiDate = useSelector(selectAiCompletionDate);
  const [visible, setVisible] = useState(false);
  const isGilroyDrawer = useSelector(selectOpenGilroyDrawer);
  const certificateLoading: boolean = useSelector(selectPDFLoading);
  const pdfLink = useSelector(selectPDFLink);
  const imgLink = useSelector(selectImgLink);
  const aiCertificateLoading: boolean = useSelector(selectAiPDFLoading);
  const aiPdfLink = useSelector(selectAiPDFLink);
  const aiImgLink = useSelector(selectAiImgLink);
  const loading = isGilroyDrawer ? aiCertificateLoading : certificateLoading;
  const date = isGilroyDrawer ? aiDate : salesXDate;

  const downloadProfile = (check?: boolean) => {
    if (!imgLink?.length && !pdfLink?.length && !isGilroyDrawer) {
      dispatch(actions.getCertificatePDF({}));
    }
    if (!aiImgLink?.length && !aiPdfLink?.length && isGilroyDrawer) {
      dispatch(actions.getCertificatePDF({
        type: 'ai_cert',
      }));
    }
    if (!check) { setVisible(true); }
  };

  const isVisible = () => {
    setVisible(!visible);
  };

  const content = (
    <ContentWrapper className="sharingPopoverContentWrapper">
      <h6 className="heading-popover">QuickShare to:</h6>
      {loading
        ? (
          <>
            {[1, 2, 3].map(() => (
              <SkeletonCustom
                width="19px"
                height="19px"
                diameter="50%"
                margin="0 4px"
                marginSm="0px 4px"
              />
            ))}
          </>
        ) : (
          <SocialShareIcons
            certificationPage
            title="xiQ Sales Xelerator Certified"
            entity="article"
          />
        )}
    </ContentWrapper>
  );

  return (
    <Wrapper className={classNames({ gilroyCongratsDiv: isGilroyDrawer })}>
      <Header>
        <CongratulationBadgeIcon />
        <div className="ml-2 header">
          <Text>Congratulations</Text>
          <Text className="second-text">for completing your certificate</Text>
        </div>
      </Header>
      <Display className="mt-c-15">
        <Text className="third-text">You completed this certification on </Text>
        <Text className="third-text green-text">{date}</Text>
      </Display>
      <Display className="mt-c-25 p-relative">
        <Popover
          placement="rightBottom"
          content={content}
          trigger="click"
          getPopupContainer={(e: HTMLElement) => e}
          className="popover-sharing-custom line-block certification-popover popoverSharingCustom"
        >
          <div
            className="article-share-icon"
            aria-hidden="true"
            onClick={(e) => {
              e.stopPropagation();
              downloadProfile(true);
            }}
          >
            <Button>
              <ShareIcon />
              Share
            </Button>
          </div>
        </Popover>
        <ViewCertificatePopup
          visible={visible}
          setVisible={isVisible}
        />
        <ViewCertification
          onClick={() => downloadProfile()}
          onKeyPress={() => downloadProfile()}
          role="button"
          aria-label="Download PDF"
          tabIndex={0}
        >
          <EyeIcon />
          <Text className="third-text green-text">View Certificate</Text>
        </ViewCertification>
      </Display>
    </Wrapper>
  );
};
export default CongratulationDiv;
