/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IndustriesState } from 'Types/GlobalTypes';
import {
  IndustriesResponse, Industry, IndustryNewsResponse, IndustryRequest,
} from 'Types/IndustryTypes';
import { AddToFavoriteRequest } from 'Types/RequestBodies';

export const initialState: IndustriesState = {
  recommendedIndustries: [],
  followedIndustries: [],
  industriesEmpty: false,
  loading: false,
  newsLoading: false,
  newsArticles: [],
  newsTabPage: 1,
  newsFilter: [],
  paginationLoader: false,
  industryName: '',
  followLoading: false,
  selectedNewsFilter: [],
  articlesNewsEnded: false,
  industryAdded: false,
  newsApiCall: false,
  socialFlurry: {},
  newsFlurry: {},
};

const IndustrySlice = createSlice({
  name: 'industry',
  initialState,
  reducers: {
    getIndustries(state) {
      state.loading = true;
      state.industriesEmpty = false;
    },
    setIndustries(state, action: PayloadAction<IndustriesResponse>) {
      const { payload } = action;
      state.loading = false;
      state.recommendedIndustries = payload.recommendedIndustries;
      state.followedIndustries = payload.followedIndustries;
      if (state.followedIndustries.length < 1) state.industriesEmpty = true;
    },
    removingIndustries(state, action: PayloadAction<Industry[]>) {
      state.loading = true;
    },
    removeIndustry(state, action: PayloadAction<Industry[]>) {
    },
    removedIndustries(state, action: PayloadAction<Industry[]>) {
      state.loading = false;
      state.followedIndustries = state.followedIndustries.filter(
        (ind) => !action.payload.find(({ id }) => ind.id === id),
      );
      const unfollowedIndustries = action.payload.map(
        (industry) => ({ ...industry, added: false }),
      );
      state.recommendedIndustries = [...unfollowedIndustries, ...state.recommendedIndustries];
      if (state.followedIndustries.length < 1) {
        state.industriesEmpty = true;
      }
    },
    addingIndustry(state, action: PayloadAction<Industry>) {
      if (action.payload.type === '') {
        state.followLoading = true;
      } else {
        const index = state.recommendedIndustries.findIndex(
          (v) => v.id === action.payload.id,
        );
        if (index > -1) {
          state.recommendedIndustries[index].loading = true;
        }
      }
    },
    addedIndustryDetail(state, action: PayloadAction<Industry>) {
      state.industryAdded = true;
      const temp = state.recommendedIndustries.find(
        (v) => v.id === action.payload.id,
      );
      if (temp) {
        temp.added = true;
        state.followedIndustries.unshift(temp);
      }
      state.followLoading = false;

      state.recommendedIndustries = state.recommendedIndustries.filter(
        (ind) => action.payload.id !== ind.id,
      );
      if (state.followedIndustries.length >= 1) {
        state.industriesEmpty = false;
      }
    },
    addedIndustry(state, action: PayloadAction<Industry>) {
      state.recommendedIndustries = state.recommendedIndustries.filter(
        (ind) => action.payload.id !== ind.id,
      );
      const industry = { ...action.payload };
      industry.added = true;
      industry.loading = false;
      state.followedIndustries.unshift(industry);

      state.industriesEmpty = false;
    },
    addedIndustryError(state, action: PayloadAction<Industry>) {
      const index = state.recommendedIndustries.findIndex(
        (v) => v.id === action.payload.id,
      );
      if (index >= 0) {
        state.recommendedIndustries[index].loading = false;
      }
      if (state.followLoading) {
        state.followLoading = false;
      }
    },

    getNewsActivites(state, action:PayloadAction<IndustryRequest>) {
      const { payload } = action;
      if (payload.page === 1) {
        state.articlesNewsEnded = false;
        state.newsLoading = true;
        state.newsArticles = [];
        state.newsTabPage = 1;

        if (!payload.filters) {
          state.newsFilter = [];
          state.selectedNewsFilter = [];
          state.industryName = '';
        } else {
          state.selectedNewsFilter = payload.filters;
        }

        state.industryAdded = false;
      } else state.paginationLoader = true;
    },
    setNewsActivities(state, action:PayloadAction<IndustryNewsResponse>) {
      const { payload } = action;
      if (state.newsTabPage === 1) {
        state.newsLoading = false;
        state.newsArticles = payload?.response?.news;
        state.newsFilter = state.newsFilter.length ? state.newsFilter
          : payload?.response?.filter.map((v) => ({ ...v, isSelected: true }));
        state.industryAdded = payload.response?.added;
        state.industryName = payload.response.industryName;
      } else {
        state.newsArticles = state.newsArticles.concat(payload?.response?.news);
      }
      state.newsTabPage += 1;
      state.paginationLoader = false;
    },
    setNewsArticlesEnded(state) {
      state.articlesNewsEnded = true;
    },
    addToFavoriteSuccess(state, action: PayloadAction<AddToFavoriteRequest>) {
      const { articleId, favoriteFlag } = action.payload;
      if (articleId) {
        if (state.newsArticles && state.newsArticles.length) {
          const matchedArticles = state.newsArticles.filter(
            (article) => article.articleId === articleId,
          );
          if (matchedArticles.length > 0) {
            matchedArticles.forEach((arrayItem) => {
              const article = arrayItem;
              article.isFavorite = favoriteFlag === 1;
            });
          }
        }
      }
    },
    setSocialFlurryApi(state, action:PayloadAction<object>) {
      const { payload } = action;
      state.socialFlurry = payload;
    },
    setNewsFlurryApi(state, action:PayloadAction<object>) {
      const { payload } = action;
      state.newsFlurry = payload;
    },
    setIsCallNews(state, action: PayloadAction<boolean>) {
      const { payload } = action;
      state.newsApiCall = payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = IndustrySlice;
