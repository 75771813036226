import styled from 'styled-components';
import classNames from 'classnames';
import { maxMobile, maxTablet } from 'Utils/utilsStyle';
import ArticleHeroSkeleton from './ArticleHeroSkeleton';

const Wrapper = styled.div`
  display: flex;
  gap: 40px;
  margin-bottom: 20px;
  margin-top: 10px;
  width: 100%;

  @media all and (max-width: ${maxTablet}) {
    gap: 20px;
  }

  @media all and (max-width: ${maxMobile}) {
    flex-direction: column;
  }

  &:first-child{
    margin-top: 20px;
  }
  
  .mainTileSkeleton{
    flex: 1;
    max-width: calc(50% - 20px);

    @media all and (max-width: ${maxMobile}) {
      max-width: 100%;
    }

    .image-box{
      @media all and (max-width: ${maxTablet}) {
        min-height: 350px;
      }
    }
  }

  .gridTilesSkeleton{
    flex: 1;
    max-width: calc(50% - 20px);
    display: flex;
    flex-wrap: wrap;
    gap: 9px 20px;

    @media all and (max-width: ${maxMobile}) {
      max-width: 100%;
    }

    & > div{
      max-height: calc(50% - 4.5px);
      min-height: calc(50% - 4.5px);
      max-width: calc(50% - 10px);
      margin-bottom: 0;
    }
  }

  //////////////////////////////////////////
  //////////////////////////////////////////

  &.isReversed{
    flex-direction: row-reverse;

    @media all and (max-width: ${maxMobile}) {
      flex-direction: column;
    }
  }
`;

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  halfColumn?: boolean,
  arrayLength?: number
};
const defaultProps = {
  halfColumn: false,
  arrayLength: 10,
};

const splitArrayChunks = (arr: number[], chunkSize: number) => {
  const chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    chunks.push(chunk);
  }

  return chunks;
};

const ArticleLoadingSkeletonBundle = function ArticleLoadingSkeletonBundle(props: Props) {
  const { arrayLength = 10 } = props;

  const arr = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < arrayLength; i++) {
    arr.push(i);
  }

  return (
    <>
      {/* {[1, 2, 3, 4, 5, 6, 7].map(() => (
        <Col
          span={12}
          xs={24}
          sm={halfColumn ? 12 : 24}
          md={12}
          className="article-grid"
        >
          <ArticleHeroSkeleton articleHeight="389px" />
        </Col>
      ))} */}

      {
        arr.length && arr.length > 0 && splitArrayChunks(arr, 5).map((chunk, index) => {
          const isReversed = !(index % 2 === 0);
          return (
            <Wrapper className={classNames({ isReversed })}>
              <div className="mainTileSkeleton">
                <ArticleHeroSkeleton articleHeight="389px" />
              </div>
              <div className="gridTilesSkeleton">
                {chunk.length && chunk.length > 0 && chunk.slice(1).map(() => (
                  <ArticleHeroSkeleton articleHeight="134px" />
                ))}
              </div>
            </Wrapper>
          );
        })
      }
    </>
  );
};

ArticleLoadingSkeletonBundle.defaultProps = defaultProps;
export default ArticleLoadingSkeletonBundle;
