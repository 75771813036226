import { Row } from 'antd';
import DrawerAlerts from 'Components/alerts/components/DrawerAlerts';
import BackIcon from 'Components/common/assets/svgIcons/BackIcon';
import AccountPopOver from 'Components/common/components/AccountPopOver';
import GlobalSearch from 'Components/globalSearch/components/GlobalSearch';
import NavBarPrimary from 'Components/layout/headers/NavBarPrimary';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { actions as customWidgetActions } from 'Slice/CustomWidgetSlice';
import {
  selectNewsData, selectNewsEnded, selectNewsFilter,
  selectNewsLoading, selectNewsPageNumber, selectNewsSelectedFilter, selectPagination,
} from 'Selector/CustomWidgetSelector';
import NoDataFound from 'Components/common/components/NoDataFound';
import SkeletonCustom from 'Components/common/skeleton/SkeletonCustom';
import styled from 'styled-components';
import { SLFilterType } from 'Types/ServiceLinesTypes';
import {
  allValueInKeyOfArrayExists, getLocalStorageObject, IsTablet,
} from 'Utils/UtilityFunctions';
import ArticleLoadingSkeletonBundle from 'Components/common/skeleton/ArticleLoadingSkeletonBundle';
import FilterPopOverWithCompany from 'Components/common/components/FilterPopOverWithCompany';
import { Filter } from 'Types/FilterTypes';
import {
  ALERTS, COMPANIES, NODATABASEDONFILTERS, NODATAFOUND,
} from 'Utils/Constants';
import ViewTabs from 'Components/common/tabs/ViewTabs';
import SubNavBarPrimary from 'Components/layout/headers/SubNavBarPrimary';
import classNames from 'classnames';
import { ViewInterface } from 'Types/GlobalTypes';
import { selectViewTab } from 'Selector/UISelector';
import ArticleListLoadingSkeletonBundle from 'Components/common/skeleton/ArticleListLoadingSkeletonBundle';
import ArticleGridLayout from 'Components/common/components/ArticleGridLayout';
import { actions as UiAction } from '../../Slice/UISlice';

const NewsSection = styled.div`
margin-top: 15px;
`;

const Wrapper = styled.div`
  .header-wrapper {
    .left-side-wrap {
      .heading {
        line-height: 35px;
      }
      svg {
        min-width: 20px;
      }
    }
  }
`;

const CustomWidget = function CustomWidget() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading: boolean = useSelector(selectNewsLoading);
  const isPagination: boolean = useSelector(selectPagination);
  const newsPageNumber = useSelector(selectNewsPageNumber);
  const newsEnded = useSelector(selectNewsEnded);
  const news = useSelector(selectNewsData);
  const {
    articles, widgetTitle,
  } = news;
  const newsfilters = useSelector(selectNewsFilter);
  const selectedNewsFilters = useSelector(selectNewsSelectedFilter);
  const [filters, setFilters] = useState<SLFilterType[]>([]);
  const [previousNewsFiltersApplied, setPreviousNewsFiltersApplied] = useState<SLFilterType[]>([]);
  const [selectAllAllert, setSelectAllAlert] = useState<boolean>(true);
  const [selectAllAccount, setSelectAllAccount] = useState<boolean>(true);
  const tabView: ViewInterface = useSelector(selectViewTab);
  const isListView = tabView.view === 'listView';
  const getPath = getLocalStorageObject('ViewTab') as ViewInterface;
  const [searchParams] = useSearchParams();
  const widget = searchParams.get('widget') || '';
  const [showFilter, setShowFilter] = useState(true);
  const isTab = IsTablet();

  const initialComponentActions = () => {
    if (widget) {
      dispatch(customWidgetActions.getCustomWidgetNewsData({
        widget,
        page: 1,
        ...(filters.length > 0 && { filters }),
      }));
    }
  };

  function setSelectAll(filter: Filter[]) {
    const alertFilters = filter?.filter((item) => item.filterTypeName === ALERTS);
    const accountFilters = filter?.filter((item) => item.filterTypeName === COMPANIES);
    setSelectAllAlert(allValueInKeyOfArrayExists(alertFilters, 'isSelected', true));
    setSelectAllAccount(allValueInKeyOfArrayExists(accountFilters, 'isSelected', true));
  }

  useEffect(() => {
    if (selectedNewsFilters.length > 1) {
      setFilters(selectedNewsFilters);
      setSelectAll(selectedNewsFilters);
    } else {
      setFilters(newsfilters);
      setPreviousNewsFiltersApplied(newsfilters);
      setSelectAll(newsfilters);
    }
  }, [newsfilters]);

  const onChangeFilters = (flag: boolean, idx: string | number) => {
    const tempFilters = filters.map((filter) => {
      if (filter.filterId === idx) {
        return { ...filter, isSelected: flag };
      } return filter;
    });
    setFilters(tempFilters);
    setSelectAll(tempFilters);
  };

  const selectOrUnselectAllAlert = (flag: boolean) => {
    const tempFilters = filters.map(
      (filter) => (filter.filterTypeName === ALERTS ? { ...filter, isSelected: flag } : filter),
    );
    setFilters(tempFilters);
  };

  const selectOrUnselectAllAccount = (flag: boolean) => {
    const tempFilters = filters.map(
      (filter) => (filter.filterTypeName === COMPANIES ? { ...filter, isSelected: flag } : filter),
    );
    setFilters(tempFilters);
  };

  const onClickHandlerAlert = () => {
    selectOrUnselectAllAlert(!selectAllAllert);
    setSelectAllAlert(!selectAllAllert);
  };

  const onClickHandlerAccount = () => {
    selectOrUnselectAllAccount(!selectAllAccount);
    setSelectAllAccount(!selectAllAccount);
  };

  const applyFilterHandler = () => {
    const params = {
      page: 1,
      widget,
      ...(filters.length > 0 && { filters }),
    };
    dispatch(customWidgetActions.getCustomWidgetNewsData(params));
    setPreviousNewsFiltersApplied(filters);
  };

  const checkForApplyFilter = () => {
    for (let i = 0; i < filters.length; i += 1) {
      if (filters[i].isSelected !== previousNewsFiltersApplied[i]?.isSelected) return false;
    }
    return true;
  };

  useEffect(() => {
    initialComponentActions();
    return () => {
      dispatch(customWidgetActions.resetNewsData());
    };
  }, [widget]);

  useEffect(() => {
    if (!isLoading && !filters?.length) setShowFilter(false);
    else setShowFilter(true);
  }, [isLoading, filters]);

  useEffect(() => {
    dispatch(UiAction.isTabView({
      view: getPath.view,
    }));
  }, [getPath.view]);

  const fetchMoreNews = () => {
    if (
      articles
      && articles?.length
      && !isLoading
      && widget
    ) {
      dispatch(customWidgetActions.getCustomWidgetNewsData({
        widget,
        page: newsPageNumber,
        ...(filters.length > 0 && { filters }),
      }));
    }
  };
  return (
    <Wrapper>
      <NavBarPrimary headerClassName="no-overflow-on-header">
        <div className="left-side-wrap service-lines-left-wrap">
          <BackIcon onClick={() => navigate(-1)} />
          {isLoading && newsPageNumber === 1
            ? <SkeletonCustom height="28px" width="360px" margin="0px 0px 0px 10px" marginSm="0px 0px 0px 10px" />
            : <h1 className="heading text-truncate-one-line">{widgetTitle}</h1>}
        </div>
        <div className="right-side-wrap">
          <GlobalSearch iconMode={isTab} />
          <DrawerAlerts />
          <AccountPopOver />
        </div>
      </NavBarPrimary>
      <SubNavBarPrimary>
        <div className="heading-inner-page tabs-placeholder">
          <div className="head-wrap" />
          <div className="tips-placeholder" />
          <div className="right-side-sub-header">
            <div className={classNames('view-tabs', {
              notdisabledTabs: true,
            })}
            >
              <ViewTabs
                disabled={false}
                listView={isListView}
              />
            </div>
            <div className={classNames('filter-placeholder serviceLines', {
              notDisabledFilter: showFilter,
            })}
            >
              <FilterPopOverWithCompany
                loading={isLoading}
                filters={filters}
                selectAllAllert={selectAllAllert}
                selectAllAccount={selectAllAccount}
                onChangeFilters={onChangeFilters}
                onClickHandlerAlert={onClickHandlerAlert}
                onClickHandlerAccount={onClickHandlerAccount}
                applyFilterHandler={applyFilterHandler}
                disableApplyBtn={checkForApplyFilter()}
                iconMode={isTab}
              />
            </div>
          </div>
        </div>
      </SubNavBarPrimary>
      <div className="container">
        <NewsSection>
          {isLoading
            ? (
              <Row gutter={[16, 16]} id="tabs-details">
                {isListView ? (
                  <ArticleListLoadingSkeletonBundle />
                ) : (
                  <ArticleLoadingSkeletonBundle />
                )}
              </Row>
            )
            : (
              <div id="tabs-details">
                <InfiniteScroll
                  dataLength={articles ? articles.length : 0}
                  next={fetchMoreNews}
                  hasMore={!newsEnded}
                  loader={false}
                  className="o-x-hidden o-y-hidden"
                >

                  {!isLoading
                    && (
                      <ArticleGridLayout
                        isLoading={isPagination}
                        articlesArray={articles}
                        listview={isListView}
                      />
                    )}
                </InfiniteScroll>
              </div>
            )}
        </NewsSection>
        {isPagination && !newsEnded && (
          <Row gutter={[16, 16]}>
            {isListView ? (
              <ArticleListLoadingSkeletonBundle />
            ) : (
              <ArticleLoadingSkeletonBundle />
            )}
          </Row>
        )}
        {!articles.length && !isLoading && !isPagination ? <NoDataFound title={`${selectedNewsFilters?.length ? NODATABASEDONFILTERS : NODATAFOUND}`} /> : null}
      </div>
    </Wrapper>
  );
};

export default CustomWidget;
