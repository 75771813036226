/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { actions } from 'Slice/MarketingCompaignSlice';
import { actions as globalSearchContentActions } from 'Slice/GlobalSearchContentSlice';

import axios from 'Utils/Axios';
import { API } from 'Utils/Constants';
import { MarketingCompaignRequest } from 'Types/RequestBodies';
import { GlobalResponse } from 'Types/ResponseBodies';
import { getCurrentTime, valueToObject } from 'Utils/UtilityFunctions';
import { actions as helperActions } from 'Slice/HelperSlice';

export function* updatePopup(dispatched: PayloadAction<MarketingCompaignRequest>) {
  const startTime = getCurrentTime();
  const payload = dispatched?.payload;
  const data = {
    id: payload?.id,
  };
  try {
    const response: GlobalResponse = yield call(
      axios.post,
      API.MARKETING_COMPAGIN,
      data,
    );
    if (response.status === true) {
      yield put(globalSearchContentActions.updateMarketingCampaignResponse());
      if (payload.callURl !== '') {
        yield put(helperActions.callFlurryEvent(
          valueToObject('article_marketing_campaign', startTime, {
            callToActionUrl: payload.callURl,
          }),
        ));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* MarketingCompaignSaga() {
  yield takeEvery(actions.updatePopup.type, updatePopup);
}
