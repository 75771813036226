/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Article } from 'Types/ArticleTypes';
import { FavouriteState } from 'Types/GlobalTypes';
import { AddToFavoriteRequest, FavoriteArticlesRequest } from 'Types/RequestBodies';

export const initialState: FavouriteState = {
  articleAddedInFavourites: false,
  favoriteArticlesPage: 1,
  favoriteArticlesLoading: false,
  paginationLoader: false,
  favoriteArticles: [],
  favoriteArticlesEnded: false,
  nextCall: true,
};

const sriSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {
    addToFavorite(state, action: PayloadAction<AddToFavoriteRequest>) {
      state.articleAddedInFavourites = false;
    },
    addToFavoriteSuccess(state, action: PayloadAction<AddToFavoriteRequest>) {
      state.articleAddedInFavourites = true;
      const { articleId, favoriteFlag } = action.payload;
      if (articleId) {
        if (state.favoriteArticles && state.favoriteArticles.length) {
          if (favoriteFlag < 1) {
            state.favoriteArticles = state.favoriteArticles
              .filter((article) => article.articleId !== articleId);
          }
          if (state.favoriteArticles.length < 10 && !state.favoriteArticlesEnded) {
            state.nextCall = true;
          }
        }
      }
    },
    getFavoriteArticles(state, action: PayloadAction<FavoriteArticlesRequest>) {
      const { payload } = action;
      if (payload.offset === 0) {
        state.favoriteArticlesEnded = false;
        state.favoriteArticlesLoading = true;
        state.favoriteArticles = [];
        state.favoriteArticlesPage = 1;
      } else { state.paginationLoader = true; }
      state.nextCall = false;
    },
    setFavoriteArticles(state, action: PayloadAction<Article[]>) {
      const { payload } = action;
      state.favoriteArticlesLoading = false;
      if (state.favoriteArticlesPage === 1) {
        state.favoriteArticles = payload;
      } else {
        state.favoriteArticles = state.favoriteArticles.concat(payload);
      }
      state.favoriteArticlesPage += 1;
      state.paginationLoader = false;
    },
    setFavoriteArticlesEnded(state) {
      state.favoriteArticlesEnded = true;
      state.favoriteArticlesLoading = false;
    },
    resetState(state) {
      state.favoriteArticlesPage = 1;
      state.favoriteArticlesLoading = false;
      state.paginationLoader = false;
      state.favoriteArticles = [];
      state.favoriteArticlesEnded = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = sriSlice;
