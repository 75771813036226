import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { actions as certificationActions } from 'Slice/CertificationSlice';
import { selectData } from 'Selector/CertificationSelector';
import {
  boxShadow, primaryColor, primaryFont, wMedium, maxWidth, retina,
} from '../../../Utils/utilsStyle';

export type GroupsProps = {
  active?: boolean
  createGroup: (name: string) => void
  cancelGroup: () => void
  createLoader: boolean
} & typeof defaultProps;

const defaultProps = {
  active: false,
};
const Wrapper = styled.div<{ color: string, }>`
  background: ${(props) => props.color};
  padding: 4px;
  border-radius: 20px;
  margin-bottom: 6px;
  position: relative;
  cursor: pointer;
  min-height: 100px;
  width: 100%;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    border-radius: 11px;
  }
  .card-inner-wrapper {
    background-color:#FFFFFF;
    border-radius: 16px;
    width:100%;
    min-height: 100px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      border-radius: 10px;
    }
    .logo-side {
      display: flex;
      .avatar-text {
        width: 45px;
        height: 45px;
        min-width: 45px;
        background: rgb(217, 217, 217);
        border-radius: 50%;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        font-style: normal;
        font-weight: ${wMedium};
        font-family: ${primaryFont};
        color: rgb(255, 255, 255);
        font-size: 25px;
        line-height: 25px;
        -webkit-box-pack: center;
        justify-content: center;
        @media all and (max-width: ${maxWidth}) , ${retina} {
          font-size: 18px;
          width: 30px;
          height: 30px;
          min-width: 30px;
        }
      }
      .edit-input {
        background-color: #fff;
        border-radius: 8px;
        border: none;
        width: 100%;
        height: 45px;
        outline: none;
        padding: 0 70px 0 15px;
        font-size: 16px;
        box-shadow: ${boxShadow};
        margin-left: 15px;
        font-family: ${primaryFont};
        font-style: normal;
        @media all and (max-width: ${maxWidth}) , ${retina} {
          height: 32px;
          margin-left: 10px;
          font-size: 12px;
          padding: 0 40px 0 15px;
        }
        &:placeholder {
          font-style: italic;
        }
      }
    }
    .other-side {
      .actions {
        margin-top: 14px;
        display: flex;
        justify-content: flex-end;
        .cancel {
            background: rgb(250, 250, 250);
            border: 1px solid rgb(217, 217, 217);
            box-sizing: border-box;
            border-radius: 5px;
            font-style: normal;
            font-weight: ${wMedium};
            font-size: 14px;
            font-family: ${primaryFont};
            line-height: 22px;
            color: rgb(140, 140, 140);
            cursor: pointer;
            margin-right: 12px;
            width: 77px;
            height: 32px;
            padding: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media all and (max-width: ${maxWidth}) , ${retina} {
              width: 60px;
              height: 25px;
              font-size: 11px;
            }
        }
        .create {
            background: rgb(30, 192, 82);
            border-radius: 5px;
            border: 1px solid rgb(30, 192, 82);
            font-style: normal;
            font-weight: ${wMedium};
            font-family: ${primaryFont};
            font-size: 14px;
            line-height: 22px;
            color: rgb(255, 255, 255);
            cursor: pointer;
            width: 77px;
            height: 32px;
            padding: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media all and (max-width: ${maxWidth}) , ${retina} {
              width: 60px;
              height: 25px;
              font-size: 11px;
            }
            .disabled{
              pointer-events: none;
              opacity: 0.5;
            }
        }
      }
    }
  }
`;

const NewGroupWidget = function NewGroupWidget(props: GroupsProps) {
  const {
    createGroup, cancelGroup, active, createLoader,
  } = props;
  const componentRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (componentRef.current) {
      componentRef.current.focus();
    }
  });
  const [editTitle, setEditTitle] = useState('');

  const data = useSelector(selectData);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(certificationActions.certificationAction({
      type: 'create_click',
      source: 'buyer',
      data,
    }));
    createGroup(editTitle);
  };

  return (
    <Wrapper
      color={primaryColor}
      className={classNames({ active })}
    >
      <div className="card-inner-wrapper">
        <div className="logo-side">
          <span className="avatar-text">G</span>
          <input
            ref={componentRef}
            type="text"
            name="title"
            placeholder="Enter your Group Name here..."
            value={editTitle}
            className="edit-input"
            onChange={(e) => setEditTitle(e.target.value)}
            maxLength={30}
            disabled={createLoader}

          />
        </div>
        <div className="other-side">
          <div className="actions">
            <span
              className="cancel"
              onClick={cancelGroup}
              onKeyPress={cancelGroup}
              role="button"
              aria-label="Cancel"
              tabIndex={0}
            >
              Cancel
            </span>
            <span
              className={`create ${createLoader || editTitle.trim() === '' ? 'disabled' : ''}`}
              onClick={handleClick}
              onKeyPress={handleClick}
              role="button"
              aria-label="Create"
              tabIndex={0}
            >
              Create
            </span>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

NewGroupWidget.defaultProps = defaultProps;
export default NewGroupWidget;
