import React from 'react';

export const Cross = function Cross(props: any) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="cross-icon-scg"
      {...props}
    >
      <path
        d="M11.8995 2L8.04969 5.8498L4.19989 2L2 4.19989L5.8498 8.04969L2 11.8995L4.19989 14.0994L8.04969 10.2496L11.8995 14.0994L14.0994 11.8995L10.2496 8.04969L14.0994 4.19989L11.8995 2Z"
        fill="#C5C5C5"
      />
    </svg>
  );
};

export default Cross;
