/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/*
 * UserAuth Slice
 *
 * Here we define:
 * - The shape of our container's slice of Redux store,
 * - All the actions which can be triggered for this slice, including their effects on the store.
 *
 * Note that, while we are using dot notation in our reducer, we are not actually mutating the state
 * manually. Under the hood, we use immer to apply these updates to a new copy of the state.
 * Please see https://immerjs.github.io/immer/docs/introduction for more information.
 *
 */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  SignUpEmailVerificationResponse, SignUpFormResponse, TellMeMoreResponse,
  SendVerificationCodeEmailResponse,
  SubmitVerificationCodeResponse,
} from 'Types/SignUpTypes';
import { SignUpAuthState } from 'Types/GlobalTypes';
import {
  SignUpRequest, TellMeMoreRequest, SignUpFormRequest,
  SendVerificationCodeEmailRequest, SubmitVerificationCodeRequest,

} from 'Types/RequestBodies';

export const initialState: SignUpAuthState = {
  emailForVerification: '',
  emailForVerificationResponse: null,
  SignUpFormRequestBody: null,
  SignUpFormResponse: null,
  emailSentTellMeMore: '',
  tellMeMoreResponse: null,
  loading: false,
  error: '',
  sendVerificationCodeEmail: {
    loading: false,
    loaded: false,
    data: undefined,
  },
  submitVerificationCode: {
    loading: false,
    loaded: false,
    data: undefined,
  },
};

const userSignUpSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    emailVerification(state, action: PayloadAction<SignUpRequest>) {
      state.emailForVerification = action.payload.email;
      state.loading = true;
      state.error = '';
    },
    emailVerificationResponseRecieved(
      state,
      action: PayloadAction<SignUpEmailVerificationResponse>,
    ) {
      state.emailForVerificationResponse = action.payload;
      state.loading = true;
      state.error = '';
    },
    clearResponse(state) {
      state.emailForVerificationResponse = null;
    },
    signUpFormReq(state, action: PayloadAction<SignUpFormRequest>) {
      state.SignUpFormRequestBody = action.payload;
    },
    clearState(state) {
      state.emailForVerification = '';
      state.emailForVerificationResponse = null;
      state.SignUpFormRequestBody = null;
      state.SignUpFormResponse = null;
      state.emailSentTellMeMore = '';
      state.loading = false;
      state.error = '';
    },
    signUpFormRes(state, action: PayloadAction<SignUpFormResponse>) {
      state.SignUpFormResponse = action.payload;
    },
    tellMeMore(state, action: PayloadAction<TellMeMoreRequest>) {
      state.emailSentTellMeMore = action.payload.email;
    },
    tellMeMoreResponse(state, action: PayloadAction<TellMeMoreResponse>) {
      state.tellMeMoreResponse = action.payload;
    },
    sendVerificationCodeEmail(
      state,
      action: PayloadAction<SendVerificationCodeEmailRequest>,
    ) {
      state.sendVerificationCodeEmail.loading = true;
      state.sendVerificationCodeEmail.loaded = false;
    },
    sendVerificationCodeEmailResponse(
      state,
      action: PayloadAction<SendVerificationCodeEmailResponse>,
    ) {
      state.sendVerificationCodeEmail.data = action.payload;
      state.sendVerificationCodeEmail.loading = false;
      state.sendVerificationCodeEmail.loaded = true;
    },
    submitVerificationCode(
      state,
      action: PayloadAction<SubmitVerificationCodeRequest>,
    ) {
      state.submitVerificationCode.loading = true;
      state.submitVerificationCode.loaded = false;
    },
    submitVerificationCodeResponse(
      state,
      action: PayloadAction<SubmitVerificationCodeResponse>,
    ) {
      state.submitVerificationCode.loading = false;
      state.submitVerificationCode.loaded = true;
      state.submitVerificationCode.data = action.payload;
    },
    resetCodeSubmission(
      state,
    ) {
      state.submitVerificationCode.loaded = false;
      state.sendVerificationCodeEmail.loaded = false;
      state.submitVerificationCode.loading = false;
      state.sendVerificationCodeEmail.loading = false;
      state.submitVerificationCode.data = undefined;
      state.sendVerificationCodeEmail.data = undefined;
    },
  },
});

export const { actions, reducer, name: sliceKey } = userSignUpSlice;
