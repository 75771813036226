import classNames from 'classnames';
import styled from 'styled-components';
import { maxTablet, retina } from 'Utils/utilsStyle';

const Wrapper = styled.div<{ diameterSm: string, diameter: string }>`
  &.searchSkeleton {
    margin: 20px 0px 20px 0px;
  }
  overflow: hidden;
  margin: 0px 25px 50px 25px;
  @media all and (max-width: 1439px), (max-height: 729px), (-webkit-device-pixel-ratio: 1.25) {
      margin: 0 0 20px 0;
  }
  .person-skeleton-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
   .person-box {
    width: ${(props) => props.diameter};
    height: ${(props) => props.diameter};
    min-width: ${(props) => props.diameter};
    border-radius: 50%;
    margin: 0px 3px 5px 3px;
    @media all and (max-width: 1540px) , ${retina} {
      width: ${(props) => props.diameterSm};
      height: ${(props) => props.diameterSm};
      min-width: ${(props) => props.diameterSm};
    }
    @media all and (max-width: ${maxTablet}) {
      width: 100px;
      height: 100px;
      min-width: 100px;
    }
   }
   .title-box {
     height: 20px;
     border-radius: 25px;
     width: 80%;
     margin-bottom: 10px;
     margin-top: 20px;
   }
   .badge-box {
    width: 120px;
    height: 20px;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
    margin-left: 0;
    margin-top: -20px;
    margin-bottom: 0;
      @media all and (max-width: ${maxTablet}) {
       width: 80px;
      }
    }
    .title-box-short{
     width: 110px;
     height: 20px;
     border-radius: 25px;
     margin-bottom: 30px;
      @media all and (max-width: ${maxTablet}) {
       width: 70px;
      }
    }
  }
  .skeleton-box {
    height: 20px;
    border-radius: 25px;
    width: 50px;
  }
  .btn {
    border-radius: 25px;
    width: 94px;
    height: 40px;
    @media all and (max-width: ${maxTablet}) {
      height: 20px;
    }
  }
`;

type Props = {
  diameterSm?: string,
  diameter?: string,
  hideBtn?: boolean,
  hideTitle?: boolean,
  onlyPersonImage?: boolean,
  className?: string,
  inGlobalSearch?: boolean,
} & typeof defaultProps;

const defaultProps = {
  diameterSm: '',
  diameter: '140px',
  hideBtn: false,
  hideTitle: false,
  onlyPersonImage: false,
  className: '',
  inGlobalSearch: false,
};

const PersonSkeleton = function PersonSkeleton(props: Props) {
  const {
    diameterSm, hideBtn, hideTitle, diameter, onlyPersonImage,
    className, inGlobalSearch,
  } = props;
  return (
    <Wrapper
      diameterSm={diameterSm}
      diameter={diameter}
      className={classNames('person-skeleton-loader', className, {
        searchSkeleton: inGlobalSearch,
      })}
    >
      <div className="person-skeleton-wrapper">
        <span className="skeleton-box person-box" />
        <span className="skeleton-box badge-box" />
        {!onlyPersonImage ? (
          <>
            {hideTitle ? null : <span className="skeleton-box title-box" />}
            {hideTitle ? null : <span className="skeleton-box title-box-short" />}
            {hideBtn ? null : <span className="skeleton-box btn" />}
          </>
        ) : null}

      </div>
    </Wrapper>
  );
};

PersonSkeleton.defaultProps = defaultProps;
export default PersonSkeleton;
