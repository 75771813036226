import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'Store/InjectorsTypes';
import { initialState } from 'Slice/ProfileConfirmationSlice';

const selectDomain = (state: RootState) => state?.profileConfirmation || initialState;

export const selectPopupVisible = createSelector([selectDomain], (state) => state.popupVisible);
export const selectPeopleLoading = createSelector([selectDomain], (state) => state.peopleLoading);
export const selectSearchedPeople = createSelector([selectDomain], (state) => state.results);
export const confirmedPersonCount = createSelector(
  [selectDomain],
  (state) => state.confirmedPersonCount,
);

export const selectConfirmedPersonLinkedinCount = createSelector(
  [selectDomain],
  (state) => state.confirmedPersonLinkedinCount,
);

export const selectSearchError = createSelector([selectDomain], (state) => state.searchError);
export const selectPage = createSelector([selectDomain], (state) => state.page);
export const selectSearchEnded = createSelector([selectDomain], (state) => state.searchEnded);
export const selectConfirmSuccess = createSelector([selectDomain], (state) => state.confirmSuccess);
export const selectConfirmFail = createSelector([selectDomain], (state) => state.confirmFail);
export const selectConfirming = createSelector([selectDomain], (state) => state.confirming);
export const selectMappedId = createSelector([selectDomain], (state) => state.mappedPersonId);
export const selectLinkedinPeopleLoading = createSelector(
  [selectDomain],
  (state) => state.linkedinPeopleLoading,
);
export const selectLinkedinSearchError = createSelector(
  [selectDomain],
  (state) => state.linkedinSearchError,
);
export const selectLinkedinSearchEnded = createSelector(
  [selectDomain],
  (state) => state.linkedinSearchEnded,
);
export const selectConfirmedPerson = createSelector(
  [selectDomain],
  (state) => state.confirmedPerson,
);
