/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  ReactNode,
  Suspense, lazy, useEffect,
  useState,
} from 'react';
import {
  Navigate, Outlet, Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';
import 'App.css';
import GlobalInjector from 'Store/GlobalInjector';
import {
  FRESHCHAT_TOKEN, FRESHCHAT_URL, ONBOARDING_ROUTE, USER_EXPIRED_ROUTE,
} from 'Utils/Constants';
import NavBarPrimary from 'Components/layout/headers/NavBarPrimary';
import SkeletonCustom from 'Components/common/skeleton/SkeletonCustom';
import CustomWidget from 'Pages/customWidget/CustomWidget';
import classNames from 'classnames';
import RestrictUserModal from 'Components/common/modal/RestrictUserModal';
import RestrictLayout from 'Components/layout/RestrictLayout';
import MobileAppBanner from 'Components/common/modal/MobileAppBanner';
import CompanyComparison from 'Pages/companyComparison/CompanyComparison';
import SimilarPeople from 'Pages/person/SimilarPeople';
import { Dropdown } from 'Translation/Dropdown';
import { useSelector } from 'react-redux';
import { selectDisclessPersonality, selectGilroyDiscalimer } from 'Selector/GlobalSearchContentSelector';
import Layout from './Components/layout/Layout';
import {
  getLocalStorageValue,
  guestRouteCheck,
  isActive,
  isGuest,
  isSalesforce,
  IsMobile,
  navigateToAuthServer,
  skipOnboarding, isEnterpriseUser,
  getLangCookie,
  isGuestfromEmail,
  Options,
} from './Utils/UtilityFunctions';
import NotFound from 'Pages/NotFound';

const Freshchat = lazy(() => import('reactjs-freshchat')
  .then((module) => ({ default: module.Freshchat })));
const Feed = lazy(() => import('Pages/feed/Feed'));
const Person = lazy(() => import('Pages/person/Person'));
const PeoplePage = lazy(() => import('Pages/people/PeoplePage'));
const PeopleGroupDetail = lazy(() => import('Pages/people/PeopleGroupDetail'));
const ArcTypes = lazy(() => import('Pages/arctypes/ArcTypes'));
const SearchDetails = lazy(() => import('Pages/search/SearchDetails'));
const Settings = lazy(() => import('Pages/person/Settings'));
const Company = lazy(() => import('Pages/company/Company'));
const DeepLinkView = lazy(() => import('deeplinking/DeepLinkView'));
const BranchIo = lazy(() => import('deeplinking/BranchIo'));
const SalesIndex = lazy(() => import('Pages/sri/SalesIndex'));
const IndustriesHome = lazy(() => import('Pages/industries/IndustriesPage'));
const DocumentHub = lazy(() => import('Pages/document/DocumentHub'));
const IndustriesDetailPage = lazy(() => import('Pages/industries/IndustriesDetailPage'));
const CompanyNotableStaff = lazy(() => import('Pages/company/CompanyNotableStaff'));
const Favorites = lazy(() => import('Pages/person/Favorites'));
const Login = lazy(() => import('Pages/auth/Login'));
const SSO = lazy(() => import('Pages/auth/SSO'));
const Onboardingv2 = lazy(() => import('Pages/onboardingRevamp/Onboarding'));
const Search = lazy(() => import('Pages/search/GlobalSearchResults'));
const CompaniesHome = lazy(() => import('Pages/company/CompaniesHome'));
const Articles = lazy(() => import('Pages/dailyIq/DailyIq'));
const GuestSearch = lazy(() => import('Pages/search/GuestSearchResults'));
const ServiceLinesHome = lazy(() => import('Pages/serviceline/serviceLinesPage'));
const ServiceLinesDetailPage = lazy(() => import('Pages/serviceline/serviceLinesDetailPage'));
const SalesforceRouter = lazy(() => import('Components/Salesforce/SalesforceRouter'));

const PrivateWrapper = function PrivateWrapper() {
  const { pathname, search }: { pathname: string, search: string } = useLocation();
  if (isActive() || isGuestfromEmail()) {
    if (isGuest() || isGuestfromEmail()) {
      if (!guestRouteCheck() && !isGuestfromEmail()) {
        navigateToAuthServer(pathname + search);
      } else {
        return <Layout><Outlet /></Layout>;
      }
    }
    if (pathname !== ONBOARDING_ROUTE && !skipOnboarding()) return <Navigate to="/onboarding" />;
    return <Layout><Outlet /></Layout>;
  }
  navigateToAuthServer(pathname + search);
  return null;
};

const getApp = function getApp(app: string, target: string) {
  const root = document.querySelector(target);
  const node = root?.classList?.add(app);
  return node;
};

type Props = {
  children: object,
};

// interface RedirectIfEndsWithDotProps {
//   children: ReactNode;
// }

// const RedirectIfEndsWithDot: React.FC<RedirectIfEndsWithDotProps> = ({ children }) => {
//   const { pathname, search } = useLocation();
//   const navigate = useNavigate();
//   console.log('here111', location)
//   useEffect(() => {
//     if (pathname.match(/\.$/) || search.match(/\.$/)) {
//       navigate('/notfound', { replace: true });
//     }
//   }, [location, navigate]);

//   return <>{children}</>;
// };

const App = function App(props: Props) {
  const { children } = props;
  const [shouldRenderDropdown, setShouldRenderDropdown] = useState<boolean>(false);
  const { pathname }: { pathname: string } = useLocation();
  const { showDisclaimer } = useSelector(selectGilroyDiscalimer);
  GlobalInjector();
  const disclessPersonality = useSelector(selectDisclessPersonality);

  useEffect(() => {
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };
  }, []);

  useEffect(() => (isSalesforce() ? getApp('salesForceApp', '#root') : getApp('salesApp', '#root')), []);
  useEffect(() => (getApp(!isEnterpriseUser() ? 'freeUser' : 'notFreeUser', 'body')), []);
  useEffect(() => {
    document.documentElement.lang = getLangCookie();
  }, [getLangCookie()]);

  useEffect(() => {
    const node = document.querySelector('body');
    if (pathname === ONBOARDING_ROUTE) {
      node?.classList.add('onboarding');
      setShouldRenderDropdown(true);
    } else if (isGuestfromEmail()) {
      setShouldRenderDropdown(true);
      node?.classList.remove('onboarding');
    } else {
      setShouldRenderDropdown(false);
      node?.classList.remove('onboarding');
    }
  }, [pathname]);

  return (
    <div className={classNames('App', { nutenixUser: disclessPersonality })}>
      {!shouldRenderDropdown && isActive() && <Dropdown options={Options} />}
      <Suspense fallback={(
        isSalesforce() ? <div /> : (
          <Layout>
            <NavBarPrimary>
              <span className="resolution-fix navbarFix">
                <div className="left-side-wrap">
                  <SkeletonCustom
                    width="20%"
                    smWidth="150px"
                    height="28px"
                    margin="0px 0px 0px 0px"
                    marginSm="0px 0px 0px 0px"
                  />
                </div>
              </span>
              <span className="resolution-fix navbarFix">
                <div className="right-side-wrap">
                  <SkeletonCustom
                    width="20%"
                    smWidth="150px"
                    height="28px"
                    margin="0px 0px 0px 0px"
                    marginSm="0px 0px 0px 0px"
                  />
                  <div className="showTabs" />
                </div>
              </span>
            </NavBarPrimary>
            <div />
          </Layout>
        )
      )}
      >
        <BranchIo />
        {isActive() ? (
          <Suspense
            fallback={
              <div />
            }
          >
            <Freshchat
              token={FRESHCHAT_TOKEN}
              host={FRESHCHAT_URL}
              externalId={getLocalStorageValue('email') || ''}
              email={getLocalStorageValue('email') || ''}
              firstName={getLocalStorageValue('fullname') || ''}
              config={{
                headerProperty: {
                  hideChatButton: true,
                },
                content: {
                  headers: {
                    faq: 'xiQ University',
                  },
                },
              }}
              faqTags={{
                tags: ['Sales X'],
                filterType: 'category',
              }}
            />
          </Suspense>
        ) : null}
        {(IsMobile() && !isSalesforce() && !showDisclaimer) ? (
          <MobileAppBanner />
        ) : null}
        {/* <RedirectIfEndsWithDot>
        </RedirectIfEndsWithDot> */}
        <Routes>
          <Route path="sso/:token" element={<SSO />} />
          <Route path="/webapp/" element={<DeepLinkView />} />
          <Route path="/password*" element={<Login />} />
          <Route
            path="article"
            element={(
              <Layout><Articles /></Layout>
            )}
          />
          <Route
            path="guestSearch"
            element={(
              <Layout><GuestSearch /></Layout>)}
          />
          <Route
            path={USER_EXPIRED_ROUTE}
            element={(
              <RestrictLayout>
                <RestrictUserModal />
              </RestrictLayout>
            )}
          />
          {/* All private routes will be nested under this wrapper route */}
          <Route element={<PrivateWrapper />}>
            {/* <Route path="onboarding" element={<Onboarding />} /> */}
            <Route path="onboarding" element={<Onboardingv2 />} />
            <Route path="feed/:section" element={<Feed />} />
            <Route path="feed" element={<Feed />} />
            <Route path="companies" element={<CompaniesHome />} />
            <Route path="company/:section" element={<Company />} />
            <Route path="notablestaff" element={<CompanyNotableStaff />} />
            <Route path="person/:section" element={<Person />} />
            <Route path="settings" element={<Settings />} />
            <Route path="favorites" element={<Favorites />} />
            <Route path="people" element={<PeoplePage />} />
            <Route path="comparecompany" element={<CompanyComparison />} />
            <Route path="industries" element={<IndustriesHome />} />
            <Route path="documents" element={<DocumentHub />} />
            <Route path="industry/:section" element={<IndustriesDetailPage />} />
            <Route path="servicelines" element={<ServiceLinesHome />} />
            <Route path="serviceline/:section" element={<ServiceLinesDetailPage />} />
            <Route path="search" element={<Search />} />
            <Route path="search/globalsearch" element={<SearchDetails />} />
            <Route path="groupdetails/:section" element={<PeopleGroupDetail />} />
            <Route path="arctypes/:section" element={<ArcTypes />} />
            <Route path="sri/:section" element={<SalesIndex />} />
            <Route path="news" element={<CustomWidget />} />
            <Route path="similarpeople" element={<SimilarPeople />} />
            <Route path="salesforce/:section" element={<SalesforceRouter />} />
            {children}
            <Route path="notfound" element={<NotFound />} />
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
};
export default App;
