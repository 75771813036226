/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FollowedResponse } from 'Types/CompaniesTypes';
import { Filter } from 'Types/FilterTypes';
import { PeopleState } from 'Types/GlobalTypes';
import { FilterFollowedType, RecommendedResponse, ResponseExport } from 'Types/PersonTypes';
import { FollowedPeopleRequest, ExportFollowRequest } from 'Types/RequestBodies';

export const initialState: PeopleState = {
  followedPeople: [],
  peopleCount: 0,
  followedPeopleLoading: false,
  recommendedPeople: [],
  recommendedTitle: '',
  recommendedPeopleLoading: false,
  followedPeopleMoreLoading: false,
  exportFollowedLoading: false,
  removed: false,
  filters: [],
  appliedFilters: [],
  followedPeopleEnded: false,
  exportPeopleUrl: '',
  recommendedPeopleLoaded: false,
};

const PeopleSlice = createSlice({
  name: 'people',
  initialState,
  reducers: {
    getFollowedPeople(state, action: PayloadAction<FollowedPeopleRequest>) {
      if (action.payload?.offset !== 0) {
        state.followedPeopleMoreLoading = true;
      } else {
        state.followedPeopleLoading = true;
        state.followedPeople = [];
      }
    },
    getExportFollowedPeople(state, action: PayloadAction<ExportFollowRequest>) {
      state.exportFollowedLoading = true;
    },
    setExportFollowedPeople(state, action: PayloadAction<ResponseExport>) {
      state.exportFollowedLoading = false;
      state.exportPeopleUrl = action.payload.url;
    },
    setInitialFollowedPeople(state, action: PayloadAction<FollowedResponse>) {
      const { members, followedPeople, filters } = action.payload;
      state.followedPeople = members;
      state.followedPeopleLoading = false;
      state.followedPeopleMoreLoading = false;
      state.peopleCount = followedPeople;
      state.filters = filters || [];
      if (members.length) {
        state.followedPeopleEnded = false;
      } else {
        state.followedPeopleEnded = true;
      }
    },
    setMoreFollowedPeople(state, action: PayloadAction<FollowedResponse>) {
      const { members } = action.payload;
      state.followedPeople = state.followedPeople.concat(members);
      state.followedPeopleMoreLoading = false;
      if (members.length) {
        state.followedPeopleEnded = false;
      } else {
        state.followedPeopleEnded = true;
      }
    },
    getRecommendedPeople(state) {
      state.recommendedPeopleLoading = true;
    },
    setPeopleRemoved(state, action: PayloadAction<boolean>) {
      state.removed = action.payload;
    },
    setRecommendedPeople(state, action: PayloadAction<RecommendedResponse>) {
      state.recommendedPeopleLoading = false;
      state.recommendedTitle = action.payload?.title;
      state.recommendedPeople = action.payload?.data;
      state.recommendedPeopleLoaded = true;
    },
    filterFollowedPeople(state, action: PayloadAction<FilterFollowedType>) {
      state.followedPeople = state.followedPeople.filter(
        (people) => !action.payload.feedIds.includes(people.personId),
      );
      state.peopleCount -= action.payload.feedIds.length;
    },
    setPeopleCount(state, action: PayloadAction<number>) {
      state.peopleCount = action.payload;
    },
    updateRecommendedPeopleList(state, action: PayloadAction<number>) {
      let findItem = state.recommendedPeople.filter(
        (person) => person.personId === action.payload,
      );
      findItem = findItem.filter(
        ({ personId: id1 }) => !state.followedPeople.some(({ personId: id2 }) => id2 === id1),
      );
      if (state.appliedFilters.length) {
        const filteredExecutives = findItem.filter((executive) => {
          const archetypeMatched = state.appliedFilters.some(
            (filter) => filter.filterName === executive.archeType.name
             && filter.isSelected === true,
          );
          return archetypeMatched;
        });
        if (filteredExecutives.length) {
          filteredExecutives.forEach((filter) => state.followedPeople.unshift(filter));
          state.peopleCount += 1;
        }
      } else {
        findItem.forEach((filter) => state.followedPeople.unshift(filter));
        state.peopleCount += 1;
      }
      state.recommendedPeople = state.recommendedPeople.filter(
        (person) => person.personId !== action.payload,
      );
    },
    handlePersonNotes(state, action: PayloadAction<number>) {
      const id = action.payload;
      const members = state.followedPeople ?? [];
      const filteredPerson = members
        .find((person) => person.personId === id);
      if (filteredPerson) {
        filteredPerson.personNotes = 0;
      }
    },
    setExportPeopleUrl(state, action: PayloadAction<string>) {
      state.exportPeopleUrl = action.payload;
    },
    setAppliedFilters(state, action: PayloadAction<Filter[]>) {
      state.appliedFilters = action.payload;
    },
    // setRecommendedLoaded(state, action: PayloadAction<boolean>) {
    //   state.recommendedPeopleLoaded = action.payload;
    // },
  },
});

export const { actions, reducer, name: sliceKey } = PeopleSlice;
