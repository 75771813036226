import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import {
  maxMobile,
  primaryFont, primaryHeadingColor, secondaryFont, wBold, wLight,
} from 'Utils/utilsStyle';
import { PopUpResponse } from 'Types/PersonTypes';
import { selectExpiredPopup, selectLoading } from 'Selector/GlobalSearchContentSelector';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'Slice/GlobalSearchContentSlice';
import SkeletonCustom from '../skeleton/SkeletonCustom';

const Wrapper = styled.div``;
const LogoutModal = styled(Modal)`
  .ant-modal-content{
    border-radius: 18px;
    text-align: center;

    .ant-modal-body{
      padding: 30px 0 0 0;
      padding-top: 50px !important;

      h4.heading_logout_popup{
        font-family: ${secondaryFont};
        font-size: 32px;
        letter-spacing: -0.8;
        line-height: 1.469;
        color: ${primaryHeadingColor};
        font-weight: ${wBold};
        margin: 30px 0;
        @media all and (max-width: ${maxMobile}) {
          font-size: 22px;
          line-height: 24px;
          margin: 20px 0;
        }
      }
      .text_logout_popup{
        font-family: ${primaryFont};
        font-size: 20px;
        font-weight: ${wLight};
        letter-spacing: -0.56;
      }
    }
    .ant-modal-footer{
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;
    }
  }
`;

const RestrictUserModal = function RestrictUserModal() {
  const expiredPopup: PopUpResponse = useSelector(selectExpiredPopup);
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();
  useEffect(() => {
    if (expiredPopup.popupText === '') {
      dispatch(actions.globalSearchContent());
    }
  }, []);
  return (
    <Wrapper>
      <LogoutModal
        visible
        centered
        width="530px"
        className="logout-modal-c way-no-close"
        wrapClassName="noWayToClose"
        getContainer={() => document.querySelector('.target-here-modal') || document.body}
      >
        {loading
          ? (
            <SkeletonCustom
              width="100%"
              height="300px"
              margin="0px 0px 0px 0px"
              marginSm="0px 0px 0px 0px"
              logo
              logoSize="100px"
            />
          )
          : (
            <div className="ant-modal-body-custom">
              <h4 className="heading_logout_popup">{expiredPopup.popupHeading}</h4>
              <p className="text_logout_popup">{expiredPopup.popupText}</p>
              <a
                className="mail_link"
                href={`mailto:${expiredPopup.contactusEmail}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {expiredPopup.contactusEmail}
              </a>
              <a
                className="ant-btn email-trigger-btn"
                href={`mailto:${expiredPopup.contactusEmail}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact Us
              </a>
            </div>
          )}
      </LogoutModal>
    </Wrapper>
  );
};

export default RestrictUserModal;
