import React from 'react';
import AuthStyle from './AuthStyle';
import DynamicAppStyle from './DynamicAppStyle';
import FontFace from './FontsFace';
import FreshBotStyle from './FreshBotStyle';
import GeneralStyle from './GeneralStyle';
import GeneralStyleV2 from './GeneralStyleV2';
import GoogleTranslatorStyle from './GoogleTranslatorStyle';
import ResponsiveStyle from './ResponsiveStyle';
import SafariStyle from './SafariStyle';

const Style: React.FC = function Style() {
  return (
    <>
      <FreshBotStyle />
      <FontFace />
      <GeneralStyle />
      <GeneralStyleV2 />
      <ResponsiveStyle />
      <AuthStyle />
      <DynamicAppStyle />
      <GoogleTranslatorStyle />
      <SafariStyle />
    </>
  );
};

export default Style;
