import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getLocalStorageValue } from 'Utils/UtilityFunctions';
import { Modal, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'Slice/LogoutSlice';
import { selectUpdated } from 'Selector/SettingsSelector';
import {
  primaryColor, secondaryFont, primaryHeadingColor, wBold,
  primaryFont, wLight, userPlaceHolder, maxWidth, retina, minWidthApp, maxMobile,
} from '../../../Utils/utilsStyle';
import SmallButton from './SmallButton';
import CrossCircle from '../assets/svgIcons/CrossCircle';
import PeopleWidgetWithOutHolo from './PeopleWidgetWithOutHolo';

type Props = {
  persona?: string,
} & typeof defaultProps;

const defaultProps = {
  persona: '#E79540 0%,#37CC10 31%,#E3433F 69%,#306FE8 100%',
};

const Wrapper = styled.div<{ color: string, }>`
  h5 {
    font-size: 18px;
    color: ${primaryHeadingColor};
    font-weight: 800;
    font-family: ${secondaryFont};
    letter-spacing: -0.45px;
    line-height: 20px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      font-size: 16px;
      line-height: 18px;
    }
  }
  p {
    font-size: 16px;
    color: #7E7E7E !important;
    font-weight: 400;
    font-family: ${primaryColor};
    letter-spacing: -0.75px;
  }
  .peopleWidget {
    margin-right: 13px;
  }
  // .text-popover-div {
  //   @media all and (max-width: ${minWidthApp}) {
  //     padding-left: 2px;
  //   }
  // }
  .logout-dropdown-btn {
    @media all and (max-width: ${minWidthApp}){
      button {
        padding-left: 5px;
      }
    }
  }
  .logout-name-here {
    @media all and (max-width: ${minWidthApp}) {
      padding-left: 4px;
    }
  }
`;

const LogoutModal = styled(Modal)`
  .ant-modal-content{
    border-radius: 18px;
    text-align: center;

    .ant-modal-body{
      padding: 30px 0 0 0;
      padding-top: 50px !important;
      
      h4.heading_logout_popup{
        font-family: ${secondaryFont};
        font-size: 32px;
        letter-spacing: -0.8;
        line-height: 1.469;
        color: ${primaryHeadingColor};
        font-weight: ${wBold};
        margin: 30px 0;
        @media all and (max-width: ${maxMobile}) {
          font-size: 22px;
          line-height: 24px;
          margin: 20px 0;
        }
      }
      .text_logout_popup{
        font-family: ${primaryFont};
        font-size: 20px;
        font-weight: ${wLight};
        letter-spacing: -0.56;
      }
    }
    .ant-modal-footer{
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;
    }
  }
`;

const UserInfoCard = function UserInfoCard(props: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [fullName, setFullName] = useState<string>('');
  const updated: boolean = useSelector(selectUpdated);
  const dispatch = useDispatch();
  const {
    persona,
  } = props;

  const logout = () => {
    setSpinner(true);
    dispatch(actions.logoutUser({
      deviceToken: '',
    }));
  };

  useEffect(() => {
    setFullName(getLocalStorageValue('fullname') || '');
  }, [updated, localStorage]);

  return (
    <Wrapper color={persona}>
      <div className="d-flex align-items-center">
        <div className="peopleWidget d-flex align-items-center">
          <PeopleWidgetWithOutHolo
            diameter="92px"
            diameterSm="50px"
            diameterXsm="50px"
            logo={getLocalStorageValue('profilePic') || userPlaceHolder}
            persona={persona}
            margin="0"
            wrapperColor={localStorage.getItem('personalitycolors') || ''}
          />
        </div>
        <div className="text-popover-div">
          <h5 className="primary-color text-start logout-name-here text-truncate-two-line">
            {fullName}
          </h5>
          <div className="d-flex logout-dropdown-btn">
            <SmallButton
              title="Logout"
              onButtonClick={() => setIsVisible(true)}
              variant="text"
              fontSize="12px"
              padding="5px 10px 0 0"
            />
          </div>
        </div>
      </div>
      <LogoutModal
        visible={isVisible}
        onOk={logout}
        onCancel={() => setIsVisible(false)}
        centered
        cancelText="No"
        okText="Yes"
        closeIcon={<CrossCircle />}
        width="530px"
        className="logout-modal-c"
      >
        {spinner
          && (
            <div className="spinner-layer">
              <Spin />
            </div>
          )}
        <h4 className="heading_logout_popup">Log Out</h4>
        <p className="text_logout_popup">Are you sure you want to log out?</p>
      </LogoutModal>
    </Wrapper>
  );
};

UserInfoCard.defaultProps = defaultProps;

export default UserInfoCard;
