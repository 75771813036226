/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'Utils/Axios';
import { API } from 'Utils/Constants';
import { FiltersDataResponse } from 'Types/CompaniesTypes';
import { actions } from '../Slice/TriggersSlice';
import { FiltersRequest } from '../Types/RequestBodies';

export function* getFilters(dispatched: PayloadAction<FiltersRequest>) {
  try {
    const response: FiltersDataResponse = yield call(
      axios.post,
      API.GET_NOTIFICATIONS_FILTERS,
      dispatched?.payload,
    );
    if (response?.status === true) {
      yield put(actions.setFilters(response?.response));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getPeopleFilters(dispatched: PayloadAction<FiltersRequest>) {
  try {
    const response: FiltersDataResponse = yield call(
      axios.post,
      API.GET_PEOPLE_NOTIFICATION_FILTER,
      dispatched?.payload,
    );
    if (response?.status === true) {
      yield put(actions.setPeopleFilters(response?.response));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* triggersSaga() {
  yield takeLatest(actions.getFilters.type, getFilters);
  yield takeLatest(actions.getPeopleFilters.type, getPeopleFilters);
}
