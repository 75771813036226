/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
import {
  call, put, takeEvery, takeLatest, all,
} from 'redux-saga/effects';
import axios from 'Utils/Axios';
import { actions } from 'Slice/CertificationSlice';
import { API } from 'Utils/Constants';
import {
  CertificatePDFResponse, CertificationResponse, EditNameResponse, GetCertificationData,
} from 'Types/CertificationTypes';
import { PayloadAction } from '@reduxjs/toolkit';
import { CertificationActionPayload, EditNameRequest } from 'Types/RequestBodies';
import { callFlurryForCertification, getCurrentTime, valueToObject } from 'Utils/UtilityFunctions';
import { actions as flurryActions } from 'Slice/HelperSlice';

export function* getCertificationData(dispatched: PayloadAction<GetCertificationData>) {
  const { payload } = dispatched;
  try {
    const response: CertificationResponse = yield call(
      axios.post,
      API.GET_CERTIFICATION_DATA,
      payload,
    );
    if (response?.status === true) {
      if (payload?.type && payload?.type === 'ai_cert') {
        yield put(actions.setAiCertificationData(response.response));
      } else {
        yield put(actions.setCertificationData(response.response));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* editName(dispatched: PayloadAction<EditNameRequest>) {
  try {
    const response: EditNameResponse = yield call(
      axios.post,
      API.EDIT_NAME,
      dispatched?.payload,
    );
    yield put(actions.setName(response.response.certification_name));
  } catch (err) {
    console.log(err);
  }
}

export function* getCertificatePDF(dispatched: PayloadAction<GetCertificationData>) {
  const { payload } = dispatched;
  try {
    const response: CertificatePDFResponse = yield call(
      axios.post,
      API.GET_CERTIFICATE_PDF,
      payload,
    );
    if (response?.status) {
      if (payload?.type === 'ai_cert') {
        yield put(actions.setAiCertificatePDF(response?.response));
      } else {
        yield put(actions.setCertificatePDF(response?.response));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* certificationAction(dispatched: PayloadAction<CertificationActionPayload>) {
  const { type, source, data } = dispatched.payload;
  try {
    const { module, task, event } = yield call(callFlurryForCertification, data, type, source);
    if (event >= 0 && task >= 0 && module >= 0) {
      const flurryEventAction = flurryActions.callFlurryEvent(
        valueToObject(
          type,
          getCurrentTime(),
          {
            source,
          },
        ),
      );
      const setScoreAction = actions.setScore({ module, task, event });
      yield all([
        put(flurryEventAction),
        put(setScoreAction),
      ]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* aiCertificationAction(dispatched: PayloadAction<CertificationActionPayload>) {
  const { type, source, data } = dispatched.payload;
  try {
    const { module, task, event } = yield call(callFlurryForCertification, data, type, source);
    if (event >= 0 && task >= 0 && module >= 0) {
      const flurryEventAction = flurryActions.callFlurryEvent(
        valueToObject(
          type,
          getCurrentTime(),
          {
            source,
          },
        ),
      );
      const setScoreAction = actions.setAiScore({ module, task, event });
      yield all([
        put(flurryEventAction),
        put(setScoreAction),
      ]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* certificationSaga() {
  yield takeEvery(actions.getCertificationData.type, getCertificationData);
  yield takeLatest(actions.editName.type, editName);
  yield takeLatest(actions.getCertificatePDF.type, getCertificatePDF);
  yield takeEvery(actions.certificationAction.type, certificationAction);
  yield takeEvery(actions.aiCertificationAction.type, aiCertificationAction);
}
