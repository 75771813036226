import React from 'react';
import styled from 'styled-components';
import { minWidthApp, primaryFont, wLight } from 'Utils/utilsStyle';

const Span = styled.span`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: ${wLight};
  font-size: 12px;
  line-height: 14px;
  color: rgba(151, 151, 151, 0.5);
  text-align: right;
  display: inline-block;
  width: 100%;
  padding-right: 10px;
  @media all and (max-width: ${minWidthApp}){
    font-size: 8px;
  }
`;

type Props = {
  label: string
  className?: string
} & typeof defaultProps;

const defaultProps = {
  className: '',
};

const DisclaimerMessage = function DisclaimerMessage(props: Props) {
  const { label, className } = props;
  return (
    <Span className={className}>{label}</Span>
  );
};

DisclaimerMessage.defaultProps = defaultProps;
export default DisclaimerMessage;
