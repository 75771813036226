import styled from 'styled-components';
// import { retina } from 'Utils/utilsStyle';

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  /* margin: 0px 25px 50px 25px; */
  padding: 7px 12px;
  max-height: 55px;
  /* @media all and (max-width: 1439px), (max-height: 729px), (-webkit-device-pixel-ratio: 1.25) {
      margin: 0 0 10px 0;
  } */
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 25%; */
    .person-icon {
      width: 35px;
      height: 35px;
      margin: 0 10px;
      border-radius: 50%;
    }
  }
  .person-data-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    margin-left: 10px;
    .group-1 {
      height: 12px;
      border-radius: 25px;
      width: 85%;
      margin-bottom: 5px;
      margin-top: 5px;
    }
    .group-2 {
      height: 10px;
      border-radius: 25px;
      width: 60%;
      margin-bottom: 5px;
    }
    .group-3 {
      height: 10px;
      border-radius: 25px;
      width: 70%;
      margin-bottom: 5px;
    }
  }
  .skeleton-box {
    height: 20px;
    border-radius: 25px;
    width: 50px;
  }
`;

// type Props = {
//   diameterSm?: string,
// } & typeof defaultProps;

// const defaultProps = {
//   diameterSm: '',
// };

const AffinitySearchResultItemSkeleton = function AffinitySearchResultItemSkeleton() {
  return (
    <Wrapper>
      <div className="image-container">
        <span className="skeleton-box person-icon" />
      </div>
      <div className="person-data-container">
        <span className="skeleton-box group-1" />
        <span className="skeleton-box group-2" />
        <span className="skeleton-box group-3" />
      </div>
    </Wrapper>
  );
};

// AffinitySkeleton.defaultProps = defaultProps;
export default AffinitySearchResultItemSkeleton;
