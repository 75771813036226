/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  call, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'Utils/Axios';
import { API } from 'Utils/Constants';

import { actions as SRIActions } from 'Slice/SRISlice';
import { actions as FavouriteArticleActions } from 'Slice/FavoriteArticlesSlice';
import { actions as SpotlightActions } from 'Slice/SpotlightSlice';
import { actions as FeedActivitiesActions } from 'Slice/FeedActivitiesSlice';
import { actions as PersonProfileActions } from 'Slice/PersonProfileSlice';
import { actions as CompanyActions } from 'Slice/CompaniesSlice';
import { actions as IndustryActions } from 'Slice/IndustriesSlice';
import { actions as GlobalSearchResultsActions } from 'Slice/GlobalSearchResultsSlice';
import { actions as ArticleActions } from 'Slice/ArticlesSlice';
import { actions as AlertActions } from 'Slice/AlertsSlice';
import { actions as ServiceLineActions } from 'Slice/ServiceLinesSlice';

import { actions as helperActions } from 'Slice/HelperSlice';
import { actions as CustomWidgetActions } from 'Slice/CustomWidgetSlice';
import { getCurrentTime, valueToObject } from 'Utils/UtilityFunctions';
import { actions as BuyerGroupActions } from 'Slice/BuyerGroupsPageSlice';
import { AddToFavoriteRequest, FavoriteArticlesRequest } from 'Types/RequestBodies';
import { AddToFavoriteResponse, FavouriteArticlesResponseExtended } from 'Types/FavoriteArticlesTypes';

export function* addToFavorite(dispatched: PayloadAction<AddToFavoriteRequest>) {
  const startTime = getCurrentTime();
  const {
    isSimilarArticle, isDetail, isSimilarArticleTwo, ...params
  } = dispatched.payload;
  try {
    const response: AddToFavoriteResponse = yield call(
      axios.post,
      API.ADD_REMOVE_FAVORITE_ARTICLE,
      params,
    );

    if (response?.status === true) {
      if (isSimilarArticle) {
        yield put(ArticleActions.addToFavoriteSuccessSimilar(dispatched?.payload));
      } else {
        yield put(ArticleActions.addToFavoriteSuccess(dispatched?.payload));
      }
      yield put(FavouriteArticleActions.addToFavoriteSuccess(dispatched?.payload));
      yield put(SRIActions.addToFavoriteSuccess(dispatched?.payload));
      yield put(SpotlightActions.addToFavoriteSuccess(dispatched?.payload));
      yield put(FeedActivitiesActions.addToFavoriteSuccess(dispatched?.payload));
      yield put(PersonProfileActions.addToFavoriteSuccess(dispatched?.payload));
      yield put(CompanyActions.addToFavoriteSuccess(dispatched?.payload));
      yield put(IndustryActions.addToFavoriteSuccess(dispatched?.payload));
      yield put(GlobalSearchResultsActions.addToFavoriteSuccess(dispatched?.payload));
      yield put(AlertActions.addToFavoriteSuccess(dispatched?.payload));
      yield put(ServiceLineActions.addToFavoriteSuccess(dispatched?.payload));
      yield put(CustomWidgetActions.addToFavoriteSuccess(dispatched?.payload));
      yield put(BuyerGroupActions.addToFavoriteSuccess(dispatched?.payload));
      if (dispatched?.payload?.favoriteFlag) {
        yield put(helperActions.callFlurryEvent(
          valueToObject('article_favorite_complete', startTime, dispatched?.payload),
        ));
      } else {
        yield put(helperActions.callFlurryEvent(
          valueToObject('remove_favorite_succeeded', startTime, dispatched?.payload),
        ));
      }

      yield put(helperActions.setNotification({
        message: response?.message,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: true,
      }));
    } else {
      yield put(helperActions.setNotification({
        message: response?.message,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: false,
      }));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getFavoriteArticles(dispatched: PayloadAction<FavoriteArticlesRequest>) {
  const startTime = getCurrentTime();
  try {
    const response: FavouriteArticlesResponseExtended = yield call(
      axios.post,
      API.GET_FAVORITE_ARTICLE,
      dispatched?.payload,
    );

    if (response?.status === true) {
      const { response: { articles } } = response;
      if (articles.length > 0) {
        yield put(FavouriteArticleActions.setFavoriteArticles(articles));
      } else {
        yield put(FavouriteArticleActions.setFavoriteArticlesEnded());
      }
      yield put(helperActions.callFlurryEvent(
        valueToObject('favourite_articles_page_load', startTime, dispatched?.payload),
      ));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* FavouriteArticleSaga() {
  yield takeEvery(FavouriteArticleActions.addToFavorite.type, addToFavorite);
  yield takeLatest(FavouriteArticleActions.getFavoriteArticles.type, getFavoriteArticles);
}
