import React from 'react';

export const SearchArrowUp = function SearchArrowUp() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path d="M11.4774 11.4772C11.8337 11.1207 11.8337 10.5426 11.4774 10.1863L3.37175 2.08087L8.9837 2.08087C9.48771 2.08087 9.89659 1.67221 9.89659 1.16798C9.89659 0.663745 9.48771 0.255084 8.9837 0.255084L1.1679 0.255084C1.04927 0.255084 0.931303 0.279033 0.820213 0.32471C0.709344 0.370388 0.607345 0.437131 0.522198 0.5225C0.437051 0.607869 0.370086 0.709646 0.32463 0.820293C0.278952 0.931605 0.255004 1.04979 0.255004 1.16842L0.255005 8.98378C0.255005 9.48801 0.663667 9.89667 1.1679 9.89667C1.67191 9.89667 2.08079 9.48801 2.08079 8.98378L2.08057 8.98378L2.08057 3.37183L10.186 11.4772C10.5427 11.8338 11.1206 11.8338 11.4774 11.4772Z" fill="#A0A0A0" />
    </svg>
  );
};

export default SearchArrowUp;
