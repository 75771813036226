/* eslint-disable react/require-default-props */
import { Article } from 'Types/ArticleTypes';
import { IsMobile, Retina125, splitArrayChunks } from 'Utils/UtilityFunctions';
import styled from 'styled-components';
import classNames from 'classnames';
import ArticleGridGlobal from './ArticleGridGlobal';
import ArticleTileGlobal from './ArticleTileGlobal';

const AritclesGridLayout = styled.div``;

type Props = {
  articlesArray: Article[] | null | undefined,
  listview?: boolean
  isLoading?: boolean
};

const ArticleGridLayout = function (props: Props) {
  const {
    articlesArray,
    listview = false,
    isLoading = false,
  } = props;

  const isMobile = IsMobile();
  const isRes = Retina125();
  return (
    <AritclesGridLayout>
      {articlesArray && articlesArray.length > 0
        && splitArrayChunks(articlesArray, 5, isMobile, listview).map(
          (chunk, index) => {
            const isReversed = (!isMobile && !(index % 2 === 0));
            const isChunkLengthLow = chunk.length <= 4;
            return (
              <ArticleGridGlobal
                isReversed={isReversed}
                listView={listview}
              >
                <div className={classNames('articleMainTileWrapper', { heightUnset: isLoading })}>
                  {!(isReversed && isChunkLengthLow) && (
                    <ArticleTileGlobal
                      key={isReversed
                        ? chunk[chunk.length - 1]?.articleId
                        : chunk[0]?.articleId}
                      article={isReversed ? chunk[chunk.length - 1] : chunk[0]}
                      gridTile
                      heroArticle
                      activityTile
                      tileListView
                      listView={listview}
                      articleWidth="530"
                      articleHeight="530"
                    />
                  )}
                </div>
                <div className="articleGridTilesWrapper">
                  {chunk.length > 0
                    // eslint-disable-next-line no-nested-ternary
                    && (isReversed
                      ? isChunkLengthLow
                        ? chunk
                        : chunk.slice(0, -1)
                      : chunk.slice(1)
                    ).map(
                      (item) => (
                        <ArticleTileGlobal
                          key={item?.articleId}
                          article={item}
                          gridTile
                          heroArticle
                          activityTile
                          tileListView
                          listView={listview}
                          articleWidth={isRes ? '314' : '249'}
                          articleHeight={isRes ? '314' : '249'}
                        />
                      ),
                    )}
                </div>

              </ArticleGridGlobal>
            );
          },
        )}
    </AritclesGridLayout>
  );
};

export default ArticleGridLayout;
