import classNames from 'classnames';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ANDROID_STORE_URL, IOS_STORE_URL } from 'Utils/Constants';
import { getMobileOS } from 'Utils/UtilityFunctions';
import {
  primaryColor, primaryFont, secondaryFont, smMobile, wMBold, wMedium,
} from 'Utils/utilsStyle';
import Cross from '../assets/svgIcons/Cross';
import Logo from '../assets/svgIcons/Logo';

const Wrapper = styled.div`
  &.displayNone {
    display: none;
  }
  background-color: ${primaryColor};
  padding: 16px 30px 15px 24px;
  width: 100%;
  height: 92px;
  display: flex;
  align-items: center;
  position: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  right: 0;
  bottom: 0;
  z-index: 9999;
  .cross-icon {
    position: absolute;
    right: 3px;
    top: 7px;
    width: 23px;
    height: 20px;
    cursor: pointer;
    svg {    
      height: 12px;
      width: 12px;
      polygon {
        fill: #fff;
      }
    }
  }
  .banner-img {
    background-color: #fff;
    height: 61px;
    width: 61px;
    border-radius: 12px;
    padding: 14px 5px;
    svg {
      min-width: 50px;
    }
  }
  .banner-text {
    font-family: ${secondaryFont};
    font-weight: ${wMedium};
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    width: calc(100% - 120px);
    padding: 23px 23px 23px 16px;
    text-align: start;
    @media all and (max-width :${smMobile}) {
      font-size: 14px;
      line-height: 16px;
    }
  }
  .button-text {
    font-family: ${primaryFont};
    background-color: #fff;
    padding: 6px 12px 5px 15px;
    border-radius: 5px;
    font-weight: ${wMBold};
    font-size: 10px;
    line-height: 12px;
    color: ${primaryColor};
    width: 58px;
    cursor: pointer;
  }
`;

const MobileAppBanner = function MobileAppBanner() {
  const [hideBanner, setHideBanner] = useState(false);
  const bannerText = 'For optimal experience please download our mobile app.';
  const mobileOS = getMobileOS();

  const onClickInstall = () => {
    const androidUrl = ANDROID_STORE_URL;
    const IOSurl = IOS_STORE_URL;
    if (mobileOS === 'android') {
      window.open(androidUrl);
    } else if (mobileOS === 'ios') {
      window.open(IOSurl);
    }
  };

  const onCross = () => {
    setHideBanner(true);
  };

  return (
    mobileOS === 'unknown' ? null
      : (
        <Wrapper className={classNames({ displayNone: hideBanner })}>
          <span
            tabIndex={0}
            role="button"
            onClick={() => onCross()}
            onKeyPress={() => onCross()}
            className="cross-icon"
          >
            <Cross />
          </span>
          <div
            className="banner-img"
          >
            <Logo />
          </div>
          <span
            className="banner-text"
          >
            {bannerText}
          </span>
          <span
            tabIndex={0}
            role="button"
            onClick={() => onClickInstall()}
            onKeyPress={() => onClickInstall()}
            className="button-text"
          >
            Install
          </span>
        </Wrapper>
      )
  );
};
export default MobileAppBanner;
