import React from 'react';

type Props = {
  color?: string;
} & typeof defaultProps;

const defaultProps = {
  color: '#FFFFFF',
};

const MessageIcon = function MessageIcon({ color }: Props) {
  return (
    <svg width="13" height="13" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.79678 1.8159L1.79679 1.81588C2.40786 1.18547 3.23123 0.836198 4.08416 0.836198H15.9845C17.7538 0.836198 19.2325 2.3236 19.2325 4.21326L19.2326 13.1014C19.2326 14.9911 17.7539 16.4785 15.9845 16.4785H6.83247C5.67277 16.4785 4.5532 16.8986 3.6693 17.6602C3.66923 17.6602 3.66917 17.6603 3.66911 17.6603L0.836198 20.1001V4.21326C0.836198 3.30921 1.18447 2.44755 1.79678 1.8159Z" stroke={color} strokeWidth="1.6724" />
      <path d="M12.111 11.5014C12.2208 11.5014 12.339 11.598 12.339 11.7542C12.339 11.9109 12.2204 12.0075 12.111 12.0075H4.7641C4.65471 12.0075 4.5361 11.9109 4.5361 11.7542C4.5361 11.598 4.65427 11.5014 4.7641 11.5014H12.111ZM15.5595 8.2592C15.6692 8.2592 15.7875 8.35597 15.7875 8.51202C15.7875 8.66871 15.6689 8.76534 15.5595 8.76534H4.76431C4.65491 8.76534 4.5363 8.66871 4.5363 8.51202C4.5363 8.35588 4.65455 8.2592 4.76431 8.2592H15.5595ZM15.5595 5.01718C15.6693 5.01718 15.7875 5.11377 15.7875 5.27001C15.7875 5.42661 15.6688 5.52333 15.5595 5.52333H4.76431C4.65506 5.52333 4.5363 5.42651 4.5363 5.27001C4.5363 5.11377 4.65448 5.01718 4.76431 5.01718H15.5595Z" stroke={color} strokeWidth="1.11493" />
    </svg>
  );
};

MessageIcon.defaultProps = defaultProps;
export default MessageIcon;
