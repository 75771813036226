/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import {
  primaryFont, maxWidth, retina, wBold, maxTablet,
} from '../../../Utils/utilsStyle';
import GlobalStyle from '../../auth/components/GlobalStyle';

const Btn = styled.button < { color: string, backgroundColor: string, padding: string, paddingSm: string, border: boolean, fontSize: string, fontSizeSm: string, borderRadius: string, borderColor: string }>`
  border-radius: ${(props) => props.borderRadius};
  font-weight: ${wBold};
  padding: ${(props) => props.padding};
  line-height: 1.2;
  font-family: ${primaryFont};
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border: ${(props) => (props.border ? `solid 2px ${props.borderColor}` : 'none')};
  font-size: ${(props) => props.fontSize};
  transition: 0.3s;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    font-size: ${(props) => props.fontSizeSm};
  }
  @media all and (max-width: ${maxTablet}) {
    padding: ${(props) => props.paddingSm};
  }
  cursor: pointer;
  &.outline {
    background-color: #ffffff;
    color: ${(props) => props.color};
    &:active,
    &:focus,
    &:hover {
      background-color: ${(props) => props.color};
      color: #ffffff;
    }
  }
  &:disabled {
    cursor: default;
    opacity: 0.6;
  }
`;

type Props = {
  title?: string;
  buttonType?: any;
  disabled?: boolean;
  color?: string;
  variant?: string;
  borderRadius?: string;
  fontSize?: string;
  fontSizeSm?: string;
  padding?: string;
  paddingSm?: string;
  className?: string;
  onButtonClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
} & typeof defaultProps;

const defaultProps = {
  title: '',
  buttonType: 'button' || undefined,
  disabled: false,
  color: '#1ec252',
  variant: 'filled',
  fontSize: '16px',
  borderRadius: '27px',
  fontSizeSm: '12px',
  padding: '9px 22px',
  paddingSm: '7px 15px',
  className: '',
  loading: false,
  onButtonClick(e: React.MouseEvent<HTMLButtonElement>) { },
};

const SmallButton = function SmallButton(props: Props) {
  const {
    title,
    buttonType,
    color,
    variant,
    fontSize,
    fontSizeSm,
    padding,
    paddingSm,
    loading,
    disabled,
    borderRadius,
    className,
    onButtonClick,
  } = props;

  return (
    <>
      <GlobalStyle />
      <Btn
        type={buttonType}
        {...props}
        backgroundColor={variant === 'filled' ? color : 'transparent'}
        color={variant === 'filled' ? '#ffffff' : color}
        fontSize={fontSize}
        borderRadius={borderRadius}
        fontSizeSm={fontSizeSm}
        padding={padding}
        paddingSm={paddingSm}
        border={variant !== 'text'}
        borderColor={color}
        onClick={onButtonClick}
        disabled={loading || disabled}
        className={classNames('small-button-chinese', className)}
        aria-label={title}
      >
        {loading ? (
          <Spin indicator={
            (
              <LoadingOutlined
                style={{ fontSize: 18, color: variant === 'filled' ? '#ffffff' : color }}
                spin
              />
            )
          }
          />
        )
          : title}
      </Btn>
    </>
  );
};

SmallButton.defaultProps = defaultProps;

export default SmallButton;
