import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = function ScrollToTop() {
  const { pathname, search } = useLocation();
  const path = pathname.split('/');
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [path[1], search]);

  return null;
};
export default ScrollToTop;
