import { Col } from 'antd';
import React from 'react';
import ArticleTileSkeleton from './ArticleTileSkeleton';

const ArticleListLoadingSkeletonBundle = function ArticleListLoadingSkeletonBundle() {
  return (
    <>
      {[1, 2, 3, 4, 5, 6, 7].map(() => (
        <Col span={24} className="article-grid px-1">
          <ArticleTileSkeleton />
        </Col>
      ))}
    </>
  );
};

export default ArticleListLoadingSkeletonBundle;
