/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import { API, GUEST_USERNAME } from 'Utils/Constants';
import { PayloadAction } from '@reduxjs/toolkit';
import { GuestLoginResponse } from 'Types/GuestSearchResultTypes';
import { GuestloginRequest } from 'Types/RequestBodies';
import { actions as guestActions } from 'Slice/GuestSlice';
import { actions as helperActions } from 'Slice/HelperSlice';
import {
  getCurrentTime,
  saveUserDetails,
  valueToObject,
} from 'Utils/UtilityFunctions';
import axios from '../Utils/Axios';

export function* guestUserLogin(dispatched: PayloadAction<GuestloginRequest>) {
  const startTime = getCurrentTime();

  try {
    const payload = dispatched?.payload;
    const response: GuestLoginResponse = yield call(
      axios.post,
      API.GUEST_lOGIN,
      payload,
    );
    if (response.status === true) {
       const userInfo: any = response?.response?.result?.login_data?.login_data?.response;
      yield put(guestActions.setGuestUserData(response));
      const flurry = {
        username: GUEST_USERNAME,
      };
      saveUserDetails(userInfo, false, true);
      yield put(helperActions.callFlurryEvent(
        valueToObject(
          'search_started',
          startTime,
          flurry,
        ),
      ));
    }
  } catch (error) {
    console.log('error', error);
  }
}
export function* guestSaga() {
  yield takeLatest(guestActions.getGuestUserData.type, guestUserLogin);
}
