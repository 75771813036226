import { createGlobalStyle } from 'styled-components';
import {
  appBgColor,
  boxShadow, min, primaryColor, primaryFont,
  secondaryFont, wMBold, wLight,
  wMedium, primaryHeadingColor, maxWidth,
  retina, retinaOne,
  backDropPersonality, wbSmartLayer, wBold,
  joinSession, scrollbar, maxWidth1300, minWidthApp,
  textTruncateSnippet, maxTablet, maxMobile,
  scrollbarTransparent, smMobile, midMobile,
  primaryBgColor, backgroundGreenLayer,
  SecondaryColor, midTablet, white,
  minExtraLargeScreen, minStandardScreen,
  greyColor,
  wMLight,
  minTablet,
  midWidthApp,
} from '../Utils/utilsStyle';

const GeneralStyle = createGlobalStyle`
  body {
   overflow-x : hidden;
   &.onboarding {
    overflow-y: hidden;
   }
   .skiptranslate > {
    iframe.skiptranslate{
      display: none;
    }
   }
   .googleTranslateTile {
    display:none !important; 
   }
  }
  .languageTranslationGlobal {
    position: fixed;
    right: 120px;
    width: auto;
    top: 20px; 
    z-index: 4;
    @media all and (max-width: 1440px) and (min-width: 1414px) {
      right: 125px;
    }
    @media all and (max-width: 1414px) and (min-width: 1399px) {
      right: 115px;
    }
    @media all and (max-width: 1398px) and (min-width: 1388px) {
      right: 112px;
    }
    @media all and (max-width: 1387px) and (min-width: 1366px) {
      right: 96px;
    }
    @media all and (max-width: 1366px) and (min-width: 1360px) {
      right: 90px;
    }
    @media all and (max-width: 1360px) and (min-width: 1350px) {
      right: 92px;
    }
    @media all and (max-width: 1350px) and (min-width: 1336px) {
      right: 86px;
    }
     @media all and (max-width: 1336px) and (min-width: 1330px) {
      right: 75px;
    }
    @media all and (max-width: 1335px) and (min-width: 1300px) {
      right: 65px;
    }
    @media all and (max-width: 1300px) and (min-width: 1290px) {
      right: 67px;
    }
    @media all and (max-width: 1291px) and (min-width: 1280px) {
      right: 61px;
    }
    @media all and (max-width: 1279px) and (min-width: 1121px) {
      right: 70px;
    } 
    @media all and (max-width: 1121px) and (min-width: 1100px) {
      right: 65px;
    }
    @media all and (max-width: 1099px) and (min-width: 1024px) {
      right: 61px;
    } 
    @media all and (max-width: 1023px) {
      /* top: 20px;
      width: 24px;
      right: 60px; */
      display: none;
    }
    @media all and (max-width:767px) {
      right: 40px;
    }
    .placeholder-flag {
      height: 28px;
      width: 28px;
      object-fit: cover;
      border-radius: 50%;
      &.inner {
        margin-right: 8px;
      }
    }
    .language-heading {
      padding: 10px 18px;
      padding-bottom: 0px;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.792px;
      color: rgb(66, 75, 80);
      cursor: auto;
      text-align: left;
      font-family: ${secondaryFont};
    }
    .button-wrapper-t {
      height: 43px;
      width: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-dropdown {
      overflow: hidden !important;
      box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.102473) !important;
      border-radius: 10px !important;
      background-color: #ffffff !important;
      border: 1px solid #EcEcEc !important;
    }
    .ant-dropdown-menu-root {
      /* height: 100% !important; */
      margin: 5px 14px;
      padding: 0px !important;
      border: none !important;
      width: 225px !important;
      box-shadow: none !important;
      height: 370px !important;
      overflow-y: auto !important;
      overflow-x: hidden !important;
      &::-webkit-scrollbar {
      width: 4px;
      border-radius:8px;
      }

      &::-webkit-scrollbar-thumb {
        background: #001760;
        border-radius: 12px;
      }

      &::-webkit-scrollbar-track {
      background: #CFDAE8;
      }
      &{
      scrollbar-face-color: #001760;
      border-radius: 12px;
      }
    }
    
    .ant-dropdown-menu-root .ant-dropdown-menu-item {
      padding: 5px 18px !important;
      &:nth-child(2) {
        &:hover {
          background-color: transparent;
        }    
      }
      &:first-child {
        & input{
          &,
          &:focus,
          &:hover{
            border: none;
            box-shadow: none;
            outline: none;
          }
        }
      }
    }
    .ant-dropdown-menu-root .ant-dropdown-menu-item {
      .ant-dropdown-menu-title-content {
        .dropDown-flag {
          height: 28px;
          width: 28px;
          object-fit: cover;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
    
    .ant-dropdown-menu-root .ant-dropdown-menu-item.selectedValue {
      background-color:rgb(30, 194, 82) !important;
      color: #fff !important;
      pointer-events: none !important;
      &:hover {
        background-color:rgb(30, 194, 82) !important;
        color: #fff !important;
      }
    }

    .ant-dropdown-menu-root .ant-dropdown-menu-item.Select.Language {
      display: none;
    }

    .widget-search-bar {
      margin: 5px 14px;
      background-color: #fff;
      background-image: none;
      border: 1px solid rgb(218, 218, 218);
      border-radius: 25px;
      color: rgba(0, 0, 0, .85);
      display: inline-flex;
      line-height: 1.5715;
      min-width: 0;
      font-size: 14px;
      padding: 6.5px 11px;
      position: relative;
      transition: all .3s;
      width: 90%;
      height: 32px;
    }
    .ant-input-prefix {
      margin-right: 8px;
      padding-right: 8px;
      border-right: 2px solid rgb(218, 218, 218);
      cursor: auto;
    }
    .search-global-icon-svg {
      height: 16px;

    }
    .empty-message {
      margin: 0px auto;
      padding: 10px;
      height: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .flagPlaceholder {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      object-fit: cover;
    }
        
  }
  .allowCopy {
    user-select: text !important;
    -moz-user-select: text !important;
    .slick-slider,  .slick-slide  {
      user-select: text !important;
      -moz-user-select: text !important;
    }
    .slick-current {
      z-index: 999;
    }
  }
  .chatBubble {
    &.chatImage {
      width: 140px;
      border-radius: 50%;
      @media all and (max-width: ${maxWidth}), ${retina} {
        width: 140px;
      }
    } 
    width: 140px;
    @media all and (max-width: ${maxWidth}), ${retina} {
      width: 140px;
    }
  }
  .h-fit {
    height: fit-content;
  }
  main {
   background-color: ${appBgColor}
  }
  /* @media all and (max-width: ${maxTablet}){
    .fc-widget-small {
      display: none !important;
    }
    .freshDesk-Bot {
      display: none !important;
    }
  } */
  .sales-sigma {
    display: flex;
    align-items: center;
    strong {
     font-size: 12px;
     font-weight: 400;
    }
  }
  .container-company-card {
    .download-profile-blue, .compare-companies-blue, .buyer-group-blue,.share-blue {
      svg {
        min-width:18px;
      }
    }
  }
  #onesignal-bell-container {
    margin-right: 14px;
    bottom: 30px !important;
    right: auto !important;
    left: 160px !important;
    margin-right: 14px;
    display: none;
  }
  #root {
    + {
      /* div {
        display:none;
      } */
    }
  }
  .affinity-search-widget {
    &.icon-mode {
      .search-field-text {
        max-width: 200px;
        font-weight: ${wBold};
        font-size: 16px;
        @media all and (max-width: 576px) and (min-width: ${midMobile}) {
          margin-top: 0px;
          margin-left: -24px;
        }
        @media all and (max-width: ${midMobile}) {
          max-width: 149px;
        }
        @media all and (max-width: ${smMobile}) {
         font-size: 12px;
         margin-left: -12px;
         margin-top: 8px;
        }
      }
      .ant-input-prefix {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: #DADADA;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        svg {
          height: 14px !important;
        }
      }
      .input-dropdown-wrapper {
        @media all and (max-width: ${smMobile}) {
          display: none;
        }
      }
    }
  }
  @-webkit-keyframes shimmer {
    100% {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
  }
  @keyframes shimmer {
    100% {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
  }
  .img-fluid {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
  .img-ranking {
    height: auto;
  }
  .ant-badge-count {
    cursor: pointer;
  }
  .tabsAppend {
    .header-wrapper {
      .left-side-wrap {
        max-width: 20%;
        .heading {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 20px;
        }
      }
      .right-side-wrap {
        max-width: 80%;
      }
    }
  }
    .header-wrapper{
      height: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .bell-icon-primary {
        cursor: pointer;
      }
      .left-side-wrap {
        &.service-lines-left-wrap {
          max-width: 40%;
          @media all and (max-width: ${maxMobile}) {
            max-width: 60%;
          }
        }
        display: flex;
        align-items: center;
        @media all and (max-width: ${maxTablet}) {
        margin-left: 10px;
        }
        .back-icon-svg {
          margin-top: 2px;
          min-width: 20px;
        }
        .skeleton-box {
          @media all and (max-width: ${maxWidth1300}) {
            width: 150px;
            min-width: 150px;
          }
          @media all and (max-width: ${minWidthApp}) {
            margin-left: 10px;
          }
          @media all and (max-width: ${maxTablet}) {
            width: 140px;
            min-width: 140px;
          }
          @media all and (max-width: ${smMobile}) {
            width: 80px;
            min-width: 80px;
          }
        }
        svg {
          width: 23px;
          height: 25px;
          min-width: 16px;
          /* margin-top: 3px; */
          @media all and (max-width: ${maxWidth}) , ${retina} {
            width: 20px;
          }
        }
        .heading{
          &.guestUser{
            border:none;
          }
          font-size: 28px;
          line-height: 35px;
          font-family: ${secondaryFont};
          font-weight: ${wMBold};
          color: ${primaryHeadingColor};
          margin-bottom: 0px;
          border-left: 1px solid #DDDDDD;
          padding-left: 10px;
          margin-left: 10px;
          text-transform: initial;
          @media all and (max-width: ${maxWidth}) , ${retina} {
            font-size: 28px;
            padding-left: 7px;
            margin-left: 7px;
          }
          @media all and (max-width: ${minWidthApp})
          {
            line-height:36px;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            word-wrap: break-word;
          }
        }
      }
      .right-side-wrap{
        display: flex;
        align-items: flex-start;
        margin-top: 3px;
        padding-right: 80px;
        @media all and (max-width: ${maxTablet}) {
          padding-right: 33px;
        }
        @media all and (max-width: ${maxMobile}) {
         padding-right: 0px;
        } */
        span {
          &:last-child {
            margin-right:0px;
          }
        }
        .iconModeActive {
          .filter-icon-dropdown {
            @media all and (max-width: ${maxWidth}) , ${retina} {
              width: 17px;
            }
          }
        }
      }
      .placeholder-flag {
        height: 28px;
        width: 28px;
        object-fit: cover;
        border-radius: 50%;
        &.inner {
          margin-right: 8px;
        }
      }
      .languageTranslationGlobal {
        position: absolute;
        right: 37px;
        width: auto;
        top: 8px; 
        .ant-dropdown {
          overflow: hidden !important;
          box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.102473) !important;
          border-radius: 10px !important;
          background-color: #ffffff !important;
          border: 1px solid #EcEcEc !important;
        }
        .ant-dropdown-menu-root {
          /* height: 100% !important; */
          margin: 5px 14px;
          padding: 0px !important;
          border: none !important;
          width: 225px !important;
          box-shadow: none !important;
          height: 370px !important;
          overflow-y: auto !important;
          overflow-x: hidden !important;
          &::-webkit-scrollbar {
          width: 4px;
          border-radius:8px;
          }

          &::-webkit-scrollbar-thumb {
            background: #001760;
            border-radius: 12px;
          }

          &::-webkit-scrollbar-track {
          background: #CFDAE8;
          }
          &{
          scrollbar-face-color: #001760;
          border-radius: 12px;
          }
        }
        
        .ant-dropdown-menu-root .ant-dropdown-menu-item {
          padding: 5px 18px !important;
          &:nth-child(2) {
            &:hover {
              background-color: transparent;
            }    
          }
          &:first-child {
            & input{
              &,
              &:focus,
              &:hover{
                border: none;
                box-shadow: none;
                outline: none;
              }
            }
          }
        }
        .ant-dropdown-menu-root .ant-dropdown-menu-item {
          .ant-dropdown-menu-title-content {
            .dropDown-flag {
              height: 28px;
              width: 28px;
              object-fit: cover;
              border-radius: 50%;
              margin-right: 8px;
            }
          }
        }
        
        .ant-dropdown-menu-root .ant-dropdown-menu-item.selectedValue {
          background-color:rgb(30, 194, 82) !important;
          color: #fff !important;
          pointer-events: none !important;
          &:hover {
            background-color:rgb(30, 194, 82) !important;
            color: #fff !important;
          }
        }

        .ant-dropdown-menu-root .ant-dropdown-menu-item.Select.Language {
          display: none;
        }

        .widget-search-bar {
          margin: 5px 14px;
          background-color: #fff;
          background-image: none;
          border: 1px solid rgb(218, 218, 218);
          border-radius: 25px;
          color: rgba(0, 0, 0, .85);
          display: inline-flex;
          line-height: 1.5715;
          min-width: 0;
          font-size: 14px;
          padding: 6.5px 11px;
          position: relative;
          transition: all .3s;
          width: 90%;
          height: 32px;
        }
        .ant-input-prefix {
          margin-right: 8px;
          padding-right: 8px;
          border-right: 2px solid rgb(218, 218, 218);
          cursor: auto;
        }
        .search-global-icon-svg {
          height: 16px;

        }
        .language-heading {
          padding: 10px 18px;
          padding-bottom: 0px;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.792px;
          color: rgb(66, 75, 80);
          cursor: auto;
          text-align: left;
        }

        .empty-message {
          margin: 0px auto;
          padding: 10px;
          height: 70%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .flagPlaceholder {
          height: 30px;
          width: 30px;
          border-radius: 50%;
          object-fit: cover;
        }
        
      }
      .filterIconMode {
        .iconModeActive {
          svg {
            @media all and (max-width: ${maxWidth}) , ${retina} {
            width: 17px;
            }
          }
        }
      }
      .icon-mode {
        svg {
          @media all and (max-width: ${maxWidth}) , ${retina} {
          width: 16px !important;
          }
        }
      }
      .bell-icon-primary {
        @media all and (max-width: ${maxWidth}) , ${retina} {
          width: 18px;
        }
      }
    }
    .xiq-intelligence {
      /* margin-bottom: 13px; */
      text-align: center;
      svg {
        width:20px;
        height:20px;
        margin-left:10px;
        margin-top: 3px;
        cursor: pointer;
      }
      .text {
        display: inline-flex;
        background-color: #1EC252;
        color: white;
        padding: 1px 12px 2px 29px;
        font-weight:400;
        font-family: 'Roboto';
        font-size: 16px;
        letter-spacing: -0.4px;
      }
      .underline-text {
        margin-left: 3px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .container {
      margin-right: auto;
      margin-left: auto;
      width:100%;
      &.header-container {
        @media all and (max-width: ${maxTablet}) {
          padding: 0px;
        }
        @media all and (max-width: ${maxMobile}) {
          padding: 0px;
        }
      }
      @media all and (max-width: ${maxTablet}) {
        overflow-x: hidden;
        overflow-y: hidden;
      }
      &.o-revert {
        overflow-x: revert;
        overflow-y: revert;
      }
      @media all and (max-width: ${maxTablet}) {
        padding: 0px 30px;
      }
      @media all and (max-width: ${maxMobile}) {
        padding: 0px 15px;
      }
    }
    @media (min-width: 1023px) {
      .container {
       margin-right: auto;
       margin-left: auto;
       padding: 0px 40px;
       width:100%;
      }
    }
    @media all and (max-width: 1120px) and (min-width: 1023px) {
      .container {
        padding: 0px 20px;
      }
    }
    @media (min-width: 1280px) {
      .container {
       width: 1170px;
       padding: 0px 10px;
      }
    }

     @media (min-width: 1441px) {
      .container {
        width: 100%;
        padding: 0px 90px;
      }
    }

    .container-search {
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
      width:100%;
    }
    @media (min-width: 1024px) {
      .container-search {
      width: 950px;
      }
    }
    @media (min-width: 1320px) {
      .container-search {
      width: 1130px;
      }
    }
    .skeleton-box {
      display: inline-block;
      height: 1em;
      position: relative;
      overflow: hidden;
      background-color: #e9e9e9;
      &.redius-1 {
        border-radius: 10px;
      }
      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        background-image: -webkit-gradient(
          linear,
          left top,
          right top,
          color-stop(0, rgba(255, 255, 255, 0)),
          color-stop(20%, rgba(255, 255, 255, 0.2)),
          color-stop(60%, rgba(255, 255, 255, 0.5)),
          to(rgba(255, 255, 255, 0))
        );
        background-image: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0,
          rgba(255, 255, 255, 0.2) 20%,
          rgba(255, 255, 255, 0.5) 60%,
          rgba(255, 255, 255, 0)
        );
        -webkit-animation: shimmer 2s infinite;
        animation: shimmer 2s infinite;
        content: "";
      }
    }
    .skeleton-box-transparent {
      display: inline-block;
      height: 1em;
      position: relative;
      overflow: hidden;
       &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        background-image: -webkit-gradient(
          linear,
          left top,
          right top,
          color-stop(0, rgba(255, 255, 255, 0)),
          color-stop(20%, rgba(255, 255, 255, 0.2)),
          color-stop(60%, rgba(255, 255, 255, 0.5)),
          to(rgba(255, 255, 255, 0))
        );
        background-image: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0,
          rgba(255, 255, 255, 0.2) 20%,
          rgba(255, 255, 255, 0.5) 60%,
          rgba(255, 255, 255, 0)
        );
        -webkit-animation: shimmer 2s infinite;
        animation: shimmer 2s infinite;
        content: "";
      }
    }
    .date-picker-dropdown, .time-picker-dropdown {
      font-family: ${primaryFont};
    }
  .p-relative {
    position: relative;
  }
  .border-0 {
    border: none;
  }
  .weight-900 {
    font-weight: 900;
  }
  .weight-500{
    font-weight: 500;
  }
  .weight-700{
    font-weight: 700;
  }
  .index-one {
    z-index: 1;
  }
  .indexZero {
    z-index: 0 !important;
  }
  .indexOne {
    z-index: 1;
  }
  .d-flex {
    display:flex;
  }
  .align-items-center{
    align-items: center;
  }
  .d-none {
    display:none;
  }
  .vHidden {
    visibility: hidden;
  }
  .dNone {
    display:none !important;
  }
  .justify-content-center {
    justify-content: center;
  }
  .justify-content-between {
    justify-content: space-between;
  }
   .justify-content-around {
    justify-content: space-around;
  }

  .justify-content-end, .justifyContentFlexEnd{
    justify-content: flex-end;
  }
  .flex-column{
    flex-direction: column;
  }
  .flex-row{
    flex-direction: row;
  }
  .radius-50 {
    border-radius: 50px !important;
  }
  .radius-25 {
    border-radius: 25px !important;
  }
  .radius-20 {
    border-radius: 20px !important;
  }
  .radius-10 {
    border-radius: 10px !important;
  }
  .width-100 {
    width:100%;
  }
  .w-50 {
    width: 50%;
    min-width: 50%;
  }
  .w-49 {
    width: 49%;
    min-width: 49%;
  }
  .w-100{
    width:100%;
    min-width:100%;
  }
  .w-70{
    width: 70%;
    min-width: 70%;
  }
  .w-80{
    width:80%;
    min-width:80%;
  }
  .w-90{
    width:90%;
    min-width:90%;
  }
  .text-center{
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  .text-left {
    text-align: left;
  }
  .h-100 {
    height: 100%;
  }
  .pl-c-0 {
    padding-left: 0px;
  }
  .mt-c-0 {
    margin-top: 0px !important;
  }
  .mb-c-0 {
    margin-bottom: 0px !important;
  }
  .pb-4{
    padding-bottom: 1.5rem!important;
  }
  .pb-15{
    padding-bottom: 15px;
  }
  .px-3{
    padding-left: 1rem!important;
    padding-right: 1rem!important;
  }
  .align-self-center {
    align-self: center!important;
  }
  button{
    cursor: pointer;
  }
  .mb-0 {
    margin-bottom: 0!important;
  }
  .mx-c-negative {
    margin-right: -9px;
    margin-left: -9px;
  }
  .mr-0{
    margin-right: 0!important;
  }
  .pr-0 {
    padding-right: 0 !important;
  }
  .ml-0{
    margin-left: 0!important;
  }
  .ml-1 {
    margin-left: 10px;
  }
  .ml-2 {
    margin-left: 20px;
  }
  .ml-3 {
    margin-left: 30px;
  }
  .ml-4 {
    margin-left: 40px;
  }
  .ml-5 {
    margin-left: 50px;
  }
  .mr-1 {
    margin-right: 10px;
  }
  .mr-2 {
    margin-right: 20px;
  }
  .mr-3 {
    margin-right: 30px;
  }
  .mr-4 {
    margin-right: 40px;
  }
  .mr-5 {
    margin-right: 50px !important;
  }
  .mt-c-6 {
   margin-top: 6px;
  }
  .mt-c-10 {
   margin-top: 10px;
  }
  .mt-c-5 {
   margin-top: 5px;
  }
  .mt-c-15 {
   margin-top: 15px;
  }
  .mt-c-20 {
   margin-top: 20px;
  }
  .mtC20 {
    margin-top: 20px;
  }
  .mt-c-25 {
   margin-top: 25px;
  }
  .mt-c-30 {
   margin-top: 30px;
  }
  .mt-c-32 {
    margin-top: 32px;
  }
  .mt-c-40 {
   margin-top: 40px;
  }
  .mt-c-50 {
   margin-top: 50px;
  }
  .mb-c-5 {
    margin-bottom: 5px;
  }
  .mb-c-10 {
    margin-bottom: 10px;
  }
  .mbC10 {
    margin-bottom: 10px !important;
  }
  .mb-c-15 {
   margin-bottom: 15px;
  }
  .mb-c-20 {
   margin-bottom: 20px;
  }
  .mb-c-30 {
   margin-bottom: 30px;
  }
  .mb-c-40 {
   margin-bottom: 40px;
  }
  .mb-c-50 {
   margin-bottom: 50px;
  }
  .ml-c-5 {
    margin-left: 5px;
  }
  .ml-c-10 {
    margin-left: 10px;
  }
  .ml-c-15 {
   margin-left: 15px;
  }
  .ml-c-20 {
   margin-left: 20px;
  }
  .ml-c-30 {
   margin-left: 30px;
  }
  .ml-c-40 {
   margin-left: 40px;
  }
  .ml-c-50 {
   margin-left: 50px;
  }
  .m-x-y-c-0 {
    margin: 0 0 !important;
  }
  .px-0 {
    padding: 0px !important;
  }
  .px-0-res-1_5 {
    padding: 0px 90px !important;
    @media all and (max-width: ${maxWidth}) , ${retina} { 
      padding: 0px !important;
    }
  }
  .px-1 {
    padding: 0px 10px;
  }
  .px-2 {
    padding: 0px 20px;
  }
  .px-3 {
    padding: 0px 30px;
  }
  .px-4 {
    padding: 0px 40px;
  }
  .px-5 {
    padding: 0px 50px;
  }
  .py-1 {
    padding: 10px 0px;
  }
  .py-2 {
    padding: 20px 0px;
  }
  .py-3 {
    padding: 30px 0px;
  }
  .py-4 {
    padding: 40px 0px;
  }
  .py-5 {
    padding: 50px 0px;
  }
  .o-hidden, .oHidden {
    overflow: hidden;
  }
  .line-block {
    display: inline-block !important;
  }
  .h-auto {
    height: auto !important;
  }
  .headers-space {
    margin-bottom: 25px;
  }
  .box-shadow {
    box-shadow: ${boxShadow} !important;
  }
  .f-size-16 {
    font-size: 16px !important;
  }
  .account-activity-wrapper {
    padding-top: 24px;
  }
  .comment-wrapper-area {
    &.highlight {
      .text-area-notes {
        box-shadow: ${primaryColor} 0px 0px 0px 1px !important;
        border-color: ${primaryColor} !important;
      }
    }
  }
  .fStructure {
    display: flex;
    align-items: center;
    @media all and (max-width: ${maxTablet}) {
      padding: 0px 30px 0px 0px;
    }
     @media all and (max-width: ${maxMobile}) {
      padding: 0px 10px 0px 0px;
    }
  }
  .toaster-wrapper{
    min-width: 320px;
    position: fixed;
    left: 50%;
    top: 80px;
    z-index: 9999;
    transform: translateX(-50%);
    border-radius: 0px 0px 8px 8px;
    padding: 10px 40px 10px 20px;
    background-color: ${primaryColor};
    color: #fff;
    font-family: ${primaryFont};
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    @media all and (max-width: ${maxMobile}) { 
     top: 65px;
     line-height: 20px;
    }
    &.hideToaster {
      display: none !important;
    }
    .closeIcon-toaster {
      position: absolute;
      right: 7px;
      top: 4px;
      width: 20px;
      cursor: pointer;
    }
    .ant-spin {
      .ant-spin-dot {
        width: 17px;
        height: 17px;
        margin-right: 10px;
        .ant-spin-dot-item {
          background-color: #fff;
        }
      }
    }
  }
  .wrap-slider {
    width: 100%;
    flex-flow: row nowrap;
    @media all and (max-width: ${midTablet}) { 
      overflow-y: hidden;
      overflow-x: scroll;
    }
    &.forOverFlow {
      @media all and (max-width: ${maxWidth1300}) { 
        overflow-y: hidden;
        overflow-x: scroll;
      }
    }
    &.overFlowOnMaxWidth {
      overflow-y: hidden;
      overflow-x: scroll;
      ${scrollbarTransparent};
    }
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .hidden-scroll {
    @media all and (max-width: ${maxWidth1300}) {
      ${scrollbarTransparent}
    }
  }
  #fc_frame {
    bottom: 30px !important;
    right: 22px !important;
    z-index: 10 !important;
  }
  .feed-page-tabs {
    .heading-inner-page {
      .tips-placeholder  {
        margin-left: 275px;
        @media all and (max-width: ${maxMobile}) {
          margin-left: 0px;
        }
      }
    }
  }
  .heading-inner-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .heading {
      font-size: 28px;
      font-family: ${secondaryFont};
      font-weight: ${wMBold};
      color: ${primaryHeadingColor};
      margin-bottom: 0px;
      @media all and (max-width: ${maxWidth}) , ${retina} {
        font-size: 20px;
      }
      @media all and (max-width: ${maxTablet}) {
        font-size: 15px;
      }
    }
    .tips-placeholder  {
      margin-left: 70px;
      @media all and (max-width: ${maxMobile}) {
        margin-left: 0px;
      }
    }
    &.sri-page-nav {
      .tips-placeholder  {
        margin-left: 150px;
      }
    }
  }
  .ant-notification-notice{
    &.errorNotification {
      background-color: red;
      padding: 17px 11px;
      border-radius: 8px;
      max-width: 260px;
    }
    &.successNotification {
      background-color: ${primaryColor};
      padding: 17px 11px;
      border-radius: 8px;
      max-width: 260px;
      &.warningNotification {
        background-color: #F2A43E;
        border: 1px solid #F2A43E;
        .ant-notification-notice-message { 
          color: #fff;
        }
      }
    }
    &.fav-prompt {
      &.undo-prompt {
        @media all and (max-width: ${smMobile}) {
          .ant-notification-notice-message{ 
            line-height: 25px !important;
          }
        }
      }
      max-width: 439px;
      width: 439px;
      @media all and (max-width: ${smMobile}) {
        width: 300px;
        max-width: 300px;
      }
    }
    &.socketNotification {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    &.pointer {
      .ant-notification-notice-message {
        cursor: pointer;
      }
    }
    .ant-notification-notice-message {
      font-family: ${secondaryFont};
      color: #fff;
      font-size: 12px;
      line-height: 14px;
      font-weight: ${wMedium};
      margin-bottom: 0px;
      .socket-toaster {
        display: flex;
        .ant-spin {
          .ant-spin-dot {
            width: 17px;
            height: 17px;
            margin-right: 10px;
            .ant-spin-dot-item {
              background-color: #fff;
            }
          }
        }
        .skt-heading {
          font-family: ${secondaryFont};
          font-weight: ${wMedium};
          font-size: 16px;
        }
      }
     }
    .ant-notification-notice-description {
      font-family: ${secondaryFont};
      color: #fff;
      font-size: 12px;
      line-height: 20px;
      font-weight: ${wLight};
      margin-bottom: 0px;
      margin-top: 10px;
      @media all and (max-width: ${maxMobile}) {
        font-size: 10px;
        line-height: 12px;
      }
    }
    .ant-notification-notice-close {
      right: 10px;
      color: #fff;
    }
    &.centerMessage{
     min-width: 300px;
     text-align: center;
     padding: 15px 30px 15px 10px;
     box-sizing:border-box;
     left: 50%;
     position: fixed;
     transform: translateX(-50%);
     opacity:1;
     transition: 0.3s;
     &.centerMessageCompany {
      min-width: 695px;
      @media all and (max-width: ${maxMobile}) {
        min-width: 350px;
      }
      @media all and (max-width: ${smMobile}) {
        min-width: 300px;
      }
      .ant-notification-notice-message {
        font-weight: ${wLight};
        line-height: 20px;
        @media all and (max-width: ${maxMobile}) {
         font-size: 12px;
         line-height: 15px;
        }
      }
      .toaster-content {
        display: flex;
        flex-direction: row;
        .weight-900 {
          margin: 0px 3px 0px 0px;
        }
        .info {
          svg {
              width: 11px;
              margin: -5px 4px;
            g, path {
              fill:#fff;
            }
          }
        }
        .people {
          svg {
            width: 14px;
            margin: -8px 4px;
            g, path {
              fill:#fff;
            }
          }
        }
      }
     }
     &.ant-notification-fade-appear{
       opacity:0 !important;
     }
      .ant-notification-notice-message{
       color:#fff;
       font-size: 14px;
       line-height: 18px;
       font-weight: ${wMBold};
       font-family: ${secondaryFont};
       display: inline-block;
       padding-right: 5px;
       @media all and (max-width: ${maxMobile}) {
        font-size: 12px;
        line-height: 14px;
       }
      }
     .ant-notification-notice-description{
       display: inline-block;
     }
     .ant-notification-notice-btn{
       margin-top:-5px;
       float:none;
       color:#fff;
       font-size:14px;
       font-weight:400;
       display: inline-block;
       text-decoration: underline;
       cursor: pointer;
       font-weight:${wLight};
       font-family:${secondaryFont};
     }
    }
    &.moreWidth{
      @media all and (min-width: ${maxTablet}){
        min-width: 450px;
      }
    }
  }


  .grecaptcha-badge{
    display:none;
  }
  .ant-drawer {
   z-index: 2;
   &.highIndex {
     z-index: 3;
   }
  }
  .certification-drawer {
    &.gilroyCertification {
      .ant-drawer-content-wrapper {
        @media all and (max-width: ${maxWidth}), ${retina} {
          width: 531px !important;
        }
      }
    }
    &.ant-drawer {
      z-index: 4;
    }
    .ant-drawer-content-wrapper {
      z-index: 2000;
    }
  }
  .ant-drawer-mask {
    box-shadow: none;
    animation: none;
    background-color: transparent;
  }
  .ant-drawer-content-wrapper {
    box-shadow: ${boxShadow} !important;
  }
  .ant-drawer-body{
    padding:0px;
  }
  .space-remove {
    .ant-tooltip-inner {
      white-space: nowrap;
    }
  }
  .text-truncate {
	 overflow: hidden;
	 text-overflow: ellipsis;
	 white-space: nowrap;
  }
  .textTruncateOneLine {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-wrap: break-word;
  }
  .text-truncate-one-line {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-wrap: break-word;
  }
  .text-truncate-two-line {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-wrap: break-word;
  }
  .text-truncate-three-line {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-wrap: break-word;
  }
  .textTruncateThreeLine {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-wrap: break-word;
  }
  .text-truncate-four-line {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-wrap: break-word;
  }
  .ant-checkbox-inner {
    border:2px solid #C3C3C3;
    width: 20px;
    height: 20px;
    border-radius:6px;
  }
  .ant-checkbox-checked {
    border-radius:0px;
    border:0px;
    &:after{
    border: 2px solid ${primaryColor};
    border-radius:6px;
    }
    .ant-checkbox-inner {
      background-color: #fff;
      border-color: #fff;
      border:2px solid #C3C3C3;
      &:after {
        border: 2px solid ${primaryColor};
        border-top: 0px;
        border-left: 0px;
        top: 45%;
        left: 25.5%;
        @media all and (max-width: ${maxWidth}), ${retina} {
          left: 24.5%;
        }
        @media all and (max-width: ${midWidthApp}) {
          top: 44%;
          left: 20.5%;
        }
      }
    } 
  }
  .company-states-row {
    .ant-col {
      .state { 
        @media all and (max-width: ${maxWidth1300}){
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inherit;
        }
      }
    }
  }
  .ant-checkbox-wrapper {
    &:hover {
      .ant-checkbox-inner {
        border-color: #C3C3C3;
      }
    }
    + {
      .ant-checkbox-wrapper {
        margin-left: 0px;
      }
    }
  }
  .ant-checkbox {
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        border: 2px solid ${primaryColor};
      }
    }
    &:hover {
      .ant-checkbox-inner {
        border-color: #C3C3C3;
      }
    }
    + {
      span {
        display: flex;
        align-items: center;
      }
    }
  }
  .ant-checkbox-input {
    &:focus {
      + {
        .ant-checkbox-inner {
          border-color: #C3C3C3;
        }
      }
    }
  }
  .ant-radio-wrapper {
    .ant-radio {
      height: 20px;
      width: 20px;
      top: 0px;
      &.ant-radio-checked {
        ::after {
          border: 2px solid ${primaryColor};
        }
        .ant-radio-input:focus+.ant-radio-inner {
          box-shadow: 0px 0px 2px ${primaryColor};
        }
        .ant-radio-inner {
          border: 2px solid ${primaryColor};
          ::after {
            height: 20px;
            width: 20px;
            margin-top: -10px;
            margin-left: -10px;
            background-color: ${primaryColor};
          }
        }
      }
      .ant-radio-input {
        height: 20px;
        width: 20px;
      }
      .ant-radio-inner {  
        height: 20px;
        width: 20px;
        border: 2px solid rgb(195, 195, 195);
      }
    }
  }
  .ant-layout{
    min-height:100vh;
    position: relative;
  }
  .ant-layout-sider{
    background-color: #fff;
    box-sizing: border-box;
    min-height: 100vh;
    position: relative;
    border-right: 1px solid #e7e7e7;
  }
  .logout-tip{
    .ant-popover-inner-content{
      padding: 5px 16px;
      margin-bottom: 10px;
      height: fit-content !important;
      width: fit-content !important;
    }
  }
  .ant-layout-header{
    /* &.tooltip {
      margin-bottom: 0px;
    } */
    padding: 0px;
    background: #ffffff;
    box-shadow: ${boxShadow};
    position: sticky;
    top: 0px;
    z-index:2;
    &.header-main-wrapper {
     height: 80px;
     box-shadow: none;
     &.certificationIndex {
        z-index: 5;
      &::before {
        content: '';
        left: 0px;
        top: 0px;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
      }
      .profileIndex {
        z-index: -1;
      }
     }
     @media all and (max-width: ${maxTablet}){
      height: 65px;
      width: 100%;
     }
      &.no-overflow-on-header {
        .container {
          @media all and (max-width: ${maxTablet}) {
           overflow-x: revert !important;
           overflow-y: revert !important;
          }
        }
      }
    }
    &.sub-header-main-wrapper {
     position: sticky;
     top: 80px;
     background-color: ${appBgColor};
     box-shadow: none;
     z-index: 1;
     .head-wrap {
      margin-right: auto;
     }
     @media all and (max-width: ${maxTablet}){
      width: 100%;
      height: auto;
      top: 65px;
     }
    .sub-header-wrapper {
      padding: 14px 0px;
      @media all and (max-width: ${maxTablet}){
       padding: 10px 0px;
      }
      .heading-inner-page {
        .tips-placeholder {
          margin-right: auto;
        }
        .right-side-sub-header {
          display: flex;
          align-items: center;
          .view-tabs {
            pointer-events: none;
            &.notdisabledTabs{
              pointer-events: auto;
            }
          }
          .filter-placeholder {
            min-width: 52px;
            margin-left: 16px;
            @media all and (max-width: ${maxTablet}){
              min-width: 20px;
            }
          }
        }
      }
    } 
     &.isSticky {
      box-shadow: 0px 15px 24px rgb(0 0 0 / 10%);
     }
     .filter-placeholder  {
      .tabs-wrapper {
       pointer-events: none;
       cursor: no-drop;
       white-space: nowrap;
       @media all and (max-width: ${maxTablet}){
        margin-left: 0px;
       }
        .tabs-item {
          @media all and (max-width: ${maxTablet}){
            border: 1px solid #DADADA;
            margin-left: 0px;
            width: 30px;
            height: 24px;
            padding-left: 3px;
            padding-top: 2px;
          }
        }
       &.loading-tab {
        .tabs-item {
          padding: 3px 15px;
          height: 30px;
          margin-top: 4px;
          @media all and (max-width: ${maxTablet}) {
            height: auto;
            margin-left: 0px;
            padding: 5px 0px 0px 5px;
          }  
          .ant-spin-dot {
            font-size: 17px;
          }
          .ant-spin-spinning {
            @media all and (max-width: 1240px){
              margin-top: -1px;
            }
          }
        }
       }
      }
      .global-tabs-item {
        background-color: ${appBgColor};
        opacity: 0.7;
      }
      &.notDisabledFilter {
        .global-tabs-item {
          background: #fff;
          opacity: 1;
        }
        .tabs-wrapper {
          pointer-events: auto;
          cursor: pointer;
        }
      }
     }
    }
  }
  .aside-wrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 87px;
    z-index:1;
    &.restrict-asider {
      z-index: 9999;
    }
  }
  .ant-input-affix-wrapper {
	  &:focus, &:hover {
	   border-color: ${primaryColor} !important;
     box-shadow: none !important;
		 outline: 0;
	  }
    input {
      &::placeholder{
        font-style: italic;
      }
    }
  }
  .ant-input-affix-wrapper-focused {
    border-color: ${primaryColor} !important;
	  box-shadow: none !important;
	  outline: 0;
  }
  .dropdown-user-profile {
    padding: 0px;
    border: none;
    height: auto;
    cursor: pointer;
    .persona-place {
      width: 34px;
      height: 34px;
      @media all and (max-width: ${maxWidth}) , ${retina} {
        width: 30px;
        height: 30px;
      }
      @media all and (max-width: ${maxTablet}) {
        width: 25px;
        height: 25px;
        margin-top: 3px;
      }
      .User_Image {
        width: 30px;
        height: 30px;
        @media all and (max-width: ${maxWidth}) , ${retina} {
          width: 26px;
          height: 26px;
        }
        @media all and (max-width: ${maxTablet}) {
          width: 22px;
          height: 22px;
        }
      }
    }
    .ant-popover-inner-content {
      z-index: 2;
      .logout-name-here {
        margin-bottom: 0px;
        word-break: break-all;
      }
    }
  }
  .ant-icon-button {
     &:hover {
       color: ${primaryColor};
     }
  }
  .horizontallyAligned {
    button {
        background: transparent;
        border: none;
        box-shadow: none;
        color: ${primaryColor};
        font-family: ${primaryFont};
        font-size: 14px;
        font-weight: ${wMLight};
        padding: 0px;
        margin-left:0px;
        margin-right:10px;
        @media all and (min-width: ${minExtraLargeScreen}) {
          font-size: 20px;
        }
        @media all and (max-width: ${maxWidth}), ${retina} {
          font-size: 16px;
        }
        @media all and (max-width: ${maxTablet}) {
          font-size: 12px;
        }
        @media all and (max-width: ${maxMobile}) {
          font-size: 12px;
        }
        &:hover {
         background: transparent;
        }
      &.rightIconAlign{
        display: flex;
        flex-direction: row-reverse;
        svg {
          margin-left: 10px;
          margin-right: 0px;
          display: none;
        }
      }
    }
  }
  .search-distance-row {
    margin: 0px auto 0px auto !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      &.py-2 {
        padding: 0px 0px;
      }
    }
    &.after-none {
      &:after {
        content: "";
        @media all and (max-width: ${maxWidth}) , ${retina} {
          display: none;
        }
      }
    }
    &:after {
     content: "";
     flex: auto;
    }
    &.withoutBox {
      .not-found{
        @media all and (max-width: ${maxWidth}) , ${retina} {
          font-size: 14px;
        }
      }
      .company-item {
      margin:0px 20px 30px 20px;
        @media all and (max-width: ${maxWidth}) , ${retina} {
          margin:0px 10px 20px 10px;
        }
      }
    }
    &.withoutBox {
      .person-item {
        margin:0px 8px 20px 8px;
        @media all and (max-width: ${maxWidth}) , ${retina}{
          margin:0px 10px 20px 10px;
        }
      }
    }
    .person-item {
      margin:0px 3px 20px 3px;
      @media all and (max-width:${min}) {
       margin:0px 0px 20px 0px;
       width: 172px;
       max-width: 172px;
      } 
    }
    .company-item {
      margin:0px 10px 50px 10px;
      @media all and (max-width:${min}) {
        margin:0px 0px 50px 0px;
      }
    }
    &.onboarding{
      &:after {
        content: unset;
        flex: unset;
      }
    }
  }
  .ant-menu-item-selected {
    a {
      color: ${primaryColor};
    }
  }
  .global-search-dropdown {
    .ant-menu-item-selected a { 
      color: #5e7079 !important; 
    }
  }

  .disabled {
    opacity:0.5;
    pointer-events:none;
  }

  .disableButton{
     pointer-events:none;
  }

  .c-auto {
    cursor: auto;
  }

  .c-pointer{
    cursor: pointer;
  }

  .o-x-hidden{
    overflow-x: hidden !important;
  }
  .o-y-hidden {
    overflow-y: hidden !important;
  }
  .tabs-wrapper, .c-tabs {
    display:flex;
    &.alerts-tab-padding {
      @media all and (max-width: ${maxWidth}) , ${retina} {
        padding:0px 38px;  
      }
      @media all and (max-width: ${maxTablet}) {
        padding:0px 10px;  
      }
      padding:0px 60px;
    }
    button {
      margin-right: 0px !important;
        &.tabs-item{
          &.select-btn {
            @media all and (max-width: ${maxWidth}), ${retina} {
              min-height: 33px;
            }
            @media all and (max-width: ${maxTablet}) {
              min-height: 32px;
            }
          }
        min-height:38px;
        @media all and (max-width: ${maxWidth}) , ${retina} {
          min-height:28px;
        }
        > svg{
          @media all and (max-width: ${maxWidth}) , ${retina} {
            height:12px;
            width:auto;
          }
        }
        span{
          @media all and (max-width: ${maxWidth}) , ${retina} {
            font-size:12px;
          }
        }
      }
    }
    &.iconModeActive {
      .tabs-item {
        padding: 0px;
        width: auto;
        background: transparent;
        display: inline-flex;
        border: none;
        margin-left: 30px; 
        @media all and (max-width: 1245px){
          margin-left: 10px;  
        }
        svg {
         height: 27px;
         width: 21px;
         @media all and (max-width: ${maxTablet}){
          height: 20px;
          width: 14px;
         }
          path {
            fill: #7E7E7E;
          }
        }
        > span {
          display: none;
        }
      }
    }
  }
  .c-tabs {
    margin-top: 44px;
  }
  .ant-tabs-nav {
    &:before {
      display:none;
    }
  }
  .ant-tabs-tab {
    padding: 0px !important;
    border: none !important;
    margin: 0px !important;
    background-color: transparent !important;
    &:first-child {
      .ant-tabs-tab-btn {
        border-radius: 8px 0px 0px 8px;
      }
    }
    &:nth-child(3) {
      .ant-tabs-tab-btn {
        border-radius: 0px 8px 8px 0px;
      }
    }
    .ant-tabs-tab-btn {
      border-radius: 0px;
    }
    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
      background:#DEF6E6 !important;
      color:#09A43A !important;
      }
    }
  }
  .tabs-item, .ant-tabs-tab-btn {
     &.alerts-tabs {
    width: 240px;
    height: 35px;
    text-align: center;
    line-height: 20px;
    font-size: 16px;
    margin: 0px !important;
    &.left-tab {
      border-bottom-right-radius: 0px !important;
      border-top-right-radius: 0px !important;
    }
    &.right-tab {
      border-bottom-left-radius: 0px !important;
      border-top-left-radius: 0px !important;
    }
      }
      &.unFollowBtn {
        padding: 7px 12px 7px 17px;
        .bin-icon {
          width: 20px;
        }
      }
      &.select-btn {
        padding: 7px 11px 7px 15px;
        border: 1.6px solid #DADADA; 
      }
      &.cancel-btn {
        border: 0px;
        background: unset;
        box-shadow: unset;
        &:hover, &:active {
          border: 0px;
          color: #7E7E7E;
        }
      }
    background:#fff;
    padding:7px 15px;
    color: #7E7E7E;
    font-size:16px;
    line-height:20px;
    font-family: ${primaryFont};
    font-weight:${wMedium};
    border-radius:8px !important;
    border:1px solid #DADADA;
    margin-right:13px;
    transition: 0.5s ease all;
    white-space: pre;
    cursor: pointer;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      font-size: 12px;
      line-height:14px;
      border-radius:6px;
    }
    @media all and (max-width: ${maxTablet}) {
      padding: 5px 15px;
    }
    &.active {
      background:#DEF6E6;
      color:#09A43A;
    }
    &:last-child {
      margin-right:0px !important;
    }
    &:hover, &:active {
      border:1px solid #DADADA;
      color: #7E7E7E;
    }
    &.disable {
      pointer-events: none;
    }
  }
  .ant-btn[disabled] {
    color: #7E7E7E;
    pointer-events: none;
    opacity: 0.5;
    background:#fff;
  }
  .hideTabs {
    display: none;
  }
  .ant-modal-root{
    /* .ant-modal-mask{
      display:none;
    } */
    .ant-modal-content{
      border-radius:22px;
      box-shadow:${boxShadow};
      overflow: hidden;
      .ant-modal-close{
        svg{
          margin:24px 22px 0 0;
          @media all and (max-width: ${midMobile}) {
            margin: 8px -5px 0 0;
          }
        }
      }
      .ant-modal-body{
          .main-message{
            color: ${primaryHeadingColor};
            font-weight:${wMBold};
            font-size:28px;
            font-family:${secondaryFont};
            margin:0;
            text-align:center;
            @media all and (max-width: ${maxMobile}) {
              font-size: 18px;
              line-height: 20px;
            }
          }
      }
      .ant-modal-footer{
        border:none;
        text-align: center;
        padding: 30px 10px 48px 10px;
        button{
          border-radius:8px;
          font-size:16px;
          font-weight:${wMBold};
          font-family:${secondaryFont};
          min-width:115px;
          text-align:center;
          height:auto;
          margin:0 8px;
          &.ant-btn-default{
            color:${primaryColor};
            border-color:${primaryColor};
            &:hover, &:focus{
              color:${primaryColor};
              border-color:${primaryColor};
              outline:1px solid ${primaryColor};
            }
          }
          &.ant-btn-primary{
            background-color:${primaryColor};
            border-color:${primaryColor};
            color:#fff;
            &:hover, &:focus{
              background-color:${primaryColor};
              border-color:${primaryColor};
              outline:1px solid ${primaryColor};
              color:#fff;
            }
          }
        }
      }
    }
  }
  .f-right {
    float: right;
  }
  .ant-notification-topLeft{
    left: 50% !important;
    right:auto !important;
    top:100px !important;
    bottom:auto !important;
    transform: translate(-50%, 0%);
  }
  .popover-sharing {
    .ant-popover-placement-left {
        left: -243px !important;
        top: -40px !important;
       @media all and (max-width: ${maxWidth}) , ${retina} {
        top: -45px !important;
        left: -215px !important;
       }
    }
   svg {
    width: 14px;
    height: 14px;
   }
  }
  .grid-checkbox-wrapper {
    span[data-preview="true"] {
      display: flex;
      flex-direction: row-reverse;
      img[alt="Share to Microsoft Teams"] {
        margin-right: 5px;
        margin-left: -3px;
      }
    }
  }
  .modal-c-warning {
    &.ant-modal-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
      width: 100vw;
      background-color:#000;;
      svg {
        align-self:center;
      }
    .ant-modal {
      @media all and (max-width: 768px) {
        width: 350px !important;
        max-width: 350px !important;
      }
    }
    .ant-modal-close-x {
      cursor: default;
    }
    .ant-modal-body {
      display:flex;
      flex-direction: column;
      text-align: center;
      padding: 0px;
      .body-part {
       background: #F7F7F7;
       padding: 40px 0px;
        svg {
          width: 150px;
          height: 100px;
        } 
      }
    }
    .title {
      font-family:${secondaryFont};
      font-size: 42px;
      line-height: 32px;
      letter-spacing: -1.17px;
      font-weight:${wBold};
      margin-bottom: 12px;
      margin-top: 32px;
      color:#424B50;
      @media all and (max-width: 768px) {
        font-size: 32px;
      }
    }
    .paragraph {
      font-family: ${primaryFont};
      font-weight: ${wLight};
      font-style: normal;
      font-size: 22px;
      line-height: 32px;
      text-align: center;
      letter-spacing: -0.555556px;
      color: #5E7079;
      margin-bottom: 24px;
       @media all and (max-width: 768px) {
        font-size: 18px;
        line-height: 24px;
        padding: 0px 24px;
       }
      .bold {
        font-weight: bold;
        padding: 0px 10px;
         @media all and (max-width: 768px) {
          padding: 0px 5px;
         }
      }
      .break {
        display: block;
        @media all and (max-width: 768px) {
         display: inline-block;
         padding: 0px 2px;
        } 
      }
    }
    .footer {
      display:flex ;
      justify-content: center;
      margin-bottom: 32px;
    }
    .img {
      width:126px;
      height:37px;
      margin-right: 10px;
    }
    .ant-modal-footer {
      display: none;
    }

    }
  }
  .modal-copy-to-clipboard {
    .cross-icon-x {
      svg {
        circle {
          fill-opacity: 1;
          fill: #c9c9c9 !important;
        }
      }
    }
    .ant-modal-content {
      .ant-modal-body {
        background-image: url(${backgroundGreenLayer});
        background-position: center;
        background-size: 106% 106%;
        padding: 27px 32px 43px;
        .heading-copy-popup {
          font-family: ${secondaryFont};
          font-weight: ${wMBold};
          font-size: 28px;
          color: ${primaryHeadingColor};
        }
        .input-button-div {
          display: flex;
          align-items: center;
          margin-top: 28px;
          .clipboard-icon {
            width: 52px;
            min-width: 44px;
            height: 43px;
            border-radius: 5px;
            border: 1px solid rgba(228,228,228,1);
            margin-right: 4px;
            padding: 8px 5px 5px 7px;
          }
          .clipboard-input {
            height: 43px;
            border: 1px solid rgba(228,228,228,1);
            font-family: ${primaryFont};
            font-weight: ${wLight};
            font-style: italic;
            font-size: 17px;
            color: rgba(151, 151, 151, 1);
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            padding-left: 20px;
            ${textTruncateSnippet};
            &:focus {
              box-shadow: none;
              outline: none;
            }
          }
          .copy-link-button {
            background-color: ${primaryColor};
            color: #fff;
            font-family: ${primaryFont};
            font-weight: ${wMedium};
            font-size: 17px;
            padding: 8px 19px;
            white-space: pre;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            cursor: pointer;
          }
        }
      }
    }
    .cross-icon-x {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      background-color: rgba(96, 96, 96, 0.62);
      margin: 14px 20px 0px 0px;
      position: relative;
      svg {
        margin: 0px !important;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        circle {
          fill: transparent;
        }
      }
    }
    .ant-modal-footer {
     display: none;
    } 
  }
  
  .modal-new-sales {
    .ant-modal-body {
      padding-top: 0px !important;
    }
    .cross-icon-x {
      height: 35px;
      width: 35px;
      border-radius: 50%;
      background-color: #838282;
      margin: 20px 20px 0px 0px;
      position: relative;
      svg {
        margin: 0px !important;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        circle {
          fill: transparent;
        }
      }
    }
    .ant-modal-footer {
     display: none;
    } 
    .ant-modal-content {
      height: 477px;
      background-image: url(${joinSession});
      background-position: center;
      background-size: cover;
      .whiteTextLogo {
        margin-top: 60px;
      }
      .primary-head-welcome {
        font-size: 42px;
        font-weight: ${wBold};
        font-family: ${secondaryFont};
        letter-spacing: -0.77px;
        color: #fff;
        margin-top: 40px;
      }
      .date-area {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .calender-book-icon {
       height: 36px;
      }
      .calender-text {
        color: #fff;
        font-size: 20px;
        font-weight: ${wMedium};
        font-family: ${primaryFont};
        margin-left: 20px;
      }
      .is-coming {
        font-size: 36px;
        font-weight: ${wBold};
        font-family: ${secondaryFont};
        letter-spacing: -0.64px;
        color: #fff;
        margin-top: 40px;
        margin-bottom: 15px;
      }
      .action-to-get {
        font-family: ${primaryFont};
        color: #fff;
        font-size: 18px;
        font-weight: ${wMedium};
        background-color: #001760;
        padding: 7px 20px;
        border-radius: 10px;
        cursor: pointer;
        margin-top: 390px;
        margin-left: 10px;
        display: inline-block;
      }
    }
  }
  .modal-c-widget {
    &.gilroyV3Popup {
      .ant-modal {
        width: 437px !important;
        .ant-modal-content {
          .ant-modal-body {
            padding-top: 34px;
            .title {
              font-size: 34px;
              line-height: 38px;
              margin-bottom: 10px;
            }
            .description {
              font-size: 16px;
              line-height: 18px;
              margin-top: 16px;
            }
            a {
              font-size: 16px;
              line-height: 18px;
            }
          }
          .ant-modal-footer {
            .ant-btn {
              min-width: 100px;
              font-size: 14px;
            }
          }
        }
      }
    }
    &.ant-modal-centered {
      &.company-wrapper {
        .ant-modal {
          @media all and (max-width: ${maxMobile}) {
            /* transform: translateX(-5%); */ //need in future thats why comment.
          }
        }
      }
    }
    &.affinityPopup{
      .ant-modal-close-x {
        cursor: default;
      }
      .the-title {
        font-family:${primaryFont};
        font-size:32px;
        color: #424B50;
        display:block;
        font-weight: ${wBold};
       }
       .button {
          font-family: ${primaryFont};
          font-weight: ${wMBold};
          padding: 4px 43px;
          display: inline-block;
          border-radius: 17.55px;
          border:2px solid ${primaryColor};
          font-size:16px;
          cursor:pointer;
          margin-right: 10px;
        &.btn-yes {
          background-color: ${primaryColor};
          color:#fff;
        }
        &.btn-no {
          background-color: #fff;
          color : ${primaryColor};
        }
       }
       .name {
        font-family: ${primaryFont};
        font-weight: ${wMBold};
        font-size: 18px;
        line-height: 32px;
        color: #000;
       }
       .designation{
         font-family: ${primaryFont};
        font-weight: ${wMedium};
        font-size: 16px;
        line-height: 12px;
        color:BlueGrey #424B50;
       }
    }
    &.affinityPopup {
      .image-wrap-placeholder {
        width: 80%;
        height: 80%;
        min-width: 80%;
        transform: translate(-51%,-51%);
        .person-image-holder {
          margin: 2.5px;
        }
      }
    }
    &.smartContentPopupWrapper {
      .ant-modal-close-x {
        cursor: default;
      }
      .ant-modal-body {
      background-image: url(${wbSmartLayer});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      padding-top: 60px;
      padding-left: 35px;
      padding-right: 35px;
      }
      .the-title {
        font-family:${primaryFont};
        font-size: 35px;
        color: ${primaryColor};
        display:block;
        @media all and (max-width: ${maxMobile}) {
          font-size: 25px;
        }
       }
      .img-div {
        @media all and (max-width: ${maxMobile}) {
          margin-top: 30px;
        }
      }
      .smartContentParagraph{
        padding:0px 0px;
        font-family: ${primaryFont};
        font-weight: ${wMedium};
        letter-spacing: -0.56px;
        font-size: 20px;
        line-height: 28.4px;
        color: #5E7079;
        margin-top: 50px;
        margin-bottom: 0px;
        @media all and (max-width: ${maxMobile}) {
          font-size: 16px;
          margin-top: 20px;
          line-height: 22px;
        }
      }
      .smart-content-heaing {
        margin: 0px;
        font-weight: ${wBold};
         
      }
      .button-book-meeting {
        font-family: ${primaryFont};
        font-weight: ${wMBold};
        background-color: ${primaryColor};
        border-color:${primaryColor};
        padding:5px 12px;
        display: inline-block;
        margin-right: 10px;
        border-radius: 17.55px;
        color:#fff;
        font-size:16px;
        cursor:pointer;
        @media all and (max-width: ${maxMobile}) {
          white-space: pre;
          font-size: 12px;
          line-height: 21px;
        }
      }
      .button-close-modal{
        font-family: ${primaryFont};
        font-weight: ${wMBold};
        background-color: #fff;
        color : ${primaryColor};
        padding: 4px 43px;
        display: inline-block;
        border-radius: 17.55px;
        border:2px solid ${primaryColor};
        font-size:16px;
        cursor:pointer;
        @media all and (max-width: ${maxMobile}) {
          white-space: pre;
          font-size: 12px;
        }
      }
      .smart-content-title-div-center{
        height: 100%;
        display:flex ;
        align-items: center;
      }
    }
    .ant-modal-body {
     text-align: center;
    }
    .title {
      font-size: 42px;
      line-height: 44px;
      font-family: ${secondaryFont};
      font-weight: ${wMedium};
      color: #5E7079;
      @media all and (max-width: ${maxMobile}) {
       font-size: 26px;
        line-height: 30px;
      }
    }
    .description {
    font-family: ${primaryFont};
    font-weight: ${wLight};
    font-size: 20px;
    line-height: 24px;
    color: #5E7079;
    padding: 0px 30px;
    margin-top: 30px; 
    margin-bottom: 0px;
    }
    a {
     color: #00AAFF;
     font-size: 18px;
     line-height: 24px;
     font-family: ${primaryFont};
    }
    .ant-modal-footer {
      display: none;
      &.custom-footer {
        display: block;
        @media all and (max-width: ${maxMobile}) {
          display: flex;
          justify-content: center;
        }
      }
      button{
        border-radius:25px !important;
      }
    }
  }

  .modal-guest {
    .ant-modal-body{
      padding: 0px !important;
    }
    .ant-modal-footer {
      display:none;
    }
  }
   .article-preview{
    .ant-drawer-content-wrapper{
      @media all and (max-width: ${maxWidth}) , ${retina} {
        width: 450px !important;
      }
      @media all and (max-width: ${maxTablet}) {
        width: 375px !important;
      }
      @media all and (max-width: 420px) {
        width: 85% !important;
      }
    }
  }
  .bottom-accordion-document-hub {
    .ant-drawer-content-wrapper {
      width: calc(100vw - 87px);
      left: 87px;
      @media all and (max-width: ${maxTablet}) {
        width: calc(100vw - 0px);
        left: 0px;
      }
      .ant-drawer-content {
        .ant-drawer-wrapper-body {
          .ant-drawer-header {
            display: none;
          }
          .ant-drawer-body { 
            padding: 27px 40px;
            overflow: hidden;
            @media all and (max-width: ${maxMobile}) {
              padding: 17px 20px;
            }
          }
        }
      }
    }
  }
  .drawer-alerts{
    &.nestedDrawerViewer {
      right: 200px;
    }
    .ant-drawer-content-wrapper {
      @media all and (max-width: ${maxTablet}) {
        transform: unset !important;
      }
    }
    &.nestedTwoDrawerViewer {
      .ant-drawer-content-wrapper {
        transform: translateX(-180px) !important;
        @media all and (max-width: ${maxTablet}) {
          transform: unset !important;
        }
      }
    }
    &.nestedThreeDrawerViewer {
      .ant-drawer-content-wrapper {
        transform: translateX(-370px) !important;
        @media all and (max-width: ${maxTablet}) {
          transform: unset !important;
        }
      }
    }
    &.certification-drawer {
      .ant-drawer-content-wrapper {
        @media all and (max-width: ${maxWidth}), ${retina} {
          width: 531px !important;
        }
      }
    }
    &.chatbot-history-drawer {
      z-index: 5; 
      .ant-drawer-content-wrapper {
        .ant-drawer-content {
          .ant-drawer-wrapper-body {
            .ant-drawer-header {
              .ant-drawer-header-title {
                display: flex;
                .ant-drawer-close {
                  display: none;
                }
                .ant-drawer-title {
                  .header-panel {
                    &:hover {
                      color: ${primaryColor};
                      .add-icon-svg {
                        path {
                          fill: ${primaryColor};
                        }
                      }
                    }
                    font-family: ${primaryFont};
                    font-weight: ${wMedium};
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: -0.4px;
                    margin-bottom: 0px;
                    display: flex;
                    align-items: center;
                    padding: 0px 12px 0px;
                    color: ${SecondaryColor};
                    margin-left: -5px;
                    cursor: pointer;
                    .add-icon-svg {
                      margin-right: 10px;
                      width: 20px;
                      height: 25px;
                      rect {
                        fill: #FFF;
                      }
                      path {
                        fill:  ${SecondaryColor};
                      }
                    }
                  }
                }
              }
            }
            .ant-drawer-body {
              .drawer-scroll-infinite {
                height: calc(100vh - 70px) !important;
                max-height: calc(100vh - 70px) !important;
                ${scrollbar};
              }
            }
          }
        }
      }
    }
    &.smart-account-drawer {
      &.nestedDrawerViewer {
        right: 200px;
      }
      z-index: 5; 
      .ant-drawer-content-wrapper{
      @media all and (max-width: ${maxWidth}) , ${retina} {
        width: 530px !important;
      }
      @media all and (max-width: ${maxTablet}) {
        width: 480px !important;
      }
      @media all and (max-width: ${midMobile}) {
        width: 90% !important;
      }
        .ant-drawer-content {
          .ant-drawer-wrapper-body {
            .ant-drawer-header {
              padding: 0px;
              .ant-drawer-extra {
                .close-alerts {
                  position: absolute;
                  width: 14px;
                  height: 14px;
                  top: 26px;
                  right: 24px;
                  cursor: pointer;
                  @media all and (max-width: ${maxWidth}),${retina} {
                    top: 17px;
                  }
                  @media all and (max-width: ${maxTablet}) {
                    top: 19px;
                    right: 8px;
                  }
                  @media all and (max-height: ${midTablet}) {
                    min-width: 24px;
                    top: 11px;
                    right: 15px;
                  }
                }
              }
            }
          }
        }
      }
      .ant-drawer-body {
        position: relative;
      }
      .ant-modal-mask, .ant-modal-wrap {
        position: absolute;
      }
    }
    .ant-drawer-content-wrapper{
      @media all and (max-width: ${maxWidth}) , ${retina} {
        width: 460px !important;
      }
      @media all and (max-width: ${smMobile}) {
        width: 90% !important;
      }
      @media all and (max-width: 420px) {
        width: 95% !important;
      }
    }
  }

  .gillRoyEmail {
    .ant-drawer-content-wrapper {
      @media all and (max-width: ${maxWidth}) and (min-width: ${maxTablet}){
        width: 600px !important;
      }
    }
  }

  
  .grabber {
   cursor: grab;
  }
  .drag-appeaser{
    position: fixed;
    pointer-events: none;
    z-index: 100;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    > div {
      height: 200px;
      width: 200px;
    }
    .below-card-detail {
      display: none;
    }
    .person-item {
      box-sizing: border-box;
      transform: scale(0.7);
      border-radius: 5px;
      padding-top: 0px;
      z-index: 3;
      height: 200px;
      width: 200px;
      > .c-pointer {
        display: none;
      }
      /* &.isDragItem {
        .name-wrapper, .name-wrapper-v2 {
          display: none;
        }
      } */
    }
    .drawer-action, .drawerAction  {
      display: none;
    }
    .dragDropdown{
      display: none !important;
    }
  }
  .back-drag-check {
    display: none;
    opacity: 0.7;
    background-color: #f9f8f6;
    border: none;
    box-sizing: border-box;
    border-radius: 0px;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 11;
  }
  .group-detail-large-mode-fix {
    .large-mode {
      @media all and (max-width: ${maxWidth}) , ${retina} {
        min-width: 270px;
      }
      @media all and (max-width: 1560px) {
        min-width: 200px;
      }
      @media all and (max-width: 1286px) {
        min-width: 170px;
      }
      @media all and (min-width: 1550px) and (max-width: 1850px) {
       min-width: 280px;
      }
    }
    .icon-mode {
      margin-right: 5px;
      .ant-input-prefix {
        svg {
          height: 20px;
          @media all and (max-width: ${maxWidth}) , ${retina} {
            height: 16px;
          }
        }
      }
    }
  }
  .filter-tabs-not-move {
    .large-mode {
      position: absolute;
      right: -5px;
      top: 0px;
      margin-right: 0px;
    }
    &.people-i-follow {
      position: relative;
      .icon-mode {
         height: 38px;
         @media all and (max-width: ${maxWidth}) , ${retina} {
           height: 32px;
         }
        > div {
          width: 30px;
        }
        .ant-input-prefix {
         right: 9px;
         top: 5px;
          @media all and (max-width: ${maxWidth}) , ${retina} {
           top: 1px;
          }
        }
        svg {
          height: 18px;
        }
      }
      .large-mode {

      }
    }
    .icon-mode {
      position: relative;
      background-color: #fff;
      margin: 0px 0px 0px 10px;
      padding: 0px 20px;
      border-radius: 8px;
      border: 1px solid #DADADA;
      .ant-input-prefix {
        position: absolute;
        right: 10px;
        top: 2px;
        width: 100%;
        border-right: none;
         @media all and (max-width: ${maxWidth}) , ${retina} {
          right: 7px;
          svg {
            height: 17px;
          }
        }
      }
    }
  }
  .backActive {
    .back-drag-check {
      display: block;
    }
  }
  .activeDrop {
    .card-inner-wrapper {
      background-color: ${primaryColor} !important;
    }
    .other-side {
      .buyer-name {
          color: #fff !important;
      }
      svg {
        path {
          fill: #fff !important;
        }
      }
    }
    .drop-placement {
      border: 2px solid #1EC252;
      background-color: ${primaryColor} !important;
      svg {
        path {
          fill: #fff !important;
        }
      }
      .title {
        color: #fff !important;
      }
    }
  }
  .add-line-break {
    white-space: pre-wrap;
  }
  .ant-spin-dot {
    .ant-spin-dot-item {
     background-color: ${primaryColor};
    }
  }
  .no-record-found {
    padding: 50px;
    border-radius: 16px;
    box-shadow: ${boxShadow};
    background-color: #fff;
    text-align: center;
    .heading {
      font-size: 22px;
      line-height: 25px;
      font-family: ${secondaryFont};
      color: ${primaryHeadingColor};
      margin: 200px 0px;
    }
  }
  .filter-dropdown {
    .menu-styles {
      max-height: 300px;
      ${scrollbar};
    }
    .ant-dropdown-menu {
      min-width: 225px;
      background-color: #fff;
      border-radius: 8px;
      @media all and (max-width: ${maxWidth}) , ${retina} {
        min-width: 200px; 
      }
    }
    .ant-dropdown-menu-item {
      padding: 5px 30px;
      font-size: 16px;
      font-family: ${primaryFont};
      color: #3F4B50;
      @media all and (max-width: ${maxWidth}) , ${retina} {
        padding: 2px 15px;
        font-size: 13px;
      }
      &:hover {
        color: ${primaryColor};
      }
    }
  }
  .barchart {
    .highcharts-background {
      fill: transparent;
    } 
  }
  .alert-article {
    .evanote-icon {
      margin-top: 0px;
    }
  }
  .tabs-details {
    .article-tile-global {
      .evanote-icon {
        margin-top: -5px;
        @media all and (max-width: ${maxWidth}), ${retina} {
          margin-top: -5px;
        }
      }
    }
  }
  .global-filter {
    z-index: 1;
  }
  .company-filter,.global-filter {
    .ant-popover-inner-content {
      width: 100%;
      padding: 0px !important;
    }
    .ant-popover-content {
      width: 400px;
      @media all and (max-width: ${maxWidth}) , ${retina} {
        width: 340px; 
      }
      @media all and (max-width: ${smMobile}) {
        width: 310px;
      }
    }
  }
  .logout-modal-c {
    .ant-modal-content {
      position: relative;
      .spinner-layer {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        background-color: rgba(249, 248, 246, 0.5);
        z-index: 1;
      }
    }
  }
  .container-company-card {
    .scc-place {
     width: 24px;
     margin-right: 10px;
     @media all and (max-width: ${maxWidth}) , ${retina} {
      width: 20px;
      height: 20px;
     }
    }
  }
  .search-responsive {
    @media all and (max-width: ${maxWidth}) , ${retina} {
      height: 32px !important;
    }
    .crossVisible {
      @media all and (max-width: ${maxWidth}) , ${retina} {
       top: 8px !important;
      }
    }
  }
  .download-profile-popover {
    .profile-dropdown {
       min-width: 320px;
      h3 {
         font-size: 24px !important;
         line-height: 20px;
        svg {
            margin-right: 10px;
        }
      }
      .download-doc {
          padding: 10px 5px 10px 5px !important;
        button {
            padding: 5px 10px 5px 20px;
          .separator-right {
              margin: -4px 5px -5px 10px;
              height: 16px;
          }
        }
        .side-by-icon {
          h4 {
              margin: 0px 0 0 6px !important;
              font-size: 14px !important;
          }
          ul {
              margin: 8px 0 8px 6px;
          }
          svg {
              width: 35px;
          }
        }

      }
    }
  }
  .download-profile-btn {
    button {
      @media all and (max-width: ${maxWidth}) , ${retina} {
       padding: 6px 10px 4px 10px;
      }
      .separator-right {
          height: 16px;
          margin: 0 5px 0px 10px;
      }
      svg {
        margin-right: 0px;
      }
    }
  }
  .left-side-action-area {
    button {
      svg {
        margin-right: 6px;
      }
    }
  }
  .scc-place {
    &.export{
      background-color: #fff;
      margin-left: 10px;
      margin-right:0px;
      .shareIcon-svg{
        #Group {
          fill: black;
        }
      }
      
    }
    &.twit-icon {
      margin-right: 19px;
    }
    &:nth-child(2){
      svg {
         width: 30px;
         height: 30px;
      }
    }
  }
  .fin-state {
    margin-right: 10px;
    margin-top: -2px;
  }
  .fin-inner-details{
    .ant-col {
     button {
       /* @media all and (max-width: ${maxWidth}) , ${retina} {
         height:  62px;
       } */
     }
    }
  }
  #root {
    + div {
      /* display: none; */
    }
  }
  .engagement-company {
    background-size: 70%;
  }
  .align-item-center-100-res {
    @media all and (max-width: ${maxWidth}) , ${retinaOne} {
      align-items: center;
    }
  }
  .arctypes-container-style {
    .tabs-wrapper {
      .tabs-item {
        @media all and (max-width: ${maxWidth}) , ${retinaOne} {
          padding: 7px 40px;
        }
      }
    }
  }
  .Grid-width-row {
    margin: 0px -5px !important;
    row-gap: 0px !important;
    &.lastTwoRemove {
      .ant-col {
        &:nth-last-child(1), &:nth-last-child(2){
           @media all and (max-width: 1440px){
            display: none !important;
           }
        }
      } 
    }
    &.person-i-follow-negative {
      @media all and (max-width: ${maxMobile}) {
        margin-top: -20px !important;
      }
    }
    &.lastOneRemove {
      .ant-col {
        &:nth-last-child(1){
           @media all and (max-width: 1440px){
            display: none !important;
           }
        }
      } 
    }
    &.topPeopleGridFix {
      .ant-col {
        @media all and (max-width: 1450px){
          flex: 0 0 16.5% !important;
          max-width: 16.5% !important;
        }
        @media all and (max-width: ${maxMobile}){
          flex: 0 0 32% !important;
          max-width: 32% !important;
        }
        @media all and (max-width: 400px){
          flex: 0 0 50% !important;
          max-width: 50% !important;
       }
        
      }

    }
    &.col-six-fix-md {
      /* margin-top: 20px !important; */
      .ant-col {
        @media all and (max-width: 1440px){
          flex: 0 0 20% !important;
          max-width: 20% !important;
        }
        @media all and (max-width: ${maxMobile}){
          flex: 0 0 25% !important;
          max-width: 25% !important;
        }
        @media all and (max-width: 650px){
          flex: 0 0 32% !important;
          max-width: 32% !important;
        }
        @media all and (max-width: 480px){
          flex: 0 0 50% !important;
          max-width: 50% !important;
        }
      }
      .StatesWidgetPrimary-col-size {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        margin: 0px;
        @media all and (max-width: ${minWidthApp})
        {
          margin: 4px;
        }
      }
    }
    &.col-six-fix {
      margin-top: 20px !important;
      .ant-col {
        flex: 0 0 20% !important;
        max-width: 20% !important;
      }
      &.col-six-md-sm {
        @media all and (max-width: ${maxMobile}) {
          margin: 10px 0px 0px -14px !important;
        }
       .ant-col {
          @media all and (max-width: ${maxMobile}) {
            flex: 0 0 50% !important;
            max-width: 50% !important;
          }
        } 
      }
      .StatesWidgetPrimary-col-size {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        margin: 0px 0px 10px 0px;
        @media all and (max-width: ${minWidthApp})
        {
          margin: 4px;
        }
      }
    }
    &.white-layer-col {
      .ant-col {
        @media all and (max-width: 900px) {
          flex: 0 0 25% !important;
          max-width: 25% !important;
        }
        @media all and (max-width: ${maxMobile}){
          flex: 0 0 32% !important;
          max-width: 32% !important;
        }
        @media all and (max-width: ${smMobile}){
          flex: 0 0 50% !important;
          max-width: 50% !important;
        }
      }
    }
    &.gridUpdated {
      row-gap: 24px !important;
      margin: 0px -10px !important;
      @media all and (max-width: ${maxTablet}) {
        margin: 0px -16px !important;
      }
      @media all and (max-width: ${maxMobile}) {
        margin: 0px -8px !important;
      }
      .loader-wrapper {
        .main {
          height: 100%;
          padding: 54px 10px;
          @media all and (max-width: ${maxWidth1300}) {
            padding: 27px 10px;
          }
          @media all and (max-width: ${minWidthApp}) {
            padding: 12px 10px;
          }
          @media all and (max-width: ${maxTablet}) {
            padding: 3px 10px;
          }
        }
        .text {
          @media all and (max-width: ${maxWidth}), ${retina} {
            font-size: 15px;
          }
          @media all and (max-width: ${maxWidth1300}) {
            font-size: 13px;
            width: 110px;
          }
          @media all and (max-width: ${minWidthApp}) {
            font-size: 14px;
            width: 100px;
          }
          @media all and (max-width: ${maxTablet}) {
            font-size: 12px;
            width: 90px;
          }
        }
      }
      .ant-col {
        margin: 0px 0px !important;
        @media all and (max-width: ${maxWidth}) {
          flex: 0 0 16.6667% !important;
          max-width: 16.6667% !important;
        }
        @media all and (max-width: ${minWidthApp}) {
          flex: 0 0 20% !important;
          max-width: 20% !important;
        }
        @media all and (max-width: 1024px) {
          flex: 0 0 16.6667% !important;
          max-width: 16.6667% !important;
        }
        @media all and (max-width: ${midTablet}) {
          flex: 0 0 20% !important;
          max-width: 20% !important;
        }
        @media all and (max-width: ${minTablet}) {
          flex: 0 0 25% !important;
          max-width: 25% !important;
        }
        @media all and (max-width: ${midMobile}){
          flex: 0 0 50% !important;
          max-width: 50% !important;
        }
        .person-item {
          max-width: 100%;
          width: 100%;
        }
        .company-item {
          max-width: 100%;
          width: 100%;
          .exceptButtonContent {
            .export-widget-industries {
              width: 100%;
            }
            .company-image-parent {
              width: 100%;
            }
          }
          .companyName {
            @media all and (max-width: ${minWidthApp}){
              margin-left: 5px;
              text-align: center;
            }
          }
          .company-stats{
            p {
              margin-left: 5px;
              &:nth-child(1){
                margin-left: 0px;
                padding-right: 5px;
              }
            }
            .company-ticker-stats {
              max-width: 37%;
            }
            span {
              @media all and (max-width: ${minWidthApp}){
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
    .ant-col {
      @media all and (max-width:1450px) {
       flex: 0 0 20% !important;
       max-width: 20% !important;
      }
      @media all and (max-width: ${maxMobile}){
        flex: 0 0 32% !important;
        max-width: 32% !important;
      }
      @media all and (max-width: ${smMobile}){
        flex: 0 0 50% !important;
        max-width: 50% !important;
      }
      .person-item {
        max-width: 100%;
        width: 100%;
      }
      .company-item {
        max-width: 100%;
        width: 100%;
        .exceptButtonContent {
          .export-widget-industries {
            width: 100%;
          }
          .company-image-parent {
            width: 100%;
          }
        }
        .companyName {
          @media all and (max-width: ${minWidthApp}){
            margin-left: 5px;
            text-align: center;
          }
        }
        .company-stats{
          p {
            margin-left: 5px;
            &:nth-child(1){
              margin-left: 0px;
              padding-right: 5px;
            }
          }
          .company-ticker-stats {
            max-width: 37%;
          }
          span {
            @media all and (max-width: ${minWidthApp}){
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  .onboarding-search-wrapper {
    .container {
      padding: 0px !important;
      width: 100% !important;
    }
  }
  .ant-btn {
    &:active, &:focus {
      color: ${primaryColor} !important;
      border-color: ${primaryColor} !important;
      background: transparent !important;
    }
  }
  .staff-buyers-group {
    .ant-col {
     margin-bottom: 30px
    }
  }
  .spotlight-unique-class {
    .tagArticle {
      font-size: 10px;
      padding: 0px 10px;
      right: 10px;
      top: 13px;
    }
    .ant-col {
      @media all and (max-width: ${maxTablet}) {
        padding-left: 6px !important;
        padding-right: 6px !important;
      }
    }
    .other-states-area {
      @media all and (max-width: ${maxTablet}) {
        margin-left: -4px !important;
      }
    }
    .text-area {
      .footer-data-article {
        .ant-popover-inner-content {
          .teams-share-button {
            &:before {
              width: 16px;
              height: 16px;
              margin-top: 7.2px;              
              @media all and (max-width:${maxWidth})and (${retina}) {
                margin-top: 8.6px;
              }
              @media all and (max-width:${maxWidth}) and (${retinaOne}) {
                margin-top: 7px;
              }
            }
          }
        }
      }
    }
  }
  .article-search-details {
    .article-grid {
      margin-bottom: 20px;
      width: 100%;
    }
  }
  .width-img {
    width: 315px;
    margin: 50px 0px 50px -30px;
  }
  .staff-btn-style {
    .share-button {
      margin-right: 20px;
    }
    .shareIcon-svg {
      margin-right: 10px;
    }
    .global-search-dropdown {
      &.large-mode {
        @media all and (max-width: 1250px) and (min-width: 1022px){
          min-width: 200px;
          max-width: min-content;
        }
      }
    }
  }
  .separatedSpace {
    height: auto !important;
    .ant-spin-spinning {
     margin-right: 10px !important;
     margin-top: -6px !important;
      .ant-spin-dot {
        font-size: 15px !important;
      }
    }
  }
  .person-page-personality-fix {
    background: transparent !important;
    border: 1px solid #DADADA !important;
  }
  .ant-drawer-header {
    padding: 16px 24px 0px 24px;
    @media all and (max-width: ${maxTablet}) {
     padding: 16px 5px 0px 10px;
    }
  }
  .arctype-drawer {
    .ant-drawer-close {
      display:none;
    }
    .ant-drawer-header {
      width: 50px;
      padding: 16px 0px 16px 24px;
      border-bottom: none;
    }
    .ant-drawer-wrapper-body {
      &:before {
        content: "";
        background-image: url(${backDropPersonality});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 100% 0px;
        height: 400px;
        width: 100%;
        position: absolute;
        top: -33px;
        right: 0px;
        z-index: -1;
      }
    }
    .disclaimer-style {
      text-align: left;
      margin-bottom: 30px;
      padding-right: 0px;
    }
  }
  .name-wrapper {
    cursor: auto !important;
  }
  .header-wrapper {
    .DateRangePiker {
      margin-top: 7px;
      @media all and (max-width: ${maxWidth}) , ${retina} {
       margin-top: 0px;
      }
    }
  }
  .spotlightMiniTileView {
    .ant-popover {
      top: 4px !important;
    }
  }
  .video-popup-wrapper {
    .ant-modal-content {
      box-shadow: none;
      background-color: transparent;
      .ant-modal-close{
        svg{
          margin:24px 10px 0 0;
        }
      }
      .ant-modal-close-x {
        @media all and (max-width: ${maxMobile}) {
          position: absolute;
          top: -20px;
          right: -14px;
        }
      }
    }
    .ant-modal-body {
      padding: 50px !important;
       @media all and (max-width: ${maxMobile}) {
        padding: 10px !important;
       }
      iframe {
        border-radius: 20px !important;
      }
    }
    .videoFrame{
      height: 510px;
      width: 100%;
      @media all and (max-width: ${maxMobile}) {
        height: 230px;
      }
    }
    .ant-modal-close {
      display: block;
    }
    .ant-modal-footer {
      display: none;
    }
  }
  .delete-document-popup {
    &.sap-history-delete-popup {
      .ant-modal-content {
        border-radius: 14px;
        .ant-modal-body {
          padding: 38px 30px 0px;
          .gilroy-white-svg {
            margin-top: 0px;
          }
        }
        .ant-modal-footer {
          padding: 25px 10px 34px;
          .ant-btn {
            width: 102px;
            height: 32px;
          }
        }
      }
    }
   .ant-modal-content {
    border-radius: 13px;
    .ant-modal-close{
      display: none;
    }
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 47px;
      padding: 47px 90px 0;
      .gilroy-white-svg {
        height: 80px;
        width: 87px;
        margin-top: 22px;
        @media all and (max-width: ${maxTablet}) {
          height: 74px;
          width: 70px;
          margin-top: 16px;
        }
      }
      .clear-text-modal {
        font-family: ${secondaryFont};
        font-weight: ${wMBold};
        font-size: 22px;
        line-height: 24px;
        color: ${primaryHeadingColor};
        text-align: center;
        @media all and (max-width: ${maxTablet}) {
          font-size: 18px;
          line-height: 20px;
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: center;
      padding: 24px 10px 69px;
      @media all and (max-width: ${maxTablet}) {
        padding: 15px 10px 25px;
      }
      .ant-btn {
        font-family: ${primaryFont};
        font-weight: ${wMBold};
        font-size: 14px;
        border-radius: 23px;
        line-height: 16px;
        min-width: 100px;
        padding: 7px 0px;
        margin: 0px 5px;
        height: 33px;
        @media all and (max-width: ${maxTablet}) {
          padding: 5px 0px;
          font-size: 12px;
          min-width: 80px;
        }
        &:hover {
          outline: none !important;
        }
      }
    }
  }
  }
  .profile-comparison-popup {
    .ant-modal {
      @media all and (max-width: ${maxWidth}) , ${retina} {
        width: 784px !important;
      }
      @media all and (max-width: ${maxMobile}) {
        width: 540px !important;
      }
      .ant-modal-content {
        .ant-modal-body {
          padding: 45px 32px 45px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          @media all and (max-width: ${maxWidth}) , ${retina} {
            padding: 35px 22px 25px;
          }
          .popup-heading {
            font-family: ${secondaryFont};
            font-weight: ${wMBold};
            font-size: 40px;
            line-height: 46px;
            color: ${primaryHeadingColor};
            margin-bottom: 20px;
            @media all and (max-width: ${maxWidth}) , ${retina} {
              font-size: 32px;
              line-height: 38px;
              margin-bottom: 10px;
            }
            @media all and (max-width: ${maxMobile}) {
              font-size: 22px;
              line-height: 26px;
            }
          }
          .person-comparison-row {
            width: calc(100% - 10px);
            margin-bottom: 20px;
            max-height: 520px;
            ${scrollbar};
            @media all and (max-width: ${maxWidth}) , ${retina} {
              max-height: 470px;
            }
            @media all and (max-width: ${minWidthApp}) {
              max-height: 410px;
            }
            @media all and (max-width: ${maxMobile}) {
              max-height: 220px;
            }
            .comparison-grid {
              margin-right: 25px !important;
              margin-left: 10px !important;
            }
            .person-image-holder {
              margin: 2.5px;  
            }
            .name-wrapper {
              h4 {
                font-size: 12px !important;
                margin: 2px !important;  
              }
            }
            .below-card-detail {
              .person-name-here {
                font-size: 16px;
                @media all and (max-width: ${maxWidth}) , ${retina} {
                  font-size: 14px;
                }
              }
              p {
                font-size: 14px;
                 @media all and (max-width: ${maxWidth}) , ${retina} {
                  font-size: 12px;
                }
              }
            }
            &.infinite-scroll-div {
              .person-comparison-skeleton-col {
                &.profile-confirmation {
                  .ant-col {
                    flex: 0 0 30% !important;
                    max-width: 30% !important;
                    @media all and (max-width: ${maxMobile}) {
                      flex: 0 0 50% !important;
                      max-width: 50% !important;
                    }
                    @media all and (max-width: ${smMobile}) {
                      flex: 0 0 100% !important;
                      max-width: 100% !important;
                    }
                    .person-skeleton-loader {
                      margin: 0 0 50px 0;
                    }
                  }
                }
                .Grid-width-row.col-six-fix-md {
                  .ant-col {
                    flex: 0 0 25% !important;
                    max-width: 25% !important;
                    @media all and (max-width: ${maxMobile}) {
                      flex: 0 0 50% !important;
                      max-width: 50% !important;
                    }
                    @media all and (max-width: ${smMobile}) {
                      flex: 0 0 100% !important;
                      max-width: 100% !important;
                    }
                  }
                }
              }
              .no_data_found {
                  height: calc(100vh - 418px);
              }
              .person-comparison-col {
                  @media all and (max-width: ${maxMobile}) {
                    flex: 0 0 50% !important;
                    max-width: 50% !important;
                  }
                  @media all and (max-width: ${smMobile}) {
                    display: flex;
                    justify-content: center;
                    flex: 0 0 100% !important;
                    max-width: 100% !important;
                  }
                  .card {
                    border: 1.5px solid #fff;
                    padding: 0px 12px 12px 12px;
                  }
                  .activeCard {
                    border-radius: 12px;
                    border: 1.5px solid ${primaryColor};
                    box-shadow: 0px 0px 24px rgba(30, 194, 82, 0.3);
                  }
                  .person-item {
                    padding-top: 15px;
                    margin-bottom: 0px !important;
                  }
                  &.outer-search {
                    @media all and (max-width: ${smMobile}) {
                      flex: 0 0 60% !important;
                      max-width: 60% !important;
                      margin: 0 auto;
                    }
                    > div {
                      height: 100%;
                    }
                    .main {
                      margin: 0px;
                      padding: 20px 10px;
                      height: 100%;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                    }
                    .text {
                      font-size: 12px;
                      line-height: 16px;
                      width: 100%;
                    }
                  }
              }
            }
          }
        }
        .comparison-primary-button {
            &.disabled {
              opacity: 0.6;
              pointer-events: none;
              cursor: auto;
            } 
            &.secondary-button {
              background-color: #fff;
                color: ${SecondaryColor};
                border: 1px solid ${SecondaryColor};
                margin-left: 10px;
              @media all and (max-width: ${maxMobile}) {
                margin-left: 0px;
              }
            }
            .ant-modal-footer {
            display: none;
            }
            font-family: ${primaryFont};
            background-color: ${primaryColor};
            color: #fff;
            font-weight: ${wMBold};
            font-size: 16px;
            line-height: 20px;
            width: 100%;
            border-radius: 100px;
            border: 2px solid ${primaryColor};
            padding: 12px 0;
            text-align: center;
            cursor: pointer;
              @media all and (max-width: ${maxWidth}) , ${retina} {
                font-size: 14px;
                padding: 7px 0;
              }
              @media all and (max-width: ${maxMobile}) {
                margin-bottom: 10px;
              }
        }
      }
    }
  }
  .share-group-with-people-popup {
    &.sapBuyerAnalysisStyles {
      .ant-modal {
        @media all and (max-width: ${maxMobile}) {
          width: 290px !important;
          height: 430px;
        }
        .ant-modal-content {
          .ant-modal-body {
          .person-search-bar {
              .search-wrapper {
                .ant-input-affix-wrapper {
                  .ant-input::placeholder {
                    color: #C1C1C1;
                    font-weight: ${wLight};
                  }
                }
              }
            }
            @media all and (max-width: ${maxMobile}) {
              padding: 20px 24px 22px;
              .heading-popup {
                font-size: 18px;
                line-height: 20px;
                &.second-heading-popup {
                  font-size: 15px;
                  line-height: 16px;
                }
              }
              .person-search-bar {
                .search-wrapper {
                  padding: 11px 0px 9px;
                  .ant-input-affix-wrapper {
                    height: 28px;
                    .ant-input-prefix {}
                    .ant-input {
                      font-size: 10px;
                      font-weight: ${wLight};
                    }
                    .ant-input::placeholder {
                      font-size: 10px;
                      color: #C1C1C1;
                      font-weight: ${wLight} !important;
                    }
                  }
                  .cross-icon {
                    @media all and (max-width: ${maxMobile}) {
                      top: 17.5px;
                    }
                  }
                }
              }
            }
            #share-group-dropdown {
              .infinite-scroll-component__outerdiv {
                .share-group-dropdown {
                  border-radius: 0px;
                  @media all and (max-width: ${maxMobile}) {
                    height: 196px !important;
                  }
                  .skeletons-for-search-item {
                    margin-left: 10px;
                  }
                  .search-item {
                    .affinity-search-item {
                      width: 100%;
                      .ant-radio-wrapper {
                        height: 20px;
                      }
                      .image {
                        margin-left: 0px;
                        .person-without-holo {
                          margin: 0px 5px;
                        }
                      }
                    }
                  }
                  .no_data_found {
                    height: 195px;
                  }
                }
              }
            }
            .share-group-footer {
              .share-group-button {
                @media all and (max-width: ${maxMobile}) {
                  height: 28px;
                  width: 90px;
                  font-size: 12px;
                  padding: 3px 15px;
                }
              }
            }
          }
        }
      }
    }
    &.responsiveSAP {
      .ant-modal-content {
      .ant-modal-body {
        padding: 17px 20px;
        .heading-popup {
          font-size: 20px;
          &.second-heading-popup {
            font-size: 18px;
          }
        }
        .person-search-bar{
          .search-wrapper {
            padding: 8px 0px 6px 0px;
          }
        }
        .share-group-dropdown {
          height: 204px !important;
        }
        .share-group-footer {
          .share-group-button {
            font-size: 14px;
            line-height: 15px;
          }
        }
      }
    }
  }
    .ant-modal-content {
      .ant-modal-body {
        background-image: url(${backgroundGreenLayer});
        background-position: center;
        background-size: 106% 106%;
        padding-top: 0px;
        padding: 27px 30px;
        .heading-popup {
          font-family: ${secondaryFont};
          font-weight: ${wMBold};
          font-size: 24px;
          line-height: 27px;
          color: ${primaryHeadingColor};
          &.second-heading-popup {
            font-size: 20px;
            line-height: 22px;
          }
          &.selected-people {
            cursor: pointer;
            font-family: ${primaryFont};
            font-size: 11px;
            line-height: 22px;
            color: ${primaryColor};
          }
        }
        .person-search-bar{
          .search-wrapper {
            padding: 16px 0px 12px 0px;
            width: 100%;
            .ant-input-affix-wrapper {
              height: 32px;
              .ant-input-prefix {
                .anticon-search {
                  font-size: 16px;
                }
              }
              >input {
                font-family: ${primaryFont};
                font-weight: ${wLight};
                font-size: 14px;
                line-height: 13.57px;
              }
            }
            .cross-icon  {
              top: 23px;
              right: 10px;
              @media all and (max-width: ${maxWidth}), ${retina}{
                top: 24.5px;
              }
            }
          }
        }
        .share-group-dropdown {
          ${scrollbar};
          height: 269px !important;
          margin-top: 17px;
          .search-item {
            display: flex;
            align-items: center;
            width: 100%;
            &:hover {
              background-color: ${primaryBgColor};
            }
            .checkbox-search {
              .ant-checkbox-wrapper {
                width: 10%;
                margin-bottom: 0px;
              }
            }
            .content {
              margin-left: 8px;
            }
            .affinity-search-item {
              width: 90%;
              height: 72px;
              .affinity-search-results {
                max-height: 72px;
                height: 72px;
              }
              .content {
                row-gap: 1px;
                width: calc(100% - 65px);
                .name {
                  font-size: 16px;
                  line-height: 18px;
                  font-weight: ${wMBold};
                }
                .email {
                  font-size: 12px;
                  line-height: 14px;
                  width: 100%;
                }
                .designation {
                  font-size: 12px;
                  line-height: 16px;
                  margin: 0px;
                }
                .company {
                  font-size: 12px;
                }
              }
            }
          }
            .no_data_found{
              div{
                height: 269px;
              }
            }
        }
      }
    }
    
    .ant-modal-close {
      display: none;
    }
    .ant-modal-footer {
      display: none;
    }
  }
  .share-group-footer {
  &.footerSAP {
    flex-direction: row-reverse;
    .share-group-button {
      &.share-button {
        margin-right: 0px;
      }
      &.cancel-button {
        margin-right: 8px;
      }
    }
  }
    display: flex;
    align-content: center;
    justify-content: center;
    padding-top: 30px;
    .share-group-button {
      border-radius: 18px;
      border: 2px solid ${primaryColor};
      font-family: ${primaryFont};
      font-weight: ${wMBold};
      text-align: center;
      font-size: 16px;
      line-height: 19px;
      white-space: pre;
      cursor: pointer;
      &.share-button {
        padding: 7px 28px 7px 28px;
        color: #fff;
        background-color: ${primaryColor};
        margin-right: 8px;
        &:disabled {
          cursor: default;
          opacity: 0.6;
        }
      }
      &.cancel-button {
        padding: 7px 28px 7px 28px;
        color:${primaryColor};
        background-color: #fff;
      }
      &.green-button {
        padding: 7px 46px 7px 45px;
        color: #fff;
        background-color: ${primaryColor};
        margin-right: 8px;
      }
      &.white-button {
        padding: 7px 44px 7px 44px;
        color:${primaryColor};
        background-color: #fff;
      }
    }
  }
  .share-group-modal {
    .ant-modal-content {
      .ant-modal-body {
        background-image: url(${backgroundGreenLayer});
        background-size: 106% 106%;
        background-position: center;
        background-repeat: no-repeat;
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 30px 110px 40px 110px;
        @media all and (max-width: ${maxMobile}) {
          padding: 30px 20px 40px 20px;
        }
        .heading-popup {
          font-family: ${secondaryFont};
          font-weight: ${wMBold};
          font-size: 30px;
          line-height: 40px;
          color: ${primaryHeadingColor};
          @media all and (max-width: ${maxMobile}) {
            font-size: 22px;
            line-height: 25px;
          }
          &.person-name {
            font-size: 18px;
            line-height: 15px;
            color: #000000;
            }
          &.person-designation {
            font-size: 16px;
            line-height: 12px;
            color: #7E7E7E;
            font-weight: ${wMedium};
            margin-top: 12px;
            }
          &.second-heading-popup {
            font-size: 20px;
            line-height: 21px;
          }
        }
        .people-widget-div {
          display: flex;
          justify-content: center;
          margin-top: 30px;
          margin-bottom: 10px; 
          .person-overlap-wrapper {
            position: relative;
            margin: 10px 0px 0px;
            z-index: 0;
            padding-right: 170px;
            @media all and (max-width: ${maxMobile}) {
              padding-right: 120px;
            }
            .person-without-holo {
              @media all and (max-width: ${maxMobile}) {
                width: 50px;
                height: 50px; 
                min-width: 50px;
              }
            }
            &.person-item-2 {
              padding-right: 65px; 
              @media all and (max-width: ${maxMobile}) {
                padding-right: 30px;
              }
            }
            &.person-item-3 {
              padding-right: 110px; 
              @media all and (max-width: ${maxMobile}) {
                padding-right: 75px; 
              }
            }
            &.person-item-4 {
              padding-right: 180px; 
              @media all and (max-width: ${maxMobile}) {
                padding-right: 115px;
              }
            }
             > div{
              position: absolute;
              top:0;
              left:0;
              &:nth-child(1) {
                z-index: 5;
                position: relative;
              }
              &:nth-child(2) {
                left:60px;
                z-index:4;
                @media all and (max-width: ${maxMobile}) {
                  left: 40px;
                }
              }
              &:nth-child(3) {
                left:120px;
                z-index:3;
                @media all and (max-width: ${maxMobile}) {
                  left: 80px;
                }
              }
              &:nth-child(4) {
                left:180px;
                z-index:2;
                @media all and (max-width: ${maxMobile}) {
                  left: 120px;
                }
              }
            }
          }
        }
        .image-wrap-placeholder {
          transform: translate(-50.7%, -51%);
          @media all and (max-width: ${maxMobile}) {
            transform: translate(-50.7%, -54%);
          }
          .person-image {
            margin: 2px;
          }
        }
      }
    }
    .ant-modal-close-x {
      display: none;
    }
    .ant-modal-footer {
      display: none;
    }
  }
  .ant-switch {
    background-image: linear-gradient(90deg,#1EC252,#1EC252),linear-gradient(90deg,#fff,#fff) !important;
  }
  .ant-switch-checked {
    background:  linear-gradient(90deg,rgba(0,0,0,.25),rgba(0,0,0,.25)),linear-gradient(90deg,#fff,#fff) !important;
  }
  .ant-switch-inner {
    font-family:${primaryFont};
    font-weight:500;
  }
  .drawer-alerts {
    &.people-group-drawer {
      &.doc-viewer {
        &.docViewGilV3 {
          z-index: 11;
        }
        .ant-drawer-header {
          padding-top: 0px;
        }
        .ant-drawer-content-wrapper {
          width: 713px !important;
        }
        .drawer-img {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }  
  } 
  #google_translate_element{
    position: fixed;
    right: 20px;
    top: 29px;
    z-index: 4;
    @media all and (max-width: 1350px) {
      right: 3px;
      top: 25px;
    }
    @media all and (max-width: ${maxWidth}), ${retina}{
      top: 25px;   
    }

    @media all and (max-width: ${maxTablet}){
      display: none;
    }
    .goog-te-gadget {
      .goog-te-combo {
        display: none;
        &:nth-child(1) {
         display: block;
        }
      }
    }
  }
  .people-group-drawer {
    z-index: 5; 
    .search-wrapper {
      .cross-icon {
        width: 14px;
        height: 14px;
        top: 7px;
        right: 7px;
        cursor: pointer;
      }
      .search-field-icon{
        display: flex;
        top: 11;
        justify-content: center;
        align-items: center;



      }
    }
    .ant-input-affix-wrapper {
      >input.ant-input {
        padding-right: 10px;
        min-height: 18px;
        padding-bottom: 0px;
        line-height: 26px;
      }
    }
    .buyer-skeleton-wrapper {
      margin-bottom: 16px;
    }
  }
  .sales-sigma-leadership-drawer {
    .ant-drawer-body {
      display: flex;
      flex-direction: column;
      padding: 0 40px;
      ${scrollbar};
      overflow: auto !important;
      @media all and (max-width: ${maxMobile}) {
        padding: 0 10px;
      }
    }
  }
  .welcome-tour-popup{
    .ant-modal-close,.ant-modal-footer {
      display: none;
    }
    .ant-modal-content  {
      .ant-modal-body {
        padding: 0px;
        padding-top: 0px;
      }
    }
   
  }
  .welcome-tour-popup{
    .ant-modal-close,.ant-modal-footer {
      display: none;
    }
    .ant-modal-content  {
      .ant-modal-body {
        padding: 0px;
        padding-top: 0px;
      }
    }
   
  }
  .ant-input-textarea {
    .ant-input {
    background: #FBFBFB;
    border: 1px solid #E4E4E4;
    border-radius: 5px;  
    }
    &.ant-input-textarea-show-count {
      &:after {
      bottom: 5px;
      position: absolute;
      right: 12px;
      font-size: 11px;
      font-family: 'Roboto';
      font-style: italic;
      }
    }
  }
  .ant-input-textarea, .ant-input-affix-wrapper-textarea-with-clear-btn {
    position: relative;
    .ant-input {
      padding: 32px 26px 32px 16px;
      font-style: italic;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #979797;
      font-family: ${primaryFont};
      resize: none;
      &:focus, &:hover {
		    border-color: ${primaryColor};
        box-shadow: 0 0 0 1px ${primaryColor};
	    }
      &.text-area-reason {
        padding: 16px 23px 16px 12px;
        font-weight: ${wLight};
        font-size: 11.8033px;
        line-height: 16px;
        color: #67696A;
        font-style: normal;
        border-radius: 4px !important; // by reason important
      }
    }
  }
  .serviceLines {
    .ant-popover-content {
      @media all and (max-width: ${maxWidth}) , ${retina} {
        width: 335px !important;
      }
      .grid-checkbox-wrapper {
        max-height: 310px;
        ${scrollbar};
      }
    }
  }
  &.reason-drawer {
    z-index: 5;
    .ant-drawer-body {
      overflow: hidden;
      .unique-reason-class {
        .infinite-scroll-component__outerdiv {
          height: 100%;
        }
      }
      .data-append-wrapper {
        padding-bottom: 50px;
        height: calc(100% - 150px) !important;
        @media all and (max-width: ${maxWidth}) , ${retina} {
         height: calc(100% - 110px) !important;
        }
        ${scrollbar};
      }
    }
    .reason-drawer-close {
      position: absolute;
      right: 20px;
    }
    .add-note{
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 50px;
      padding: 15px 35px 15px 30px;
      margin-top: 10px;
      @media all and (max-width: ${minWidthApp}) {
        display: none;
      }
      .add-note-input {
        width: 100%;
        margin-left: 12px;
        font-style: italic;
        padding: 5px 10px 5px 20px;
        border-radius: 5px;
        border: 1px solid #E4E4E4;
        font-family: ${primaryFont};
        font-size: 13px;
        line-height: 18px;
        font-weight: ${wLight};
        color: #E4E4E4;
      }
    }
    .person-dropdown {
      @media all and (max-width: ${smMobile}) {
        max-height: 165px;
      }
      .image {
        .person-without-holo {
          @media all and (max-width: ${smMobile}) {
              width: 25px;
              height: 25px;
              min-width: 25px;
              padding: 1px !important;
          }
        }
      }
      .content {
        @media all and (max-width: ${smMobile}) {
          .name, .designation, .company {
            font-size: 10px;
          }
        }
      }
    }
  }
  .layout-controller {
    margin-left: 10px;
    .tabs-item {
      &.disable {
        background-color: rgb(249, 248, 246);
        opacity: 0.7;
      }
        padding: 0px 10px;
        height: 30px;
        @media all and (max-width: ${maxTablet}) {
         height: 25px; 
        }
        &:nth-child(1){
          margin-right: 0px;
          border-radius: 8px 0px 0px 8px !important;
        }
        &:nth-child(2){
          border-radius: 0px 8px 8px 0px !important;
        }
    }
    svg {
      width: 22px;
      min-width: 22px;
      height: 27px;
      @media all and (max-width: ${maxTablet}) {
        height: 23px;
        width: 18px;
        min-width: 18px;
      }
    }
  }
  .resolution-fix {
    .skeleton-box {
      margin-right: 10px;
    }
    &.top-people {
      .skeleton-box {
        @media all and (max-width: ${minWidthApp}) {
         width: 65px;
        min-width: 65px;
        height: 65px !important;
        }
      }
    }
    &.top-companies {
      .skeleton-box {
        @media all and (max-width: ${minWidthApp}) {
          width: 110px;
          min-width: 110px;
          height: 70px !important;
        }
      }
    }
    &.engagement-trigger-fix {
      .skeleton-box {
        @media all and (max-width: ${maxWidth1300}) { 
          min-width: 160px;
          width: 160px;
          height: 38px !important;
        }
      }
    }
    &.engagement-text {
      .skeleton-box {
        @media all and (max-width: ${maxWidth1300}){ 
          min-width: 100px;
          width: 100px;
          height: 20px !important;
        }
      }
    }
    &.workbenchStats-company {
      .skeleton-box {
        @media all and (max-width: ${maxWidth1300}){ 
          min-width: 100px;
          width: 100px;
          height: 90px !important;
        }
      }
    }
    &.navbarFix {
      .skeleton-box {
        @media all and (max-width: ${maxWidth1300}){ 
          min-width: 400px;
          width: 400px;
          height: 28px !important;
        }
      }
    }
    &.digest-skelton-fix {
      .skeleton-box {
        @media all and (max-width: ${maxWidth1300}){ 
          min-width: 180px;
          width: 180px;
          height: 150px !important;
        }
      }
    }
    &.pulse-fix {
      .skeleton-box {
        @media all and (max-width: ${maxWidth1300}){ 
          width: 570px;
          min-width: 570px;
          height: 22px !important;
        }
        @media all and (max-width: ${minWidthApp}) {
          width: 530px;
          min-width: 530px;
          height: 18px !important;
        }
         @media all and (max-width: ${maxTablet}) {
          width: 100%;
          min-width: 100%;
          height: 18px !important;
        }
      }
    }
    &.pulse-sales-trigger {
      .skeleton-box {
        @media all and (max-width: ${minWidthApp}) {
          width: 80px;
          min-width: 80px;
          height: 18px !important;
        }
      }
    }
    &.pulse-board-tabs {
      .skeleton-box {
        @media all and (max-width: ${maxWidth1300}){ 
          width: 70px;
          min-width: 70px;
          height: 35px !important;
        }
        @media all and (max-width: ${minWidthApp}) {
          width: 50px;
          min-width: 50px;
          height: 30px !important;
        }
      }
    }
    &.for-comapny {
      .skeleton-box {
        @media all and (max-width: ${maxWidth1300}){ 
          width: 110px;
          min-width: 110px;
        }
      }
    }
    &.for-people {
      .skeleton-box {
        @media all and (max-width: ${maxWidth1300}){
          width: 70px;
          min-width: 70px;
          height: 70px !important;
        }
      }
    }
    &.global-button-fix {
      @media all and (max-width: ${minWidthApp}) {
        .resultRow {
          .button-column {
            button {
              width: 540px;
              display: inline-block !important;
              @media all and (max-width: ${maxTablet}) {
                width: 90%;
              }
            }
          }
        }
      }
    }
  }
  .tabs-wrapper {
    &.resolution-filter-popover {
      @media all and (max-width: ${minWidthApp}) {
        margin: 0px 0px 0px 10px;
      }
      @media all and (max-width: ${maxTablet}) {
        margin: 0px 0px 0px 10px;
      }
      .tabs-item {
          @media all and (max-width: ${maxTablet}){
            background-color: #fff;
            border: 1px solid #DADADA;
            margin-left: 0px;
            width: 30px;
            height: 24px;
            padding-left: 3px;
            padding-top: 2px;
            svg {
              min-width: 14px;
            }
          }
        }
    }
  }

  .fc-widget-open{
    .content-popover-c {
      display: none;
    }
  }
  
  .ant-scrolling-effect, .body-drawer-toggle {
    .freshDesk-Bot, .fc-widget-small, .fc_push_frame, #onesignal-bell-container, .freshDeskHandle, .content-popover-c {
      display: none;
    }
    .container-heading-div {
      z-index: 5 !important;
    }
  }
  .ant-scrolling-effect, .body-drawer-alerts-toggle {
    .freshDesk-Bot, .fc-widget-small, .fc_push_frame, #onesignal-bell-container, .freshDeskHandle, .content-popover-c {
      display: none;
    }
    .container-heading-div {
      z-index: 5 !important;
    }
  }
  .ant-scrolling-effect, .body-drawer-toggle-account-plan {
    .freshDesk-Bot, .fc-widget-small, .fc_push_frame, #onesignal-bell-container, .freshDeskHandle, .content-popover-c {
      display: none;
    }
    .chatbot-container {
      position: relative;
      z-index: 1;
    }
    .container-heading-div {
      z-index: 5 !important;
    }
  }
  .ant-scrolling-effect, .body-document-accordion {
    overflow-y: auto;
    width: 100%;
    .ant-layout {
      .ant-layout-sider {
        z-index: 4;
      }
      .content-popover-c {
        z-index: 2;
      }
    }
    .container {
      .sub-container-document-hub {
        padding-bottom: 90px;
      }
    }
  }
  .ant-scrolling-effect, .body-drawer-toggle-certification {
    .freshDesk-Bot, .fc-widget-small, .fc_push_frame, #onesignal-bell-container, .freshDeskHandle, .content-popover-c {
      display: none;
    }
    .container-heading-div {
      z-index: 5 !important;
    }
  }  

  .scroll-area-for-drawer {
    margin:-15px 13px 0 30px !important;
    padding-right: 0px;
    height: calc(100vh - 245px) !important;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      margin: 10px 17px 0 25px;
      height: calc(100vh - 230px) !important;
    }
    @media all and (max-width: ${maxTablet}) {
      margin: 20px 10px 0 10px !important;
    }
    ${scrollbar};
    &.infinite-scroll-component  {
      padding: 10px;
    }
    &.no-people {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }


  .infinite-scroll-group-browse {
    margin:0 17px 0 30px;
    padding-right: 10px;
    height: calc(100vh - 300px) !important;
    ${scrollbar};
    @media all and (max-width: ${maxWidth}), ${retina} {
      margin: 0 17px 0 30px;
      height: calc(100vh - 250px) !important;
    }
    @media all and (max-width: ${maxMobile}) {
      height: calc(100vh - 200px) !important;
      margin: 0 10px;
    }
    &.shareFooter {
      height: calc(100vh - 385px) !important;
      @media all and (max-width: ${maxWidth}), ${retina} {
        margin: 0 17px 0 30px;
        height: calc(100vh - 305px) !important;
      }
    }
    &.footerUnable {
      @media all and (max-width: ${maxTablet}) {
        height: calc(100vh - 365px) !important;
      }
      @media all and (max-width: ${maxMobile}) {
        height: calc(100vh - 280px) !important;
      }
    }
    &.noGroups {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  .placement-logic {
    .fancyBox {
      background: transparent;
    }
  }
  .detailPage {
    height: unset !important;
  }
  .noWayToClose{
    .way-no-close {
      .ant-modal-close, .ant-modal-footer {
        display: none !important;
      }
      .ant-modal-body {
        padding: 0px !important;
      }
      .ant-modal-body-custom {
        padding: 50px 0px 70px 0px !important;
        .text_logout_popup {
          margin-bottom: 0px;
          font-size: 16px;
        }
        .mail_link {
          font-size: 16px;
        }
        .email-trigger-btn {
          border-radius: 25px;
          font-size: 16px;
          font-weight: ${wMBold};
          font-family: ${primaryFont};
          width: 115px;
          text-align: center;
          height: auto;
          display: block;
          margin: 15px auto 0px auto;
          padding: 0px 15px !important;
          background-color: ${primaryColor};
          border-color: ${primaryColor};
          color: #fff;
        }
      }
    }
  }

  .target-here-modal {
    .ant-modal-mask, .ant-modal-wrap {
      left: 85px;
    }
  }

  .welcome-tour-popup {
    .ant-modal-close,.ant-modal-footer {
      display: none;
    }
    .ant-modal-content  {
      .ant-modal-body {
        padding: 0px;
        padding-top: 0px;
      }
    }
   
  }

  .certification-completion-popup {
    z-index: 1999;
    .ant-modal-footer {
      display: none;
    }
    .ant-modal-content  {
      .ant-modal-body {
        padding: 57px 20px 55px 20px;
        text-align: center;
        .input {
          width: 50%;
          font-family: ${secondaryFont};
          font-size: 12px;
          border: none;
          border-bottom: 1px solid ${primaryColor};
          padding: 0px 55px 5px 5px;
        }
        .certification-title {
          font-family: ${secondaryFont};
          font-weight: ${wMBold};
          font-size: 32px;
          line-height: 40px;
          letter-spacing: -0.8px;
          color: #424B50;
          margin-top: 10px;
        }
        .certification-description {
          &.name {
            font-size: 12px;
            line-height: 15px;
            margin: 0px;
            font-weight: ${wMedium};
            &.edit {
              color: ${primaryColor};
              margin-left: 3px;
              border-bottom: 1px solid ${primaryColor};
              cursor: pointer;
            }
          }
          &.task-desc {
            font-weight: ${wMedium};
            font-size: 16px;
            line-height: 20px;
            font-family: ${primaryFont};
            margin-bottom: 5px;
          }
          font-family: ${secondaryFont};
          font-weight: ${wMBold};
          font-size: 18px;
          line-height: 15px;
          letter-spacing: -0.36px;
          color: #979797;
          margin: 0px;
        }
        .certification-earned-points {
          font-family: ${secondaryFont};
          font-weight: ${wMedium};
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.36px;
          color: #424B50;
          margin-bottom: 22px;
          margin-top: 30px;
          &.showPoints {
            margin-top: 0px;
            margin-bottom: 40px;
          }
          &.show-text {
            color: #7E7E7E;  
            margin-bottom: 0px;
            margin-top: 0px;
          }
        }
        .certification-checkbox-div {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 12px;
          .ant-checkbox-wrapper {
            width: 20px;
            margin-bottom: 0px;
            .ant-checkbox {
              .ant-checkbox-inner {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
   
  }

  .certification-collapse {
    &.main-collapse > {
      .ant-collapse-item {
         padding: 0px 20px;
        &.ant-collapse-item-active {
          padding: 5px 20px 5px 20px;
          ~ {
            .ant-collapse-item {
              .ant-collapse-header {
                border-top: none;
                border-bottom: 1px solid #DADADA;
              }
              &:last-child {
                .ant-collapse-header {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
      .ant-collapse-header {
        padding: 10px 20px 10px 30px;
      }
    }
    &.sub-collapse {
      &.certificationSubCollapse {
        .footer-sub-collapse {
          margin-left: 14px;
        }
      }
      margin-top: 0px;
      padding: 0px 0px 0px 12px;
      .ant-collapse-content {
        >.ant-collapse-content-box {
          padding: 0px;
        }
        svg {
          margin-right: 0px;
        }
      }
      .ant-collapse-item-active {
        padding: 0px;
        .ant-collapse-header {
          border-bottom: none;
          padding: 10px 0px 0px 0px;
          .ant-collapse-header-text {
            color:#424B50 !important;
          }
        }
        .ant-collapse-content-active {
          border-bottom: 1px solid #DADADA;
          padding-bottom: 16px;
        }
      }
      .ant-collapse-item {
        .ant-collapse-header {
          .ant-collapse-header-text {
            color:#67696A;
          }
        }
        &:last-child {
          .ant-collapse-header {
            border-bottom: none;
          }
          .ant-collapse-content-active {
            border-bottom: none;
            padding-bottom: 10px;
          }
        }
        .ant-collapse-content-box {
          padding: 0px 0px 10px 0px;
        }
        .ant-collapse-expand-icon {
          margin: 0px -10px 0px 0px;
          svg {
            fill:#979797;
            min-width: 8px;
            width: 8px;
          }
        }
      }
    }
    margin-top: 20px;
    padding: 10px 20px 10px 20px;
    .ant-collapse-content {
        >.ant-collapse-content-box {
          padding: 0px;
          padding-top: 0px !important;
          padding-bottom: 0px !important;
        }
      }
    .ant-collapse-item-active {
      background: #F7F7F7; 
      border: 1px solid #F7F7F7;
      border-radius: 8px !important;
      .ant-collapse-header {
        border-bottom: 1px solid #DADADA;
        border-top: none !important;
      }
      }
    .ant-collapse-item {
      &:nth-child(1) {
        .ant-collapse-header {
          border-top: none;
        }
      }
      .ant-collapse-header {
        display: flex;
        flex-direction: row-reverse;
        padding: 10px 0px;
        border-top: 1px solid #DADADA;
        transition: auto;
        .ant-collapse-header-text {
          font-family: ${primaryFont};
          font-weight: ${wMedium};
          font-size: 14px;
          line-height: 20px;
          color: #424B50;
          letter-spacing: -0.36px;
          &.header-text-gray {
            font-weight: ${wLight};
            font-size: 12px;
            color: #67696A;
            margin-top: 3px;
            svg {
              fill: #67696A;
              width: 10px;
              height: 10px;
            }
          }
        }
      }
      .ant-collapse-expand-icon {
          margin: 10px -10px 0px 0px;
        svg {
          fill:#979797;
          min-width: 12px;
        }
      }
    }       
  }

  .certification-collapse-bottom {
    box-shadow: 0px 0px 24px rgb(0 0 0 / 50%); //please dont use variable on it
    position: fixed;
    bottom: 0;
    background-color: #fff;
    width: 80%;
    display: flex;
    align-self: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    z-index: 6;
    &.certification-collapse-width {
      width: 50%;
      left: 50%;
      transform: translateX(-68%);
      @media all and (max-width: ${maxWidth}), ${retina} {
       left: 50%;
       transform: translateX(-77%);
      }  
    }
    .cross-circle-accordion {
      cursor: pointer;
      position: absolute;
      right: -15px;
      top: -15px;
      svg {
        height: 15px;
        width: 15px;
        circle {
          fill:#C9C9C9;
        }
        path {
          stroke: #838383;
        }
      } 
    }
     .ant-collapse-item-active {
        .ant-collapse-header {
          border: none;
          padding: 7px 10px 0px 10px;
          .ant-collapse-header-text {
            .accordion-header {
              margin-left: 12px;
              .header-image {
                position: absolute;
                height: 43px !important;
                width: 48px !important;
                padding: 7px;
                border-radius: 22px;
                top: -5px;
                left: 6px;
                background-color: #fff;
              }
            }
          }
          .ant-collapse-header-text { 
            padding: 0 0 0 40px;
          }
        }
        .ant-collapse-expand-icon {
          margin: 0px -10px 0px 0px !important;
        }
      }
     .ant-collapse-item {
        width: 100%;
      .ant-collapse-content-box {
        padding: 0px 10px 10px 10px;
        .sub-component-div {
          display: flex;
          flex-direction: column;
          padding:0 0 0 50px;
          .panel-sub-component {
            display: flex;
            justify-content: space-between;
            font-family: ${primaryFont};
            font-weight: ${wMedium};
            font-size: 14px;
            line-height: 20px;
            color: #67696A;
            margin-bottom: 10px;
            .index{
              color: #67696A;
            }
          }
          .prompt-copy-cert-cmp {
            width: calc(100% - 118px);
            margin-left: 15px;
            margin-bottom: 16px;
          }
        }
      }
      .ant-collapse-header {
        display: flex;
        flex-direction: row-reverse;
        padding: 10px;
        .ant-collapse-header-text {
          font-family: ${primaryFont};
          font-weight: ${wMedium};
          font-size: 14px;
          line-height: 20px;
          color: #424B50;
          letter-spacing: -0.36px;
          .accordion-header {
            display: flex;
            align-items: center;
            margin-right: 10px;
            justify-content: space-between;
            .header-image {
              width: 34px;
              height: 28px;
              margin-right: 10px;
            }
          }
        }
      }
      .ant-collapse-expand-icon {
        margin: 4px -10px 0px 0px;
       svg {
          fill:#979797;
        }
      }
    } 
  }

  .primary-color{
    color: ${primaryColor};
  }
  .primaryColorGlobal{
    color: ${primaryColor};
  }
  .primary-heading-color {
    color: ${primaryHeadingColor} !important;
  }

  .mobile-scroll-row {
    @media all and (max-width: ${maxMobile}){
      padding: 0 25px;
      overflow-x: scroll;
      flex-flow: unset;
      ${scrollbarTransparent};
      .mobile-scroll-col {
        flex: 0 0 134px !important;
        min-width: 134px !important;
      }
    }
  }

  .sub-primary-nav-follow {
    @media all and (max-width: ${maxMobile}){
      margin-right: auto;
      padding-right: 15px;
    }
  }

  .global-tabs-item {
    .filter-icon-dropdown {
      min-width: 18px;
    }
  }
  .tooltip-account-popover {
    left: -350px !important;
    &.discLessUser {
      display: none;
    }
    &.certificationNewToolTip {
      left: -156px !important;
      @media all and (max-width: ${maxTablet}) {
        left: -185px !important;
      }
      @media all and (max-width: ${maxMobile}) {
        left: -217px !important;
      }
      .ant-tooltip-content {
        width: 246px;
        .ant-tooltip-arrow {
          display: none;
        }
        .ant-tooltip-inner {
          padding: 12px;
          border-radius: 8px;
        }
      }
      &.discLessUser {
        display: none;
      }
    }
    &.mapProfile {
      left: -262px !important;
    }
    &.addCrossIcon {
      .ant-tooltip-content {
        .ant-tooltip-inner {
            padding: 12px 30px 12px 22px;
        }
      }
    }
    .ant-tooltip-arrow {
      right: 16px !important; 
    }
    .ant-tooltip-content {
      width: fit-content;
      .ant-tooltip-inner {
        border-radius: 15px;
        padding: 12px 22px;
        font-family: ${primaryFont};
        font-weight: ${wLight};
        color: #5E7079;
        font-size: 13px;
        line-height: 18px;
        .bold {
          font-weight: ${wMedium};
        }
      }
    }
  }
  .highlightedComponent {
    z-index: 999;
    position: relative;
    &.backgroundColorHighlighted {
      background-color: #fff;
      border-radius: 5px;
    }
    &.subComponentOverlayWhite {
      background-color: #fff;
      padding: 20px;
      border-radius: 5px;
    }
    &.downloadProfileBtn {
      background-color: #fff;
      padding: 8px;
      border-radius: 5px;
    }
    
  }
  .highlightedSubNavBar {
    z-index: 5 !important;
    background-color: #ffffff !important;
    border-radius: 12px;
  }
  .underline-text-toaster-Message {
    text-decoration: underline;
    color: #fff;
    cursor: pointer;
    :active,:hover {
      color: #fff;
    }
  }
  .loading-toaster {
    .ant-spin {
      .ant-spin-dot {
        width: 17px;
        height: 17px;
        margin-right: 10px;
        .ant-spin-dot-item {
          background-color: #fff;
        }
      }
    }
  }
  .triggers-bullet-tooltip {
    &.disabledTooltip {
      .ant-tooltip {
        max-width: 266px;
      }
    }
    &.smartHistoryTip {
      .ant-tooltip-content {
        .ant-tooltip-inner {
          font-size: 12px;
          white-space: pre;
        }
      }
    }
  &.gilroyV2Tooltip {
    .ant-tooltip {
      .ant-tooltip-content {
        .ant-tooltip-inner {
          white-space: break-spaces;
        }
      }
    }
  }
  &.gilroyV3Tooltip {
    .ant-tooltip {
      .ant-tooltip-content {
        .ant-tooltip-arrow {
          width: 20px;
          height: 20px;
        }
        .ant-tooltip-inner {
          padding: 6px 10px;
          min-height: 25px;
          max-height: 54px;
          font-size: 12px;
          line-height: 16px;
          max-width: 200px;
          word-wrap: break-word;
          white-space: break-spaces;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          display: -webkit-box;
        }
      }
    }
  }
    &.person-designation-tip {
      .ant-tooltip {
        .ant-tooltip-content {
          .ant-tooltip-inner {
            max-width: 175px;
          }
        }
      }
    }
    &.gill-roy-tip {
      z-index: 0;
      .ant-tooltip {
        padding-top: 14px !important;
        @media all and (max-width: ${maxWidth}), ${retina} {
          padding-top: 0px !important;
        }
      }
    }
    &.tooltip-with-copy-action {
      .ant-tooltip {
        max-width: 100%;
      }
      .ant-tooltip-content {
        .ant-tooltip-arrow {
          bottom: 1px;
        }
        .ant-tooltip-inner {
          padding: 8px 16px;
          width: fit-content;
          .tooltip-content-div {
            display: flex;
            align-items: center;
            .title {
              white-space: pre;
            }
            .weight-700 {
              margin-left: 5px;
            }
            .copyIcon {
              width: 24px;
              min-width: 24px;
              height: 24px; 
              margin-left: 10px;
              background-color:#fff;
              padding: 2px 4px;
              &:hover {
                background-color: rgba(30, 194, 82, 0.1);
                border-radius: 4px;
                path {
                  fill: ${primaryColor};
                }
              }
            }
          }
        }
      }
    }
    .ant-tooltip-content {
      width: fit-content;
      .ant-tooltip-inner {
        min-width: 45px;
        font-family: ${primaryFont};
        font-weight: ${wMedium};
        padding: 10px;
        color: ${primaryHeadingColor};
        border-radius: 8px;
      }
    }
    &.button-tooltip {
      white-space: nowrap;
      .ant-tooltip{
        top: 40px !important;
      }
    }
    &.no-wrap{
      white-space: nowrap;
    }
  }
  .ask-gillRoy-drawer {
    .close-alerts {
      position: absolute;
      right: 20px;
    }
  }
  /*  .loader-wrap-animated {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 210px);
    z-index: 999;
      @media all and (max-width: ${maxWidth}) , ${retina}{
        height: calc(100vh - 200px);
      }
    img {
      width: 100px;
    }
  } */
  .filter-similar-people-popover {
      @media all and (max-width: ${smMobile}) {
        left: unset !important;
        right: 10px !important;
      }
    .ant-popover-content {
      .ant-popover-inner {
        border-radius: 13px;
      }
    }
  }
  .drawer-upload-document {
    &.sapUploadDrawer {
       .ant-drawer-content-wrapper {
        .ant-drawer-content {
          .ant-drawer-wrapper-body {
            .ant-drawer-body {
              padding: 26px 39px 0px;
            }
          }
        }
      }
    }
    z-index: 5;
    .ant-drawer-content-wrapper {
      .ant-drawer-content {
        .ant-drawer-wrapper-body {
          .ant-drawer-header {
            .ant-drawer-extra {
              display: flex;
              justify-content: flex-end;
            }
          }
          .ant-drawer-body {
            padding: 26px 35px 0px;
            @media all and (max-width: ${maxWidth}), ${retina} {
              padding: 0 24px;
            }
            @media all and (max-width: ${maxTablet}) {
              padding: 0 10px;
            }
            .drawer-heading {
              font-family: ${secondaryFont};
              font-size: 32px;
              font-weight: ${wMBold};
              color: ${primaryHeadingColor};
              line-height: normal;
              letter-spacing: -0.709px;
              margin-bottom: 12px;
              @media all and (max-width: ${maxWidth}), ${retina} {
                font-size: 24px;
                line-height: 28px;
              }
            }
            .upload-btn-div {
              display: flex;
              align-items: center;
              .add-file-btn {
                display: none;
              }
              .add-link-btn {
                display: none;
              }
              svg {
                width: 20px;
                height: 20px;
                @media all and (max-width: ${maxWidth}), ${retina} {
                  width: 18px;
                  height: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
  .doc-tooltip-layer {
    z-index: 3 !important;
  }
  .popover-tagging-gilroy {
    left: 0px !important;
    padding-bottom: 0px;
    &.gilroyV3TaggingPopover {}
    .ant-popover-content {
      .ant-popover-arrow {
        display: none;
      }
      .ant-popover-inner {
        border-radius: 8px;
        background: #FFF;
        box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.28);
        min-width: 323px;
        .ant-popover-title {
          @media all and (max-width: ${maxTablet}) {
            display: none;
          }
        }
        .ant-popover-inner-content {
          padding: 0px;
          width: 100%;
          height: 100%;
          max-width: 100%;
          min-height: 210px;
        }
      }
    }
  }
  .results-toggle, .not-recommended-result-toggle {
    display: none;
    &.showRecommended {
      display: block;
    }
  }
  .list-data-recommended {
    @media all and (max-width: ${maxMobile}) {
      margin-top: -20px;
    }
  }
  .intent-drawer-wrapper {
    .ant-drawer-extra  {
      width: 100%;
    }
  }
  .share-profile-dropdown {
    z-index: 1;
  }
  .share-profile-dropdown .ant-popover-inner{
    border-radius:20px;
    padding:0;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      border-radius:10px;
    }
  }

  .articlesWrapperFeed{
    margin-top: 16px;
  }

  .spotLightWrapper2.parentClassToHighPrecedence{
    .heroTilesWrapper{
      height: 100%;
      @media screen and (max-width: ${maxMobile} ){
        height: auto;
      }
      .ant-col{
        padding: 0 8px !important;
      }
    }
    .desktopListViewTilesWrapper{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .articleGridTilesWrapper{
      @media screen and (max-width: ${maxMobile} ){
        margin-bottom: 10px !important;
      } 
      .gridTile{
        height: 100%;
        max-height: 100%;
        width: 100%;
        max-width: 100%; 
        .profile-area{
          min-height: 249px;
          max-height: 249px;
          @media all and (min-width: ${minExtraLargeScreen}) {
            min-height: 428px;
            max-height: 428px;
          }
          @media all and (max-width: ${maxWidth}) , ${retina} {
            min-height: 236px; 
            max-height: 236px;
          }
          @media all and (max-width: ${minStandardScreen}) {
            min-height: 235px; 
            max-height: 235px;
          }
          @media all and (max-width: ${maxTablet}) {
            min-height: 184px; 
            max-height: 184px;
          }
          @media screen and (max-width: ${maxMobile} ){
            min-height: 293px; 
            max-height: 293px;
          }
        }        
        .text-area{
          min-height: 97px;
          @media all and (min-width: ${minExtraLargeScreen}) {
            min-height: 137px;
          }
          @media all and (max-width: ${maxWidth}) , ${retina} {
            min-height: 97px;
          }
          @media all and (max-width: ${minStandardScreen}) {
            min-height: 75px;
          }
          @media all and (max-width: ${maxTablet}) {
            min-height: 59px;
          }
          @media screen and (max-width: ${maxMobile} ){
            min-height: unset;
          }
          &::before {
            @media all and (min-width: ${minExtraLargeScreen}) {
              width: 9px;
            }
            @media all and (max-width: ${minStandardScreen}) {
              width: 6px;
            }
            @media all and (max-width: ${maxTablet}) {
              width: 4px;
            }
            @media all and (max-width: ${maxMobile}) {
              width: 4px;
            }
          }
          .heading{
            @media all and (max-width: ${maxTablet}){
              margin-bottom: 14px;
            }
          }
        }
      }
    }
    .listView{
      &:last-child{
        .tileListView{
          margin-bottom: 0;
        }
      }
      .tileListView{
        @media screen and (min-width: ${minExtraLargeScreen} ){
          height: 187px;
        }
        .profile-area,
        .videoFrameArea{
        @media screen and (min-width: ${minExtraLargeScreen} ){
            width: 155px;
            min-width: 155px;
            height: 159px;
            border-radius: 12px;
          }
        }
        .text-area{
          width: unset;
          min-width: unset;
          padding-right: 0;
          overflow: hidden;
          .heading{
            margin-bottom: 0;
            padding-right: 10px;
            &.text-truncate-one-line{
              -webkit-line-clamp: 3;
            }
          }
          .article_summary {
            display: none;
          }
        }
      }
    }
    .states-widget-primary{
      background: ${white};
      min-height: 123px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 8px;
      @media all and (min-width: ${minExtraLargeScreen}) {
        min-height: 174px;
      }
      @media all and (max-width: ${maxWidth}) , ${retina} {
        min-height: 90px;
      }
      @media all and (max-width: ${minStandardScreen}) {
        min-height: 80px;
      }
      @media all and (max-width: ${maxTablet}){
        min-height: 72px;
      }
    }
  }

  /* person news and company news search tags and workbench stats */

  .listviewTagsAndStatsWrapper.classToMakePrecedenceHigh{
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
    .workbenchStatWrapper{
      border-radius: 8px;
      background: ${white};
      box-shadow: 0px 0px 13px -2px rgba(0, 0, 0, 0.08);
      padding: 19px 16px 24px 24px;
      @media screen and (max-width: ${maxTablet}) {
        padding: 16px;
      }
      .headingText{
        color: ${primaryHeadingColor};
        font-family: ${secondaryFont};
        font-size: 20px;
        font-size: 24px;
        font-weight: ${wMBold};
        letter-spacing: -0.7px;
        margin-bottom: 13px;
        line-height: 26.6px;
        margin-bottom: 17px;
        @media screen and (max-width: ${maxTablet}) {
          font-size: 20px;
        }
        @media screen and (max-width: ${maxMobile}) {
          font-size: 16px;
          margin-bottom: 8px;
        }
      }
      .listview-wrapper{
        display: flex;
        gap: 10px;
        flex-direction: row;
        @media screen and (max-width: ${maxTablet}) {
          align-items: center;
        }
        @media screen and (max-width: ${maxMobile}) {
          flex-direction: column;
          align-items: unset;
        }
        .activity_wrapper{
          gap: 8px;
          @media screen and (max-width: ${maxTablet}) {
            flex-wrap: nowrap;
            flex: 1;
          }
          @media screen and (max-width: ${midMobile}) {
            flex-wrap: wrap;
          }
          .data-entity{
            margin: 0;
            @media screen and (max-width: ${maxTablet}) {
              min-width: unset;
              max-width: unset;
              width: unset;
            }
            @media screen and (max-width: ${midMobile}) {
              min-width:calc(50% - 4px);
              max-width: calc(50% - 4px);
            }
          }
        }
        .analyze_button{
          min-width: 241px;
          width: 241px;
          @media screen and (max-width: ${maxMobile}) {
            width: 100%;
          }
          button{
            border-radius: 100px;
            color: ${white};
            font-family: ${primaryFont};
            font-size: 16px;
            font-style: normal;
            font-weight: ${wMedium};
            line-height: 20px;
            letter-spacing: -0.4px;
            display: flex;
            align-items: center;
            gap: 9px;
            height: 38px;
            @media screen and (max-width: ${maxTablet}) {
              padding: 4px 18px;
            }
            @media screen and (max-width: ${maxMobile}) {
              width: 100%;
              justify-content: center;
              font-size: 14px;
            }
            @media screen and (max-width: ${smMobile}) {
              font-size: 12px;
            }        
            svg {
              min-width: 17px;
              min-height: 20px;
              height: 20px;
              margin: 0;
                @media screen and (max-width: ${maxMobile}) {
                min-width: 14px;
              }
            }
          }
        }
      }
    }
    .intentDataWrapper{
      .listViewIntentData{
        margin-bottom: 0;
      }
      .heading{
        color: ${primaryHeadingColor};
        font-family: ${secondaryFont};
        font-size: 20px;
        font-size: 24px;
        font-weight: ${wMBold};
        letter-spacing: -0.7px;
        margin-bottom: 13px;
        line-height: 26.6px;
        margin-bottom: 24px;
        @media screen and (max-width: ${maxTablet}) {
          font-size: 20px;
        }
        @media screen and (max-width: ${maxMobile}) {
          font-size: 16px;
          margin-bottom: 8px;
        }
      }
      .smallBtnText{
        padding-right: 24px;
        font-size: 16px;
        font-weight: ${wLight};
      }
    }
  }
  .context-menu-panel {
    border: unset;
    background-color: unset;
    .context-menu-button {
        &.disabled {
          opacity:0.5;
          pointer-events:none;
        }
        &:hover {
          border: 0.8px solid ${primaryColor};
          color: ${primaryColor};
          background: rgba(222, 246, 230, 1);
        }
        &.divide {
          display: none;
        }
        width: 101px;
        height: 31px;
        border-radius: 6px;
        border: 0.8px solid #979797;
        background: #FFF;
        margin-bottom: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      .menu-item-label {
        cursor: pointer;
        color: #000;
        text-align: center;
        font-family: ${primaryFont};
        font-size: 12px;
        font-weight: ${wMedium};
        letter-spacing: -0.311px;
      }
    }
  }
  .samsung-disclaimer-div {
    &.nutanixUser {
      padding: 12px 134px 6px 17px;
      @media all and (max-width: ${maxWidth}), ${retina} {
        bottom: 0px;
        padding: 13px 120px 13px 17px;
      }
       @media all and (max-width: 1134px) and (min-width: 1121px) {
        padding: 6px 120px 6px 17px;
      }
      @media all and (max-width: 1054px) {
        padding: 6px 120px 6px 17px;
      }
      .InfoIconTwoRed, .disclaimer-text {
        path {
          fill: #7E7E7E;
        }
        color: #7E7E7E;
      }
      &.gilroyV3Disclaimer {
        padding: 14px 130px 12px 17px;
        @media all and (max-width: 1192px) {
          padding: 6px 130px 6px 17px;
        }
      }
    }
    &.gilroyV3Disclaimer {
      padding: 6px 130px 6px 17px;
      bottom: 0px;
      .disclaimer-text {
        br {
          display: none;
        }
      }
    }
    display: flex;
    padding: 6px 134px 6px 17px;
    position: absolute;
    border-top: 0.4px solid #BFBFBF;
    width: 100%;
    left: 0px;
    @media all and (max-width: ${maxWidth}), ${retina} {
      bottom: 0px;
      padding: 6px 120px 8px 17px;
    }
    @media all and (max-width: ${maxTablet}) {
      padding: 6px 17px 6px 17px;
    }
    .InfoIconTwoRed {
      width: 10px;
      min-width: 10px;
      height: 10px;
      margin-right: 4px;
      margin-top: 2px;
    }
    .disclaimer-text {
      color: #CF1322;
      font-family: ${primaryFont};
      font-size: 12px;
      font-weight: ${wLight};
      line-height: 14px;
      letter-spacing: -0.311px;
      width: calc(100% - 14px);
      max-width: calc(100% - 14px);
      @media all and (max-width: ${minStandardScreen}) {
        font-size: 10px;
      }
    }
  }
  .ant-menu-submenu-popup {
    .ant-menu-sub {
      border: 1px solid #DADADA;
      border-radius: 0px;
      .ant-menu-item {
        &.ant-menu-item-selected {
          background-color: #fff;
          color: ${primaryColor};
          svg {
            path {
              fill: ${primaryColor};
            }
          }
          .ant-menu-title-content {
            color: ${primaryColor};
          }
        }
        &.ant-menu-item-active {
          color: ${primaryColor};
          background-color: #fff;
          svg {
            path {
              fill: ${primaryColor};
            }
          }
          .ant-menu-title-content {
            color: ${primaryColor};
          }
        }
        .ant-menu-title-content {
          margin-left: 6px;
          color: ${greyColor};
          font-family: ${primaryFont};
          font-size: 16px;
          font-weight: ${wMedium};
          line-height: 20px;
          letter-spacing: -0.4px;
          @media all and (max-width: ${maxWidth}), ${retina} {
            font-size: 14px;
          }
        }
      }
    }
  }
  .ipPopup {
    .ant-modal-body{
      height: 539px;
      padding: 26px 40px !important;
      .header {
        .title {
          font-size: 32px !important;
          line-height: 42px !important;
        }
      }
      .body {
        .text {
          font-size: 20px !important;
          line-height: 28px !important;
        }
      }
    }
  }
`;
export default GeneralStyle;
