/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, {
  useEffect, useState,
} from 'react';
import styled from 'styled-components';
import {
  Checkbox,
  Modal,
} from 'antd';
import classNames from 'classnames';
import {
  primaryColor,
  primaryFont, primaryHeadingColor,
  secondaryFont,
  wLight, wMBold,
  wMLight,
  wMedium,
  white,
} from 'Utils/utilsStyle';
import { CompliancePopupContent } from 'Types/LoginTypes';
import { useDispatch, useSelector } from 'react-redux';
import { selectCompliancePopup } from 'Selector/LoginSelector';
import { actions as loginActions } from 'Slice/LoginSlice';
import { actions as globalActions } from 'Slice/GlobalSearchContentSlice';
import { selectComplianceAckCallSuccess, selectComplianceAckCalling } from 'Selector/GlobalSearchContentSelector';
import { translateSkipAreas } from 'Utils/Constants';
import LockCloseIcon from '../assets/svgIcons/LockCloseIcon';
import GlobalButton from './GlobalButton';

const UserComplianceGuideModal = styled(Modal)`
  width: calc(100% - 40px) !important;
  max-width: 702px;
  .ant-modal-content{
    padding: 26px 40px 42px 41px;
    border-radius: 10px !important;
  }
  .ant-modal-header{
    border: none;
    border-radius: 0;
    padding: 0;
  }
  .ant-modal-body{
    margin-top: 18px;
    border: 1px solid #D4D4D4;
    border-radius: 10px;
    padding: 12px 8px 12px 16px !important;
    .textContentBox{
      overflow: auto;
      padding-right: 24px;
      max-height: 406px;
      &::-webkit-scrollbar {
        width: 5px;
        border-radius:8px;
      }
      &::-webkit-scrollbar-thumb {
        background: #979797;
        border-radius: 12px;
      }
      &::-webkit-scrollbar-track {
        background: #D9D9D9;
        border-radius: 12px;
      }
      .guidlineItem{
        display: flex;
        .srNum,
        .text{
          color: ${primaryHeadingColor};
          font-family: ${primaryFont};
          font-size: 16px;
          font-style: normal;
          font-weight: ${wLight};
          line-height: 24px;
          letter-spacing: -0.536px;
        }
        .srNum{
          margin-right: 6px;
        }
      }
      .questionItem{
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        .title,
        .srNum,
        .text{
          color: ${primaryHeadingColor};
          font-family: ${primaryFont};
          font-size: 16px;
          font-style: normal;
          font-weight: ${wLight};
          line-height: 24px;
          letter-spacing: -0.536px;
        }
        .title{
          font-weight: ${wMLight};
        }
        .descBox{
          display: flex;
          padding-left: 4px;
          .srNum{
            margin-right: 6px;
          }
        }
      }
      .prohibtedItemsBox{
        border: 1px solid #D4D4D4;
        border-radius: 10px;
        max-width: 550px;
        margin: 12px 0 12px auto;
        display: flex;
        flex-direction: column;
        .header{
          border-bottom: 1px solid #D4D4D4;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          .heading{
            color: ${primaryHeadingColor};
            font-family: ${primaryFont};
            font-size: 16px;
            font-style: normal;
            font-weight: ${wMBold};
            line-height: 26px;
            letter-spacing: -0.536px;
          }
        }
        .content{
          padding: 10px 18px;
          display: flex;
          flex-direction: column;
          .prohibtedItem{
            &:not(:last-child){
              margin-bottom: 4px ;
            }
            .prohibtedDesc,
            .srNumCircle{
              color: ${primaryHeadingColor};
              font-family: ${primaryFont};
              font-size: 16px;
              font-style: normal;
              font-weight: ${wLight};
              line-height: 20px;
              letter-spacing: -0.536px;
            }
            .srNumCircle{
              border: 1px solid ${primaryHeadingColor};
              min-height: 14px;
              min-width: 14px;
              border-radius: 50%;
              margin-right: 4px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              line-height: 10px;
              font-size: 10px;
              transform: translateY(-2px);
            }
          }
        }
      }
      .ant-checkbox-wrapper{
        margin-bottom: 0;
        margin-top: 16px;
        display: flex;
        align-items: center;
        width: max-content;
        .ant-checkbox-checked::after{
          animation: none;
        }
        .ant-checkbox-inner{
          height: 24px;
          width: 24px;

        }
        .ant-checkbox + span{
          color: ${primaryHeadingColor};
          font-family: ${primaryFont};
          font-size: 16px;
          font-style: normal;
          font-weight: ${wMedium};
          line-height: 18px;
          letter-spacing: -0.6px;
          margin-top: 6px;
        }
      }
    }
  }
  .ant-modal-footer{
    padding: 0 !important;
    margin-top: 16px;
    button.submit_btn.forHightPrecedence{
      display: flex;
      justify-content: center;
      background-color: ${primaryColor};
      color: ${white};
      margin: 0;
      border-radius: 100px;
      padding: 0px;
      height: 41px;
      line-height: normal;
      font-family: ${primaryFont};
      font-size: 18px;
      width: 100%;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .title{
    color: ${primaryHeadingColor};
    font-family: ${secondaryFont};
    font-size: 32px;
    font-style: normal;
    font-weight: ${wMBold};
    line-height: normal;
    letter-spacing: -1.672px;
  }
`;

const HeaderHtml = function HeaderHtml({ text }: { text: string }) {
  return (
    <Header>
      <LockCloseIcon />
      <div className={classNames('title', `${translateSkipAreas}`)}>{text}</div>
    </Header>
  );
};

const UserCompliancePopup = function UserCompliancePopup() {
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(true);
  const { visible, content } = useSelector(selectCompliancePopup);
  const isCalling = useSelector(selectComplianceAckCalling);
  const isSuccess = useSelector(selectComplianceAckCallSuccess);
  const {
    prohibitedItemsForInput, questions, guidelines, complianceId, header, button, checkbox,
  } = content || {};
  const handleClick = () => {
    dispatch(globalActions.complianceAckCalling({ complianceId }));
  };

  useEffect(() => {
    if (isSuccess) {
      localStorage.removeItem('userCompliancePopup');
      dispatch(loginActions.setCompliancePopup({
        visible: false,
        content: {} as CompliancePopupContent,
      }));
    }
  }, [isSuccess]);

  return (
    <UserComplianceGuideModal
      centered
      closable={false}
      open={visible && !isSuccess}
      wrapClassName={classNames('userComplianceModal', 'userComplianceModalWrapper')}
      title={<HeaderHtml text={header} />}
      onOk={() => { }}
      maskStyle={{
        backdropFilter: 'blur(10px)',
      }}
      footer={[
        <GlobalButton
          buttonClassName={classNames('submit_btn forHightPrecedence', `${translateSkipAreas}`)}
          title={button}
          disabled={disable || isCalling}
          onButtonClick={handleClick}
        />,
      ]}
    >
      <div className="textContentBox">
        {visible && guidelines?.length > 0
          && guidelines.map((guideline, index) => (
            <>
              <div key={`${index}a`} className={classNames('guidlineItem', `${translateSkipAreas}`)}>
                <span className="srNum">
                  {index + 1}
                  .
                </span>
                <span className="text">{guideline}</span>
              </div>
              {index === 2 && prohibitedItemsForInput?.desc.length > 0 && (
                <div className="prohibtedItemsBox">
                  <div className="header">
                    <span className={classNames('heading', `${translateSkipAreas}`)}>{prohibitedItemsForInput.title}</span>
                  </div>
                  <div className="content">
                    {prohibitedItemsForInput?.desc.map((prohibtedItem, i) => (
                      <div key={`${i}a`} className={classNames('prohibtedItem', `${translateSkipAreas}`)}>
                        <span className="srNumCircle">{i + 1}</span>
                        <span className="prohibtedDesc">{prohibtedItem}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          ))}

        {visible && questions && questions.length > 0
          && questions.map((question, index) => (
            <div key={`${index}a`} className={classNames('questionItem', `${translateSkipAreas}`)}>
              <span className={classNames('title', `${translateSkipAreas}`)}>{question.title}</span>
              {question?.desc.map((item, j) => (
                <div key={`${j}a`} className="descBox">
                  <span className="srNum">
                    {j + 1}
                    .
                  </span>
                  <span className="text">{item}</span>
                </div>
              ))}
            </div>
          ))}
        <Checkbox className={classNames('checkbox-wrap', `${translateSkipAreas}`)} onChange={(e) => { setDisable(!e.target.checked); }}>
          {checkbox}
        </Checkbox>
      </div>
    </UserComplianceGuideModal>
  );
};

export default UserCompliancePopup;
