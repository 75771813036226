import React from 'react';

type Props = {
  color?: string;
} & typeof defaultProps;

const defaultProps = {
  color: '#000000',
};

const IndustriesSvg = function IndustriesSvg(props: Props) {
  const { color } = props;
  return (
    <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Dev-Ready-prototype" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M17.7272727,0 L17.7272727,6.46306818 L14.1818182,8.56818182 L14.1818182,4.98579545 L12.4090909,6.09375 L8.27272727,8.56818182 L8.27272727,4.98579545 L6.5,6.09375 L0.590909091,9.63920455 L0,9.97159091 L0,26 L26,26 L26,0 L17.7272727,0 Z M20.0909091,2.36363636 L23.6363636,2.36363636 L23.6363636,23.6363636 L2.36363636,23.6363636 L2.36363636,11.2642045 L5.90909091,9.15909091 L5.90909091,12.7414773 L7.68181818,11.6335227 L11.8181818,9.15909091 L11.8181818,12.7414773 L13.5909091,11.6335227 L19.5,8.08806818 L20.0909091,7.75568182 L20.0909091,2.36363636 Z M4.72727273,14.1818182 L4.72727273,16.5454545 L7.09090909,16.5454545 L7.09090909,14.1818182 L4.72727273,14.1818182 Z M9.45454545,14.1818182 L9.45454545,16.5454545 L11.8181818,16.5454545 L11.8181818,14.1818182 L9.45454545,14.1818182 Z M14.1818182,14.1818182 L14.1818182,16.5454545 L16.5454545,16.5454545 L16.5454545,14.1818182 L14.1818182,14.1818182 Z M18.9090909,14.1818182 L18.9090909,16.5454545 L21.2727273,16.5454545 L21.2727273,14.1818182 L18.9090909,14.1818182 Z M4.72727273,18.9090909 L4.72727273,21.2727273 L7.09090909,21.2727273 L7.09090909,18.9090909 L4.72727273,18.9090909 Z M9.45454545,18.9090909 L9.45454545,21.2727273 L11.8181818,21.2727273 L11.8181818,18.9090909 L9.45454545,18.9090909 Z M14.1818182,18.9090909 L14.1818182,21.2727273 L16.5454545,21.2727273 L16.5454545,18.9090909 L14.1818182,18.9090909 Z M18.9090909,18.9090909 L18.9090909,21.2727273 L21.2727273,21.2727273 L21.2727273,18.9090909 L18.9090909,18.9090909 Z" id="Shape-Copy" fill={color} fillRule="nonzero" />
      </g>
    </svg>
  );
};

IndustriesSvg.defaultProps = defaultProps;
export default IndustriesSvg;
