import React, { useEffect, useState } from 'react';
import {
  Collapse,
} from 'antd';
import SRISvg from 'Components/layout/assets/SRISvg';
import styled from 'styled-components';
import {
  playTourVideo,
  primaryColor,
  primaryFont, wMedium,
} from 'Utils/utilsStyle';
import {
  CaretDownOutlined,
  DownOutlined,
} from '@ant-design/icons';

import CheckedIcon from 'Components/alerts/assets/svg/CheckedIcon';
import {
  CertificationData, CertificationTask,
} from 'Types/CertificationTypes';
import { useNavigate } from 'react-router-dom';
import { getPageUrl, valueToObject } from 'Utils/UtilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'Slice/CertificationSlice';
import VideoPopup from 'Components/common/modal/VideoPopup';
import {
  selectModuleIndex, selectTaskIndex, selectActiveModule,
  selectAiTaskIndex, selectAiModuleIndex, selectOpenGilroyDrawer,
} from 'Selector/CertificationSelector';
import { actions as flurryActions } from 'Slice/HelperSlice';
import classNames from 'classnames';
import { actions as UiActions } from 'Slice/UISlice';
import PromptCopyCmp from './PropmtCopyCmp';

const ContentWrapper = styled.div`
  margin-top: 16px;
  padding-left: 12px;
`;

const Title = styled.p`
  font-family: ${primaryFont};
  font-weight: ${wMedium};
  font-size: 16px;
  line-height: 20px;
  color: #424B50;
  letter-spacing: -0.36px;
  margin: 0px;
  .prompt-copy-cert-cmp {
    margin-top: 8px;
    margin-left: 14px;
    width: calc(100% - 13px);
    .arrow-action-icon {
      width: 8.5px;
      height: 8.5px;
      margin-left: 33px;
    }
  }
  &.sub-component-title {
    color:#67696A;
    font-size: 14px;
    margin-bottom: 8px;
    &.taskCompletePrompt {
      .prompt-copy-cert-cmp {
        width: calc(100% + 12px);
      }
    }
  }
  &.test-yourself {
    font-size: 13px;
    color: ${primaryColor};
  }
  &.sigma-points {
    font-size: 13px;
    margin-left: 10px;
    svg {
      fill: #67696A;
      width: 10px;
      height: 10px;
    }
  }
  &.watch-video{
    font-size: 13px;
    color: ${primaryColor};
    margin-right: 6px;
  }
  &.separator-left {
    border-left: 1.5px solid #DDDDDD;
    padding: 0px 10px;
    margin: 0px 0px 0px 5px;
  }
  &.separatorLeft {
    border-left: 1.5px solid #DDDDDD;
    padding: 0px 10px;
    margin: 0px 0px 0px 5px;
  }
  &.separator-right {
    border-right: 1.5px solid #DDDDDD;
    padding-right: 10px;
    margin: 0px;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin: 0px 5px 0px 10px;
`;

const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  padding-right: 0px;
  svg {
    margin-right: 8px;
    width: 16px;
    min-width: 16px;
    margin-left: 10px;
    height: 16px;
    align-self: flex-start;
    margin-top: 3px;
  }
  &.start{
    justify-content: start;
  }
  &.space{
    justify-content: space-between;
  }
`;

const VideoDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type Props = {
  certificationData: CertificationData,
};

type CustomProps = {
  isActive: boolean | undefined,
};

export const CustomCaretDownOutlined = function CustomCaretDownOutlined(props: CustomProps) {
  const { isActive } = props;
  return (<CaretDownOutlined rotate={isActive ? 180 : 0} />);
};
export const CustomDownOutlined = function CustomDownOutlined(props: CustomProps) {
  const { isActive } = props;
  return (<DownOutlined rotate={isActive ? 180 : 0} />);
};

const CustomizedPanel = function CustomizedPanel(props: Props) {
  const { certificationData } = props;
  const {
    data, personId, companyId, groupId,
  } = certificationData;
  const [isVisible, setIsVisible] = useState(false);
  const [videoIndex, setVideoIndex] = useState(-1);
  const { Panel } = Collapse;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isGilroyDrawer = useSelector(selectOpenGilroyDrawer);
  const taskIndex = useSelector(selectTaskIndex);
  const moduleIndex = useSelector(selectModuleIndex);
  const aiTaskIndex = useSelector(selectAiTaskIndex);
  const aiModuleIndex = useSelector(selectAiModuleIndex);

  const [taskNo, setTaskNo] = useState<string | string[]>(
    isGilroyDrawer ? aiTaskIndex.toString() : taskIndex.toString(),
  );
  const [moduleNo, setModuleNo] = useState<string | string[]>(
    isGilroyDrawer ? aiModuleIndex.toString() : moduleIndex.toString(),
  );

  useEffect(() => {
    if (isGilroyDrawer) {
      dispatch(actions.setAiModuleIndex(moduleNo as string));
    } else {
      dispatch(actions.setModuleIndex(moduleNo as string));
    }
  }, [moduleNo]);

  useEffect(() => {
    if (isGilroyDrawer) {
      dispatch(actions.setAiTaskIndex(taskNo as string));
    } else {
      dispatch(actions.settTaskIndex(taskNo as string));
    }
  }, [taskNo]);

  const flurryObject = useSelector(selectActiveModule);
  useEffect(() => {
    setModuleNo(isGilroyDrawer ? aiModuleIndex.toString() : moduleIndex.toString());
    setTaskNo(isGilroyDrawer ? aiTaskIndex.toString() : taskIndex.toString());
  }, [taskIndex, moduleIndex, aiTaskIndex, aiModuleIndex, isGilroyDrawer]);

  const redirect = (task: CertificationTask) => {
    const { type } = task.events[0];
    dispatch(actions.setOpenDrawer(false));
    if (isGilroyDrawer) {
      dispatch(actions.setAiModuleIndex('-1'));
      dispatch(actions.setAiTaskIndex('-1'));
      dispatch(UiActions.isShowGilroyGlobalToggle(false));
    } else {
      dispatch(actions.setModuleIndex('-1'));
      dispatch(actions.setTaskIndex('-1'));
    }
    dispatch(actions.setActiveTask(task));
    dispatch(actions.setCurrentType(type));
    const redirection = getPageUrl({
      type, personId, companyId, groupId,
    });
    if (redirection.id) {
      dispatch(actions.setScrollToId(redirection.id));
    }
    if (redirection.page) {
      dispatch(actions.setScrollToPage(redirection.page));
    }
    dispatch(actions.setIsScroll(true));
    dispatch(actions.setToasterFlag(true));
    dispatch(actions.setShowAccordion(true));
    if (redirection.page) {
      navigate(redirection.page);
    }
  };

  const handleVideoClick = (index: number, task = '') => () => {
    setIsVisible(true);
    setVideoIndex(index);
    dispatch(flurryActions.callFlurryEvent(
      valueToObject(`certificate_watch_video_${task}`, 0, {
        ...flurryObject,
      }),
    ));
  };

  return (
    <Collapse
      className="certification-collapse main-collapse"
      expandIcon={({ isActive }) => CustomCaretDownOutlined({
        isActive,
      })}
      ghost
      accordion
      activeKey={moduleNo}
      onChange={(indexNo) => {
        setModuleNo(indexNo);
        setTaskNo('');
      }}
    >
      {
        data?.map((module, index) => (
          <Panel
            header={(
              <div className="d-flex justify-content-between align-items-center mr-1">
                <div className="d-flex flex-column">
                  <span className="ant-collapse-header-text">
                    {module.header}
                  </span>
                  <span className="ant-collapse-header-text header-text-gray">
                    <SRISvg />
                    :
                    {` ${module.score > 1 ? `${module.score}pts` : `${module.score}pt`}`}
                  </span>
                </div>
                {module.completed ? <CheckedIcon /> : null}
              </div>
            )}
            key={JSON.stringify(index)}
          >
            <Collapse
              className={classNames('certification-collapse sub-collapse', { certificationSubCollapse: isGilroyDrawer })}
              expandIcon={({ isActive }) => CustomDownOutlined({
                isActive,
              })}
              ghost
              accordion
              activeKey={index.toString() === moduleNo ? taskNo : ''}
              onChange={(indexNo) => { setTaskNo(indexNo); }}
            >
              {module.dataList && module.dataList.map((task: CertificationTask, key) => (
                <Panel
                  header={(
                    <div className="d-flex flex-column">
                      <span className="ant-collapse-header-text">
                        <TitleDiv className="space">
                          <span className="text-truncate-two-line">{task.header}</span>
                          {task.completed ? <CheckedIcon /> : null}
                        </TitleDiv>
                      </span>
                    </div>
                  )}
                  key={JSON.stringify(key)}
                >
                  <ContentWrapper key={1}>
                    {task.events.length && task.events.map((event, i) => (
                      <TitleDiv className="space">
                        <Title className={classNames('sub-component-title', { taskCompletePrompt: event.status === 1 })}>
                          <span className="text-truncate-two-line">{event.title}</span>
                          {task.hintPrompt?.length && i === task.events.length - 1 ? (
                            <PromptCopyCmp
                              eventType={event.type}
                              promptTitle={task.hintPrompt}
                              source={task?.source}
                              isDrawer
                            />
                          ) : null}
                        </Title>
                        {event.status === 1 ? <CheckedIcon /> : null}
                      </TitleDiv>
                    ))}
                    <Footer className="footer-sub-collapse">
                      <Title
                        className="test-yourself separator-right c-pointer"
                        onClick={() => {
                          dispatch(flurryActions.callFlurryEvent(
                            valueToObject(`certificate_test_yourself_${task?.header}`, 0, {
                              ...flurryObject,
                            }),
                          ));
                          redirect(task);
                        }}
                      >
                        Test yourself

                      </Title>
                      {task.walkThrough
                        ? (
                          <VideoDiv
                            onClick={handleVideoClick(key, task?.header)}
                          >
                            <Image
                              className="separator-left"
                              src={playTourVideo}
                            />
                            <Title
                              className="watch-video c-pointer"
                            >
                              Watch Video
                            </Title>
                          </VideoDiv>
                        )
                        : null}

                      <Title className={classNames('sigma-points grey-color', { separatorLeft: task.walkThrough?.length })}>
                        <SRISvg />
                        :
                        {` ${task.score > 1 ? `${task?.score} points` : `${task.score} point`}`}
                      </Title>
                    </Footer>
                    <VideoPopup
                      key={task.events[0]?.type}
                      videoLink={task.walkThrough}
                      visible={isVisible && videoIndex === key}
                      setVisible={() => setIsVisible(false)}
                    />
                  </ContentWrapper>
                </Panel>
              ))}
            </Collapse>
          </Panel>
        ))
      }
    </Collapse>
  );
};
export default CustomizedPanel;
