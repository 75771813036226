/* eslint-disable func-names */
import React, { useEffect, useState } from 'react';

interface EditTranslationProps {
  initialText: string;
  targetElement: HTMLElement;
  initWidth: number;
  initHeight: number;
  onSave: (text: string, initialText: string, targetElement: HTMLElement) => void;
  onCancel: (initialText: string, targetElement: HTMLElement) => void;
}

export const EditTranslationComponent = function (props: EditTranslationProps) {
  const {
    initialText, targetElement, initWidth, initHeight, onSave, onCancel,
  } = props;

  const [text, setText] = React.useState(initialText);
  const [width, setWidth] = useState(100);
  const [height, setHeight] = useState(30);

  const handleSave = () => {
    onSave(text, initialText, targetElement);
  };

  const handleCancel = () => {
    onCancel(initialText, targetElement);
  };

  const handleTextareaClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        onCancel(initialText, targetElement);
      }
    });

    setWidth(Math.max(width, initWidth));
    setHeight(Math.max(height, initHeight));
  }, []);

  return (
    <span>
      <textarea
        className="skiptranslate"
        style={{ width: `${width}px`, height: `${height}px` }}
        value={text}
        onClick={handleTextareaClick}
        onChange={(e) => setText(e.target.value)}
      />
      <button type="button" className="btn btn-primary skiptranslate" onClick={handleSave}>Save</button>
      <button type="button" className="btn btn-danger skiptranslate" onClick={handleCancel}>Cancel</button>
    </span>
  );
};
