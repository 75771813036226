import { Menu } from 'antd';
import styled from 'styled-components';
import {
  Link,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { GROUPDETAILS, SEARCH_ROUTE } from 'Utils/Constants';
import { selectLoading, selectRecentSearches } from 'Selector/GlobalSearchContentSelector';
import { useDispatch, useSelector } from 'react-redux';
import { Section } from 'Types/GlobalSearchContentTypes';
import { actions } from 'Slice/GlobalSearchContentSlice';
import { isSalesforce } from 'Utils/UtilityFunctions';
import {
  primaryFont,
  wMedium,
  primaryBgColor,
  primaryColor,
  primaryHeadingColor,
  maxWidth,
  retina,
  maxMobile,
  minWidthApp,
  maxTablet,
} from '../../../Utils/utilsStyle';
import SearchArrowUp from '../assets/SearchArrowUp';
import SearchAreaIcon from '../assets/SearchAreaIcon';
import SearchCrossIcon from '../assets/SearchCrossIcon';

const MenuWrapper = styled.div`
  color:red;
  .m-left-50 {
    margin-left: 50px;
    @media all and (max-width: ${maxMobile}) {
     margin-left: 20px;
    }
  }
  .recent-search-text {
    color: #979797;
    font-weight: ${wMedium};
    font-size: 12px;
    line-height: 20px;
    font-family: ${primaryFont};
    margin-top: 18px;
    margin-left: 16px;
    margin-bottom: 4px;
    letter-spacing: -0.38px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      font-size: 13px;
      line-height: 24px;
    }
    @media all and (max-width: ${maxMobile}) {
      margin-left: 10px;
    }

  }
  .disabledPill{
    pointer-events: none;
    cursor: no-drop !important;
    opacity: 0.2;
  }
  .recent-search-item {
    color: ${primaryHeadingColor};
    font-family: ${primaryFont};
    font-weight: ${wMedium};
    line-height: 20px;
    font-size: 14px;
    padding-left: 16px;
    padding-bottom: 8px;
    letter-spacing: -0.24px;
    height: auto;
    background-color: transparent !important;
    cursor: auto;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      line-height: 24px;
      font-size: 12px;
    }
    @media all and (max-width: ${maxMobile}) {
     margin-left: 10px;
    }
    .ant-dropdown-menu-title-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &:hover {
      background-color: #F6F6F6 !important;
      .action-items-wrap {
        display: block;
      }
    }
    .first-side {
      display: flex;
      align-items: center;
      width: 90%;
      @media all and (max-width: ${maxTablet}) {
        width: 80%;
      }
      &:hover {
        svg {
          path {
            fill: ${primaryColor};
          }
        }
      }
      svg {
       margin-right: 5px;
       min-width: 14px;
      }
    }
    .action-items-wrap {
      display: none;
      height: 14px;
      .first-one {
       svg {
        width: 10px;
       }
      }
      .second-one {
        margin-left: 12px;
        &:hover {
          svg {
            path {
              fill: red;
            }
          }
        }
      }
      svg {
        cursor: pointer;
        &:hover {
          path {
            fill: ${primaryColor};
          }
        }
      }
    }
    
    &:focus {
      background-color: transparent;
    }
    a {
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 400px;
      width: 100%;
      display: inline-flex;
      align-items: center;
      color: ${primaryHeadingColor};
      @media all and (max-width: ${minWidthApp}){
        max-width: 330px;
      }
      @media all and (max-width: ${maxMobile}){
        max-width: 200px;
      }
      &:hover {
        color: ${primaryColor};
      }
    }
  }
  .recent-search-item:last-child {
    margin-bottom: 18px;
  }
  .trending-topics-menu {
    background-color: ${primaryBgColor};
  }
  .padded-heading{
    padding: 5px 0px 10px 0px;
    border-radius: 6px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      padding: 5px 0px 10px 0px;
      border-radius: 6px;
    }
  }
  .ant-menu-item-divider {
    margin: 0px;
    border:none;
  }
`;

type Props = {
  getScreenNameFun: () => void;
  onDataPass: (data: string) => void;
};

export const GlobalSearchMenu = function GlobalSearchMenu(props: Props) {
  const dispatch = useDispatch();
  const { getScreenNameFun, onDataPass } = props;
  const { pathname } = useLocation();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get('groupId') || '';
  const groupIdParsed = groupId ? parseInt(groupId, 10) : 0;
  const loading: boolean = useSelector(selectLoading);
  const recentSearches: Section = useSelector(selectRecentSearches) as Section;
  const reversedSearches = recentSearches.searches ? [...recentSearches.searches].reverse() : [];
  const currentPath = location.pathname + location.search;
  // const dummyTrendingSearches: string[] = ['Metaverse', 'NFTs', 'DAOs'];

  const clickRecentSearch = (search: string) => {
    getScreenNameFun();
    dispatch(actions.updateRecentSearch(search));
  };
  const route = (search: string) => {
    let path = '';

    if (!isSalesforce()) {
      path = `${SEARCH_ROUTE}${search?.replace(/&/g, '%26')}`;
      if (pathname.includes(GROUPDETAILS) && groupId && parseInt(groupId, 10) > 0) {
        path = `${path}&groupId=${groupId}`;
      }
    } else {
      const section = searchParams.get('extend') || '';
      path = `${SEARCH_ROUTE}${search?.replace(/&/g, '%26')}&extend=${section}`;
    }
    return path;
  };
  const handleClick = (searchQuery: string) => {
    // const data = 'Hello from Child!';
    // Call the function passed from the parent with the data
    onDataPass(searchQuery);
  };

  const handleClickRemove = (search: string) => {
    dispatch(actions.removeSearchItem(search));
    dispatch(actions.deleteRecentSearch({ search }));
  }

  return (
    loading === true ? <> </>
      : (
        <MenuWrapper>
          <Menu>
            {reversedSearches?.length
              ? (
                <div className="recent-search-text m-left-50">
                  {`${recentSearches.title}`}
                </div>
              )
              : (
                <div className="recent-search-text m-left-50">
                  <p>No Recent Search found</p>
                </div>
              )}
            {reversedSearches.slice(0, 10).map((search) => (
              <Menu.Item className="recent-search-item">
                <div className='first-side'>
                  <Link
                    className="link-action-main"
                    onClick={(e) => { clickRecentSearch(search); e.stopPropagation() }}
                    to={route(search)}
                    state={!isSalesforce() ? {
                      enableBackButton: true,
                      query: search,
                      previousPath: currentPath,
                      ...(pathname.includes(GROUPDETAILS)

                        && groupIdParsed > 0 && { group_id: groupIdParsed }),
                    } : {}}
                  >
                    <SearchAreaIcon />
                    {search}
                  </Link>
                </div>
                <div className='action-items-wrap'>
                  <span className='action-item first-one'
                    onClick={(e) => { e.stopPropagation(); handleClick(search) }}>
                    <SearchArrowUp />
                  </span>
                  <span className='action-item second-one'
                    onClick={(e) => { e.stopPropagation(); handleClickRemove(search) }}
                  ><SearchCrossIcon /></span>
                </div>
              </Menu.Item>
            ))}
          </Menu>
        </MenuWrapper >
      )
  );
};
