import React from 'react';

type Props = {
  color?: string;
} & typeof defaultProps;

const defaultProps = {
  color: '#FFFFFF',
};

const ShareIcon = function ShareIcon({ color }: Props) {
  return (
    <svg className="shareIcon-svg" width="16px" height="19px" viewBox="0 0 17 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="xiQ-Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="News-Card---Big-[no-Image]" transform="translate(-536.000000, -268.000000)" fill={color} fillRule="nonzero">
          <g id="Group">
            <path d="M549.8125,275.333333 L550.875,275.333333 C552.048605,275.333333 553,276.271403 553,277.428571 L553,287.904762 C553,289.06193 552.048605,290 550.875,290 L538.125,290 C536.951395,290 536,289.06193 536,287.904762 L536,277.428571 C536,276.271403 536.951395,275.333333 538.125,275.333333 L539.1875,275.333333 C539.774303,275.333333 540.25,275.802368 540.25,276.380952 C540.25,276.959536 539.774303,277.428571 539.1875,277.428571 L538.125,277.428571 L538.125,287.904762 L550.875,287.904762 L550.875,277.428571 L549.8125,277.428571 C549.225698,277.428571 548.75,276.959536 548.75,276.380952 C548.75,275.802368 549.225698,275.333333 549.8125,275.333333 Z M543.692807,268.366364 C543.887675,268.142105 544.177007,268 544.5,268 C544.822993,268 545.112325,268.142105 545.307193,268.366364 L548.256505,271.274369 C548.671437,271.68349 548.671437,272.346805 548.256505,272.755926 C547.841573,273.165047 547.168835,273.165047 546.753903,272.755926 L545.5625,271.581209 L545.5625,281.619048 C545.5625,282.197632 545.086802,282.666667 544.5,282.666667 C543.913198,282.666667 543.4375,282.197632 543.4375,281.619048 L543.4375,271.581209 L542.246097,272.755926 C541.831165,273.165047 541.158427,273.165047 540.743495,272.755926 C540.328563,272.346805 540.328563,271.68349 540.743495,271.274369 L543.692807,268.366364 Z" id="Shape" />
          </g>
        </g>
      </g>
    </svg>
  );
};

ShareIcon.defaultProps = defaultProps;

export default ShareIcon;
