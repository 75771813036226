const defaultProps = {
  color: '#B4B4B4',
};
type Props = {
  color?: string
} & typeof defaultProps;
const LockCloseIcon = function LockCloseIcon(props: Props) {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="24" viewBox="0 0 19 24" fill="none">
      <path d="M15.9777 9.0964V6.47741C15.9777 2.89988 13.0781 0 9.50028 0C5.92247 0 3.02287 2.89961 3.02287 6.47741V9.0964C1.32152 9.31015 0 10.7632 0 12.5208V19.8616C0 21.7659 1.55023 23.3161 3.45455 23.3161H15.5455C17.4498 23.3161 19 21.7659 19 19.8616V12.5208C19 10.7622 17.6786 9.3103 15.9771 9.0964H15.9777ZM9.50028 3.02275C11.4046 3.02275 12.9548 4.57299 12.9548 6.4773V9.06605H6.04573V6.4773C6.04573 4.57299 7.59596 3.02275 9.50028 3.02275ZM10.3639 16.1672V18.7818C10.3639 19.259 9.97745 19.6454 9.50028 19.6454C9.02311 19.6454 8.63664 19.259 8.63664 18.7818V16.1672C8.12277 15.8681 7.773 15.3176 7.773 14.6796C7.773 13.7252 8.54597 12.9523 9.50028 12.9523C10.4546 12.9523 11.2275 13.7252 11.2275 14.6796C11.2275 15.3176 10.8778 15.8681 10.3639 16.1672Z" fill={color} />
    </svg>
  );
};

LockCloseIcon.defaultProps = defaultProps;
export default LockCloseIcon;
