import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Pulse } from 'Types/PulseTypes';
import { parseColorGradients } from '../../../Utils/UtilityFunctions';
import ProgressBarSkeleton from '../skeleton/ProgressBarSkeleton';

const ProgressBar = styled.div<{ background: string, singleColor: string }>`
  height: 5px;
  border-radius: 8px;
  width: 100%;
  background: linear-gradient(to right, ${(props) => props.background} );
  background-color: ${(props) => props.singleColor};
`;

type Props = {
  load?: boolean,
  pulse: Pulse[],
} & typeof defaultProps;

const defaultProps = {
  load: false,
};

const AlertsProgressTriggers = function AlertsProgressTriggers(props: Props) {
  const [colors, setColors] = useState<string>('');
  const { load, pulse } = props;
  useEffect(() => {
    if (pulse?.length > 0) {
      setColors(parseColorGradients(pulse));
    }
  }, [pulse]);
  return (
    <div className="progress-wrapper">

      {load ? <ProgressBarSkeleton />
        : <ProgressBar background={pulse.length > 1 ? colors : ''} singleColor={colors} />}
    </div>
  );
};
AlertsProgressTriggers.defaultProps = defaultProps;
export default AlertsProgressTriggers;
