import React from 'react';

type Props = {
  color?: string;
};

const defaultProps = {
  color: '#7E7E7E',
};
const GridViewIcon: React.FC<Props> = function GridViewIcon({ color }) {
  return (
    <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="15" height="15" rx="1.42188" fill={color} />
      <rect y="9" width="6" height="6" rx="1.42188" fill={color} />
      <rect x="9" width="6" height="6" rx="1.42188" fill={color} />
      <rect x="9" y="9" width="6" height="6" rx="1.42188" fill={color} />
      <rect x="18" width="6" height="6" rx="1.42188" fill={color} />
      <rect x="18" y="9" width="6" height="6" rx="1.42188" fill={color} />
    </svg>
  );
};
GridViewIcon.defaultProps = defaultProps;
export default GridViewIcon;
