/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ServiceLinesState } from 'Types/GlobalTypes';
import { AddToFavoriteRequest } from 'Types/RequestBodies';
import {
  ServiceLine, SLDetailResponse, SLNewsRequest, SLResponse, SLWidgetResponse,
} from 'Types/ServiceLinesTypes';
import { mergeFilters } from 'Utils/UtilityFunctions';

export const initialState: ServiceLinesState = {
  followedServiceLines: [],
  serviceLinesEmpty: false,
  loading: false,
  recommendedServiceLines: [],
  followLoading: false,

  detailLoading: false,
  newsArticlesLoading: false,
  newsTabPage: 1,
  newsArticles: [],
  articleNewsEnded: false,
  newsFilter: [],
  selectedNewsFilter: [],

  widgetLoading: false,
  serviceLineWidgets: [],

  servicelineName: '',
  paginationLoader: false,
  servicelineAdded: false,
};

const ServiceLinesSlice = createSlice({
  name: 'serviceline',
  initialState,
  reducers: {
    getServiceLines(state) {
      state.loading = true;
      state.serviceLinesEmpty = false;
    },
    setServiceLines(state, action: PayloadAction<SLResponse>) {
      const { payload } = action;
      state.loading = false;
      state.recommendedServiceLines = payload.recommendedServiceLines;
      state.followedServiceLines = payload.followedServiceLines;
      if (state.followedServiceLines.length < 1) state.serviceLinesEmpty = true;
    },
    removeServiceLines(state, action: PayloadAction<ServiceLine[]>) {
      state.loading = true;
    },
    removedtServiceLines(state, action: PayloadAction<ServiceLine[]>) {
      state.loading = false;
      state.followedServiceLines = state.followedServiceLines.filter(
        (ind) => !action.payload.find(({ id }) => ind.id === id),
      );
      const unfollowedServiceLine = action.payload.map(
        (serviceLine) => ({ ...serviceLine, added: false }),
      );
      state.recommendedServiceLines = [...unfollowedServiceLine, ...state.recommendedServiceLines];
      if (state.followedServiceLines.length < 1) {
        state.serviceLinesEmpty = true;
      }
    },
    addingServiceLine(state, action: PayloadAction<ServiceLine>) {
      if (action.payload.type === 'detailPage') {
        state.followLoading = true;
      } else {
        const index = state.recommendedServiceLines.findIndex(
          (v) => v.id === action.payload.id,
        );
        if (index > -1) {
          state.recommendedServiceLines[index].loading = true;
        }
      }
    },
    addedServiceLineDetail(state, action: PayloadAction<ServiceLine>) {
      state.servicelineAdded = true;
      const temp = state.recommendedServiceLines.find(
        (v) => v.id === action.payload.id,
      );
      if (temp) {
        temp.added = true;
        state.followedServiceLines.unshift(temp);
      }
      state.followLoading = false;

      state.recommendedServiceLines = state.recommendedServiceLines.filter(
        (ind) => action.payload.id !== ind.id,
      );
      if (state.followedServiceLines.length >= 1) {
        state.serviceLinesEmpty = false;
      }
    },
    addedServiceLine(state, action: PayloadAction<ServiceLine>) {
      state.recommendedServiceLines = state.recommendedServiceLines.filter(
        (ind) => action.payload.id !== ind.id,
      );
      const serviceline = { ...action.payload };
      serviceline.added = true;
      serviceline.loading = false;
      state.followedServiceLines.unshift(serviceline);

      state.serviceLinesEmpty = false;
    },
    addedServiceLineError(state, action: PayloadAction<ServiceLine>) {
      const index = state.recommendedServiceLines.findIndex(
        (v) => v.id === action.payload.id,
      );
      if (index >= 0) {
        state.recommendedServiceLines[index].loading = false;
      }
      if (state.followLoading) {
        state.followLoading = false;
      }
    },
    getServiceLineWidgets(state, action: PayloadAction<SLNewsRequest>) {
      state.widgetLoading = true;
      state.servicelineName = '';
      state.servicelineAdded = false;
      state.serviceLineWidgets = [];
    },
    setServiceLineWidgets(state, action: PayloadAction<SLWidgetResponse>) {
      const { payload } = action;
      state.widgetLoading = false;
      state.servicelineName = payload?.name;
      state.servicelineAdded = payload?.subscribed;
      state.serviceLineWidgets = payload?.widgets?.map(
        ((widget) => ({
          ...widget,
          page: 1,
          ...(widget.filters && { newsFilters: mergeFilters(widget.filters || []) }),
          ...(widget.filters && { selectedFilters: [] }),
          articleEnded: false,
        })),
      ) || [];
    },
    getServiceLineDetails(state, action: PayloadAction<SLNewsRequest>) {
      const { payload } = action;
      const AppliedFilters = payload?.filters?.filter(
        (item) => item.isSelected,
      ).map((item) => ({
        id: item.filterId,
        type: item.filterTypeName,
      }));
      state.detailLoading = true;
      if (payload.page === 1) {
        state.newsArticles = [];
        if (payload.widget_name) {
          const widtget = state.serviceLineWidgets?.find(
            (widget) => widget.title === payload.widget_name,
          );
          if (widtget) {
            widtget.articleEnded = false;
            widtget.news = widtget.news?.length && !AppliedFilters?.length
              ? widtget.news : [];
            widtget.page = 1;
            if (!payload.filters) {
              widtget.newsFilters = widtget.newsFilters?.length ? widtget.newsFilters : [];
              widtget.selectedFilters = [];
            } else {
              widtget.selectedFilters = payload.filters;
            }
          }
          state.newsArticlesLoading = !!AppliedFilters?.length;
          state.paginationLoader = true;
        } else {
          state.serviceLineWidgets = [];
          state.articleNewsEnded = false;
          state.servicelineName = '';
          state.servicelineAdded = false;
          state.newsArticlesLoading = true;
          state.newsTabPage = 1;
          if (!payload.filters) {
            state.newsFilter = [];
            state.selectedNewsFilter = [];
          } else {
            state.selectedNewsFilter = payload.filters;
          }
        }
      } else state.paginationLoader = true;
    },
    setWidgetNewsDetails(state, action: PayloadAction<SLDetailResponse>) {
      const { payload } = action;
      const widtget = state.serviceLineWidgets?.find(
        (widget) => widget.title === payload.title,
      );
      if (widtget) {
        if (widtget.page === 1) {
          state.newsArticlesLoading = false;
          const concatedArticles = widtget.news.concat(payload?.articles);
          widtget.news = concatedArticles.filter(
            (value, index, self) => index === self.findIndex((t) => (
              t.articleId === value.articleId
            )),
          );
          widtget.newsFilters = widtget.newsFilters.length ? widtget.newsFilters
            : mergeFilters(payload?.filters || []);
        } else {
          widtget.news = widtget.news.concat(payload?.articles);
        }
        widtget.page += 1;
      }
      state.detailLoading = false;
      state.paginationLoader = false;
    },
    setWidgetArticlesEnded(state, action: PayloadAction<string>) {
      const { payload } = action;
      const widtget = state.serviceLineWidgets?.find(
        (widget) => widget.title === payload,
      );
      if (widtget) {
        widtget.articleEnded = true;
      }
    },
    setNewsDetails(state, action: PayloadAction<SLDetailResponse>) {
      const { payload } = action;
      state.detailLoading = false;
      if (state.newsTabPage === 1) {
        state.newsArticlesLoading = false;
        state.servicelineName = payload?.name;
        state.servicelineAdded = payload?.subscribed;
        state.newsArticles = payload?.articles;
        state.newsFilter = state.newsFilter.length ? state.newsFilter
          : mergeFilters(payload?.filters || []);
      } else {
        state.newsArticles = state.newsArticles.concat(payload?.articles);
      }
      state.newsTabPage += 1;
      state.paginationLoader = false;
    },
    setNewsArticlesEnded(state) {
      state.articleNewsEnded = true;
    },
    addToFavoriteSuccess(state, action: PayloadAction<AddToFavoriteRequest>) {
      const { articleId, favoriteFlag } = action.payload;
      if (articleId) {
        if (state.newsArticles && state.newsArticles.length) {
          const matchedArticles = state.newsArticles.filter(
            (article) => article.articleId === articleId,
          );
          if (matchedArticles.length > 0) {
            matchedArticles.forEach((arrayItem) => {
              const article = arrayItem;
              article.isFavorite = favoriteFlag === 1;
            });
          }
        }
        if (state.serviceLineWidgets && state.serviceLineWidgets.length) {
          state.serviceLineWidgets.forEach((widget) => {
            const matchedArticles = widget.news.filter(
              (article) => article.articleId === articleId,
            );
            if (matchedArticles?.length > 0) {
              matchedArticles.forEach((arrayItem) => {
                const article = arrayItem;
                article.isFavorite = favoriteFlag === 1;
              });
            }
          });
        }
      }
    },
  },
});

export const { actions, reducer, name: sliceKey } = ServiceLinesSlice;
