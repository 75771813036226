import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import {
  maxMobile,
  maxWidth1300, minWidthApp, primaryFont, SecondaryColor, wMedium,
} from 'Utils/utilsStyle';
import LayoutIcon from '../assets/svgIcons/LayoutIcon';

type Props = {
  label?: string;
  withAbsolute?: boolean,
} & typeof defaultProps;

const defaultProps = {
  label: 'Shared',
  withAbsolute: false,
};

const Badge = styled.div`
 &.shared {
  font-family: ${primaryFont};
  font-weight: ${wMedium};
  background-color: ${SecondaryColor};
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 24px;
  font-size: 10px;
  line-height: 11px;
  display: inline-flex;
  align-items: center;
  &.absolute {
    position: absolute;
    left: 10px;
    bottom: 5px;
    overflow: hidden;
    max-width: 170px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    @media all and (max-width: ${maxWidth1300}) {
      max-width: 120px;
    }
    @media all and (max-width: ${minWidthApp}) { 
      left: 5px;
    }
  }
  svg {
    height: 10px;
    width: 16px;
    min-width: 16px;
    margin-right: 5px;
  }
  @media all and (max-width: ${maxMobile}) {
    margin-left: 0px;
  }
}
`;

const SharedBadge = function SharedBadge(props: Props) {
  const { label, withAbsolute } = props;
  return (
    <Badge className={classNames('shared', { absolute: withAbsolute })}>
      <LayoutIcon color="#fff" />
      {label}
    </Badge>
  );
};

SharedBadge.defaultProps = defaultProps;
export default SharedBadge;
