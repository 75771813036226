import { Modal, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import {
  backgroundColorParsing,
} from 'Utils/UtilityFunctions';
import {
  selectGroupShared, selectSelectedExecutives, selectSelectedGroups, selectShareLoading,
} from 'Selector/GroupsSelector';
import { useDispatch, useSelector } from 'react-redux';
import { SHARE_GROUP_CONFIRMATION } from 'Utils/Constants';
import { Executive } from 'Types/PersonTypes';
import { actions } from 'Slice/GroupsSlice';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { actions as certificationActions } from 'Slice/CertificationSlice';
import { selectData } from 'Selector/CertificationSelector';
import PeopleWidgetWithOutHolo from '../components/PeopleWidgetWithOutHolo';
import PeopleWidget from '../components/PeopleWidget';

const ShareButtonWrapper = styled.span<{ loading: boolean }>`
    ${({ loading }) => loading && `
      padding:  7px 40px 7px 24px !important;
    `}
    .ant-spin.ant-spin-spinning {
      ${({ loading }) => loading && `
        margin-right: 14px;
      `}
    }
`;

type Props = {
  showPopup: boolean,
  setShowPopup: (a: boolean, b?: boolean) => void,
  isDetailPage?: boolean
} & typeof defaultProps;

const defaultProps = {
  isDetailPage: false,
};

const ShareGroupConformationModal = function ShareGroupConformationModal(props: Props) {
  const { showPopup, setShowPopup, isDetailPage } = props;
  const dispatch = useDispatch();
  const selectedExecutives = useSelector(selectSelectedExecutives);
  const [firstPerson, setFirstPerson] = useState<Executive>();
  const [checkLength, setCheckLength] = useState(0);
  const selectedGroups = useSelector(selectSelectedGroups);
  const [firstGroupName, setFirstGroupName] = useState('');
  const groupShareLoading = useSelector(selectShareLoading);
  const groupShared = useSelector(selectGroupShared);

  useEffect(() => {
    if (selectedExecutives?.length) {
      setFirstPerson(selectedExecutives[0]);
    }
    if (selectedGroups?.length) {
      setFirstGroupName(selectedGroups[0]?.name);
    }
    setCheckLength(selectedExecutives?.length >= 4 ? 4 : selectedExecutives?.length);
  }, [selectedExecutives, selectedGroups]);

  const shareGroupHandler = () => {
    const obj = {
      userIds: selectedExecutives?.map((item) => item.userId || 0),
      groupIds: selectedGroups?.map((item) => item.id),
      ...isDetailPage && { isDetailPage: true },
    };
    dispatch(actions.shareGroup(obj));
  };

  const data = useSelector(selectData);

  const confirmHandler = () => {
    if (selectedExecutives?.length) {
      shareGroupHandler();
      dispatch(certificationActions.certificationAction({
        type: 'share_confirm',
        source: 'buyer',
        data,
      }));
    }
  };

  useEffect(() => {
    if (groupShared) {
      setShowPopup(false);
    }
  }, [groupShared]);

  return (
    <Modal
      centered
      visible={showPopup}
      width={582}
      wrapClassName="share-group-modal"
      closable
    >
      <span className="heading-popup">
        {`${SHARE_GROUP_CONFIRMATION} 
        ${selectedGroups?.length === 1 ? `the group "${firstGroupName}"?` : `"${firstGroupName}" and ${selectedGroups.length - 1} other groups?`}`}
      </span>
      <div className="people-widget-div">
        {selectedExecutives?.length === 1 ? (
          <PeopleWidget
            diameter="150px"
            diameterSm="150px"
            diameterXsm="150px"
            holoDiameterHeight="140px"
            holoDiameterWidth="140px"
            holoDiameterMinHeight="140px"
            holoDiameterMinWidth="140px"
            imageClassName="person-image"
            marginSm="0px 5px 0px 0px"
            margin="0px 0px 0px 0px"
            wrapperColor={backgroundColorParsing(firstPerson?.personalityTypes || [])}
            logo={firstPerson?.personImage || ''}
            personId={firstPerson?.personId || 0}
            ringPadding="0px"
            ringPaddingSm="0px"
            personalityTypesColors={firstPerson?.personalityTypes}
            disableNavigation
          />
        ) : (
          <div className={`person-overlap-wrapper person-item-${selectedExecutives.length}`}>
            {selectedExecutives?.slice(0, checkLength).map((person) => (
              <PeopleWidgetWithOutHolo
                key={person?.userId}
                diameter="90px"
                diameterSm="90px"
                diameterXsm="90px"
                imageClassName="person-image"
                marginSm="0px 0px 0px 0px"
                margin="0px 0px 0px 0px"
                wrapperColor={backgroundColorParsing(person?.personalityTypes || [])}
                logo={person?.personImage}
                personId={person?.personId}
                ringPadding="3px"
                ringPaddingSm="4px"
                disableNavigation
              />
            ))}
          </div>
        )}
      </div>
      <div>
        {selectedExecutives?.length === 1 ? (
          <div className="d-flex flex-column">
            <span className="heading-popup person-name">
              {firstPerson?.personName}
            </span>
            <span className="heading-popup person-designation">
              {firstPerson?.personDesignation}
            </span>
          </div>
        ) : (
          <span className="heading-popup second-heading-popup">
            {`${firstPerson?.personName || ''} and ${selectedExecutives.length - 1} others.`}
          </span>
        )}
      </div>
      <div className="share-group-footer">
        <ShareButtonWrapper
          loading={groupShareLoading}
          tabIndex={0}
          role="button"
          className={`share-group-button green-button ${groupShareLoading ? 'disabled' : ''}`}
          onClick={confirmHandler}
          onKeyPress={confirmHandler}
        >
          {selectedExecutives?.length && groupShareLoading
            ? (
              <Spin indicator={
                (
                  <LoadingOutlined
                    style={{
                      fontSize: 18,
                      color: '#fff',
                    }}
                    spin
                  />
                )
              }
              />
            )
            : null}
          {!selectedExecutives?.length ? 'Accept' : 'Yes'}
        </ShareButtonWrapper>
        <span
          tabIndex={0}
          role="button"
          className={`share-group-button white-button ${groupShareLoading ? 'disabled' : ''}`}
          onClick={() => setShowPopup(false, true)}
          onKeyPress={() => setShowPopup(false, true)}
        >
          {!selectedExecutives?.length ? 'Decline' : 'No'}
        </span>
      </div>

    </Modal>
  );
};

ShareGroupConformationModal.defaultProps = defaultProps;
export default ShareGroupConformationModal;
