/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable no-param-reassign */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FeedActivitiesState } from 'Types/GlobalTypes';
import {
  CompaniesPulse, FeedActivitiesAllResponse, NewsActivitiesResponse, SocialActivitiesResponse,
} from 'Types/FeedActivitiesTypes';
import { FeedAllTabRequest, FeedNewsTabRequest, FeedSocialTabRequest } from 'Types/RequestBodies/Feed/AllTab';
import { AddToFavoriteRequest } from 'Types/RequestBodies';

export const initialState: FeedActivitiesState = {
  activitiesLoading: false,
  activitiesLoaded: false,
  allArticles: [],
  socialArticles: [],
  socialsLoading: false,
  allTabPage: 1,
  articlesEnded: false,
  newsArticles: [],
  newsTabPage: 1,
  newsLoading: false,
  newsLoaded: false,
  articlesNewsEnded: false,
  articlesSocialEnded: false,
  socialTabPage: 1,
  paginationLoader: false,
  newsFilter: [],
  socialFilters: [],
  selectedNewsFilter: [],
  selectedSocialFilter: [],
  feedBoardPulseLoading: false,
  feedBoardPulseData: {},
  newsFlurry: {},
  socialFlurry: {},
  allFlurry: {},
  AllActivitiesApiCall: false,
  newsActivitiesApiCall: false,
  socialActivitiesApiCall: false,
  currentSections: [],
};

const feedIntentDataSlice = createSlice({
  name: 'feedActivities',
  initialState,
  reducers: {
    getAllActivities(state, action: PayloadAction<FeedAllTabRequest>) {
      const { payload } = action;
      state.activitiesLoaded = false;
      if (payload.page === 1) {
        state.activitiesLoaded = false;
        state.activitiesLoading = true;
        state.allArticles = [];
        state.allTabPage = 1;
        state.AllActivitiesApiCall = false;
        state.allFlurry = {};
      } else state.paginationLoader = true;
      state.articlesEnded = false;
    },
    setAllActivities(state, action:PayloadAction<FeedActivitiesAllResponse>) {
      const { payload } = action;
      state.activitiesLoading = false;
      state.allArticles = payload?.response?.articles;
      state.allTabPage += 1;
      state.paginationLoader = false;
      state.activitiesLoaded = true;
    },
    setMoreAllFeedArticles(state, action: PayloadAction<FeedActivitiesAllResponse>) {
      const { payload } = action;
      state.allTabPage += 1;
      state.allArticles = state.allArticles.concat(payload?.response?.articles);
      state.paginationLoader = false;
    },
    setArticlesEnded(state) {
      state.articlesEnded = true;
      state.activitiesLoading = false;
    },
    getSocialActivities(state, action: PayloadAction<FeedSocialTabRequest>) {
      const { payload } = action;
      if (payload.page === 1) {
        state.socialArticles = [];
        state.socialsLoading = true;
        state.socialTabPage = 1;
        state.socialActivitiesApiCall = false;
        state.socialFlurry = {};
        if (!payload.filters) {
          state.socialFilters = [];
          state.selectedSocialFilter = [];
        } else {
          state.selectedSocialFilter = payload.filters;
        }
      } else state.paginationLoader = true;
    },
    setSocialActivities(state, action: PayloadAction<SocialActivitiesResponse>) {
      const { payload } = action;
      state.socialsLoading = false;
      state.socialArticles = payload?.response?.articles;
      state.socialTabPage += 1;
      state.socialFilters = !state.socialFilters.length
        ? payload?.response?.filters : state.socialFilters;
      state.paginationLoader = false;
    },
    getNewsActivites(state, action:PayloadAction<FeedNewsTabRequest>) {
      const { payload } = action;
      if (payload.page === 1) {
        state.newsLoaded = false;
        state.newsLoading = true;
        state.newsArticles = [];
        state.newsTabPage = 1;
        state.newsActivitiesApiCall = false;
        state.newsFlurry = {};
        if (!payload.filters) {
          state.newsFilter = [];
          state.selectedNewsFilter = [];
        } else {
          state.selectedNewsFilter = payload.filters;
        }
      } else state.paginationLoader = true;
    },
    setNewsActivities(state, action:PayloadAction<NewsActivitiesResponse>) {
      const { payload } = action;
      state.newsLoaded = true;
      state.newsLoading = false;
      state.newsArticles = payload?.response?.articles;
      state.newsTabPage += 1;
      state.newsFilter = !state.newsFilter.length ? payload?.response?.filters : state.newsFilter;
      state.paginationLoader = false;
    },
    setMoreAllNewsArticles(state, action: PayloadAction<FeedActivitiesAllResponse>) {
      const { payload } = action;
      state.newsTabPage += 1;
      state.newsArticles = state.newsArticles.concat(payload?.response?.articles);
      state.paginationLoader = false;
    },

    setNewsArticlesEnded(state) {
      state.articlesNewsEnded = true;
      state.activitiesLoading = false;
    },
    setMoreAllSocialArticles(state, action: PayloadAction<FeedActivitiesAllResponse>) {
      const { payload } = action;
      state.socialTabPage += 1;
      state.socialArticles = state.socialArticles.concat(payload?.response?.articles);
      state.paginationLoader = false;
    },
    addToFavoriteSuccess(state, action: PayloadAction<AddToFavoriteRequest>) {
      const { articleId, favoriteFlag } = action.payload;
      if (articleId) {
        if (state.allArticles && state.allArticles.length) {
          const matchedArticles = state.allArticles.filter(
            (article) => article.articleId === articleId,
          );
          if (matchedArticles.length > 0) {
            matchedArticles.forEach((arrayItem) => {
              const article = arrayItem;
              article.isFavorite = favoriteFlag === 1;
            });
          }
        }
        if (state.newsArticles && state.newsArticles.length) {
          const matchedArticles = state.newsArticles.filter(
            (article) => article.articleId === articleId,
          );
          if (matchedArticles.length > 0) {
            matchedArticles.forEach((arrayItem) => {
              const article = arrayItem;
              article.isFavorite = favoriteFlag === 1;
            });
          }
        }
      }
    },
    getFeedPulseBoard(state, action: PayloadAction<object>) {
      state.feedBoardPulseLoading = true;
    },
    setFeedPulseBoard(state, action: PayloadAction<CompaniesPulse>) {
      state.feedBoardPulseLoading = false;
      state.feedBoardPulseData = action.payload;
    },
    setCxoTweetsFlurry(state, action: PayloadAction<object>) {
      state.socialFlurry = action.payload;
    },
    setSalesTriggersFlurry(state, action: PayloadAction<object>) {
      state.newsFlurry = action.payload;
    },
    setAllActivitiesFlurry(state, action: PayloadAction<object>) {
      state.allFlurry = action.payload;
    },
    setAllActivitiesCall(state, action:PayloadAction<boolean>) {
      state.AllActivitiesApiCall = action.payload;
    },
    setNewsActivitiesCall(state, action:PayloadAction<boolean>) {
      state.newsActivitiesApiCall = action.payload;
    },
    setSocialActivitiesCall(state, action:PayloadAction<boolean>) {
      state.socialActivitiesApiCall = action.payload;
    },
    setActivitiesLoaded(state, action: PayloadAction<boolean>) {
      state.activitiesLoaded = action.payload;
    },
    pushCurrentSection(state, action: PayloadAction<string>) {
      const item = action.payload;
      if (item === 'null') {
        state.currentSections = [];
      } else {
        if (!state.currentSections.includes(item)) {
          state.currentSections.push(item);
        }
        if (item === 'Gilroy') {
          const index = state.currentSections.indexOf('News');
          if (index !== -1) {
            state.currentSections.splice(index, 1);
          }
        }
      }
    },
    removeCurrentSection(state, action: PayloadAction<string>) {
      const item = action.payload;
      const index = state.currentSections.indexOf(item);
      if (index !== -1) {
        state.currentSections.splice(index, 1);
      }
    },
  },

});

export const { actions, reducer, name: sliceKey } = feedIntentDataSlice;
