import React from 'react';

type Props = {
  onClick?: () => void,
} & typeof defaultProps;

const defaultProps = {
  onClick: () => { },
};

const Cross = function Cross(props: Props) {
  const { onClick } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 31.112 31.112" xmlSpace="preserve" width="512px" height="512px" onClick={onClick}>
      <polygon points="31.112,1.414 29.698,0 15.556,14.142 1.414,0 0,1.414 14.142,15.556 0,29.698 1.414,31.112 15.556,16.97   29.698,31.112 31.112,29.698 16.97,15.556 " fill="#ee1919" />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
};
Cross.defaultProps = defaultProps;
export default Cross;
