import classNames from 'classnames';
import MailInboxIcon from 'Components/common/assets/svgIcons/MailInboxIcon';
import React from 'react';
import styled from 'styled-components';
import {
  primaryColor, secondaryFont, primaryHeadingColor, wMedium, maxWidth, retina, maxTablet,
} from 'Utils/utilsStyle';

type Props = {
  title?: string,
} & typeof defaultProps;

const defaultProps = {
  title: 'Drop people here to create a new group…',
};

const Wrapper = styled.div`
  background: linear-gradient(180deg, rgba(30, 194, 82, 0.188608) 0%, rgba(30, 194, 82, 0.0442275) 100%);
  border: 2px dashed #1EC252;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 28px 48px;
  display: flex;
  align-items: center;
  width: 100%;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    padding: 15px 20px;
    border-radius: 7px;
    margin-bottom: 15px;
  }
  .title {
    color: ${primaryHeadingColor};
    font-family: ${secondaryFont};
    font-size: 22px;
    line-height: 25px;
    font-weight: ${wMedium};
    margin-left: 20px;
    letter-spacing:-0.45px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      font-size: 18px;
      line-height: 21px;
      margin-left: 15px;
    }
     @media all and (max-width: ${maxTablet}) {
      width: calc(100% - 50px);
    }
  }
  svg {
    height: 50px;
    width: 75px;
    margin-top: -5px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      height: 45px;
      width: auto;
    }
    @media all and (max-width: ${maxTablet}) {
      width: 45px;
    }
  }
`;

const DropHereToCreateGroup = function DropHereToCreateGroup(props: Props) {
  const { title } = props;
  return (
    <Wrapper className={classNames('drop-placement')}>
      <MailInboxIcon color={primaryColor} />
      <span className="title">{title}</span>
    </Wrapper>
  );
};

DropHereToCreateGroup.defaultProps = defaultProps;
export default DropHereToCreateGroup;
