import React from 'react';

type Props = {
  color?: string;
} & typeof defaultProps;

const defaultProps = {
  color: '#FFF',
};

const PenIcon = function PenIcon({ color }: Props) {
  return (
    <svg width="13" height="13" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.05954 17.8689L1.59827 18H1.55453C1.13902 18 0.745379 17.8252 0.461082 17.5413C0.154917 17.2573 -0.0200352 16.8422 0.00183382 16.4053L0.133048 11.949C0.154917 11.5558 0.307999 11.1845 0.592296 10.9005L11.0238 0.458738C11.6143 -0.152913 12.5984 -0.152913 13.2107 0.458738L17.5408 4.80583C17.8469 5.08981 18 5.48301 18 5.89806C18 6.31311 17.825 6.70631 17.5408 6.99029L7.10925 17.4102C6.82495 17.6942 6.45318 17.8471 6.05954 17.8689ZM16.163 5.87621L12.1172 1.83495L1.88257 12.0801L1.81696 14.1117L3.89451 16.1869L5.92833 16.1214L16.163 5.87621Z" fill={color} />
      <path d="M5.00974 14.7233C5.18469 14.8981 5.40338 14.9854 5.62207 14.9854C5.84076 14.9854 6.05945 14.8981 6.2344 14.7233L14.1947 6.77185C14.5446 6.42233 14.5446 5.87621 14.1947 5.5267C13.8448 5.17719 13.2981 5.17719 12.9482 5.5267L5.00974 13.5C4.65984 13.8277 4.65984 14.3956 5.00974 14.7233Z" fill={color} />
      <path d="M3.89453 13.2597C4.11322 13.2597 4.33191 13.1723 4.50687 12.9976L12.4672 5.04612C12.8171 4.6966 12.8171 4.15048 12.4672 3.80097C12.1173 3.45145 11.5705 3.45145 11.2206 3.80097L3.2822 11.7743C2.9323 12.1238 2.9323 12.6699 3.2822 13.0194C3.45715 13.1723 3.67584 13.2597 3.89453 13.2597Z" fill={color} />
    </svg>
  );
};

PenIcon.defaultProps = defaultProps;
export default PenIcon;
