import React from 'react';

const PlayIcon = function PlayIcon() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.11328e-05 5.98275C-0.0107669 2.65081 2.78873 -0.0865465 6.16563 0.00209391C9.28561 0.0860646 12.0132 2.66024 12 6.02707C11.9941 7.61708 11.3563 9.13955 10.2269 10.2598C9.09758 11.3799 7.56915 12.0059 5.97788 12C4.38656 11.9941 2.86283 11.3568 1.74169 10.2284C0.620556 9.09998 -0.00581884 7.57282 5.25494e-05 5.98286L3.11328e-05 5.98275ZM8.86562 6.0883C8.7876 5.97999 8.69603 5.88214 8.59324 5.79692C7.39325 5.08551 6.18918 4.37972 4.98126 3.67951C4.68 3.5032 4.44002 3.62673 4.44002 3.9708C4.42805 5.38883 4.42805 6.80687 4.44002 8.2249C4.44002 8.57141 4.68 8.70446 4.98 8.52824C6.19756 7.82486 7.41041 7.11347 8.61833 6.39402C8.70714 6.33765 8.75996 6.21538 8.86677 6.08834L8.86562 6.0883Z" fill="#1EC252" />
    </svg>
  );
};

export default PlayIcon;
