import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  DeleteRecentSearch, DeviceTokenResponse, MarketingPopupResponse, Section,
} from 'Types/GlobalSearchContentTypes';
import { PopUpResponse } from 'Types/PersonTypes';
import { ComplianceAckRequest, DeleteSearchRequest, SetDeviceToken } from 'Types/RequestBodies';
import { setLocalStorageValue } from 'Utils/UtilityFunctions';
import { GlobalSearchContentState }
  from '../Types/GlobalSearchContentTypes';

export const initialState: GlobalSearchContentState = {
  loading: false,
  loaded: false,
  documentCount: 0,
  recentSearches: {
    title: 'Recent Searches',
    type: 'searches',
    searches: [] as string[],
  },
  trendingSearches: {
    title: 'Trending Topics',
    type: 'topics',
    searches: [] as string[],
  },
  popupResponse: [] as MarketingPopupResponse[],
  expiredPopup: {
    allowFullView: false,
    allowedAccounts: 0,
    allowedProfiles: 0,
    callToAction: '',
    contactusEmail: '',
    popupHeading: '',
    popupHtml: '',
    popupText: '',
    remaining: 0,
    showPlanPopup: 0,
    total: 0,
    totalSubscribed: 0,
  },
  belt: {
    id: 0,
    text: '',
    color: '',
    image: '',
    rgb: '',
    gif: '',
  },
  errorText: undefined,
  articleCount: 0,
  peopleCount: 0,
  savingDeviceTokenLoading: false,
  deviceTokenSaved: false,
  screenName: '',
  apiCalled: false,
  showMappingPopup: false,
  showMappingTooltip: false,
  showDcoumentHub: false,
  showChatbot: false,
  showGlobalGilroy: false,
  uploadDocument: false,
  userCreationDate: '',
  translationWidget: true,
  companyId: 0,
  userId: 0,
  personId: 0,
  disclessPersonality: false,
  gilroyDisclaimer: {
    showDisclaimer: false,
    disclaimerText: [],
  },
  gilroyDisclaimerGlobal: {
    showDisclaimer: false,
    disclaimerText: [],
  },
  isComplianceAckCall: false,
  isComplianceAckCallSuccess: false,
  phone: '',
  changeLanguageLoaded: false,
  restrictAppAccess: false,
  gilroyPopup: false,
  wbUsagePermission: false,
  isDellUser: false,
};

const useGlobalSearchContent = createSlice({
  name: 'globalSearchContent',
  initialState,
  reducers: {
    updateMarketingCampaignResponse(state) {
      state.popupResponse[0].read = true;
    },
    globalSearchContent(state) {
      state.loading = true;
      state.loaded = false;
      state.errorText = undefined;
      state.recentSearches = {
        title: 'Recent Searches',
        type: 'searches',
        searches: [] as string[],
      };
      state.trendingSearches = {
        title: 'Trending Topics',
        type: 'topics',
        searches: [] as string[],
      };
    },

    deleteRecentSearch(state, action: PayloadAction<DeleteSearchRequest>) {

    },

    globalSearchContentLoaded(state, action: PayloadAction<Section[]>) {
      state.loading = false;
      state.loaded = true;
      state.errorText = undefined;
      action.payload.forEach((section) => {
        if (section.type === 'searches') {
          state.recentSearches = section;
        }
        if (section.type === 'topics') {
          state.trendingSearches = section;
        }
        if (section.type === 'userCompany') {
          state.companyId = section.companyId || 0;
        }
        if (section.type === 'userId') {
          state.userId = section.userId || 0;
        }
        if (section.type === 'personId') {
          state.personId = section.personId || 0;
        }
        if (section.type === 'notificationsCount') {
          state.articleCount = section.count || 0;
          state.peopleCount = section.peopleCount || 0;
        }
        if (section.type === 'userBelt') {
          state.belt = section.belt;
        }
        if (section.type === 'popup') {
          state.popupResponse = section.popupResponse || [];
        }
        if (section.type === 'expired') {
          state.expiredPopup = section.expiredResponse || {} as PopUpResponse;
        }
        if (section.type === 'mappingPopup' && section.count === 1) {
          state.showMappingPopup = true;
        }
        if (section.type === 'mappingTooltip' && section.count === 1) {
          state.showMappingTooltip = true;
        }
        if (section.type === 'documentHub' && section.count === 1) {
          state.showDcoumentHub = true;
        }
        if (section.type === 'chatGpt' && section.count === 1) {
          state.showChatbot = true;
        }
        if (section.type === 'globalGilroy' && section.count === 1) {
          state.showGlobalGilroy = true;
        }
        if (section.type === 'uploadDocument' && section.count === 1) {
          state.uploadDocument = true;
        }
        if (section.type === 'userCreationdate') {
          if (state.userCreationDate === '') {
            state.userCreationDate = section.userCreationdate;
          }
        }
        if (section.type === 'translationWidget' && section.count === 0) {
          state.translationWidget = false;
        }
        if (section.type === 'gilroyDisclaimer' && section.count === 1) {
          state.gilroyDisclaimer.showDisclaimer = true;
          if (section.searches) {
            state.gilroyDisclaimer.disclaimerText = section.searches ?? '';
          }
        }
        if (section.type === 'gilroyDisclaimerGlobal' && section.count === 1) {
          state.gilroyDisclaimerGlobal.showDisclaimer = true;
          if (section.searches) {
            state.gilroyDisclaimerGlobal.disclaimerText = section.searches ?? '';
          }
        }
        if (section.type === 'restrictAccess' && section.count === 1) {
          state.restrictAppAccess = true;
        }
        if (section.type === 'disclessPersonality') {
          state.disclessPersonality = section.disclessPersonality || false;
          if (state.disclessPersonality) {
            setLocalStorageValue('disclessPersonality', 'true');
          } else {
            setLocalStorageValue('disclessPersonality', 'false');
          }
        }
        if (section.type === 'gilroyPopup' && section.count === 1) {
          state.gilroyPopup = true;
        }
        if (section.type === 'userContact') {
          state.phone = section.phone;
        }
        if (section.type === 'wbUsagePermission' && section.allowed === 1) {
          state.wbUsagePermission = true;
        }
        if (section.type === 'isDell' && section.count === 1) {
          state.isDellUser = true;
        }
      });
    },
    removeSearchItem(state, action: PayloadAction<string>) {
      state.recentSearches.searches = state.recentSearches.searches.filter((search: string) => search !== action.payload);
    },
    globalSearchContentError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.errorText = action.payload;
      state.recentSearches = {
        title: 'Recent Searches',
        type: 'searches',
        searches: [] as string[],
      };
      state.trendingSearches = {
        title: 'Trending Topics',
        type: 'topics',
        searches: [] as string[],
      };
    },
    updateRecentSearch(state, action: PayloadAction<string>) {
      const recentSearches = state.recentSearches as Section;
      const arr = recentSearches.searches;
      if (arr) {
        if (arr.length > 0) {
          if (arr.indexOf(action.payload) > -1) {
            arr.splice(arr.indexOf(action.payload), 1);
          }
        }
        arr.push(action.payload);
        if (arr.length > 50) {
          arr.shift();
        }
        recentSearches.searches = arr;
      }
    },
    updatePeopleCount(state, action: PayloadAction<number>) {
      const { payload } = action;
      state.peopleCount = payload;
    },
    updateArticleCount(state, action: PayloadAction<number>) {
      const { payload } = action;
      state.articleCount = payload;
    },
    getDeviceTokenSaved(state, action: PayloadAction<SetDeviceToken>) {
      console.log(action);
      state.savingDeviceTokenLoading = true;
    },
    setDeviceTokenSaved(state, action: PayloadAction<DeviceTokenResponse>) {
      console.log(action);
      state.savingDeviceTokenLoading = false;
      state.deviceTokenSaved = true;
    },
    setScreenName(state, action: PayloadAction<string>) {
      state.screenName = action.payload;
    },
    setApiCalled(state, action: PayloadAction<boolean>) {
      state.apiCalled = action.payload;
    },
    setShowMappingPopup(state, action: PayloadAction<boolean>) {
      state.showMappingPopup = action.payload;
    },
    setShowMappingTooltip(state, action: PayloadAction<boolean>) {
      state.showMappingTooltip = action.payload;
    },
    complianceAckCalling(state, action: PayloadAction<ComplianceAckRequest>) {
      state.isComplianceAckCall = true;
    },
    complianceAckCallSuccess(state) {
      state.isComplianceAckCallSuccess = true;
      state.isComplianceAckCall = false;
    },
    complianceAckCallFail(state) {
      state.isComplianceAckCallSuccess = false;
      state.isComplianceAckCall = false;
    },
    updateGilroyTooltip(state) {
    },
    updateUserContact(state, action: PayloadAction<string>) {
      state.phone = action.payload;
    },
    setUpdateGilroyTooltip(state) {
      state.gilroyPopup = false;
    },
    resetRecentSearches(state) {
      state.recentSearches = {
        title: 'Recent Searches',
        type: 'searches',
        searches: [] as string[],
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = useGlobalSearchContent;
