import InfoIconPlaceholder from 'Components/people/components/InfoIconPlaceholder';
import { lazy, Suspense } from 'react';

const PeopleIcon = lazy(() => import('../assets/svgIcons/PeopleIcon'));

const TooltipMessage = function TooltipMessage() {
  const proTip = (
    <span className="proTipText">
      <span className="weight-900">Pro tip:</span>
      Access your buyer groups by clicking on the
    </span>
  );
  const peopleTab = (
    <span className="peopleTabText">
      <span className="weight-900">People</span>
      tab in the left navigation bar
    </span>
  );
  return (
    <div className="toaster-content">
      <div className="info">
        <InfoIconPlaceholder />
        <span className="span-label">{proTip}</span>
      </div>
      <div className="people">
        <Suspense
          fallback={
            <div />
          }
        >
          <PeopleIcon />
          <span className="span-label">{peopleTab}</span>
        </Suspense>
      </div>
    </div>
  );
};

export default TooltipMessage;
