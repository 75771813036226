import React from 'react';

type Props = {
  color?: string,
} & typeof defaultProps;

const defaultProps = {
  color: '#7E7E7E',
};

const DeleteIcon = function DeleteIcon(props: Props) {
  const { color } = props;
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.93685 0.75H4.35352L3.43685 1.66667H0.228516V3.5H13.0618V1.66667H9.85352L8.93685 0.75ZM10.3118 6.25V15.4167H2.97852V6.25H10.3118ZM1.14518 4.41667H12.1452V15.4167C12.1452 16.425 11.3202 17.25 10.3118 17.25H2.97852C1.97018 17.25 1.14518 16.425 1.14518 15.4167V4.41667Z" fill={color} />
    </svg>
  );
};
DeleteIcon.defaultProps = defaultProps;
export default DeleteIcon;
