/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  all,
  call, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { actions } from 'Slice/GroupsSlice';
import { actions as buyerActions } from 'Slice/BuyerGroupsPageSlice';
import { actions as peopleActions } from 'Slice/PeopleSlice';
import { actions as globalSearchActions } from 'Slice/GlobalSearchResultsSlice';
import { actions as companiesActions } from 'Slice/CompaniesSlice';
import { actions as topCompaniesActions } from 'Slice/TopCompanyEntitySlice';
import { actions as affinityActions } from 'Slice/AffinitySearchResultsSlice';
import axios from 'Utils/Axios';
import { API, FILE_DOWNLOAD_FAIL, FILE_DOWNLOAD_SUCCESS } from 'Utils/Constants';
import {
  AllGroupsResponse, CreatedGroup, DeleteGroupResponse, MostActiveGroupsResponse,
  NewGroupResponse, OrgExecutiveResponse, PersonAddedInGroupResponse,
  ShareGroupRequest, ShareGroupResponse, StaffBuyerGroupData,
} from 'Types/GroupsTypes';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  AddPersonInGroup, CreateGroup, DeleteGroup, GetAllGroups, PersonData,
} from 'Types/RequestBodies';
import { actions as helperActions } from 'Slice/HelperSlice';
import { getCurrentTime, valueToObject } from 'Utils/UtilityFunctions';
import { AddCompanyPopup } from 'Types/CompaniesTypes';
import { ExportFollowPeopleResponse } from 'Types/PersonTypes';

export function* getMostActiveGroups() {
  try {
    const response: MostActiveGroupsResponse = yield call(
      axios.post,
      API.GET_ACTIVE_GROUPS,
      {},
    );
    if (response?.status === true) {
      yield put(actions.setMostActiveGroups(response?.response[0]));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getGroups(dispatched: PayloadAction<GetAllGroups>) {
  const startTime = getCurrentTime();

  try {
    const response: AllGroupsResponse = yield call(
      axios.post,
      API.GET_ALL_GROUPS,
      dispatched?.payload,
    );
    if (response?.status === true) {
      if (dispatched?.payload?.searchTerm && dispatched?.payload?.searchTerm !== '') {
        yield put(actions.setSearchedGroups(response?.response));
      } else yield put(actions.setAllGroups(response?.response));
      yield put(helperActions.callFlurryEvent(
        valueToObject('buyer_group_groups_loaded', startTime, dispatched?.payload),
      ));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* deleteGroup(dispatched: PayloadAction<DeleteGroup>) {
  const startTime = getCurrentTime();

  try {
    const response: DeleteGroupResponse = yield call(
      axios.post,
      API.DELETE_GROUP,
      dispatched?.payload,
    );

    if (response?.status === true) {
      yield put(buyerActions.setGroupDeleted(true));
      yield put(actions.groupDeleted(response?.response));
      yield put(companiesActions.deleteGroup(response.response.groupId));
      yield put(globalSearchActions.groupDeleted(response?.response));
      yield put(companiesActions.removeCompanyStaffBuyerGroup(dispatched?.payload.groupId));
      yield put(helperActions.callFlurryEvent(
        valueToObject('buyer_group_delete_group_succeeded', startTime, dispatched?.payload),
      ));
      yield put(helperActions.setNotification({
        message: response?.message,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: true,
      }));
    } else if (response?.status === false) {
      yield put(actions.groupDeletionFailure());
      yield put(helperActions.setNotification({
        message: response?.message,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: false,
      }));
    }
  } catch (err) {
    yield put(actions.groupDeletionFailure());
    console.log(err);
  }
}
export function* createGroup(dispatched: PayloadAction<CreateGroup>) {
  const startTime = getCurrentTime();

  try {
    const response: NewGroupResponse = yield call(
      axios.post,
      API.CREATE_GROUP,
      dispatched?.payload,
    );
    if (response?.status === true) {
      if (!response.message) {
        const res = response.response as AddCompanyPopup[];
        if (res && res.length) {
          yield all([
            put(companiesActions.buyerGroupsPopup(res[0])),
            put(actions.createdGroup({ response: [] })),
          ]);
        }
      } else {
        const res = response.response as StaffBuyerGroupData[];
        const createdGroup: CreatedGroup = {
          response: res,
          action: dispatched?.payload?.action,
        };
        if (res && res.length) {
          const { payload: { person_data: personData } } = dispatched;
          if (personData?.length) {
            const [{ entity_id: id }] = personData;
            yield all([
              put(peopleActions.updateRecommendedPeopleList(id)),
              put(globalSearchActions.searchFollowHandling(id)),
              put(companiesActions.handleFollowGroupPeople(id)),
              put(topCompaniesActions.handleFollowGroupPeople(id)),
            ]);
          }
          yield all([
            put(actions.createdGroup(createdGroup)),
            put(companiesActions.followGroupPeople()),
            put(companiesActions.addCompanyStaffBuyerGroup(res)),
          ]);
          if (!dispatched.payload.displayMessage) {
            yield put(helperActions.setNotification({
              message: response?.message,
              proTip: true,
              rightPlacement: false,
              groupId: res[0].groupId,
              classnameFlag: true,
            }));
          } else {
            yield put(helperActions.setNotification({
              message: response?.message,
              proTip: false,
              rightPlacement: false,
              groupId: 0,
              classnameFlag: true,
            }));
          }
          yield put(helperActions.callFlurryEvent(
            valueToObject('buyer_group_create_group_succeeded', startTime, dispatched?.payload),
          ));
        }
      }
    } else {
      const { companyId, action } = dispatched.payload;
      if (response.message.length) {
        if (!companyId) {
          yield put(helperActions.setNotification({
            message: response?.message,
            proTip: false,
            rightPlacement: false,
            groupId: 0,
            classnameFlag: false,
          }));
        } else if (!action) {
          yield put(actions.setShowMergePopup(true));
        }
      }
      yield put(actions.createdGroup({ response: [] }));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* addPersonInGroup(dispatched: PayloadAction<AddPersonInGroup>) {
  const startTime = getCurrentTime();

  try {
    const object: { person_data?: PersonData[], group_id?: number, feed_id?: number } = {
      ...(dispatched?.payload.person_data && { person_data: dispatched?.payload.person_data }),
      ...(dispatched?.payload.group_id && { group_id: dispatched?.payload.group_id }),

    };
    const response: PersonAddedInGroupResponse = yield call(
      axios.post,
      API.UPDATE_PERSON_PROFILE_FOLLOW,
      object,
    );
    if (response?.status === true) {
      const { payload: { person_data: personData } } = dispatched;

      if (personData?.length) {
        const [{ entity_id: id }] = personData;
        yield all([
          put(peopleActions.updateRecommendedPeopleList(id)),
          put(globalSearchActions.searchFollowHandling(id)),
          put(companiesActions.handleFollowGroupPeople(id)),
          put(topCompaniesActions.handleFollowGroupPeople(id)),
          put(affinityActions.personAddedToGroup(id)),
        ]);
      }
      yield put(helperActions.setNotification({
        message: response?.message,
        proTip: false,
        rightPlacement: true,
        groupId: response.response.groupId,
        classnameFlag: true,
      }));
      if (dispatched.payload.groupName && dispatched.payload.groupName !== '') {
        yield put(actions.personAddedInGroup(response.response));
      } else {
        if (object.person_data?.length) {
          yield put(buyerActions.removePersonAfterAdd(object.person_data[0]));
        }
        yield put(buyerActions.personAddedInGroup(response.response));
        yield put(buyerActions.resetHighlights({}));
        yield put(buyerActions.isAddRemovePersonInGroup(true));
      }

      const isFollowedAndAdded = dispatched?.payload?.isFollowed;

      if (!isFollowedAndAdded) {
        yield put(helperActions.callFlurryEvent(
          valueToObject('people_follow_succeded', startTime, dispatched?.payload),
        ));
      } else {
        yield put(helperActions.callFlurryEvent(
          valueToObject('buyer_group_add_person_in_group_succeeded', startTime, dispatched?.payload),
        ));
      }
    } else {
      yield put(helperActions.setNotification({
        message: response?.message,
        proTip: false,
        rightPlacement: true,
        groupId: 0,
        classnameFlag: false,
      }));
      yield put(buyerActions.setIsError(true));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getOrganizationExecutives(dispatched: PayloadAction<GetAllGroups>) {
  const { payload } = dispatched;
  try {
    const response: OrgExecutiveResponse = yield call(
      axios.post,
      API.GET_ORG_EXECUTIVES,
      payload,
    );
    if (response?.status === true) {
      if (!payload.query) {
        yield put(actions.setOrganizationExecutives(response?.response));
      } else {
        yield put(actions.setSearchedExecutives(response?.response));
      }
    } else {
      yield put(actions.orgExecutivesFailed());
    }
  } catch (err) {
    console.log(err);
    yield put(actions.orgExecutivesFailed());
  }
}

export function* shareGroup(dispatched: PayloadAction<ShareGroupRequest>) {
  const { payload } = dispatched;
  const startTime = getCurrentTime();
  try {
    const response: ShareGroupResponse = yield call(
      axios.post,
      API.SHARE_GROUP,
      payload,
    );

    if (response?.status === true) {
      yield put(actions.groupShared());
      yield put(helperActions.setNotification({
        message: response?.message,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: true,
      }));

      const { isDetailPage, groupIds } = payload;

      if (groupIds?.length > 1) {
        yield put(helperActions.callFlurryEvent(
          valueToObject('buyergroup_multiShare_complete', startTime, { feed_ids: groupIds.join(',') }),
        ));
      } else {
        yield put(helperActions.callFlurryEvent(
          valueToObject(`${isDetailPage ? 'homePage_' : ''}buyerGroup_share_complete${isDetailPage ? 'd' : ''}`, startTime, { feed_ids: groupIds.join(',') }),
        ));
      }
    } else {
      yield put(actions.groupSharedFailed());
    }
  } catch (err) {
    console.log(err);
    yield put(actions.groupSharedFailed());
  }
}
export function* exportGroupMembers(dispatched: PayloadAction<number>) {
  const { payload } = dispatched;
  try {
    const response: ExportFollowPeopleResponse = yield call(
      axios.post,
      API.EXPORT_GROUP_MEMBERS,
      { groupId: payload },
    );
    if (response?.status === true) {
      const newWin = window.open(response.response?.url);
      if (newWin) {
        yield put(helperActions.setNotification({
          message: FILE_DOWNLOAD_SUCCESS,
          proTip: false,
          rightPlacement: false,
          groupId: 0,
          classnameFlag: true,
        }));
      } else {
        yield put(helperActions.setNotification({
          message: FILE_DOWNLOAD_FAIL,
          proTip: false,
          rightPlacement: false,
          groupId: 0,
          classnameFlag: false,
        }));
      }
    } else {
      yield put(helperActions.setNotification({
        message: FILE_DOWNLOAD_FAIL,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: false,
      }));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* groupsSaga() {
  yield takeLatest(actions.getMostActiveGroups.type, getMostActiveGroups);
  yield takeLatest(actions.getAllGroups.type, getGroups);
  yield takeLatest(actions.deleteGroup.type, deleteGroup);
  yield takeLatest(actions.creatingGroup.type, createGroup);
  yield takeEvery(actions.addPersonInGroup.type, addPersonInGroup);
  yield takeLatest(actions.getOrganizationExecutives.type, getOrganizationExecutives);
  yield takeLatest(actions.shareGroup.type, shareGroup);
  yield takeLatest(actions.exportGroupMembers.type, exportGroupMembers);
}
