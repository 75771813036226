import React from 'react';
import styled from 'styled-components';
import { maxWidth, retina, secondaryFont } from 'Utils/utilsStyle';
import { ArrowRightOutlined } from '@ant-design/icons';
import classNames from 'classnames';

const PersonalityWrapper = styled.div<{ color: string, }>`
 min-width: 100px;
 width: fit-content;
 background-color : ${(props) => props.color};
 position: absolute;
 bottom: -37px;
 left: 50%;
 transform: translate(-50%, -50%);
 border-radius: 30px;
 cursor: pointer;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    min-width: 90px;
    bottom: -32px;
  }
  .anticon {
    min-width: 17px;
  }
  h4 {
    font-size: 14px;
    font-family: ${secondaryFont};
    letter-spacing: -0.35;
    color: #fff;
    margin: 5px;
    padding-left: 10px;
    white-space: nowrap;
  }
  &:hover {
    cursor: pointer;
  }
`;

type Props = {
  persona?: string;
  personality?: string;
};

const defaultProps = {
  persona: '',
  personality: '',
};

const PersonalityPillWidget = function PersonalityPillWidget(props: Props) {
  const {
    personality, persona,
  } = props;
  return (
    <div>
      <PersonalityWrapper
        color={persona || ''}
        className={
          classNames('d-flex justify-content-center align-items-center name-wrapper-v2')
        }
      >
        <h4>
          {personality}
        </h4>
        <ArrowRightOutlined style={{
          color: '#fff',
          marginLeft: '5px',
          marginRight: '10px',
        }}
        />
      </PersonalityWrapper>
    </div>
  );
};

PersonalityPillWidget.defaultProps = defaultProps;
export default PersonalityPillWidget;
