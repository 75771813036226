/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable no-param-reassign */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ArticleDetailState } from 'Types/GlobalTypes';
import {
  AddToFavoriteRequest, ArticleDetailRequest, ArticleSummaryRequest, SimilarArticleRequest,
} from 'Types/RequestBodies';
import {
  ArticleDetailResponse, DeepLinkResponse, SetArticleSummary, SetSimilarArticle, SimilarArticle,
} from 'Types/ArticleTypes';

export const initialState: ArticleDetailState = {
  loading: false,
  articleId: 0,
  article: null,
  deepLinkLoading: false,
  deepLink: '',
  articleDrawerOpen: false,
  similarArticle: {
    similarArticles: [],
    similarArticlesCount: 0,
  } as SimilarArticle,
  similarArticleTwo: {
    similarArticles: [],
    similarArticlesCount: 0,
  },
  similarArticleLoading: false,
  similarArticleTwoLoading: false,
  similarArticleDetail: null,
  similarArticleId: 0,
  similarArticleDetailLoading: false,
  similarArticleEnded: false,
  similarArticleTwoEnded: false,
  articleSummary: [],
  articleSummaryLoading: false,
  articleSummaryLoaded: false,
  similarArticleSummary: [],
  similarArticleSummaryLoading: false,
  similarArticleSummaryLoaded: false,
  articleItemId: 0,
  articleItemSummary: [],
  articleItemSummaryLoading: false,
  articleItemSummaryLoaded: false,
  alertDrawerVisible: false,
  similarDrawerVisible: false,
};

const articleDetailSlice = createSlice({
  name: 'articleDetail',
  initialState,
  reducers: {
    getArticle(state, action: PayloadAction<ArticleDetailRequest>) {
      if (action.payload?.isSimilarArticle) {
        state.similarArticleDetailLoading = true;
        // state.similarArticleDetail = null;
        // state.similarArticleId = 0;
      } else {
        state.loading = true;
        // state.article = null;
        // state.articleId = 0;
      }
    },

    setArticleDetail(state, action:PayloadAction<ArticleDetailResponse>) {
      const { payload } = action;
      state.loading = false;
      state.article = payload.response;
      state.articleId = payload.response.articleId;
    },
    getArticleDeepLink(state, action: PayloadAction<number>) {
      state.deepLinkLoading = true;
    },
    setArticleDeepLink(state, action: PayloadAction<DeepLinkResponse>) {
      state.deepLinkLoading = false;
      const { response: { articleLink } } = action.payload;
      state.deepLink = articleLink;
    },
    addToFavoriteSuccess(state, action: PayloadAction<AddToFavoriteRequest>) {
      const { articleId, favoriteFlag } = action.payload;
      if (state.article) { state.article.isFavorite = favoriteFlag === 1; }
    },
    setArticleDrawerOpen(state, action: PayloadAction<boolean>) {
      state.articleDrawerOpen = action.payload;
    },
    getSimilarArticle(state, action: PayloadAction<SimilarArticleRequest>) {
      if (action?.payload?.isSimilarArticle) {
        state.similarArticleTwoLoading = true;
      } else {
        state.similarArticleLoading = true;
      }
      if (!action.payload?.offset && action.payload?.isSimilarArticle) {
        state.similarArticleTwo.similarArticles = [];
        state.similarArticleTwo.similarArticlesCount = 0;
      }
      if (!action.payload?.offset && !action.payload?.isSimilarArticle) {
        state.similarArticle.similarArticles = [];
        state.similarArticle.similarArticlesCount = 0;
      }
    },
    setSimilarArticles(state, action: PayloadAction<SetSimilarArticle>) {
      const { offset, similarArticles, similarArticlesCount } = action.payload;
      if (similarArticles.length === 0) {
        state.similarArticleEnded = true;
      }
      state.similarArticleLoading = false;
      if (offset) {
        state.similarArticle = {
          similarArticles: [...state.similarArticle.similarArticles,
            ...similarArticles],
          similarArticlesCount: state.similarArticle.similarArticlesCount + similarArticlesCount,
        };
      } else {
        state.similarArticle = {
          similarArticles,
          similarArticlesCount,
        };
      }
    },
    setSimilarArticlesTwo(state, action: PayloadAction<SetSimilarArticle>) {
      const { offset, similarArticles, similarArticlesCount } = action.payload;
      state.similarArticleTwoLoading = false;
      if (similarArticles.length === 0) {
        state.similarArticleTwoEnded = true;
      }
      if (offset) {
        state.similarArticleTwo = {
          similarArticles: [...state.similarArticleTwo.similarArticles,
            ...similarArticles],
          similarArticlesCount: state.similarArticleTwo.similarArticlesCount + similarArticlesCount,
        };
      } else {
        state.similarArticleTwo = {
          similarArticles,
          similarArticlesCount,
        };
      }
    },
    setSimilarArticleDetail(state, action:PayloadAction<ArticleDetailResponse>) {
      const { payload } = action;
      state.similarArticleDetailLoading = false;
      state.loading = false;
      state.similarArticleDetail = payload.response;
      state.similarArticleId = payload.response.articleId;
    },
    addToFavoriteSuccessSimilar(state, action: PayloadAction<AddToFavoriteRequest>) {
      const {
        articleId, favoriteFlag, isSimilarArticleTwo, isDetail,
      } = action.payload;
      if (isSimilarArticleTwo) {
        const articleIndex = state.similarArticleTwo.similarArticles
          .findIndex((article) => article.articleId === articleId);
        if (articleIndex !== -1) {
          state.similarArticleTwo.similarArticles[articleIndex].isFavorite = favoriteFlag === 1;
        }
      }
      if (state?.article?.articleId === articleId) {
        state.article.isFavorite = favoriteFlag === 1;
      }

      const articleIndex = state.similarArticle.similarArticles
        .findIndex((article) => article.articleId === articleId);
      if (articleIndex !== -1) {
        state.similarArticle.similarArticles[articleIndex].isFavorite = favoriteFlag === 1;
      }
      if (isDetail && state.similarArticleDetail) {
        state.similarArticleDetail.isFavorite = favoriteFlag === 1;
      }
    },
    resetArticleTwo(state) {
      state.similarArticleDetail = null;
      state.similarArticleId = 0;
      state.similarArticleTwo.similarArticles = [];
      state.similarArticleTwo.similarArticlesCount = 0;
    },
    isRead(state, action:PayloadAction<number>) {
      const { payload } = action;
      state.similarArticle?.similarArticles?.forEach((item) => {
        const article = item;
        if (article.articleId === payload) {
          article.isRead = true;
        }
      });
    },
    getArticleSummary(state, actions: PayloadAction<ArticleSummaryRequest>) {
      const { isSimilarArticle, isArticleItem } = actions.payload;
      if (isArticleItem) {
        state.articleItemSummary = [];
        state.articleItemSummaryLoading = true;
        state.articleItemSummaryLoaded = false;
      } else if (isSimilarArticle) {
        state.similarArticleSummaryLoaded = false;
        state.similarArticleSummaryLoading = true;
      } else {
        state.articleSummaryLoading = true;
        state.articleSummaryLoaded = false;
      }
    },
    setArticleSummary(state, action: PayloadAction<SetArticleSummary>) {
      const {
        articleId, isArticleItem, isSimilarArticle, summary,
      } = action.payload;
      if (isArticleItem) {
        state.articleItemId = articleId;
        state.articleItemSummary = summary;
        state.articleItemSummaryLoading = false;
        state.articleItemSummaryLoaded = true;
      } else if (isSimilarArticle) {
        state.similarArticleSummary = summary;
        state.similarArticleSummaryLoading = false;
        state.similarArticleSummaryLoaded = true;
      } else if (articleId.toString() === state.articleId.toString()
        || articleId === state.articleItemId || !state.articleId) {
        state.articleSummary = summary;
        state.articleSummaryLoading = false;
        state.articleSummaryLoaded = true;
      }
    },
    setArticleSummaryLoading(state, actions: PayloadAction<boolean>) {
      state.articleSummaryLoading = actions.payload;
      state.articleSummaryLoaded = !actions.payload;
    },
    setArticleSummaryLoaded(state, actions: PayloadAction<boolean>) {
      state.articleSummaryLoaded = actions.payload;
    },
    resetArticleItemSummary(state) {
      state.articleItemSummary = [];
      state.articleItemSummaryLoading = false;
      state.articleItemSummaryLoaded = false;
    },
    setAlertDrawerVisible(state, actions: PayloadAction<boolean>) {
      state.alertDrawerVisible = actions.payload;
    },
    setSimilarDrawerVisible(state, actions: PayloadAction<boolean>) {
      state.similarDrawerVisible = actions.payload;
    },
  },

});

export const { actions, reducer, name: sliceKey } = articleDetailSlice;
