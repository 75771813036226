/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { actions } from 'Slice/SpotlightSlice';
import { PayloadAction } from '@reduxjs/toolkit';

import axios from 'Utils/Axios';

import { API } from 'Utils/Constants';
import { SpotlightRequest, SpotlightPulseRequest } from 'Types/RequestBodies';
import { SpotlightResponse, SpotlightPulseResponse } from 'Types/SpotlightTypes';

export function* getSpotlight(dispatched: PayloadAction<SpotlightRequest>) {
  try {
    const payload = dispatched?.payload;
    const response: SpotlightResponse = yield call(
      axios.post,
      API.GET_SPOTLIGHT,
      payload,
    );
    if (response?.status === true) {
      yield put(actions.setSpotlightData(response));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getSpotlightPulse(dispatched:PayloadAction<SpotlightPulseRequest>) {
  try {
    const payload = dispatched?.payload;
    const response: SpotlightPulseResponse = yield call(
      axios.post,
      API.GET_SPOTLIGHT_PULSE,
      payload,
    );
    if (response?.status === true) {
      yield put(actions.setSpotlightPulseData(response));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* spotlightSaga() {
  yield takeLatest(actions.getSpotlightData.type, getSpotlight);
  yield takeLatest(actions.getSpotlightPulseData.type, getSpotlightPulse);
}
