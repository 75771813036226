import React from 'react';

const ClockTimer = function ClockTimer() {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="Shape" fillRule="evenodd" clipRule="evenodd" d="M21.2406 28.8575C26.8465 26.5352 30.5012 21.0639 30.4999 14.9957C30.4996 8.94416 26.8622 3.48586 21.2774 1.15619C15.6926 -1.17347 9.25498 0.082098 4.95477 4.33969V1.00842H2.93249V8.09801H10.0271V6.07627H6.07977C8.52789 3.48495 11.9353 2.01653 15.5 2.01658C22.6561 2.01658 28.4783 7.83921 28.4783 14.9957C28.4783 22.1522 22.6561 27.9748 15.5 27.9748C8.34395 27.9748 2.52168 22.1522 2.52168 14.9957H0.500064C0.488417 18.9766 2.0699 22.7967 4.89194 25.6044C9.18194 29.8959 15.6347 31.1799 21.2406 28.8575ZM15.0946 16.6174V7.08985H17.1216V14.5903H23.4054V16.6174H15.0946Z" fill="#1EC252" />
    </svg>
  );
};

export default ClockTimer;
