import React from 'react';

type Props = {
  className?: string;
  onClick?: () => void;
};
const defaultProps = {
  className: '',
  onClick: () => { },
};
const CloseAlerts = function (props: Props) {
  const { className, onClick } = props;
  return (
    <svg width="33px" height="33px" viewBox="0 0 33 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className={className} onClick={onClick}>
      <g id="Latest-changes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Alerts-Viewer" transform="translate(-1383.000000, -24.000000)">
          <g id="Group-18" transform="translate(887.000000, 0.000000)">
            <g id="Group-2" transform="translate(496.000000, 24.000000)">
              <circle id="Oval" fillOpacity="0.620013044" fill="#606060" opacity="0.543815976" cx="16.5" cy="16.5" r="16.5" />
              <g id="Group-3" transform="translate(16.636039, 16.636039) rotate(-45.000000) translate(-16.636039, -16.636039) translate(8.136039, 8.136039)" stroke="#FFFFFF" strokeLinecap="square" strokeWidth="3">
                <line x1="0.274193548" y1="8.5" x2="16.7258065" y2="8.5" id="Line" />
                <line x1="0.274193548" y1="8.5" x2="16.7258065" y2="8.5" id="Line" transform="translate(8.500000, 8.500000) rotate(90.000000) translate(-8.500000, -8.500000) " />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
CloseAlerts.defaultProps = defaultProps;
export default CloseAlerts;
