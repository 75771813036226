import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import {
  primaryColor, primaryFont, primaryHeadingColor, secondaryFont,
} from 'Utils/utilsStyle';
import HTMLReactParser from 'html-react-parser';
import classNames from 'classnames';
import LockIcon from '../assets/svgIcons/LockIcon';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 15px !important;
    .ant-modal-body {
      padding: 40px !important;
      .header {
        display: flex;
        align-items: center;
        height: fit-content;
        .title {
          font-family: ${secondaryFont};
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
          color: ${primaryHeadingColor};
          margin: 0px;
        }
        svg {
          margin-right: 10px;
        }
      }
      .body {
        padding: 16px 30px 0px 29px;
        .text {
          font-family: ${primaryFont};
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          color: ${primaryHeadingColor};
          margin: 0px;
          a {
            color: ${primaryColor}
          }
        }
      }
    }
  }
`;

type Props = {
  isIpPopup: boolean
};

const LimitAccessPopup = function LimitAccessPopup(props: Props) {
  const { isIpPopup } = props;

  const title = isIpPopup ? 'Access Restricted' : 'Access Denied';
  const mobileContent = `
    Mobile access to xiQ Sales Xelerator is not permitted.
    Please use a PC/Desktop to log in and access xiQ Sales Xelerator
    <sup>TM</sup>
    <br />
    <br />
    For further assistance, contact
    <a href="mailto:support@xiqinc.com">support@xiqinc.com</a>
  `;
  const ipContent = `
    You are attempting to access xiQ Sales Xelerator<sup>TM</sup> from an unauthorized IP address. Please ensure you are connecting from one of the approved IP addresses.
    <br />
    <br />
    For further assistance, contact your subscription administrator or <a href="mailto:support@xiqinc.com">support@xiqinc.com</a>
  `;
  return (
    <StyledModal
      centered
      open
      closable={false}
      width={isIpPopup ? 702 : 350}
      footer={null}
      maskStyle={{
        backdropFilter: 'blur(5px)',
        backgroundColor: '#0000004D',
      }}
      className={classNames({ ipPopup: isIpPopup })}
    >
      <div className="header">
        <LockIcon />
        <p className="title">{title}</p>
      </div>
      <div className="body">
        <p className="text">
          {HTMLReactParser(isIpPopup ? ipContent : mobileContent)}
        </p>
      </div>
    </StyledModal>
  );
};

export default LimitAccessPopup;
