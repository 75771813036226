import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  &:hover {
    path {
      fill: red;
    }
  }
`;
type Props = {
  onClick?: () => void,
} & typeof defaultProps;

const defaultProps = {
  onClick: () => { },
};
const BinIconCommon = function BinIconCommon({ onClick }: Props) {
  return (
    <SVG
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="bin-icon-common-svg"
      onClick={onClick}
    >
      <g id="noun_Trash_4405222" opacity="0.5">
        <path id="Shape" fillRule="evenodd" clipRule="evenodd" d="M2.63271 18H12.3679C13.0645 18 13.6312 17.4153 13.6312 16.696V3.87812H14.3441C14.7062 3.87812 15 3.57496 15 3.20143C15 2.82789 14.7062 2.52474 14.3441 2.52474H12.9753H10.2088V1.30398C10.2088 0.584662 9.64211 0 8.94556 0H6.05444C5.35789 0 4.79121 0.584662 4.79121 1.30398V2.52474H2.0247H0.655881C0.293835 2.52474 0 2.82789 0 3.20143C0 3.57496 0.293835 3.87812 0.655881 3.87812H1.36882V16.696C1.36882 17.4153 1.93616 18 2.63271 18ZM6.10297 1.35338H8.89768V2.52406H6.10297V1.35338ZM9.55356 3.87812H5.44709H2.68059V16.6466H12.3194V3.87812H9.55356Z" fill="#424B50" />
        <path id="Path" d="M5.51184 13.5293C5.87388 13.5293 6.16772 13.2262 6.16772 12.8526V6.95257C6.16772 6.57904 5.87388 6.27588 5.51184 6.27588C5.14979 6.27588 4.85596 6.57904 4.85596 6.95257V12.8526C4.85596 13.2262 5.14979 13.5293 5.51184 13.5293Z" fill="#424B50" />
        <path id="Path_2" d="M9.48791 13.5293C9.84996 13.5293 10.1438 13.2262 10.1438 12.8526V6.95257C10.1438 6.57904 9.84996 6.27588 9.48791 6.27588C9.12587 6.27588 8.83203 6.57904 8.83203 6.95257V12.8526C8.83203 13.2262 9.12587 13.5293 9.48791 13.5293Z" fill="#424B50" />
      </g>
    </SVG>

  );
};
BinIconCommon.defaultProps = defaultProps;
export default BinIconCommon;
