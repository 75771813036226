import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  SpotlightResponse, SpotlightState, SpotlightPulseResponse, SpotlightFeed,
} from 'Types/SpotlightTypes';
import { AddToFavoriteRequest, GlobalRequest } from '../Types/RequestBodies.d';

export const initialState: SpotlightState = {
  spotlight: [],
  spotlightLoading: false,
  spotlightPulseLoading: false,
  spotlightPulse: [],
};

const SpotlightSlice = createSlice({
  name: 'spotlight',
  initialState,
  reducers: {
    getSpotlightData(state, action: PayloadAction<GlobalRequest>) {
      const { payload } = action;
      if (payload) {
        if (state.spotlight.length === 0) {
          state.spotlightLoading = true;
          state.spotlight = [];
        }
      }
    },

    setSpotlightData(state, action: PayloadAction<SpotlightResponse>) {
      const { payload } = action;
      state.spotlight = payload?.response;
      state.spotlightLoading = false;
    },

    getSpotlightPulseData(state, action: PayloadAction<GlobalRequest>) {
      const { payload } = action;
      if (payload) {
        if (state.spotlightPulse.length === 0) {
          state.spotlightPulseLoading = true;
          state.spotlightPulse = [];
        }
      }
    },

    setSpotlightPulseData(state, action:PayloadAction<SpotlightPulseResponse>) {
      const { payload } = action;
      state.spotlightPulse = payload?.response?.pulse;
      state.spotlightPulseLoading = false;
    },
    addToFavoriteSuccess(state, action: PayloadAction<AddToFavoriteRequest>) {
      const { articleId, favoriteFlag } = action.payload;
      if (articleId) {
        const contentData: SpotlightFeed[] = state.spotlight;
        if (contentData) {
          contentData.forEach((item) => {
            const section = item;
            if (section && section.articles) {
              const matchedArticles = section.articles.filter(
                (article) => article.articleId === articleId,
              );
              if (matchedArticles.length > 0) {
                matchedArticles.forEach((arrayItem) => {
                  const article = arrayItem;
                  article.isFavorite = favoriteFlag === 1;
                });
              }
            }
          });
        }
      }
    },
    reset: () => initialState,
  },
});

export const { actions, reducer, name: sliceKey } = SpotlightSlice;
