import React from 'react';

type Props = {
  color?: string;
};

const defaultProps = {
  color: '#667982',
};

const ShareArticleStrokeIcon: React.FC<Props> = function ({ color }) {
  return (
    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.88881 0.404053C7.59809 0.404053 7.33767 0.529958 7.16227 0.728652L4.50764 3.30515C4.13417 3.66763 4.13417 4.25533 4.50764 4.61781C4.88112 4.9803 5.48664 4.9803 5.86011 4.61781L6.93247 3.57701V12.4706C6.93247 12.9832 7.36064 13.3988 7.88881 13.3988C8.41698 13.3988 8.84515 12.9832 8.84515 12.4706V3.57701L9.91751 4.61781C10.291 4.9803 10.8965 4.9803 11.27 4.61781C11.6434 4.25533 11.6434 3.66763 11.27 3.30515L8.61535 0.728652C8.43995 0.529958 8.17953 0.404053 7.88881 0.404053ZM13.627 6.90167H12.6707C12.1425 6.90167 11.7143 7.31724 11.7143 7.82987C11.7143 8.34249 12.1425 8.75806 12.6707 8.75806H13.627V16.8934H2.15096V8.75806H3.1073C3.63547 8.75806 4.06363 8.34249 4.06363 7.82987C4.06363 7.31724 3.63547 6.90167 3.1073 6.90167H2.15096C1.09462 6.90167 0.238281 7.73281 0.238281 8.75806V16.8934C0.238281 17.9187 1.09462 18.7498 2.15096 18.7498H13.627C14.6834 18.7498 15.5397 17.9187 15.5397 16.8934V8.75806C15.5397 7.73281 14.6834 6.90167 13.627 6.90167Z" fill={color} />
    </svg>
  );
};

ShareArticleStrokeIcon.defaultProps = defaultProps;

export default ShareArticleStrokeIcon;
