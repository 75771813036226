import React from 'react';

type Props = {
  color?: string
} & typeof defaultProps;

const defaultProps = {
  color: '#1EC252',
};
const SimilarArticleIcon = function SimilarArticleIcon(props: Props) {
  const {
    color,
  } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="7" viewBox="0 0 9 7" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0.45C0 0.201474 0.201474 0 0.45 0H8.55C8.79854 0 9 0.201474 9 0.45C9 0.698535 8.79854 0.9 8.55 0.9H0.45C0.201474 0.9 0 0.698535 0 0.45ZM0 2.25C0 2.00147 0.201474 1.8 0.45 1.8H5.85C6.09854 1.8 6.3 2.00147 6.3 2.25C6.3 2.49853 6.09854 2.7 5.85 2.7H0.45C0.201474 2.7 0 2.49853 0 2.25ZM0 4.05C0 3.80147 0.201474 3.6 0.45 3.6H8.55C8.79854 3.6 9 3.80147 9 4.05C9 4.29854 8.79854 4.5 8.55 4.5H0.45C0.201474 4.5 0 4.29854 0 4.05ZM0 5.85C0 5.60147 0.201474 5.4 0.45 5.4H5.85C6.09854 5.4 6.3 5.60147 6.3 5.85C6.3 6.09854 6.09854 6.3 5.85 6.3H0.45C0.201474 6.3 0 6.09854 0 5.85Z" fill={color} />
    </svg>
  );
};
SimilarArticleIcon.defaultProps = defaultProps;
export default SimilarArticleIcon;
