/* eslint-disable @typescript-eslint/no-unused-vars */
import { GlobalRequest, TopCompanyEntityRequest } from 'Types/RequestBodies';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TopEntitiesResponse } from 'Types/TopEntitiesTypes';
import { TopEntitiesState } from 'Types/GlobalTypes';

export const initialState: TopEntitiesState = {
  entitiesLoading: false,
  companies: [],
  executives: [],
  companyCompetitors: [],
  companyCompetitorsExecutives: [],
  companyExecutives: [],
};
const topCompanyEntitySlice = createSlice({
  name: 'topEntities',
  initialState,
  reducers: {
    getTopEntities(state, action: PayloadAction<GlobalRequest>) {
      const { payload } = action;
      const { companies, executives } = state;
      if (payload && companies && companies.length === 0 && executives && executives.length === 0) {
        state.entitiesLoading = true;
        state.companies = [];
        state.executives = [];
      }
    },
    setTopEntities(state, action:PayloadAction<TopEntitiesResponse>) {
      const { payload } = action;
      state.entitiesLoading = false;
      state.executives = payload.response.executives;
      state.companies = payload.response.companies;
    },
    resetValues(state) {
      state.entitiesLoading = false;
      state.companies = [];
      state.executives = [];
    },
    getTopCompanyEntities(state, action: PayloadAction<TopCompanyEntityRequest>) {
      const { payload } = action;
      const { companyExecutives, companyCompetitors } = state;
      state.entitiesLoading = true;
      if (payload && companyExecutives && companyExecutives.length === 0
         && companyCompetitors && companyCompetitors.length === 0) {
        state.companyCompetitors = [];
        state.companyExecutives = [];
      }
    },
    setTopCompanyEntities(state, action:PayloadAction<TopEntitiesResponse>) {
      const { payload } = action;
      state.entitiesLoading = false;
      state.companyExecutives = payload.response.companyExecutives;
      state.companyCompetitorsExecutives = payload.response.companyCompetitorExecutives;
      state.companyCompetitors = payload.response.companyCompetitors;
    },
    handleCompanyFollowUnFollow(state, action:PayloadAction<string[]>) {
      const companyIds = action.payload;
      const { companyCompetitors } = state;
      for (let i = 0; i < companyIds.length; i += 1) {
        const index = companyCompetitors ? companyCompetitors.findIndex(
          (company) => company.companyId === parseInt(companyIds[i].trim(), 10),
        ) : -1;
        if (index !== -1 && companyCompetitors) {
          const competitor = companyCompetitors[index];
          competitor.companySubscribers = competitor.companySubscribed
            ? competitor.companySubscribers - 1 : competitor.companySubscribers + 1;
          competitor.companySubscribed = !competitor.companySubscribed;
          companyCompetitors[index] = competitor;
        }
      }
      state.companyCompetitors = companyCompetitors;
    },
    handlePeopleFollowUnFollow(state, action:PayloadAction<number[]>) {
      const personIds = action.payload;
      const { companyExecutives, companyCompetitorsExecutives } = state;
      for (let i = 0; i < personIds.length; i += 1) {
        const index = companyExecutives ? companyExecutives.findIndex(
          (person) => person.personId === personIds[i],
        ) : -1;
        if (index !== -1 && companyExecutives) {
          companyExecutives[index].personAdded = !companyExecutives[index]
            .personAdded;
        }
        const indexOfCExecutives = companyCompetitorsExecutives
          ? companyCompetitorsExecutives.findIndex(
            (person) => person.personId === personIds[i],
          ) : -1;
        if (indexOfCExecutives !== -1 && companyCompetitorsExecutives) {
          companyCompetitorsExecutives[indexOfCExecutives]
            .personAdded = !companyCompetitorsExecutives[indexOfCExecutives]
              .personAdded;
        }
      }
      state.companyExecutives = companyExecutives;
      state.companyCompetitorsExecutives = companyCompetitorsExecutives;
    },
    handleFollowGroupPeople(state, action: PayloadAction<number>) {
      const { payload } = action;
      state.companyCompetitorsExecutives?.forEach((staff) => {
        const person = staff;
        if (person.personId === payload) {
          person.personAdded = true;
        }
      });
    },
    handlePersonNotes(state, action: PayloadAction<number>) {
      const id = action.payload;
      const members = state.companyCompetitorsExecutives ?? [];
      const filteredPerson = members
        .find((person) => person.personId === id);
      if (filteredPerson) {
        filteredPerson.personNotes = 0;
      }
    },
  },
});
export const { actions, reducer, name: sliceKey } = topCompanyEntitySlice;
