/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GuestState } from 'Types/GlobalTypes';
import {
  GuestLoginResponse, GuestUserResponse, GuestLoginDataResponse,
} from 'Types/GuestSearchResultTypes';
import { GuestloginRequest } from 'Types/RequestBodies';
import _ from 'lodash';
import { PersonPopupResponse } from 'Types/PersonTypes';

export const initialState: GuestState = {
  message: '',
  status: false,
  loginResp: {} as GuestLoginDataResponse,
  bookADemoURL: '',
  popUpResponse: {} as PersonPopupResponse,
  loading: false,
  searchResult: [],
};

const guestUserSlice = createSlice({
  name: 'guest',
  initialState,
  reducers: {
    getGuestUserData(state, action: PayloadAction<GuestloginRequest>) {
      state.loading = true;
    },
    setGuestUserData(state, action: PayloadAction<GuestLoginResponse>) {
      const { message, response, status } = action.payload;
      const { result } = response;
      state.message = message;
      state.status = status;
      state.popUpResponse = response.popUpResponse;
      state.bookADemoURL = response.bookADemoURL;
      state.searchResult = result?.search_content
        ?.persons?.concat(result?.linkedin_search?.persons);
      if (!_.isEmpty(response.result.login_data)) {
        state.loginResp = response.result.login_data.login_data.response;
      }
      if (response?.popUpResponse?.showPlanPopup === 1) {
        state.loading = false;
      }
      if (response?.popUpResponse?.showPlanPopup === 0) {
        state.loading = false;
      }
    },
  },
});

export const { actions, reducer, name: sliceKey } = guestUserSlice;
