import React from 'react';

type Props = {
  color?: string;
} & typeof defaultProps;

const defaultProps = {
  color: '#000000',
};

const FeedSvg = function FeedSvg(props: Props) {
  const { color } = props;
  return (
    <svg width="23px" height="23px" viewBox="0 0 23 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="People-Copy-3" transform="translate(-32.000000, -188.000000)" fill={color} fillRule="nonzero">
          <g id="SideNav-SalesApp" transform="translate(0.000000, -1.000000)">
            <g id="Group" transform="translate(9.000000, 170.000000)">
              <g id="noun-list-1106023" transform="translate(23.000000, 19.000000)">
                <path d="M7.66666667,0 L2.55568333,0 C1.15038333,0 0,1.15 0,2.55568333 L0,7.66666667 C0,9.07235 1.15038333,10.22235 2.55568333,10.22235 L7.66666667,10.22235 C9.07158333,10.22235 10.22235,9.07235 10.22235,7.66666667 L10.22235,2.55568333 C10.22235,1.15 9.07158333,0 7.66666667,0 Z M7.66666667,7.66666667 L2.55568333,7.66666667 L2.55568333,2.55568333 L7.66666667,2.55568333 L7.66666667,7.66666667 Z" id="Shape" />
                <rect id="Rectangle" x="12.77765" y="1.27765" width="7.66666667" height="2.55568333" />
                <rect id="Rectangle" x="12.77765" y="6.38901667" width="10.22235" height="2.55568333" />
                <rect id="Rectangle" x="12.77765" y="14.0553" width="5.11136667" height="2.55606667" />
                <rect id="Rectangle" x="12.77765" y="19.1666667" width="7.66666667" height="2.5553" />
                <path d="M7.66666667,12.7780333 L2.55568333,12.7780333 C1.15038333,12.7780333 0,13.9280333 0,15.3333333 L0,20.4447 C0,21.8492333 1.15038333,23 2.55568333,23 L7.66666667,23 C9.07158333,23 10.22235,21.8492333 10.22235,20.4447 L10.22235,15.3333333 C10.22235,13.9280333 9.07158333,12.7780333 7.66666667,12.7780333 Z M7.66666667,20.4447 L2.55568333,20.4447 L2.55568333,15.3333333 L7.66666667,15.3333333 L7.66666667,20.4447 Z" id="Shape" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

FeedSvg.defaultProps = defaultProps;
export default FeedSvg;
