/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PersonProfileCareer, PersonProfileState } from 'Types/GlobalTypes';
import {
  AIDigestResponse,
  AIDigestResponseData,
  CareerDataObjectType,
  CareerTabDataObjectType,
  CreateDigestResponse,
  Executive,
  GeneratedEmail,
  InsightsCompatibility,
  PersonAchievementType,
  PersonalityInfo, PersonalityInfoResponse,
  PersonalityInsights,
  PersonCard,
  PersonCardResponse,
  PersonCareerResponseType,
  PersonCompanyState, PersonCurrentPositionCompanyDataType,
  PersonEducationDataType, PersonHighlightedInfo,
  PersonIntentData, PersonManualBoardAndCommitteesDataObject, PersonNews,
  PersonPersonalDetailsDataType, PersonPersonalDetailsType,
  PersonPersonalInfo,
  PersonProfile, PersonProfileDownloadType, PersonProfilePersonalDetailInfo,
  PersonResponseType, PersonResultType, PersonSocialType,
  PersonThemeResponse, PopUpResponse, PopupValuesType,
  ResponseExport,
  SocialLinks,
  WorkbenchStatsResponseType,
} from 'Types/PersonTypes';
import {

  AddToFavoriteRequest,
  CreateAIDigestRequest,
  EditPhoneEmailRequest,
  EmailFeedback,
  ExportSimilarPeopleRequest,
  GenerateEmail,
  GetPersonProfileRequest,
  GetPersonProfileSocialRequest, PersonCompanyCareer, SimilarPeopleRequest,
  UpdatePersonProfileFollow,
} from 'Types/RequestBodies';

import { getLocalStorageValue } from 'Utils/UtilityFunctions';
import { Company } from 'Types/CompaniesTypes';
import { PersonVideosType } from '../Types/PersonTypes.d';

export const initialState: PersonProfileState = {
  tabs: ['xiQ insights', 'News', 'Videos', 'Career', 'Personal'],
  cardResult: [],
  popups: {
    allowFullView: false,
    contactusEmail: '',
    popupHtml: '',
    popupText: '',
    showPlanPopup: 0,
    popupHeading: '',
    isDownloadPopup: false,
    callToAction: '',
    buttonText: '',
  },
  profile: {
    loading: false,
    data: {} as PersonProfile,
    followLoading: false,
  },
  similarPeopleLoading: false,
  insights: {
    loading: false,
    insightsData: [],
  },
  news: {
    loading: false,
    page: 0,
    articles: [],
    title: '',
  },
  intent: {
    loading: false,
    data: [] as string[],
    contactusEmail: '',
    popupText: '',
    intentAllowed: 0,
    popupHeading: '',
    url: '',
  },
  personalDetails: {
    highlightedInfo: [],
    personalInfo: {
      about: '',
      address: '',
      phoneNumber: '',
      family: '',
    },
    hobbies: [],
    interests: [],
    loading: false,
    title: '',
  },
  career: {
    loading: false,
    title: '',
    achievements: {
      data: [] as PersonAchievementType[],
      title: '',
    },
    manualAchievements: {
      title: '',
      data: '',
    },
    skills: {
      title: '',
      data: [] as string[],
    },
    boardsAndCommittees: {
      title: '',
      data: [] as string[],
    },
    manualBoardsAndCommittees: {
      title: '',
      data: '',
    },
    volunteerWork: {
      title: '',
      data: '',
    },
    workHistory: {
      title: '',
      data: [] as PersonCompanyState[],
    },
    currentPosition: {
      title: '',
      data: [] as PersonCurrentPositionCompanyDataType[],
    },
    educationHistory: {
      title: '',
      data: [] as PersonEducationDataType[],
    },
  } as unknown as PersonProfileCareer,
  video: {
    loading: false,
    page: 0,
    articles: [],
  },
  downloadProfile: {
    loading: false,
    link: '',
  },
  workbenchStats: {
    loading: false,
    isEmptyWorkbenchStats: true,
    data: [] as WorkbenchStatsResponseType[],
  },
  newsFlurry: {},
  socialFlurry: {},
  videoFlurry: {},
  newsApiCall: false,
  socialApiCall: false,
  videoApiCall: false,
  message: '',
  searchedPerson: {
    loading: false,
    insightsData: [],
    selectedPerson: {} as Executive,
  },
  bookADemoURL: '',
  newNotes: 0,
  showLanguagePopup: false,
  chatGptAllowed: 0,
  personNotesAvailable: 0,
  personalityDrawerOpen: false,
  email: {
    generateEmailData: {} as GenerateEmail,
    emailLoading: false,
    generatedEmailView: false,
    generatedEmail: {} as GeneratedEmail,
  },
  feedbackSubmitted: false,
  similarPeopleData: {
    page: 1,
    people: [],
    loading: false,
    loaded: false,
    ended: false,
    count: 0,
    title: '',
    name: '',
    savedId: 0,
  },
  similarPeopleFilters: {
    loading: false,
    loaded: false,
    filters: [],
    appliedFilters: [],
  },
  exportSimilarPeopleLoading: false,
  editPhoneEmailLoading: false,
  isEditingEmail: false,
  isEditingPhone: false,
  isRecommended: false,
  dossierAllowed: false,
  personThemeLoading: false,
  personThemeResponse: {
    themes: [],
    responseId: 0,
  },
  personDigestLoading: false,
  redirectWorkbench: false,
  showDigestPopup: false,
  createDigestData: {
    campaignId: 0,
    emailId: 0,
  },
};

const PersonProfileSlice = createSlice({
  name: 'personProfile',
  initialState,
  reducers: {
    getPersonProfile(state, action: PayloadAction<GetPersonProfileRequest>) {
      if (state.profile.loading === false) { state.profile.loading = true; }
    },
    getPersonTheme(state, action: PayloadAction<GetPersonProfileRequest>) {
      state.personThemeLoading = true;
    },
    setPersonTheme(state, action:PayloadAction<PersonThemeResponse>) {
      state.personThemeLoading = false;
      state.personThemeResponse = action.payload.response;
    },
    createAIDigest(state, action:PayloadAction<CreateAIDigestRequest>) {
      state.personDigestLoading = true;
    },
    workbenchRedirect(state, action:PayloadAction<boolean>) {
      state.redirectWorkbench = action.payload;
    },
    setPersonDigest(state, action:PayloadAction<AIDigestResponseData>) {
      state.personDigestLoading = false;
      state.createDigestData = action.payload;
    },
    setShowDigestPopup(state, action:PayloadAction<boolean>) {
      state.showDigestPopup = action.payload;
    },
    setPersonProfile(state, action: PayloadAction<PersonCardResponse>) {
      const { payload } = action;
      const {
        result, popUpResponse, bookADemoURL, newNotes, personNotes, chatgptAllowed,
        showLanguagePopup, dossierAllowed,
      } = payload;
      state.newNotes = newNotes;
      state.dossierAllowed = dossierAllowed;
      state.showLanguagePopup = showLanguagePopup;
      state.personNotesAvailable = personNotes;
      state.cardResult = result;
      state.bookADemoURL = bookADemoURL;
      state.chatGptAllowed = chatgptAllowed;
      const { data: newData } = state.profile;
      let newTabs = state?.tabs;
      const {
        allowFullView, showPlanPopup,
        contactusEmail, popupHtml, popupText,
        popupHeading, callToAction, buttonText,
      } = popUpResponse;
      if (showPlanPopup === 3 || showPlanPopup === 8) {
        state.popups.showPlanPopup = showPlanPopup;
        state.popups.contactusEmail = contactusEmail;
        state.popups.popupHtml = popupHtml;
        state.popups.popupText = popupText;
        state.popups.popupHeading = popupHeading;
        state.popups.allowFullView = allowFullView;
        state.popups.callToAction = callToAction;
        state.popups.buttonText = buttonText;
      } else {
        state.popups.showPlanPopup = showPlanPopup;
        state.popups.contactusEmail = contactusEmail;
        state.popups.popupHtml = popupHtml;
        state.popups.popupText = popupText;
        state.popups.popupHeading = popupHeading;
        state.popups.allowFullView = allowFullView;
        state.popups.callToAction = callToAction;

        result.forEach((item: PersonResponseType | PersonResultType) => {
          const {
            type, data, details, count,
          } = item;
          if (type === 'personCard') {
            newData.personCard = (details as PersonCard[])?.length > 0
              ? (details as PersonCard[])[0] : {} as PersonCard;
            const personCard = (details as PersonCard[])[0];
            const insight = state.insights.insightsData.find((i) => i.type === 'compatibility') as InsightsCompatibility;
            const insightIndex = state.insights.insightsData.findIndex((i) => i.type === 'compatibility');
            if (insightIndex !== -1) {
              insight.data[0].persons[1].personImage = personCard?.personImage;
              state.insights.insightsData[insightIndex].data = insight.data;
            }
          }
          if (type === 'personalityInfo') {
            const personalityInfo: PersonalityInfo = {
              behaviour: {},
              motivations: {},
              qualities: {},
              communicationTips: {},
              tips: {},
              discImage: '',
            } as PersonalityInfo;

            const personalityData: PersonalityInfoResponse[] = data;
            personalityData.map((element: PersonalityInfoResponse) => {
              const {
                type: newType, dataList, description, data: dataString,
              } = element;
              if (newType === 'behaviour') {
                personalityInfo.behaviour.description = description;
              }
              if (newType === 'qualities') {
                personalityInfo.qualities.dataList = dataList;
              }
              if (newType === 'communicationTips') {
                personalityInfo.communicationTips.dataList = dataList;
              }
              if (newType === 'motivations') {
                personalityInfo.motivations.description = description;
                personalityInfo.motivations.dataList = dataList;
              }
              if (newType === 'tips') {
                personalityInfo.tips.dataList = dataList;
              }
              if (newType === 'discImage') {
                personalityInfo.discImage = dataString;
              }
              return 0;
            });
            newData.personalityInfo = personalityInfo;
          }
          if (type === 'socialLinks') { newData.socialLinks = { data: details } as SocialLinks; }
          if (type === 'career' && !(details as PersonCareerResponseType).data.some((x: CareerTabDataObjectType) => Object.keys(x).some((key) => (key === 'data' || key === 'dataset' || key === 'description')
            && x[key] && x[key].length > 0) && x.type !== 'currentPosition')) {
            newTabs = newTabs.filter((tab) => tab !== 'Career');
          }
          if (type === 'personalDetails' && count === 0) { newTabs = newTabs.filter((tab) => tab !== 'Personal'); }
        });
        state.profile.data = { ...newData };
        state.tabs = newTabs;
        state.profile.loading = false;
      }
    },
    setProfileLoading(state, action: PayloadAction<boolean>) {
      state.profile.loading = action.payload;
    },
    setPersonNotesAvailable(state, action: PayloadAction<number>) {
      const { payload } = action;
      state.personNotesAvailable = payload;
    },
    resetNewNotes(state) {
      state.newNotes = 0;
    },
    updatePersonProfileFollow(state, action: PayloadAction<UpdatePersonProfileFollow>) {
      if (!state.profile.followLoading) {
        state.profile.followLoading = true;
      }
    },

    setPersonProfileFollowSuccess(state, action: PayloadAction<UpdatePersonProfileFollow>) {
      state.profile.followLoading = false;
      const { payload } = action;
      const { feedids, person_data: personData } = payload;
      if (feedids && feedids?.length > 0) {
        state.profile.data.personCard.personAdded = false;
      } else if (personData && personData?.length > 0) {
        state.profile.data.personCard.personAdded = true;
      }
    },

    setPersonProfileFollowFailure(state, action: PayloadAction<UpdatePersonProfileFollow>) {
      state.profile.followLoading = false;
    },

    getPersonProfileInsights(state, action: PayloadAction<GetPersonProfileRequest>) {
      state.insights.loading = true;
    },
    setPersonProfileInsights(state, action: PayloadAction<PersonalityInsights[]>) {
      state.insights.loading = false;
      state.insights.insightsData = action.payload;
    },

    setPersonProfileInsightsInitial(state, action: PayloadAction<PersonalityInsights[]>) {
      state.searchedPerson.loading = false;
      state.searchedPerson = {
        loading: false,
        insightsData: [],
        selectedPerson: {} as Executive,
      };
      if (action.payload.length) {
        state.insights.insightsData.splice(0, 2, action.payload[0], action.payload[1]);
      }
    },

    getSearchedPersonProfileInsights(state, action: PayloadAction<GetPersonProfileRequest>) {
      state.searchedPerson.loading = true;
    },
    setSearchedPersonProfileInsights(state, action: PayloadAction<PersonalityInsights[]>) {
      state.searchedPerson.loading = false;
      state.searchedPerson.insightsData = action.payload;
    },
    getSimilarPeople(state, action: PayloadAction<SimilarPeopleRequest>) {
      state.similarPeopleData.loading = true;
    },
    setSimilarPeople(state, action: PayloadAction<PersonalityInsights>) {
      const {
        data, count, title, filters, name,
      } = action.payload;

      state.similarPeopleData.loading = false;
      state.similarPeopleData.title = title;
      state.similarPeopleData.name = name ?? '';
      state.insights.insightsData = state.insights.insightsData.concat(action.payload);
      if (state.similarPeopleData.page === 1) {
        state.similarPeopleData.people = data as Executive[];
      } else {
        state.similarPeopleData.people = state.similarPeopleData.people
          .concat(data as Executive[]);
      }
      state.similarPeopleData.count = count ?? 0;
      if (!data.length) {
        state.similarPeopleData.ended = true;
      }
      if (filters?.length && state.similarPeopleData.page === 1
        && !state.similarPeopleData.loaded) {
        state.similarPeopleFilters.filters = filters.map((filter) => ({
          ...filter,
          isSelected: true,
        }));
      }
      state.similarPeopleData.page += 1;
      state.similarPeopleData.loaded = true;
    },
    setEmptySimilarPeople(state, action: PayloadAction<Executive[]>) {
      state.similarPeopleData.loading = false;
      state.similarPeopleData.people = state.similarPeopleData.people
        .concat(action.payload);
    },
    setSimilarPeopleFilters(state, action: PayloadAction<Company[]>) {
      state.similarPeopleFilters.filters = action.payload;
    },
    setSimilarPeoplePage(state, action: PayloadAction<number>) {
      state.similarPeopleData.page = action.payload;
    },
    getSimilarPeopleExport(state, action: PayloadAction<ExportSimilarPeopleRequest>) {
      state.exportSimilarPeopleLoading = true;
    },
    setSimilarPeopleExport(state, action: PayloadAction<ResponseExport>) {
      state.exportSimilarPeopleLoading = false;
      const { url } = action.payload;
      const linkO = document.createElement('a');
      linkO.href = url;
      linkO.dispatchEvent(new MouseEvent('click'));
    },
    setSearchedPersonPopUp(state, action: PayloadAction<PopUpResponse>) {
      const {
        allowFullView, showPlanPopup,
        contactusEmail, popupHtml, popupText,
        popupHeading, callToAction,
      } = action.payload;
      state.popups.showPlanPopup = showPlanPopup;
      state.popups.contactusEmail = contactusEmail;
      state.popups.popupHtml = popupHtml;
      state.popups.popupText = popupText;
      state.popups.popupHeading = popupHeading;
      state.popups.allowFullView = allowFullView;
      state.popups.callToAction = callToAction;
    },

    getPersonProfileIntentData(state, action: PayloadAction<GetPersonProfileRequest>) {
      if (!state.intent.loading) { state.intent.loading = true; }
    },

    setPersonProfileIntentData(state, action: PayloadAction<PersonIntentData>) {
      state.intent.loading = false;
      const { payload } = action;
      const {
        results, popupHeading, popupText,
        contactusEmail, intentAllowed, url,
      } = payload;
      state.intent.data = results;
      state.intent.popupHeading = popupHeading;
      state.intent.popupText = popupText;
      state.intent.contactusEmail = contactusEmail;
      state.intent.intentAllowed = intentAllowed;
      state.intent.url = url;
    },

    setSelectedSearchedPerson(state, action: PayloadAction<Executive>) {
      state.searchedPerson.selectedPerson = action.payload;
    },

    resetSelectedSearchedPerson(state) {
      state.searchedPerson.selectedPerson = {} as Executive;
    },

    getPersonProfilePersonalDetails(state, action: PayloadAction<GetPersonProfileRequest>) {
      if (!state.personalDetails.loading) { state.personalDetails.loading = true; }
    },

    setPersonProfilePersonalDetails(state, action: PayloadAction<PersonPersonalDetailsType>) {
      const { payload } = action;
      const { data, title } = payload;
      const personalDetailsInfo: PersonProfilePersonalDetailInfo = {
        highlightedInfo: [],
        personalInfo: {
          about: '',
          address: '',
          phoneNumber: '',
          family: '',
        },
        hobbies: [],
        interests: [],
        loading: false,
        title: '',
      } as PersonProfilePersonalDetailInfo;
      personalDetailsInfo.title = title;
      if (data.length > 0) {
        data.forEach((item: PersonPersonalDetailsDataType) => {
          if (item.type === 'highlightedInfo') {
            personalDetailsInfo.highlightedInfo = item.data;
          }
          if (item.type === 'personalInfo') {
            // personalDetailsInfo.personalInfo = item.data as PersonPersonalInfo[];
            const personalInfo: PersonPersonalInfo = {} as PersonPersonalInfo;
            item.data.forEach((element: PersonHighlightedInfo) => {
              if (element.title === 'About') {
                personalInfo.about = element.description;
              }
              if (element.title === 'Phone Number') {
                personalInfo.phoneNumber = element.description;
              }
              if (element.title === 'Address') {
                personalInfo.address = element.description;
              }
              if (element.title === 'Family members') {
                personalInfo.family = element.description;
              }
            });
            personalDetailsInfo.personalInfo = personalInfo;
          }

          if (item.type === 'hobbies') {
            personalDetailsInfo.hobbies = item.dataList;
          }
          if (item.type === 'manuelInterests') {
            personalDetailsInfo.interests = item.dataList;
          }
        });
      }
      state.personalDetails = personalDetailsInfo;
    },

    getPersonProfileCareer(state, action: PayloadAction<GetPersonProfileRequest>) {
      if (!state.career.loading) { state.career.loading = true; }
    },

    setPersonProfileCareer(state, action: PayloadAction<PersonCareerResponseType>) {
      const { payload } = action;
      const { data, title } = payload;
      state.career.title = title;
      state.career.loading = false;
      const object = {
        achievements: {},
        manualAchievements: {},
        skills: {},
        boardsAndCommittees: {},
        manualBoardsAndCommittees: {},
        volunteerWork: {},
        workHistory: {},
        educationHistory: {},
        currentPosition: {},
        goals: {},
        initiative: {},
        compensationSalary: {},
      } as PersonProfileCareer;
      data.forEach((item) => {
        const {
          type, title: Title, data: Data, dataList, description,
        } = item;
        if (type === 'achievements') {
          object.achievements.title = Title;
          object.achievements.data = Data as PersonAchievementType[];
        }
        if (type === 'manualAchievements') {
          object.manualAchievements.title = Title;
          object.manualAchievements.data = Data as PersonManualBoardAndCommitteesDataObject[];
        }
        if (type === 'skills') {
          object.skills.title = Title;
          object.skills.data = dataList;
        }
        if (type === 'boardsAndCommittees') {
          object.boardsAndCommittees.title = Title;
          object.boardsAndCommittees.data = Data as string[];
        }
        if (type === 'manualBoardsAndComittees') {
          object.manualBoardsAndCommittees.title = Title;
          object
            .manualBoardsAndCommittees.data = Data as PersonManualBoardAndCommitteesDataObject[];
        }
        if (type === 'volunteerWork') {
          object.volunteerWork.title = Title;
          object.volunteerWork.data = Data as PersonManualBoardAndCommitteesDataObject[];
        }
        if (type === 'groupExperience') {
          object.workHistory.title = Title;
          object.workHistory.data = description;
        }
        if (type === 'educationHistory') {
          object.educationHistory.title = Title;
          object.educationHistory.data = Data as PersonEducationDataType[];
        }
        if (type === 'currentPosition') {
          object.currentPosition.title = Title;
          object.currentPosition.data = Data as PersonCurrentPositionCompanyDataType[];
        }
        if (type === 'goals') {
          object.goals.title = Title;
          object.goals.data = Data as string[];
        }
        if (type === 'initiative') {
          object.initiative.title = Title;
          object.initiative.data = Data as string[];
        }
        if (type === 'compensationSalary') {
          object.compensationSalary.title = Title;
          object.compensationSalary.data = Data as PersonManualBoardAndCommitteesDataObject[];
        }
        state.career = { ...state.career, ...object };
      });
    },

    getPersonProfileNews(state, action: PayloadAction<GetPersonProfileRequest>) {
      if (!state.news.loading) {
        state.news.loading = true;
      }
    },

    setPersonProfileNews(state, action: PayloadAction<PersonNews>) {
      if (state.news.loading) { state.news.loading = false; }
      const { payload } = action;
      const { articles, title } = payload;
       state.news.articles = [...state.news.articles, ...articles];
       state.news.title = 'News';
      if (state.news.page === 0 && articles.length === 0
         && state.workbenchStats.isEmptyWorkbenchStats) {
        let newTabs = state?.tabs;
        newTabs = newTabs.filter((tab) => tab !== 'News');
        state.tabs = newTabs;
        state.news.title = '';
        state.news.articles=[]
      } else {
        state.news.page += 1;
      }
    },

    getPersonProfileVideos(state, action: PayloadAction<GetPersonProfileSocialRequest>) {
      const { payload } = action;
      if (payload.page === 1) {
        state.video.articles = [];
        state.video.page = 1;
      }
      state.video.loading = true;
    },

    setPersonProfileVideos(state, action: PayloadAction<PersonVideosType>) {
      if (state.video.loading) { state.video.loading = false; }
      const { payload } = action;
      const { articles: articlesData } = payload;
      state.video.articles = articlesData.articles;
      if (state.video.page === 1 && articlesData.articles.length === 0) {
        const newTabs = state?.tabs;
        state.tabs = newTabs.filter((tab) => tab !== 'Videos');
      }
    },

    setMorePersonProfileVideos(state, action: PayloadAction<PersonVideosType>) {
      const { payload } = action;
      const { articles: articlesData } = payload;
      state.video.loading = false;
      state.video.articles = [...state.video.articles, ...articlesData.articles];
      state.video.page += 1;
    },

    getPersonProfileDownload(state, action: PayloadAction<GetPersonProfileRequest>) {
      if (!state.downloadProfile.loading) { state.downloadProfile.loading = true; }
    },

    setPersonProfileDownload(state, action: PayloadAction<PersonProfileDownloadType>) {
      if (state.downloadProfile.loading) { state.downloadProfile.loading = false; }
      const { payload } = action;
      const {
        link, showPlanPopup, popupText, popupHeading, contactusEmail,
      } = payload;
      state.downloadProfile.loading = false;

      if (showPlanPopup === 3 || showPlanPopup === 4 || showPlanPopup === 5) {
        state.popups.popupHeading = popupHeading;
        state.popups.popupText = popupText;
        state.popups.showPlanPopup = showPlanPopup;
        state.popups.contactusEmail = contactusEmail;
        state.popups.isDownloadPopup = true;
      } else {
        state.downloadProfile.link = link;
        const linkO = document.createElement('a');
        linkO.href = link;
        const name = state.profile.data.personCard.personName;
        linkO.download = `${name.replace(' ', '')}_xiQ_Profile.pdf`;
        linkO.dispatchEvent(new MouseEvent('click'));
      }
    },

    failPersonProfileDownload(state, action: PayloadAction<string>) {
      if (state.downloadProfile.loading) { state.downloadProfile.loading = false; }
    },

    setPopupValues(state, action: PayloadAction<PopupValuesType>) {
      const { payload } = action;
      const {
        popupHeading, popupText, showPlanPopup, contactusEmail,
      } = payload;
      state.popups.popupHeading = popupHeading;
      state.popups.popupText = popupText;
      state.popups.showPlanPopup = showPlanPopup;
      state.popups.contactusEmail = contactusEmail;
    },

    showGenerateContentRestrictedAccessPopup(state, action: PayloadAction<boolean>) {
      const { payload } = action;
      if (payload) {
        const { contactusEmail, popupHeading, popupText } = state.intent;
        state.popups.popupHeading = popupHeading;
        state.popups.popupText = popupText;
        state.popups.contactusEmail = contactusEmail;
        state.popups.showPlanPopup = 6;
      }
    },

    resetPersonPopups(state) {
      state.popups.popupHeading = '';
      state.popups.popupText = '';
      state.popups.showPlanPopup = 0;
      state.popups.contactusEmail = '';
      state.popups.isDownloadPopup = false;
    },
    getPersonWorkbenchStats(state, action: PayloadAction<number>) {
      if (state.workbenchStats.loading === false) { state.workbenchStats.loading = true; }
      state.workbenchStats.data = [];
      state.workbenchStats.isEmptyWorkbenchStats = true;
    },
    setPersonWorkbenchStats(state, action: PayloadAction<WorkbenchStatsResponseType[]>) {
      const { payload } = action;
      if (payload) {
        state.workbenchStats.loading = false;
        state.workbenchStats.data = payload;
        state.workbenchStats.isEmptyWorkbenchStats = false;
        const index = state.tabs.indexOf('News');
        if (index === -1) {
          const arr = state.tabs;
          arr.splice(1, 0, 'News');
          state.tabs = arr;
        }
      }
    },
    setEmptyPersonWorkbenchStats(state) {
      state.workbenchStats.loading = false;
      state.workbenchStats.data = [];
      state.workbenchStats.isEmptyWorkbenchStats = true;
    },
    failPersonWorkbenchStats(state, action: PayloadAction<string>) {
      if (state.workbenchStats.loading) { state.workbenchStats.loading = false; }
      state.workbenchStats.isEmptyWorkbenchStats = false;
    },
    addToFavoriteSuccess(state, action: PayloadAction<AddToFavoriteRequest>) {
      const { articleId, favoriteFlag } = action.payload;
      if (articleId) {
        if (state.news && state.news.articles.length) {
          const matchedArticles = state.news.articles.filter(
            (article) => article.articleId === articleId,
          );
          if (matchedArticles.length > 0) {
            matchedArticles.forEach((arrayItem) => {
              const article = arrayItem;
              article.isFavorite = favoriteFlag === 1;
            });
          }
        }
      }
    },
    setSocialFlurry(state, action: PayloadAction<object>) {
      const { payload } = action;
      state.socialFlurry = payload;
    },
    setVideoFlurry(state, action: PayloadAction<object>) {
      const { payload } = action;
      state.videoFlurry = payload;
    },
    setNewsFlurry(state, action: PayloadAction<object>) {
      const { payload } = action;
      state.newsFlurry = payload;
    },
    setNewsCall(state, action: PayloadAction<boolean>) {
      const { payload } = action;
      state.newsApiCall = payload;
    },
    setSocialCall(state, action: PayloadAction<boolean>) {
      const { payload } = action;
      state.socialApiCall = payload;
    },
    setVideoCall(state, action: PayloadAction<boolean>) {
      const { payload } = action;
      state.socialApiCall = payload;
    },
    setPersonalityDrawerOpen(state, action: PayloadAction<boolean>) {
      state.personalityDrawerOpen = action.payload;
    },
    getPersonCareerCompany(state, action: PayloadAction<PersonCompanyCareer>) {

    },
    setPersonCareerCompany(state, action: PayloadAction<PersonCompanyCareer>) {
      const { payload } = action;
      const { career } = state;
      if (career.workHistory && career.workHistory && state.career.workHistory.data.length) {
        career.workHistory.data = career.workHistory.data.map((d) => {
          if (d.fullName === payload.companyName) {
            return { ...d, companyId: payload.companyId };
          }

          return d;
        });
      }

      state.career = career;
    },
    generateEmail(state, action: PayloadAction<GenerateEmail>) {
      const { payload } = action;
      state.email.emailLoading = true;
      state.email.generateEmailData = payload;
      state.feedbackSubmitted = false;
    },
    setChat(state, action: PayloadAction<GeneratedEmail>) {
      const {
        body, subject, responseId, prompt,
      } = action.payload;
      const updatedBody = body.replace(/\n/g, '<br />');
      const senderName = getLocalStorageValue('fullname');
      const data = {
        subject,
        body: updatedBody.replace(/\[Your Name\]/gi, senderName || ''),
        prompt,
        responseId,
      };
      state.email.generatedEmail = data;
      state.email.generatedEmailView = true;
      state.email.emailLoading = false;
    },
    setGenerateEmailLoading(state, action: PayloadAction<boolean>) {
      state.email.emailLoading = action.payload;
    },
    setGeneratedEmailView(state, action:PayloadAction<boolean>) {
      state.email.generatedEmailView = action.payload;
    },
    sendFeedback(state, action: PayloadAction<EmailFeedback>) {

    },
    setFeedbackSubmitted(state, action: PayloadAction<boolean>) {
      state.feedbackSubmitted = action.payload;
    },
    handleSimilarPeopleFollowUnfollow(state, action: PayloadAction<number[]>) {
      const { payload } = action;
      const index = state.similarPeopleData.people
        .findIndex((person) => person.personId === payload[0]);
      if (index !== -1) {
        const flag = state.similarPeopleData.people[index].personAdded;
        state.similarPeopleData.people[index].personAdded = !flag;
      }
    },
    saveProfile(state, action: PayloadAction<number>) {
      state.similarPeopleData.savedId = action.payload;
    },
    editPhoneEmail(state, action: PayloadAction<EditPhoneEmailRequest>) {
      state.editPhoneEmailLoading = true;
    },
    phoneEmailEdited(state, action: PayloadAction<EditPhoneEmailRequest>) {
      state.editPhoneEmailLoading = false;
      if (action.payload.email) {
        state.profile.data.personCard.email = action.payload.email;
      } else if (action.payload.phone) {
        state.profile.data.personCard.phone = action.payload.phone;
      }
    },
    setEditPhoneEmailLoading(state, action: PayloadAction<boolean>) {
      state.editPhoneEmailLoading = action.payload;
    },
    setIsEditingEmail(state, action: PayloadAction<boolean>) {
      state.isEditingEmail = action.payload;
    },
    setIsEditingPhone(state, action: PayloadAction<boolean>) {
      state.isEditingPhone = action.payload;
    },
    setIsRecommended(state, action: PayloadAction<boolean>) {
      state.isRecommended = action.payload;
    },
    resetWithoutSimiarPeople: (state) => ({
      ...initialState,
      similarPeopleData: state.similarPeopleData,
      similarPeopleFilters: state.similarPeopleFilters,
      exportSimilarPeopleLoading: state.exportSimilarPeopleLoading,
    }),
    reset: (state) => ({
      ...initialState,
      isRecommended: state.isRecommended,
    }),
  },
});

export const { actions, reducer, name: sliceKey } = PersonProfileSlice;
