/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AlertsArticlesRequest, AlertsResponse, Company, NotificationAlertRequest,
  NotificationOpenedRequest, NotificationResponse,
} from 'Types/CompaniesTypes';
import { AlertsState } from 'Types/GlobalTypes';
import { AddToFavoriteRequest } from 'Types/RequestBodies';

export const initialState: AlertsState = {
  articles: [],
  articlesLoading: false,
  toggle: false,
  articlesPage: 1,
  articlesEnded: false,
  notificationPulse: [],
  selectedFilters: [],
  selectedPeopleFilters: [],
  selectedCompanies: [],
  unSelectedCompanies: [],
  selectAllCompanies: true,
  openDrawer: false,
  notificationsLoading: false,
  peopleNotifications: [],
  notificationsPage: 1,
  notificationsEnded: false,
  openedLoading: false,
  peopleMessage: '',
};

const AlertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    toggleScreen(state, action: PayloadAction<boolean>) {
      state.toggle = action?.payload;
    },
    getAlertArticles(state, action: PayloadAction<AlertsArticlesRequest>) {
      const { payload } = action;
      if (payload.page === 1) {
        if (payload.filter?.length > 0) {
          state.selectedFilters = payload?.filter;
        }
        state.articles = [];
        state.notificationPulse = [];
        state.articlesPage = 1;
      }
      state.articlesLoading = true;
      state.articlesEnded = false;
    },
    setMoreAlertArticles(state, action: PayloadAction<AlertsResponse>) {
      const { payload } = action;
      state.articlesPage += 1;
      state.articles = state.articles.concat(payload?.articles);
    },
    setArticlesEnded(state) {
      state.articlesEnded = true;
      state.articlesLoading = false;
    },
    setAlertArticles(state, action:PayloadAction<AlertsResponse>) {
      const { payload } = action;
      state.articlesLoading = false;
      state.articles = payload?.articles;
      state.notificationPulse = payload?.notificationPulse;
      state.articlesPage += 1;
    },
    setUnSelectedAll(state, action: PayloadAction<boolean>) {
      state.selectAllCompanies = action.payload;
    },
    setUnSelectedCompanies(state, action: PayloadAction<Company[]>) {
      const { payload } = action;
      if (payload.length < 1) state.unSelectedCompanies = [];
      else { state.unSelectedCompanies = payload; }
    },
    setSelectedCompanies(state, action: PayloadAction<Company[]>) {
      const { payload } = action;
      if (payload.length < 1) state.selectedCompanies = [];
      else { state.selectedCompanies = payload; }
    },
    handleCompanyUnfollow(state, action: PayloadAction<string[]>) {
      const companiesToRemoveIds = action.payload.map(Number);
      state.unSelectedCompanies = state.unSelectedCompanies.filter(
        (company) => !companiesToRemoveIds.includes(company.companyId),
      );
      state.selectedCompanies = state.selectedCompanies.filter(
        (company) => !companiesToRemoveIds.includes(company.companyId),
      );
    },
    isReadAlert(state, action:PayloadAction<number>) {
      const { payload } = action;
      state.articles.forEach((item) => {
        const article = item;
        if (article.articleId === payload) {
          article.isRead = true;
        }
      });
    },
    setDrawerState(state) {
      state.openDrawer = true;
    },
    addToFavoriteSuccess(state, action: PayloadAction<AddToFavoriteRequest>) {
      const { articleId, favoriteFlag } = action.payload;
      if (articleId) {
        if (state.articles && state.articles.length) {
          const matchedArticles = state.articles.filter(
            (article) => article.articleId === articleId,
          );
          if (matchedArticles.length > 0) {
            matchedArticles.forEach((arrayItem) => {
              const article = arrayItem;
              article.isFavorite = favoriteFlag === 1;
            });
          }
        }
      }
    },
    getNotificationAlerts(state, action: PayloadAction<NotificationAlertRequest>) {
      const { payload } = action;
      if (payload.page === 1) {
        if (payload.filter?.length > 0) {
          state.selectedPeopleFilters = payload?.filter;
        }
        state.notificationsLoading = true;
        state.peopleNotifications = [];
        state.notificationsPage = 1;
        state.peopleMessage = '';
      }
      state.notificationsEnded = false;
    },
    setNotificationAlerts(state, action: PayloadAction<NotificationResponse>) {
      const { payload } = action;
      state.notificationsLoading = false;
      state.peopleNotifications = payload?.notifications;
      state.notificationsPage += 1;
    },
    setMoreNotificationAlerts(state, action: PayloadAction<NotificationResponse>) {
      const { payload } = action;
      state.notificationsPage += 1;
      state.peopleNotifications = state.peopleNotifications.concat(payload?.notifications);
    },
    setNotificationMessage(state, action: PayloadAction<string>) {
      state.notificationsLoading = false;
      state.notificationsEnded = true;
      state.peopleMessage = action.payload;
    },
    setNotificationAlertsEnded(state) {
      state.notificationsEnded = true;
      state.notificationsLoading = false;
    },
    notificationAlertsError(state) {
      state.notificationsLoading = false;
      state.notificationsEnded = true;
    },
    getNotificationOpened(state, action: PayloadAction<NotificationOpenedRequest>) {
      state.openedLoading = true;
    },
    setNotificationOpened(state, action: PayloadAction<number>) {
      const { payload } = action;
      const objIndex = state.peopleNotifications?.findIndex(
        (notification) => notification?.notificationID === payload,
      );
      if (objIndex) { state.peopleNotifications[objIndex].readFlag = true; }
      state.openedLoading = false;
    },
    reset: () => initialState,
  },
});

export const { actions, reducer, name: sliceKey } = AlertsSlice;
