/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable no-param-reassign */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FeedIntentDataState } from 'Types/GlobalTypes';
import { GlobalRequest } from 'Types/RequestBodies';
import { FeedIntentDataResponse } from 'Types/FeedIntentData';

export const initialState: FeedIntentDataState = {
  intentLoading: false,
  intentData: [],
};

const feedIntentDataSlice = createSlice({
  name: 'feedIntentData',
  initialState,
  reducers: {
    getFeedIntentData(state, action: PayloadAction<GlobalRequest>) {
      if (state.intentData.length === 0) {
        state.intentLoading = true;
        state.intentData = [];
      }
    },
    setFeedIntentData(state, action:PayloadAction<FeedIntentDataResponse>) {
      const { payload } = action;
      state.intentLoading = false;
      state.intentData = payload?.response?.values;
    },
  },

});

export const { actions, reducer, name: sliceKey } = feedIntentDataSlice;
