/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
import {
  call, delay, fork, put, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'Utils/Axios';
import { API } from 'Utils/Constants';
import { GlobalSearchResults, PersonLinkedinSearchResponse, SearchResultsRequest } from 'Types/GlobalSearchResultsTypes';
import { Executive } from 'Types/PersonTypes';
import { ProfileConfirmResponse } from 'Types/ResponseBodies';
import { colorParsing, setLocalStorageValue } from 'Utils/UtilityFunctions';
import { actions } from '../Slice/ProfileConfirmationSlice';
import { actions as helperActions } from '../Slice/HelperSlice';
import { actions as globalActions } from '../Slice/GlobalSearchContentSlice';

export function* getSearchPeople(dispatched: PayloadAction<SearchResultsRequest>) {
  try {
    const response: GlobalSearchResults = yield call(
      axios.post,
      API.GLOBAL_SEARCH_RESULTS,
      dispatched?.payload,
    );
    if (response?.status === true) {
      const { searchResult, mappedPersonId } = response.response;
      if (mappedPersonId) {
        yield put(actions.setMappedPersonId(mappedPersonId));
      }
      // const results : Executive[] | undefined = searchResult[0]?.persons;
      yield put(actions.setSearchPeople(searchResult));
    } else {
      yield put(actions.setSearchError(response?.message));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getSearchPeopleFromLinkedin(dispatched: PayloadAction<SearchResultsRequest>) {
  const { query } = dispatched.payload;
  const refinedPayload = { ...dispatched.payload };
  const searchTerm = query.includes(':') ? query.split(':')[1].trim() : query;
  refinedPayload.query = searchTerm;
  const response: PersonLinkedinSearchResponse = yield call(
    axios.post,
    API.GET_PERSON_EXTENDED_SEARCH,
    refinedPayload,
  );
  if (response?.status === true) {
    const results: Executive[] | undefined = response?.response.results;
    yield put(actions.setLinkedinSearchPeople(results?.length ? results : []));
  } else {
    yield put(actions.setLinkedinSearchError(response?.message));
  }
}

export function* setConfirmedPerson(dispatched: PayloadAction<Executive>) {
  const {
    personId, personType,
  } = dispatched.payload;
  const response: ProfileConfirmResponse = yield call(
    axios.post,
    API.SELF_MAPPING,
    {
      personId,
      type: personType,
    },
  );
  if (response?.status === true) {
    const {
      archeType, profilePic, fullName, userCompanyName,
    } = response.response;
    setLocalStorageValue('profilePic', profilePic);
    if (archeType?.color?.length) {
      setLocalStorageValue('personalitycolors', colorParsing(archeType));
    }
    setLocalStorageValue('personCompany', userCompanyName);
    setLocalStorageValue('fullname', fullName);
    window.dispatchEvent(new StorageEvent('storage', {}));
    yield put(actions.setConfirmSuccess(true));
    yield put(actions.setConfirmFail(false));
    yield put(globalActions.setShowMappingTooltip(false));
  } else {
    const { message } = response;
    yield put(actions.setConfirmFail(true));
    yield put(actions.setConfirmSuccess(false));
    yield put(helperActions.setNotification({
      message: message?.length ? message : 'Error',
      proTip: false,
      rightPlacement: false,
      groupId: 0,
      classnameFlag: false,
    }));
  }
}

export function* getSearchPeopleFlow(dispatched: PayloadAction<SearchResultsRequest>) {
  yield fork(getSearchPeople, dispatched);
  delay(3000);
  yield fork(getSearchPeopleFromLinkedin, dispatched);
}

export function* ProfileConfirmationSaga() {
  yield takeLatest(actions.getSearchPeople.type, getSearchPeopleFlow);
  yield takeLatest(actions.setConfirmedPerson.type, setConfirmedPerson);
}
