import React, { useEffect, useState } from 'react';
import { Filter } from 'Types/FilterTypes';
import styled from 'styled-components';
import { driver } from 'Utils/utilsStyle';
import { allValueInKeyOfArrayExists } from '../../../Utils/UtilityFunctions';
import AlertsTriggersCheckBox from './AlertsTriggersCheckBox';
import LineSkeleton from '../../common/skeleton/LineSkeleton';

type Props = {
  onClickUnSelect: (a: boolean) => void
  load: boolean
  filters: Filter[]
  onCheckedChange: (a: boolean, b: string) => void
};

const NoFilterText = styled.p`
  color: ${driver} !important;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  font-family: Roboto;
  letter-spacing: -0.4px;
`;

const Wrapper = styled.div`
  .triggers-checkbox-panel{
    .trigger-checkbox-area{
      .grid-checkbox-wrapper{
        min-height: 0px;
        padding: 18px 20px;
        .ant-checkbox-wrapper {
          margin-bottom: 6px;
          span{
            padding-left: 0px;
            .title-name{
              margin-left: 0px;
            }
          }
        }
      }
    }
  }
`;

const PeopleAlertsTriggersPanel = function PeopleAlertsTriggersPanel(props: Props) {
  const {
    onClickUnSelect,
    onCheckedChange,
    load,
    filters,
  } = props;
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [isNoFilterSelected, setIsNoFilterSelected] = useState<boolean>(false);

  useEffect(() => {
    setIsNoFilterSelected(allValueInKeyOfArrayExists(filters, 'isSelected', false));
    setSelectAll(allValueInKeyOfArrayExists(filters, 'isSelected', true));
  }, [filters]);

  useEffect(() => {
    if (filters.length === 0) {
      setIsNoFilterSelected(false);
    }
  }, []);

  const selection = () => {
    onClickUnSelect(!selectAll);
    setSelectAll(!selectAll);
  };
  return (
    <Wrapper>
      <div className="triggers-checkbox-panel">
        <div className="heading-panel">
          <h6 className="head">People Alerts:</h6>
          <span className="unselect" onClick={selection} onKeyPress={selection} tabIndex={0} role="button" aria-label="unselect all">{selectAll ? 'unselect all' : 'select all'}</span>
        </div>
        {isNoFilterSelected && <NoFilterText>Please Select One Filter at Least</NoFilterText>}
        <div className="trigger-checkbox-area">
          <div className="grid-checkbox-wrapper">
            {load ? (
              [1, 2].map(() => <LineSkeleton />)
            )
              : (filters.map((filter: Filter) => (
                <AlertsTriggersCheckBox
                  id={filter.filterId}
                  title={filter.filterName}
                  color={filter.filterColor}
                  checked={filter.isSelected}
                  onCheckedChange={onCheckedChange}
                />
              ))
              )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default PeopleAlertsTriggersPanel;
