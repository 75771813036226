import styled from 'styled-components';

type Props = {
  onClick?: () => void;
};

const defaultProps = {
  onClick: () => { },
};

const SVG = styled.svg`
`;

const FreshDeskIcon = function FreshDeskIcon(props: Props) {
  const { onClick } = props;
  return (
    <SVG width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path d="M14.4566 22.8424C13.6686 22.8161 13.0536 22.1593 13.0862 21.3798C13.1188 20.608 13.77 20.003 14.5403 20.0285C15.3284 20.0548 15.9433 20.7116 15.9107 21.4911C15.8781 22.2629 15.2269 22.8679 14.4566 22.8424Z" fill="#424B50" />
      <path d="M13.3657 16.6084C13.3657 16.1552 13.3607 15.7026 13.3671 15.2494C13.3763 14.601 13.7643 14.1471 14.4084 14.0925C15.1553 14.0293 15.815 13.8101 16.2789 13.1888C16.83 12.4518 16.9279 11.6446 16.5207 10.8204C16.0987 9.96493 15.3766 9.52586 14.419 9.56062C13.26 9.60247 12.4074 10.4132 12.2563 11.5652C12.2428 11.668 12.2386 11.7716 12.2294 11.8751C12.1712 12.5107 11.6733 12.9575 11.0526 12.9306C10.4327 12.9043 9.95742 12.417 9.97303 11.7787C10.012 10.1749 10.688 8.89031 12.035 8.02353C13.5508 7.04821 15.1603 6.99005 16.725 7.88166C18.3437 8.80378 19.1027 10.2643 19.0147 12.1234C18.9289 13.9392 17.6542 15.5437 15.8973 16.1083C15.6916 16.1743 15.6171 16.2573 15.6256 16.4772C15.644 16.9765 15.6355 17.4773 15.6306 17.9774C15.6228 18.6711 15.1447 19.1662 14.4942 19.1634C13.8431 19.1605 13.3742 18.6661 13.3671 17.9675C13.3622 17.5142 13.3664 17.0617 13.3664 16.6084H13.3657Z" fill="#424B50" />
      <circle cx="14.5" cy="14.5" r="13.35" stroke="#424B50" strokeWidth="2.3" />
    </SVG>
  );
};

FreshDeskIcon.defaultProps = defaultProps;
export default FreshDeskIcon;
