import { Input } from 'antd';
import PeopleWidgetWithOutHolo from 'Components/common/components/PeopleWidgetWithOutHolo';
import React, {
  useState, useEffect, useRef,
} from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { actions as notesSlice } from 'Slice/NotesSlice';
import {
  boxShadow,
  maxMobile,
  minWidthApp,
  primaryColor, primaryFont, scrollbar, smMobile, wLight, wMBold, wMedium,
} from 'Utils/utilsStyle';
import {
  backgroundColorParsing, convertFormattedTextToPlain, extractTextWithIndexes,
  getCaretCoordinates, getCaretIndex, getLocalStorageValue, handlePasteInTextArea, insertAnchorTags,
  insertTextInContentEditable, modifyReason, preventStylingShortcuts,
  setCaretAtTheEndHandler, valueToObject,
} from 'Utils/UtilityFunctions';
import { Executive } from 'Types/PersonTypes';

import { PERSONALITY_INSIGHTS_ROUTE, TAG_CHARACTER, translateSkipAreas } from 'Utils/Constants';
import DeletePopup from 'Components/auth/components/DeletePopup';
import {
  selectAddNoteLoading,
  selectEditNoteLoading, selectNoteAdded,
  selectNoteEdited,
  selectPersonNotes,
} from 'Selector/NotesSelector';
import { actions as groupsSliceActions } from 'Slice/GroupsSlice';
import { actions as certificationActions } from 'Slice/CertificationSlice';
import { selectData } from 'Selector/CertificationSelector';
import classNames from 'classnames';
import ContentEditable from 'react-contenteditable';
import { EntityNotes, PersonMentions } from 'Types/NotesTypes';
import { actions as flurryActions } from 'Slice/HelperSlice';
import PersonDropdown from './PersonDropdown';

const Wrapper = styled.div<{ noEdit: boolean, x: string, y: string }>`
  padding: 15px 30px;
  background-color: ${(props) => (props.noEdit ? '#FCFCFC' : '#fff')};
  &:disabled {
    cursor: default;
    opacity: 0.6;
  }
  .editResponsiveBlock {
    @media all and (max-width: ${minWidthApp}) {
      cursor: default;
      opacity: 0.6;
      pointer-events: none;
      &:hover {
        color: #67696A !important;
      }
    }
  }
  .text-area-border{
    border-color: ${primaryColor};
    box-shadow: 0 0 0 1px ${primaryColor};
  }
  
  .disabled-border{
    border: 1px solid #DCDCDC;
    background-color: #FCFCFC;
  }
  .text-area-notes{
    &.enabled-border{
      border: 1px solid ${primaryColor};
      background-color: #fff;
      box-shadow: 0 0 0 1px ${primaryColor};
    }
    user-select: text;
    font-family: ${primaryFont};
    padding: 16px 12px 20px 12px;
    font-weight: ${wLight};
    font-size: 12px;
    line-height: 16px;
    color: #67696A;
    background-color: #f5f5f5;
    font-style: normal;
    border-radius: 4px;
    overflow-y: hidden;
    outline: 0px solid transparent;
    word-wrap: break-word;
    -webkit-user-drag: none;
    -webkit-user-select: text;
    :hover {
      box-shadow:  0 0 0 1px ${primaryColor};
      border-color: ${primaryColor};
    }
    .inline_div{
      display: inline-block;
    }
    svg, textarea, input, img {
      display: none;
    }
    &.newNote{
      height: 150px;
      ${scrollbar};
      ::-webkit-scrollbar { width: 17px; } 
      ::-webkit-scrollbar-track { background-color: transparent; } 
      ::-webkit-scrollbar-thumb { background-color: #001760; border-radius: 20px; border: 7px solid transparent; background-clip: content-box; }
    }
  }
  .person-dropdown{
    top: ${(props) => props.y};
    right: ${(props) => props.x};
    z-index: 2;
    position: absolute;
    background-color: white;
    width: 250px;
    border-radius: 12px;
    box-shadow: ${boxShadow};
    max-height: 270px;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    ${scrollbar}
    @media all and (max-width: ${maxMobile}) {
      width: 200px;
    }
    @media all and (max-width: ${smMobile}) {
      width: 150px;
    }
    .m-left-50 {
      margin-left: 50px;
    }
  }
  .tagged-person{
    color: ${primaryColor};
    font-weight: ${wMedium};
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
  }
  .disabled{
    pointer-events: 'none';
  }
  .data-text-filed-wrapper {
   position: relative;
    .count-text {
      display: none;
    }
    &.errorShow {
      .text-area-notes  {
        border: 1px solid red !important;
      }
      .count-text {
        position: absolute;
        top: -17px;
        right: 0px;
        color: red;
        font-size: 10px;
        font-family: ${primaryFont};
        font-style: italic;
        display: block;
      }
    }
  }
`;

const UserDiv = styled.div<{ isPeopleNotes: boolean }>`
  display: flex;
  margin-bottom: ${(props) => (props.isPeopleNotes ? '10px' : '0px')};
  .user-info{
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    margin-top: 1px;
    gap: 6px;
    .user-name{
      font-family: ${primaryFont};
      font-size: 14px;
      font-weight: ${wMBold};
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: #222;
    }
    .designation{
      font-family: ${primaryFont};
      font-size: 12px;
      font-weight: ${wLight};
      line-height: 13px;
      letter-spacing: 0px;
      text-align: left;
      color: #222;
    }
  }
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0px 10px 0px;
  font-family : ${primaryFont};
  font-weight: ${wMedium};
  font-size: 10px;
  line-height: 10.7px;
  color:#67696A;
  .showHover {
    &:hover {
      color: ${primaryColor};
    }
  }
  .delete-note{
    margin-right: 15px;
  }
`;

const ButtonFooter = styled.div<{ isPeopleNotes: boolean }>`
  display: flex;
  justify-content: ${(props) => (!props.isPeopleNotes ? 'flex-end' : 'flex-start')};
  flex-direction: ${(props) => (props.isPeopleNotes ? 'row-reverse' : 'row')};
  margin-top: 7px;
`;

const UserName = styled.span`
align-self: center;
text-align: left;
font-family: ${primaryFont};
font-weight: ${wMedium};
font-size: 14px;
line-height: 16.39px;
color: #67696A;
margin-left: 5px;
`;

const Title = styled.span`
  &.edited {
  font-size: 9px;
  align-self: center;
  margin-top: 4px;
  margin-left: 12px;
  margin-top: 0px;
  font-weight: ${wMBold};
}
&.edited-notes {
  background-color: #E2E2E2;
  padding: 0px 5px;
  border-radius: 3px;
  color: #676767;
  margin-left: 5px;
}
font-family: ${primaryFont};
font-weight: ${wMedium};
font-size: 10px;
line-height: 16px;
color:#7E7E7E;
`;

const SmallButton = styled.span<{ isPeopleNotes: boolean }>`
font-size: ${(props) => (props.isPeopleNotes ? '10px' : '9px')};
padding: ${(props) => (props.isPeopleNotes ? '3px 11px' : '2px 8px')};
height: ${(props) => (props.isPeopleNotes ? '22px' : '17px')};
font-family: ${primaryFont};
color: #fff;
background-color: ${primaryColor};
border-radius: 25px;
font-weight: ${wMedium};
  &.cancel-button{
  background-color: transparent;
  color: #999999;
  margin-left: 5px;
}
`;

type Props = {
  notes: EntityNotes;
  isPeopleNotes?: boolean;
  isCompanyNotes?: boolean;
  noEdit?: boolean;
  noteAbout?: number;
  isNewNoteOpen?: boolean;
  newNote?: boolean
  getNoteId?: (id?: number) => void,
  editNoteId?: number,
  handleNewNoteCancel?: () => void
} & typeof defaultProps;

const defaultProps = {
  isPeopleNotes: false,
  isCompanyNotes: false,
  noEdit: false,
  isNewNoteOpen: false,
  newNote: false,
  noteAbout: 0,
  getNoteId: () => { },
  editNoteId: 0,
  handleNewNoteCancel: () => { },
};

const PeopleMoveReasonCard = function PeopleMoveReasonCard(props: Props) {
  const {
    notes, isPeopleNotes, noEdit, handleNewNoteCancel, isCompanyNotes,
    noteAbout, isNewNoteOpen, newNote, getNoteId, editNoteId,
  } = props;
  const limit = 2000;
  const { comment } = notes;

  const {
    reasonId,
    type,
    noteId,
    person,
    changed,
    editable,
    heading,
    text,
    dateTimeStamp,
  } = comment;

  const [disable, setDisable] = useState(true);
  const [editDisable, setEditDisable] = useState(false);
  const [isEditing, SetIsEditing] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [reason, setReason] = useState('');
  const [xCoordinate, setXCoordinate] = useState('');
  const [yCoordinate, setYCoordinate] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [isQueryStart, setIsQueryStart] = useState(false);
  const [query, setQuery] = useState('');

  const [tagStartIndex, setTagStartIndex] = useState(0);
  const [noteForCancelEdit, setNoteForCancelEdit] = useState('');

  const [isCharLimitCrossed, setIsCharLimitCrossed] = useState(false);

  const reasonRef = useRef<HTMLInputElement>(null);
  const notesRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { TextArea } = Input;
  const addNoteLoading = useSelector(selectAddNoteLoading);
  const noteAdded = useSelector(selectNoteAdded);
  const editNoteLoading = useSelector(selectEditNoteLoading);
  const noteEdited = useSelector(selectNoteEdited);
  const personNotes = useSelector(selectPersonNotes);
  const { notes: allNotes } = personNotes;
  const isNotes = isCompanyNotes || isPeopleNotes;
  const host = window.location.href;

  const getCurrentNode = () => document.getElementById(`${newNote ? 'contenteditablediv'
    : `cediv${noteId || ''}`}`) as HTMLElement;

  const trimHandler = () => {
    let afterTrim = '';
    const node = getCurrentNode();
    if (node) {
      afterTrim = node.innerHTML.replace(/&nbsp;/gi, ' ');
    }
    return afterTrim;
  };

  const setQueryHandler = (value: string) => {
    const node = getCurrentNode();
    if (node && type !== 'reason') {
      const index = getCaretIndex(node);
      let firstHalf = value.substring(0, index);
      if (firstHalf.lastIndexOf(TAG_CHARACTER) > -1) {
        const prevChar = firstHalf[firstHalf.lastIndexOf(TAG_CHARACTER) - 1];
        if (prevChar === undefined || /\s|;|>|<|:/.test(prevChar)) {
          setTagStartIndex(firstHalf.lastIndexOf(TAG_CHARACTER));
          firstHalf = firstHalf.slice(
            firstHalf.lastIndexOf(TAG_CHARACTER) + 1,
            firstHalf.length + 1,
          );
          if (firstHalf.split(' ').length - 1 <= 1) {
            setShowDropdown(true);
            setIsQueryStart(true);
          }
        }
      }
      if (isQueryStart) {
        const withoutTag = firstHalf.split('<')[0];
        const withoutAnd = withoutTag.split('&')[0];
        setQuery(withoutAnd || '');
      }
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (e.key === TAG_CHARACTER) {
      let coordinates: { x: number, y: number } = { x: 0, y: 0 };
      setTimeout(
        () => {
          coordinates = getCaretCoordinates();
          let { x, y } = coordinates;
          const { innerWidth: width } = window;
          const personDropdown = document.getElementById('scrollableDiv');
          const personDropdownWidth = personDropdown?.offsetWidth || 0;
          if (width - x > personDropdownWidth) {
            x = width - x - personDropdownWidth;
          } else {
            x = width - x;
          }
          y += 20;
          x = Math.round(x);
          y = Math.round(y);
          setXCoordinate(`${x.toString()}px`);
          setYCoordinate(`${y.toString()}px`);
        },
        100,
      );
    }
  };

  const handleTag = (tagPerson: Executive) => {
    const node = getCurrentNode();
    const firstHalf = reason?.substring(0, tagStartIndex);
    const seconIndex = tagStartIndex + query.length;
    const secondHalf = reason?.substring(seconIndex + 1);
    const aTag = `<a id=${tagPerson.personId} data-type=${tagPerson.personType || 'EXECUTIVE'} draggable="false"
      href="${window.location.origin}${PERSONALITY_INSIGHTS_ROUTE}${tagPerson.personId}&personType=EXECUTIVE"
      target="_blank" class="tagged-person ${tagPerson.personId ? '' : 'disabled'}" contenteditable="false">${tagPerson.personName}</a>`;
    const reasonToSet = `${firstHalf}${aTag}${secondHalf}`;

    setReason(reasonToSet);

    node.focus();
    setShowDropdown(false);
    setIsQueryStart(false);
    setQuery('');
    setTagStartIndex(0);
    dispatch(groupsSliceActions.resetSearchedExecutivesForNotes());
  };

  const handleNoteChange = (e: React.FormEvent<HTMLDivElement>) => {
    const { value } = e.target as HTMLTextAreaElement;
    setReason(value);
    setShowDropdown(false);
    dispatch(groupsSliceActions.resetSearchedExecutivesForNotes());
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReason(e.target.value);
  };

  const checkCharLimit = () => {
    if (type !== 'reason') {
      const trimmedReason = modifyReason(trimHandler());
      const { transformedText } = extractTextWithIndexes(trimmedReason);
      setIsCharLimitCrossed(transformedText.length > limit);
    } else {
      setIsCharLimitCrossed(reason.length > 150);
    }
  };

  const data = useSelector(selectData);

  const peopleNotesSaveHandler = (transformedText: string, taggedPersons: PersonMentions[]) => {
    if (isCompanyNotes) {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject(`companyNotes_${isEditing ? 'edit_confirm' : 'save_clicked'}`, 0, {
          notes_input: transformedText,
          host,
        }),
      ));
    }
    const obj = {
      note: transformedText,
      taggedPersons,
      type: 'notes',
    };
    if (isEditing) {
      dispatch(notesSlice.editPersonNote({
        noteId: noteId || 0,
        ...obj,
      }));
    } else {
      dispatch(notesSlice.addPersonNote({
        person,
        ...(!isCompanyNotes && { personId: noteAbout }),
        ...(isCompanyNotes && { companyId: noteAbout }),
        ...obj,
      }));
    }
  };

  const handleSave = () => {
    if (isNotes && type !== 'reason') {
      if (isPeopleNotes) {
        dispatch(certificationActions.certificationAction({
          type: 'person_note_save',
          source: 'person',
          data,
        }));
      }
      setNoteForCancelEdit('');
      const node = getCurrentNode();
      if (node) {
        const trimmedReason = modifyReason(trimHandler());
        setReason(trimmedReason);
        const { transformedText, taggedPersons } = extractTextWithIndexes(trimmedReason);
        peopleNotesSaveHandler(transformedText, taggedPersons);
      }
    } else if (type === 'reason') {
      dispatch(notesSlice.editPersonNote({
        reasonId,
        reasonText: reason,
        type: 'reason',
      }));
    }
  };

  const setDefault = () => {
    if (type !== 'reason') {
      if (!noteForCancelEdit.length) {
        const note = insertAnchorTags(comment);
        setReason(note);
      } else {
        setNoteForCancelEdit(noteForCancelEdit);
      }
    } else {
      setReason(text ?? '');
    }
  };

  const handleCancel = (edited?: boolean) => {
    setIsCharLimitCrossed(false);
    if (noEdit) {
      handleNewNoteCancel();
      return;
    }
    setDisable(true);
    if (edited) {
      setReason(reason);
    }
    setDefault();
  };

  const handleReasonEdit = () => {
    if (isCompanyNotes) {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('companyNotes_edit_intent', 0, {
          host,
        }),
      ));
    }
    setEditDisable(true);
    setDisable(false);
    SetIsEditing(true);
    if (isNewNoteOpen) handleNewNoteCancel();
    getNoteId(type !== 'reason' ? noteId : reasonId);
  };

  const handleNoteDelete = () => {
    if (isCompanyNotes) {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('companyNotes_delete_intent', 0, {
          host,
        }),
      ));
    }
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (isCompanyNotes) {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('companyNotes_delete_confirm', 0, {
          host,
        }),
      ));
    }
    setIsModalVisible(false);
    dispatch(notesSlice.deletePersonNote({
      noteId: noteId || 0,
      ...(isCompanyNotes && { companyId: noteAbout }),
      ...(!isCompanyNotes && { personId: noteAbout }),
      notesLength: allNotes.length - 1 || 0,
    }));
  };

  const handlePopupCancel = () => {
    setIsModalVisible(false);
  };

  const handlePasteInNotes = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const node = getCurrentNode();
    if (node) {
      const copiedData = event.clipboardData;
      if (copiedData?.files.length) event.preventDefault();
      const pastedText = convertFormattedTextToPlain(copiedData?.getData('text/plain'));
      insertTextInContentEditable(node, pastedText);
    }
  };

  const getWrapperColor = () => {
    if (person?.personName === getLocalStorageValue('fullname')) {
      return getLocalStorageValue('personalitycolors')
        || backgroundColorParsing(person?.personalityTypes || [])
        || '';
    }
    return backgroundColorParsing(person?.personalityTypes || []);
  };

  useEffect(() => {
    if (noEdit) {
      setDisable(false);
    }
  }, [noEdit]);

  useEffect(() => {
    const { transformedText } = extractTextWithIndexes(reason, true);

    if ((transformedText === '' && (reason === '' || reason === '<br>'))) {
      setEditDisable(true);
    } else if ((text.length && transformedText.length
      && transformedText === text && transformedText === reason)) {
      setEditDisable(true);
    } else {
      setEditDisable(false);
    }
  }, [text, reason]);

  useEffect(() => {
    if (!disable || noEdit) {
      if (isNotes) {
        notesRef.current?.focus();
      } else {
        reasonRef.current?.focus();
      }
    }
  }, [disable, noEdit]);

  useEffect(() => {
    if (noteAdded || noteEdited) {
      handleCancel(true);
      setEditDisable(false);
    }
  }, [noteAdded, noteEdited]);

  useEffect(() => {
    if (isNewNoteOpen && isNotes) {
      if (!newNote) {
        setDisable(true);
        handleCancel();
      }
    }
  }, [isNewNoteOpen]);

  useEffect(() => {
    setDefault();
  }, [comment]);

  useEffect(() => {
    if (type !== 'reason' && reason && (isNewNoteOpen || isEditing)) {
      setQuery('');
      setIsQueryStart(false);
      setShowDropdown(false);
      setQueryHandler(reason);
    }
    if (!reason?.replace(/&nbsp;/gi, ' ').replace(/<div><br><\/div>/gi, '').trim().length) {
      setEditDisable(true);
    }
  }, [reason]);

  useEffect(() => {
    if (reason.length && isNotes && !disable) {
      checkCharLimit();
    }
  }, [reason]);

  useEffect(() => {
    if (!newNote) {
      if (editNoteId !== noteId && editNoteId !== reasonId) {
        setDisable(true);
        handleCancel();
        setDefault();
      } else if (editNoteId === noteId) {
        const note = insertAnchorTags(comment);
        setReason(note);
      } else {
        setReason(text || '');
      }
    }
  }, [editNoteId]);

  useEffect(() => {
    if (isNotes && isEditing && !disable && type !== 'reason') {
      setCaretAtTheEndHandler(getCurrentNode());
    } else {
      reasonRef.current?.focus();
    }
  }, [isEditing, disable]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Backspace') {
      const selection = window.getSelection();
      if (selection && selection.isCollapsed && selection.anchorOffset === 0) {
        const range = selection.getRangeAt(0);
        const previousNode = range.startContainer.previousSibling;

        if (previousNode && previousNode.nodeName === 'A') {
          event.preventDefault();
          previousNode.remove();
        }
      }
    }
    preventStylingShortcuts(event);
  };

  return (
    <>
      {isNotes
        && (
          <DeletePopup
            text="Are you sure you want to delete the note?"
            visible={isModalVisible}
            handleOk={handleOk}
            handlePopupCancel={handlePopupCancel}
          />
        )}
      <Wrapper noEdit={noEdit || !disable} x={xCoordinate} y={yCoordinate} id={`scroll-to-${noteId || ''}`} className="comment-wrapper-area">
        <UserDiv isPeopleNotes={isNotes}>
          <PeopleWidgetWithOutHolo
            diameter="31px"
            diameterSm="31px"
            diameterXsm="31px"
            logo={person?.personImage || ''}
            margin="0"
            wrapperColor={getWrapperColor()}
            ringPadding="2px"
            ringPaddingSm="2px"
          />
          {isNotes ? (
            <div className="user-info">
              <div className="d-flex align-items-center">
                <span className="user-name">{person?.personName || ''}</span>
                {changed ? <Title className="edited edited-notes">Edited</Title> : null}
              </div>
              <div className="designation">
                {person?.personDesignation || ''}
                {' '}
                {`${person?.companyName ? `@${person?.companyName}` : ''}`}
              </div>
            </div>
          )
            : (
              <>
                <UserName>{person?.personName || ''}</UserName>
                {changed ? <Title className="edited">Edited</Title> : null}
              </>
            )}
        </UserDiv>
        {
          heading ? (
            <Title>
              {heading}
            </Title>
          ) : ''
        }
        <div className={classNames(`data-text-filed-wrapper ${translateSkipAreas}`, { errorShow: isCharLimitCrossed })}>
          {
            type !== 'reason' ? (
              <ContentEditable
                id={`${newNote ? 'contenteditablediv' : `cediv${noteId || ''}`}`}
                className={`text-area-notes ${newNote ? 'newNote' : ''} 
                ${!disable ? 'enabled-border' : 'disabled-border'}`}
                onChange={(e) => handleNoteChange(e)}
                onKeyPress={(e) => handleKeyPress(e)}
                onPaste={(e) => handlePasteInNotes(e)}
                onKeyDown={handleKeyDown}
                innerRef={notesRef}
                html={reason}
                suppressContentEditableWarning
                spellCheck="false"
                disabled={disable}
              />
            )
              : (
                <TextArea
                  className={`text-area-reason ${!disable ? 'text-area-border' : ''}`}
                  allowClear
                  disabled={disable}
                  size="large"
                  bordered
                  onChange={onChange}
                  onPaste={(e) => {
                    const newValue = handlePasteInTextArea(e);
                    setReason(newValue);
                  }}
                  value={reason}
                  ref={reasonRef}
                  autoSize
                />
              )
          }
          <span className="count-text">{`Word Count Exceeded (limit ${type === 'reason' ? '150' : '2000'})`}</span>
        </div>

        <div className="person-dropdown" id="scrollableDiv">
          {showDropdown && (
            <PersonDropdown query={query} handleTag={handleTag} />
          )}
        </div>
        {
          disable ? (
            <CardFooter>
              <span>
                {dateTimeStamp}
              </span>
              <span>
                {type !== 'reason' && (
                  <span
                    className={`c-pointer showHover delete-note ${editable ? '' : 'disabled'}`}
                    tabIndex={0}
                    role="button"
                    onClick={() => handleNoteDelete()}
                    onKeyPress={() => handleNoteDelete()}
                  >
                    Delete
                  </span>
                )}
                <span
                  className={`c-pointer showHover editResponsiveBlock ${editable ? '' : 'disabled'}`}
                  tabIndex={0}
                  role="button"
                  onClick={() => handleReasonEdit()}
                  onKeyPress={() => handleReasonEdit()}
                >
                  Edit
                </span>
              </span>
            </CardFooter>
          ) : (
            <ButtonFooter isPeopleNotes={isNotes}>

              <SmallButton
                className={`c-pointer ${editDisable || addNoteLoading || editNoteLoading || isCharLimitCrossed ? 'disabled' : ''}`}
                tabIndex={0}
                role="button"
                onClick={() => handleSave()}
                onKeyPress={() => handleSave()}
                isPeopleNotes={isNotes}
              >
                Save
              </SmallButton>
              <SmallButton
                className={`c-pointer cancel-button ${addNoteLoading || editNoteLoading ? 'disabled' : ''}`}
                tabIndex={0}
                role="button"
                onClick={() => handleCancel()}
                onKeyPress={() => handleCancel()}
                isPeopleNotes={isNotes}
              >
                Cancel
              </SmallButton>
            </ButtonFooter>
          )
        }
      </Wrapper>
    </>
  );
};

PeopleMoveReasonCard.defaultProps = defaultProps;
export default PeopleMoveReasonCard;
