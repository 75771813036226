import React from 'react';

const XiqSkeletonSvg = function XiqSkeletonSvg() {
  return (

    <svg width="250" height="100" viewBox="0 0 744 387" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M549.857 306.362C487.438 306.362 436.653 255.726 436.653 193.492C436.653 131.252 487.438 80.6215 549.857 80.6215C612.276 80.6215 663.061 131.252 663.061 193.492C663.061 208.514 660.042 222.827 654.679 235.947L714.612 295.709C733.167 266.04 743.921 231.023 743.921 193.492C743.921 86.6304 657.034 0 549.857 0C442.674 0 355.793 86.6304 355.793 193.492C355.793 300.353 442.674 386.983 549.857 386.983C586.104 386.983 620.011 377.045 649.034 359.797L588.503 299.444C576.422 303.835 563.447 306.362 549.857 306.362" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M710.338 302.202L651.273 243.311L612.093 204.247L606.702 198.872L598.622 206.929L555.243 250.18L599.775 294.581L658.895 353.526L684.393 378.943L692.473 387L697.864 381.625L743.927 335.692L710.338 302.202Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M307.564 0C334.113 0 356.419 22.23 356.419 48.7061C356.419 75.1714 334.113 97.4068 307.564 97.4068C281.02 97.4068 258.713 75.1714 258.713 48.7061C258.713 22.23 281.02 0 307.564 0" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M124.152 325.422C131.052 314.828 135.3 304.251 135.829 303.187C136.368 304.772 141.672 316.156 148.572 325.685L188.851 386.968H271.663L182.555 256.319L268.574 128.98H185.213L148.044 183.265C141.672 192.794 135.829 206.699 135.829 206.699C135.829 206.699 129.457 193.515 123.09 183.458L86.4555 128.98H3.09424L88.574 256.319L0 386.968H82.8276L124.152 325.422Z" fill="white" />
      <mask id="mask0_4695_64303" maskUnits="userSpaceOnUse" x="265" y="128" width="75" height="259">
        <path fillRule="evenodd" clipRule="evenodd" d="M265.02 128.98H339.395V386.966H265.02V128.98Z" fill="white" />
      </mask>
      <g mask="url(#mask0_4695_64303)">
        <path fillRule="evenodd" clipRule="evenodd" d="M265.02 338.735L297.418 386.968H339.395V128.98H293.715L265.025 171.43L265.02 338.735Z" fill="white" />
      </g>
    </svg>
  );
};

export default XiqSkeletonSvg;
