import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'Store/InjectorsTypes';
import { initialState } from 'Slice/CustomWidgetSlice';

const selectDomain = (state: RootState) => state?.customWidget || initialState;

export const selectCustomWidgetLoading = createSelector(
  [selectDomain],
  (state) => state.widgetLoading,
);
export const selectCustomWidgetData = createSelector(
  [selectDomain],
  (state) => state.customWidgetData,
);
export const selectNewsLoading = createSelector(
  [selectDomain],
  (state) => state.newsLoading,
);
export const selectNewsData = createSelector(
  [selectDomain],
  (state) => state.newsData,
);
export const selectNewsFilter = createSelector(
  [selectDomain],
  (state) => state.newsFilter,
);
export const selectNewsSelectedFilter = createSelector(
  [selectDomain],
  (state) => state.selectedNewsFilter,
);
export const selectNewsPageNumber = createSelector(
  [selectDomain],
  (state) => state.newsPageNumber,
);
export const selectNewsEnded = createSelector(
  [selectDomain],
  (state) => state.newsEnded,
);
export const selectPagination = createSelector(
  [selectDomain],
  (state) => state.paginationLoader,
);
