/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { actions } from 'Slice/ServiceLinesSlice';

import axios from 'Utils/Axios';
import { API } from 'Utils/Constants';
import { getCurrentTime, valueToObject } from 'Utils/UtilityFunctions';
import {
  ServiceLine, ServiceLineDetailsResponse, ServiceLineWidgetResponse, SLAddResponse,
  SLFollowedResponse, SLNewsRequest, SLRemoveResponse,
} from 'Types/ServiceLinesTypes';
import { PayloadAction } from '@reduxjs/toolkit';
import { actions as helperActions } from 'Slice/HelperSlice';

export function* getServiceLines() {
  const startTime = getCurrentTime();
  try {
    const apiResponse: SLFollowedResponse = yield call(
      axios.post,
      API.GET_SERVICELINE,
      {},
    );
    if (apiResponse?.status === true) {
      const { response } = apiResponse;
      yield put(actions.setServiceLines(response));
      yield put(helperActions.callFlurryEvent(
        valueToObject(
          'serviceline_page_load',
          startTime,
          {
            number_of_servicelines: response.followedServiceLines.length
             + response.recommendedServiceLines.length || 0,
          },
        ),
      ));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* removeServiceLines(dispatched: PayloadAction<ServiceLine[]>) {
  const startTime = getCurrentTime();
  const data = dispatched.payload.map((v) => +v.id);
  const serviceLineName = dispatched.payload.map((v) => v.name);
  try {
    const apiResponse: SLRemoveResponse = yield call(
      axios.post,
      API.REMOVE_SERVICELINE,
      { topicId: data.join(', ') },
    );
    if (apiResponse?.status === true) {
      yield put(helperActions.setNotification({
        message: apiResponse?.message,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: true,
      }));
      yield put(actions.removedtServiceLines(dispatched.payload));
      yield put(helperActions.callFlurryEvent(
        valueToObject(
          'servicelines_removed',
          startTime,
          {
            industry_name: serviceLineName.join(','),
            ...dispatched.payload.length > 1 && {
              serviceline_id: data.join(','),
            },
            ...dispatched.payload.length === 1 && {
              serviceline_id: data.join(','),
            },
          },
        ),
      ));
    } else {
      yield put(helperActions.setNotification({
        message: apiResponse?.message,
        proTip: false,
        rightPlacement: true,
        groupId: 0,
        classnameFlag: false,
      }));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* addingServiceLine(dispatched: PayloadAction<ServiceLine>) {
  const startTime = getCurrentTime();
  try {
    const apiResponse: SLAddResponse = yield call(
      axios.post,
      API.ADD_SERVICELINE,
      { topicId: dispatched.payload.id },
    );
    if (apiResponse?.status === true) {
      yield put(helperActions.setNotification({
        message: apiResponse?.message,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: true,
      }));
      if (dispatched.payload.type === 'detailPage') {
        yield put(actions.addedServiceLineDetail(dispatched.payload));
      } else {
        yield put(actions.addedServiceLine(dispatched.payload));
      }
      yield put(helperActions.callFlurryEvent(
        valueToObject(
          'servicelines_added',
          startTime,
          {
            serviceline_id: dispatched.payload.id,
            serviceline_name: dispatched.payload.name,
          },
        ),
      ));
    }
    if (apiResponse?.status === false) {
      yield put(helperActions.setNotification({
        message: apiResponse?.message,
        proTip: false,
        rightPlacement: true,
        groupId: 0,
        classnameFlag: false,
      }));
      yield put(actions.addedServiceLineError(dispatched.payload));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getServiceLineWidgets(dispatched: PayloadAction<SLNewsRequest>) {
  const startTime = getCurrentTime();
  try {
    const response: ServiceLineWidgetResponse = yield call(
      axios.post,
      API.GET_SERVICELINEWIDGETS,
      dispatched?.payload,
    );

    if (response?.status === true) {
      yield put(actions.setServiceLineWidgets(response?.response));
      yield put(helperActions.callFlurryEvent(
        valueToObject(
          'serviceline_news',
          startTime,
          {
            serviceline_id: dispatched.payload.id,
            serviceline_name: response?.response?.name || '',
          },
        ),
      ));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getServiceLineDetails(dispatched: PayloadAction<SLNewsRequest>) {
  const startTime = getCurrentTime();
  const { payload } = dispatched;
  const AppliedFilters = payload?.filters?.filter(
    (item) => item.isSelected,
  ).map((item) => ({
    id: item.filterId,
    type: item.filterTypeName,
  }));
  const obj = {
    id: payload.id,
    page: payload.page,
    ...payload.widget_name && { widget_name: payload.widget_name },
    ...payload.filters && {
      filters: AppliedFilters,
    },
  };
  try {
    const response: ServiceLineDetailsResponse = yield call(
      axios.post,
      API.GET_SERVICELINEDETAILS,
      obj,
    );

    if (response?.status === true) {
      if (payload?.widget_name) {
        yield put(actions.setWidgetNewsDetails(response?.response));
        if (response?.response?.articles?.length < 1) {
          yield put(actions.setWidgetArticlesEnded(payload?.widget_name));
        }
      } else {
        yield put(actions.setNewsDetails(response?.response));
        if (response?.response?.articles?.length < 1) {
          yield put(actions.setNewsArticlesEnded());
        }
      }

      yield put(helperActions.callFlurryEvent(
        valueToObject(
          'serviceline_news_page_load',
          startTime,
          {
            serviceline_id: payload?.id,
            page: payload?.page,
            ...payload?.widget_name && { widget_name: payload?.widget_name },
            serviceline_name: response?.response?.name || '',
            ...AppliedFilters?.length
            && {
              number_of_filters_applied: AppliedFilters.length,
              filter_applied: AppliedFilters,
            },
          },
        ),
      ));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* servicelineSaga() {
  yield takeLatest(actions.getServiceLines.type, getServiceLines);
  yield takeLatest(actions.removeServiceLines.type, removeServiceLines);
  yield takeLatest(actions.addingServiceLine.type, addingServiceLine);
  yield takeLatest(actions.getServiceLineWidgets.type, getServiceLineWidgets);
  yield takeLatest(actions.getServiceLineDetails.type, getServiceLineDetails);
}
