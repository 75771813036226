import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import {
  ButtonPrimaryDefault,
  ButtonSecondaryDefault,
  primaryColor, primaryFont, primaryHeadingColor, secondaryFont, welcomeTourBanner, wMBold, wMedium,
} from 'Utils/utilsStyle';
import {
  useDispatch, useSelector,
} from 'react-redux';
import { setLocalStorageValue, valueToObject } from 'Utils/UtilityFunctions';
import { CERTIFICATION_START_POPUP } from 'Utils/Constants';
import { selectActiveModule } from 'Selector/CertificationSelector';
import { actions as flurryActions } from 'Slice/HelperSlice';
import ClockTimer from '../assets/svgIcons/ClockTimer';
import { actions as certificationAction } from '../../../Slice/CertificationSlice';

const Wrapper = styled.div``;
const Image = styled.img``;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25px;
  .left-side {
    font-family: ${primaryFont};
    font-weight: ${wMBold};
    font-size: 21px;
    line-height: 25px;
    letter-spacing: -0.65px;
    color: ${primaryHeadingColor};
  }
  .right-side {
    font-weight: ${wMBold};
    font-family: ${primaryFont};
    font-size: 21px;
    line-height: 25px;
    letter-spacing: -0.65px;
    text-decoration: underline;
    color: ${primaryColor};
    cursor: pointer;
  }
`;

const ModalBody = styled.div`
 .banner-area {
  height: 205px;
  width: 100%;
  margin-bottom: 24px;
 }
 .headingMain {
  font-family: ${secondaryFont};
  font-weight: ${wMBold};
  font-size: 26px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.65px;
  color: ${primaryHeadingColor};
  margin-bottom: 7px;
 }
 .description {
  font-family: ${secondaryFont};
  font-weight: ${wMedium};
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #5E7079; 
  margin-bottom: 32px;
  padding: 0px 92px;
 }
 .timer-tag {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .minutes{
  font-family: ${secondaryFont};
  font-weight: ${wMBold};
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.181818px;
  color: #5E7079;
  margin-top: 10px;
  }
 }
`;

type Props = {
  visible: boolean
};

const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  margin: 15px 0px;
  .action-to-get {
    ${ButtonPrimaryDefault}
  }
  .cancel-action {
   ${ButtonSecondaryDefault} 
  }
`;

const BottomMessage = styled.div`
  color: #A0A0A0;
  font-family: ${primaryFont};
  font-size: 11px;
  line-height: 14px;
  margin-bottom: 20px;
  text-align: center;
  font-style: italic;
`;

const WelcomeTourPopup = function WelcomeTourPopup(props: Props) {
  const { visible } = props;
  const [show, setShow] = useState(visible);
  const dispatch = useDispatch();
  const flurryObject = useSelector(selectActiveModule);

  const startCertification = () => {
    setShow(false);
    setLocalStorageValue(CERTIFICATION_START_POPUP, 'false');
    dispatch(certificationAction.setOpenDrawer(true));
    dispatch(certificationAction.getCertificationData({}));
    dispatch(flurryActions.callFlurryEvent(
      valueToObject('certification_start_popup', 0, {
        ...flurryObject,
      }),
    ));
  };

  const clickCancel = () => {
    setLocalStorageValue(CERTIFICATION_START_POPUP, 'false');
    setShow(false);
  };

  return (
    <Wrapper>
      <Modal
        centered
        width={650}
        getContainer=""
        wrapClassName="welcome-tour-popup"
        visible={show}
        onCancel={startCertification}
        maskClosable={false}
      >
        <ModalHeader>
          <span className="left-side">Join us for the xiQ Xelerate Session!</span>
          <span
            className="right-side"
            role="button"
            tabIndex={0}
            onClick={() => window.open(process.env.REACT_APP_WEBSITE_LAUNCH_PAGE || '')}
            onKeyPress={() => window.open(process.env.REACT_APP_WEBSITE_LAUNCH_PAGE || '')}
          >
            Register Now
          </span>
        </ModalHeader>
        <ModalBody>
          <Image src={welcomeTourBanner} alt="banner" className="banner-area" />
          <h6 className="headingMain">Welcome to xiQ Sales Xelerator</h6>
          <p className="description">
            Learn about the major platform features and their best practices
            to accelerate your sales and increase social awareness.
          </p>
          <div className="timer-tag">
            <ClockTimer />
            <span className="minutes">Estimated: 35 minutes</span>
          </div>
        </ModalBody>
        <ModalFooter>
          <span
            className="action-to-get"
            onClick={startCertification}
            onKeyPress={startCertification}
            role="button"
            aria-label="Register now"
            tabIndex={0}
          >
            Get Certified
          </span>
          <span
            className="cancel-action"
            onClick={clickCancel}
            onKeyPress={clickCancel}
            role="button"
            aria-label="Register now"
            tabIndex={0}
          >
            Cancel
          </span>
        </ModalFooter>
        <BottomMessage>Complete the certification and obtain 140 sales sigma points</BottomMessage>
      </Modal>
    </Wrapper>
  );
};
export default WelcomeTourPopup;
