import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import {
  wMBold, primaryHeadingColor, secondaryFont, maxWidth, retina, maxTablet,
} from 'Utils/utilsStyle';

const Wrapper = styled.div`
  height: 280px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    font-family: ${secondaryFont};
    font-weight: ${wMBold};
    color: ${primaryHeadingColor};
    font-size: 32px;
    @media all and (max-width: ${maxWidth}), ${retina} {
      font-size: 24px;
    }
    @media all and (max-width: ${maxTablet}){
      font-size: 16px;
    }
  }
  &.peopleInFilter {
    h3 {
      padding: 0px 270px;
      text-align: center;
      white-space: break-spaces;
      @media all and (max-width: ${maxTablet}){
        padding: 0px 20px;
      }
    }
  }
`;

type Props = {
  title?: string,
  peopleNotInFilter?: boolean,
} & typeof defaultProps;

const defaultProps = {
  title: '',
  peopleNotInFilter: false,
};

const NoPersonsFound = function NoPersonsFound(props: Props) {
  const { title, peopleNotInFilter } = props;
  return (
    <Wrapper className={classNames('no-person-found-wrapper', { peopleInFilter: peopleNotInFilter })}>
      <h3 className="no-person-title">{title}</h3>
    </Wrapper>
  );
};

NoPersonsFound.defaultProps = defaultProps;
export default NoPersonsFound;
