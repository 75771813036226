import { Col } from 'antd';
import LineSkeleton from './LineSkeleton';

const FeedLoadingSkeleton = function FeedLoadingSkeleton() {
  return (
    <>
      <Col span={12}>
        <LineSkeleton />
        <LineSkeleton />
        <LineSkeleton />
        <LineSkeleton />
        <LineSkeleton />
        <LineSkeleton />
        <LineSkeleton />
        <LineSkeleton />
      </Col>
      <Col span={12}>
        <LineSkeleton />
        <LineSkeleton />
        <LineSkeleton />
        <LineSkeleton />
        <LineSkeleton />
        <LineSkeleton />
        <LineSkeleton />
        <LineSkeleton />
      </Col>
    </>
  );
};

export default FeedLoadingSkeleton;
