import { createGlobalStyle } from 'styled-components';
import { maxMobile, maxTablet } from 'Utils/utilsStyle';

const DynamicAppStyle = createGlobalStyle`
  .salesForceApp {
    .app {
      overflow-x: hidden;
      
    }
    .account-popover-wrapper {
      margin-left: 10px;
    }
    .smartAccountWrapper {
      .smart-account-drawer {
        .ant-drawer-content-wrapper {
          .smart-account-plan-slider {
            @media all and (max-width: ${maxTablet}) {
              height: 100%;
            }
            .smart-account-footer {
              justify-content: end;
            }
          }
        }
      }
    }
    .ant-layout {
      .ant-layout-content {
        .header-main-wrapper {
          .container {
            display: flex;
            .responsive-logo {
              display: none;
              @media all and (max-width: ${maxTablet}) {
                display: block;
              }
            }
            .header-wrapper {
              width: 100%;
              .right-side-wrap {
                min-height: 34px;
                .account-popover-wrapper {
                  margin-right: 0px;
                }
              }
              @media all and (max-width: ${maxTablet}) {
                width: calc(100% - 60px);
              }
            }
          }
        }
      }
    }
    ~{
      div {
        background-color: transparent;
        .people-group-drawer {
          .ant-drawer-content-wrapper {
            .ant-drawer-content {
              .ant-drawer-wrapper-body {
                >div {
                  .create-group {
                    @media all and (max-width: ${maxMobile}) {
                      display: block;
                    }
                  }
                  .groups-wrapper {
                    @media all and (max-width: ${maxMobile}) {
                      height: calc(100vh - 140px);
                      &.footerUnable {
                        height: calc(100vh - 290px);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .ant-modal-root {
          .ant-modal-wrap {
            overflow: hidden;
          }
        }
      }   
    }
  }
  
`;

export default DynamicAppStyle;
