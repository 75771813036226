import React from 'react';

const CertificationGilroySvg = function CertificationGilroySvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.6695 6.76127L16.4842 6.76127C17.3179 6.76127 18 7.46515 18 8.32543L18 10.5153C18 11.3755 17.3179 12.0794 16.4842 12.0794L15.6695 12.0794L15.6695 12.7442C15.6695 14.3083 14.4379 15.5988 12.9032 15.5988L5.09684 15.5988C3.58105 15.5988 2.33053 14.3279 2.33053 12.7442L2.33053 12.0794L1.51579 12.0794C0.682105 12.0794 2.48254e-08 11.3755 2.28175e-08 10.5153L1.77079e-08 8.32543C1.56999e-08 7.46515 0.682105 6.76127 1.51579 6.76127L2.33053 6.76127L2.33053 6.09651C2.33053 4.53235 3.56211 3.24191 5.09684 3.24191L8.39368 3.24191L8.39368 1.36492C8.39368 1.01299 8.6779 0.739258 9 0.739258C9.34105 0.739258 9.60632 1.03254 9.60632 1.36492L9.60632 3.24191L12.9032 3.24191C14.4189 3.24191 15.6695 4.51279 15.6695 6.09651L15.6695 6.76127ZM1.19344 10.4972C1.19344 10.6927 1.34502 10.8295 1.51555 10.8295L2.33028 10.8295L2.33028 7.9945L1.51555 7.9945C1.32607 7.9945 1.19344 8.15092 1.19344 8.32689L1.19344 10.4972ZM14.4752 12.7249C14.4752 13.6242 13.7742 14.3477 12.9026 14.3477L5.09625 14.3477C4.22467 14.3477 3.52362 13.6242 3.52362 12.7249L3.52362 6.09673C3.52362 5.19733 4.22467 4.47391 5.09625 4.47391L12.9026 4.47391C13.7742 4.47391 14.4752 5.19733 14.4752 6.09673L14.4752 12.7249ZM16.4841 10.8491C16.6736 10.8491 16.8062 10.6927 16.8062 10.5167L16.8062 8.32689C16.8062 8.13137 16.6546 7.9945 16.4841 7.9945L15.6694 7.9945L15.6694 10.8491L16.4841 10.8491Z" fill="#7E7E7E" />
      <path d="M7.86482 7.70059C7.86482 8.41327 7.30494 8.99102 6.6143 8.99102C5.92365 8.99102 5.36377 8.41327 5.36377 7.70059C5.36377 6.9879 5.92365 6.41016 6.6143 6.41016C7.30494 6.41016 7.86482 6.9879 7.86482 7.70059Z" fill="#7E7E7E" />
      <path d="M12.64 7.70059C12.64 8.41327 12.0801 8.99102 11.3894 8.99102C10.6988 8.99102 10.1389 8.41327 10.1389 7.70059C10.1389 6.9879 10.6988 6.41016 11.3894 6.41016C12.0801 6.41016 12.64 6.9879 12.64 7.70059Z" fill="#7E7E7E" />
      <path d="M11.5781 10.2429C11.275 10.106 10.915 10.2429 10.7823 10.5557C10.536 11.1032 9.81601 11.4942 9.00127 11.4942C8.18654 11.4942 7.46654 11.1032 7.22022 10.5557C7.08759 10.2429 6.72759 10.106 6.42443 10.2429C6.12127 10.3798 5.98864 10.7513 6.12127 11.0641C6.55706 12.0612 7.71285 12.726 9.00127 12.726C10.2897 12.726 11.4455 12.0612 11.8813 11.0641C12.0139 10.7513 11.8813 10.3798 11.5781 10.2429Z" fill="#7E7E7E" />
    </svg>
  );
};

export default CertificationGilroySvg;
