import React from 'react';

export const SearchCrossIcon = function SearchCrossIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path d="M10.8182 1L7 4.81818L3.18182 1L1 3.18182L4.81818 7L1 10.8182L3.18182 13L7 9.18182L10.8182 13L13 10.8182L9.18182 7L13 3.18182L10.8182 1Z" fill="#A0A0A0" stroke="#F6F6F6" />
    </svg>
  );
};

export default SearchCrossIcon;
