const DownloadCertificateSvg = function DownloadCertificateSvg() {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0V11.1189H2.4312V9.9378H1.18106V1.18106H12.5547V9.9378H11.3046V11.1189H13.7358V0H0Z" fill="white" />
      <path d="M9.51156 10.457C9.51538 9.70776 9.20219 8.99173 8.64935 8.48597C8.09637 7.98021 7.35531 7.73184 6.60938 7.80237C5.86332 7.87289 5.18195 8.25556 4.73352 8.85598C4.28522 9.4564 4.1116 10.2184 4.25583 10.9537L2.39684 12.8203L3.23306 13.6542L4.78795 12.094H4.78782C4.93716 12.2845 5.11169 12.454 5.30637 12.5977L3.74264 14.1661L4.57887 15L6.50046 13.0725H6.50033C6.6187 13.093 6.73825 13.1053 6.85819 13.1091C6.98868 13.1056 7.11879 13.0924 7.24743 13.0696L9.16139 14.9835L9.99749 14.1485L8.43086 12.5819V12.5817C8.62489 12.437 8.79823 12.2666 8.94637 12.0752L10.5084 13.6372L11.3434 12.8021L9.46597 10.9253C9.49497 10.7709 9.51026 10.6141 9.51145 10.457L9.51156 10.457ZM6.85825 8.98542C7.24854 8.98529 7.62304 9.14017 7.89907 9.41607C8.17522 9.6921 8.33036 10.0663 8.33049 10.4566C8.33063 10.847 8.17561 11.2214 7.89971 11.4974C7.62369 11.7735 7.24935 11.9286 6.85902 11.9287C6.46873 11.9287 6.09437 11.7737 5.81834 11.4977C5.54232 11.2217 5.38732 10.8475 5.38732 10.457C5.38851 10.0672 5.54392 9.69383 5.81942 9.41807C6.09505 9.14244 6.46846 8.98691 6.85825 8.98546V8.98542Z" fill="white" />
      <path d="M2.43073 4.90903H11.3035V6.09009H2.43073V4.90903Z" fill="white" />
      <path d="M4.27266 2.4548H9.46394V3.63587H4.27266V2.4548Z" fill="white" />
    </svg>

  );
};
export default DownloadCertificateSvg;
