import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'Store/InjectorsTypes';
import { initialState } from 'Slice/SpotlightSlice';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state?.spotlight || initialState;
export const selectLoading = createSelector([selectDomain], (state) => state.spotlightLoading);
export const selectSpotlight = createSelector([selectDomain], (state) => state.spotlight);
export const selectSpotlightPulseLoading = createSelector(
  [selectDomain],
  (state) => state.spotlightPulseLoading,
);
export const selectSpotlightPulse = createSelector([selectDomain], (state) => state.spotlightPulse);
