import React from 'react';

const ArrowActionIcon = function ArrowActionIcon() {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="arrow-action-icon">
      <path d="M13.5657 6.56568C13.8781 6.25326 13.8781 5.74673 13.5657 5.43431L8.47452 0.343145C8.1621 0.0307257 7.65556 0.0307258 7.34315 0.343145C7.03073 0.655565 7.03073 1.1621 7.34315 1.47452L11.8686 6L7.34315 10.5255C7.03073 10.8379 7.03073 11.3444 7.34315 11.6569C7.65557 11.9693 8.1621 11.9693 8.47452 11.6569L13.5657 6.56568ZM6.99382e-08 6.8L13 6.8L13 5.2L-6.99382e-08 5.2L6.99382e-08 6.8Z" fill="#1EC252" />
    </svg>
  );
};

export default ArrowActionIcon;
