/* eslint-disable react/no-unknown-property */
import React from 'react';

const TranslateIcon = function TranslateIcon() {
  return (

    <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_223_1640)">
        <circle cx="21.5" cy="21.5" r="14.5" fill="#667982" />
      </g>
      <path d="M14.6164 15.4615H20.1241C19.9887 17.0123 19.3364 18.4462 18.3087 19.56C17.6625 18.8585 17.1579 18.0215 16.8441 17.1046C16.7333 16.7846 16.3825 16.6123 16.0625 16.7231C15.7425 16.8338 15.5702 17.1846 15.681 17.5046C16.0502 18.5815 16.641 19.56 17.4041 20.3846C16.9856 20.7046 16.5241 20.9815 16.0256 21.2031L14.9856 21.6646C14.6779 21.8 14.5364 22.1631 14.6717 22.4769C14.7702 22.7108 14.9979 22.8462 15.2317 22.8462C15.3179 22.8462 15.3979 22.8277 15.4841 22.7908L16.5241 22.3292C17.1764 22.04 17.7733 21.6646 18.3148 21.2338C18.8564 21.6708 19.4533 22.0462 20.0994 22.3292L21.1394 22.7908C21.2194 22.8277 21.2994 22.8462 21.3856 22.8462C21.6194 22.8462 21.8471 22.7108 21.9456 22.4831C22.081 22.1754 21.9456 21.8062 21.6317 21.6708L20.5917 21.2092C20.0994 20.9877 19.6379 20.7108 19.2194 20.3908C20.4502 19.0554 21.2194 17.3262 21.361 15.4677H22.001C22.3394 15.4677 22.6164 15.1908 22.6164 14.8523C22.6164 14.5138 22.3394 14.2369 22.001 14.2369H18.9241V13.6154C18.9241 13.2769 18.6471 13 18.3087 13C17.9702 13 17.6933 13.2769 17.6933 13.6154V14.2308H14.6164C14.2779 14.2308 14.001 14.5077 14.001 14.8462C14.001 15.1846 14.2779 15.4615 14.6164 15.4615Z" fill="white" />
      <path d="M29.3842 28.9993C29.4581 28.9993 29.5319 28.987 29.6058 28.9562C29.9258 28.8332 30.0796 28.4762 29.9565 28.1624L27.1012 20.7347C26.8796 20.1562 26.3135 19.7686 25.6919 19.7686C25.0704 19.7686 24.5042 20.1562 24.2827 20.7347L21.4273 28.1624C21.3042 28.4824 21.4642 28.8332 21.7781 28.9562C22.0981 29.0793 22.4489 28.9193 22.5719 28.6055L23.6058 25.9224H27.7781L28.8119 28.6055C28.9042 28.8516 29.1381 28.9993 29.3842 28.9993ZM24.0796 24.6916L25.4335 21.1778C25.5135 20.9624 25.8765 20.9624 25.9565 21.1778L27.3104 24.6916H24.0796Z" fill="white" />
      <path d="M28.9471 16.8705L28.7686 17.0551V16.0766C28.7686 15.0612 27.9379 14.2305 26.9225 14.2305H25.0763C24.7379 14.2305 24.4609 14.5074 24.4609 14.8459C24.4609 15.1843 24.7379 15.4612 25.0763 15.4612H26.9225C27.2609 15.4612 27.5379 15.7382 27.5379 16.0766V17.0551L27.3594 16.8766C27.1194 16.6366 26.7317 16.6366 26.4917 16.8766C26.2517 17.1166 26.2517 17.5043 26.4917 17.7443L27.7225 18.9751C27.8394 19.092 27.9932 19.1535 28.1532 19.1535C28.3132 19.1535 28.4671 19.092 28.5902 18.9751L29.8209 17.7443C30.0609 17.5043 30.0609 17.1166 29.8209 16.8766C29.5809 16.6366 29.1871 16.6305 28.9471 16.8705Z" fill="white" />
      <path d="M14.1792 26.359C14.4192 26.599 14.8069 26.599 15.0469 26.359L15.2315 26.1744V27.1529C15.2315 28.1683 16.0623 28.999 17.0777 28.999H18.9239C19.2623 28.999 19.5392 28.7221 19.5392 28.3836C19.5392 28.0452 19.2623 27.7683 18.9239 27.7683H17.0777C16.7392 27.7683 16.4623 27.4913 16.4623 27.1529V26.1744L16.6408 26.3529C16.7639 26.476 16.9177 26.5375 17.0777 26.5375C17.2377 26.5375 17.3915 26.476 17.5146 26.359C17.7546 26.119 17.7546 25.7313 17.5146 25.4913L16.2839 24.2606C16.0439 24.0206 15.6562 24.0206 15.4162 24.2606L14.1854 25.4913C13.9392 25.7252 13.9392 26.119 14.1792 26.359Z" fill="white" />
      <defs>
        <filter id="filter0_d_223_1640" x="0.895349" y="0.895349" width="41.2093" height="41.2093" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="3.05233" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_223_1640" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_223_1640" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default TranslateIcon;
