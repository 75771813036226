/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FiltersResponse } from 'Types/CompaniesTypes';
import { FiltersRequest } from '../Types/RequestBodies';
import { TriggersState } from '../Types/GlobalTypes';

export const initialState: TriggersState = {
  triggers: [],
  peopleTriggers: [],
  triggersLoading: false,
  peopleTriggersLoading: false,
};

const TriggersSlice = createSlice({
  name: 'triggers',
  initialState,
  reducers: {
    getFilters(state, action: PayloadAction<FiltersRequest>) {
      if (state.triggers.length === 0) { state.triggersLoading = true; }
    },
    setFilters(state, action: PayloadAction<FiltersResponse>) {
      state.triggersLoading = false;
      state.triggers = action?.payload?.filters;
    },
    getPeopleFilters(state, action: PayloadAction<FiltersRequest>) {
      if (state.peopleTriggers.length === 0) { state.peopleTriggersLoading = true; }
    },
    setPeopleFilters(state, action: PayloadAction<FiltersResponse>) {
      state.peopleTriggersLoading = false;
      state.peopleTriggers = action?.payload?.filters;
    },
  },
});

export const { actions, reducer, name: sliceKey } = TriggersSlice;
