import React from 'react';
import { Layout as AntLayout } from 'antd';
import RestrictAside from './asider/RestrictAside';

type Props = {
  children: object,
};

const RestrictLayout = function RestrictLayout(props: Props) {
  const {
    Sider, Content,
  } = AntLayout;
  const { children } = props;
  return (
    <AntLayout>
      <Sider width={87}><RestrictAside /></Sider>
      <AntLayout>
        <Content className="target-here-modal">{children}</Content>
      </AntLayout>
    </AntLayout>
  );
};

export default RestrictLayout;
