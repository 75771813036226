import React from 'react';

type Props = {
  color?: string;
} & typeof defaultProps;

const defaultProps = {
  color: '#FFFFFF',
};

const ArrowLineIcon = function ArrowLineIcon({ color }: Props) {
  return (
    <svg width="13px" height="12px" viewBox="0 0 13 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Search-Results-Copy" transform="translate(-1283.000000, -404.000000)" fill={color} fillRule="nonzero">
          <g id="Group-4" transform="translate(204.000000, 266.000000)">
            <g id="Group-13" transform="translate(960.000000, 0.000000)">
              <g id="Group-3-Copy-5" transform="translate(19.000000, 130.159574)">
                <g id="noun_Arrow_3134190" transform="translate(100.168773, 8.040426)">
                  <path d="M6.84256564,0.199243219 C6.7063892,0.0380185105 6.48962463,-0.033380783 6.28268183,0.014825127 C6.07573904,0.063031037 5.91415859,0.222563217 5.86533375,0.426882709 C5.81650891,0.631202201 5.88882492,0.845218962 6.05211963,0.979669168 L10.0849752,4.96608491 L0.532979827,4.96608491 C0.234162675,4.98173792 0,5.22552361 0,5.52096776 C0,5.81641192 0.234162675,6.0601976 0.532979827,6.07585061 L10.0849752,6.07585061 L6.04579606,10.0575838 C5.83460805,10.275944 5.83460805,10.6196496 6.04579606,10.8380098 C6.15050154,10.9417128 6.29271029,11 6.44101907,11 C6.58932784,11 6.7315366,10.9417128 6.83624208,10.8380098 L11.8318609,5.90571776 C11.9392946,5.80361604 12,5.66273246 12,5.51550478 C12,5.3682771 11.9392946,5.22739352 11.8318609,5.12529181 L6.84256564,0.199243219 Z" id="Path" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

ArrowLineIcon.defaultProps = defaultProps;

export default ArrowLineIcon;
