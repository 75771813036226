import React from 'react';

const BurgerIcon = function BurgerIcon() {
  return (
    <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.15" y="0.15" width="16.7" height="2.7" fill="#444B4F" stroke="white" strokeWidth="0.3" />
      <rect x="0.15" y="12.15" width="16.7" height="2.7" fill="#444B4F" stroke="white" strokeWidth="0.3" />
      <rect x="0.15" y="6.15" width="12.8364" height="2.7" fill="#444B4F" stroke="white" strokeWidth="0.3" />
    </svg>

  );
};

export default BurgerIcon;
