import React from 'react';

type Props = {
  color?: string;
} & typeof defaultProps;

const defaultProps = {
  color: '#7E7E7E',
};

const ShareIcon = function ShareIcon({ color }: Props) {
  return (
    <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.9579 5.46143L16.0537 7.45296L6.80612 7.45296C6.0715 7.45296 5.48813 8.00731 5.48813 8.70537C5.48813 9.40343 6.0715 9.95778 6.80612 9.95778L16.0753 9.95778L13.9795 11.9493C13.7202 12.1957 13.5906 12.5037 13.5906 12.8322C13.5906 13.1607 13.7202 13.4686 13.9795 13.715C14.498 14.2078 15.3191 14.2078 15.8376 13.715L20.1589 9.58821C20.6775 9.09546 20.6775 8.31527 20.1589 7.82252L15.816 3.69574C15.2975 3.20298 14.4764 3.20298 13.9579 3.69574C13.4393 4.18849 13.4393 4.96867 13.9579 5.46143Z" fill={color} />
      <path d="M7.51897 16.1376C7.51897 15.4395 6.93559 14.8852 6.20097 14.8852L2.6359 14.8852L2.6359 2.50482L6.20097 2.50482C6.93559 2.50482 7.51897 1.95047 7.51897 1.25241C7.51897 0.554345 6.93559 0 6.20097 0L1.3179 0C0.583282 0 -9.25064e-05 0.554345 -9.25064e-05 1.25241L-9.25064e-05 16.1376C-9.25064e-05 16.8357 0.583282 17.39 1.3179 17.39H6.20097C6.93559 17.39 7.51897 16.8357 7.51897 16.1376Z" fill={color} />
    </svg>
  );
};

ShareIcon.defaultProps = defaultProps;

export default ShareIcon;
