import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import Cross from 'Components/globalSearch/assets/Cross';
import SearchGlobalIcon from 'Components/common/assets/svgIcons/SearchGlobalIcon';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import { translateSkipAreas } from 'Utils/Constants';
import {
  primaryFont, wMedium, primaryColor, maxWidth, retina, minWidthApp, maxTablet, maxMobile,
} from '../../../Utils/utilsStyle';

type Props = {
  placeholderText: string
  loading?: boolean
  onSearching: (a: string) => void
  onInputChange?: (a: string) => void
  height?: string;
  margin?: string;
  query?: string;
  customClass?: string;
  iconMode?: boolean;
} & typeof defaultProps;

const defaultProps = {
  height: '40px',
  margin: '20px',
  loading: false,
  query: '',
  customClass: '',
  onInputChange() { },
  iconMode: false,
};

const Wrapper = styled.div<{ margin: string, height: string }>`
  margin: ${(props) => props.margin};

  &.iconModeSearch {
    .cross-icon {
      display: none;
    }
    width: 30px;
    border: none;
    @media all and (max-width: ${maxTablet}) {
      width: 20px;
    }
    .ant-input-affix-wrapper {
      width: 35px;
      height: 35px !important;
      padding: 0px;
      border-radius: 0px;
      border: none;
      input {
        display: none;
      }
      .ant-input-prefix {
        width: inherit;
        color: #424B50;
        svg {
          width: inherit;
          cursor: pointer;
        }
      }
    }
    .ant-input-prefix {
      padding: 0px;
      border: 1px solid #DADADA;
      border-radius: 50%;     
      margin-right: 0px;
      svg {
        height: 15px;
      }
    }
    .clear-search {
      display: none;
    }
  }
  &.expandModeSearch {
    position: absolute !important;
    z-index: 1;
    top: 0px;
    right: 10px;
    width: 365px;
    @media all and (max-width: ${maxWidth}), ${retina}{
      width: 289px;
    }
    @media all and (max-width: ${maxMobile}){
     top: 4px;
    }
    .ant-input-prefix{
      display: none;
    }
    .ant-input-affix-wrapper {
      padding: 6px 15px;
      border: 1px solid #DADADA;
      border-radius: 25px;
      input {
        display: block;
        padding-right: 15px;
      }
    }
    .cross-icon {
      @media all and (max-width: ${maxMobile}){
        top: 8px;
      }
    }
  }
  .ant-input-affix-wrapper {
    &:not(.ant-input-affix-wrapper-disabled) {
      &:hover {
        border-color: ${primaryColor};
        z-index: 0;
      }
    }
  }
  &.search-wrapper{
    position: relative;
    svg {
      height: 16px;
      @media all and (max-width: ${maxWidth}),${retina} {
        margin-left: -5px;
        height: 14px;
      }
    }
  }
  .anticon-search{
    color: #000;
    font-size: 20px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      font-size: 12px;
    }
  }
  .ant-input-prefix{
    margin-right: 8px;
    padding-right: 8px;
    border-right: 2px solid #dadada;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      padding: 0px 5px 0px 0;
    }
    
  }
  .ant-input-affix-wrapper{
   border: 1px solid #DADADA;
   border-radius: 25px;
   height: ${(props) => props.height};
   @media all and (max-width: ${maxWidth}) , ${retina} {
      height: 28px;
    }
    @media all and (max-width: ${minWidthApp}) {
      height: 32px;
    }
   &:focus {
     box-shadow: none;
   }
  }
  input {
      font-size:15px;
      font-family: ${primaryFont};
      font-weight: ${wMedium};
      color: rgb(66, 75, 80);
    @media all and (max-width: ${maxWidth}) , ${retina} {
      font-size: 12px;
      line-height: 18px;
    }
    @media all and (max-width: ${minWidthApp}) {
      line-height: 22px;
    }
    &::placeholder{
      color:#C1C1C1;
      font-family: ${primaryFont};
      font-weight: ${wMedium};
    }
  }
  .disabled{
    opacity:0;
    display:none;
    pointer-events:none;
  }
  .pointer-cursor{
    cursor:pointer
  }
  .cross-icon {
    position: absolute;
    top: 3px;
    right: 5px;
    z-index: 1;
  }
`;

const AlertsSearch = function AlertsSearch(props: Props) {
  const {
    placeholderText, onSearching, loading, height,
    margin, query, customClass, onInputChange, iconMode,
  } = props;
  const [searchQuery, setSearchQuery] = useState('');
  const [isIconMode, setIconMode] = useState(iconMode);
  const [isExpandMode, setExpandMode] = useState(false);

  useEffect(() => {
    if (searchQuery === '') {
      onSearching('');
    }
  }, [searchQuery]);

  useEffect(() => {
    setSearchQuery(query);
  }, [query]);

  const searchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    onInputChange(e.target.value);
  };

  const sendSearchQuery = (e: { key: string; }) => {
    if (e.key === 'Enter') {
      onSearching(searchQuery);
    }
  };

  const clearSearch = (e: React.MouseEvent<HTMLOrSVGElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchQuery('');
    onInputChange('');
  };
  const expandSearchBar = (nextState: boolean) => {
    if (iconMode) {
      setExpandMode(nextState);
      setIconMode(!isIconMode);
    }
  };

  return (
    <Wrapper
      className={classNames(`search-wrapper ${translateSkipAreas}`, { iconModeSearch: isIconMode, expandModeSearch: iconMode && isExpandMode })}
      margin={margin || ''}
      height={height || ''}
    >
      <OutsideClickHandler onOutsideClick={() => (isExpandMode ? expandSearchBar(false) : '')}>
        <Input tabIndex={-1} className={classNames(`${customClass}`)} size="large" placeholder={placeholderText} disabled={loading} prefix={<SearchGlobalIcon onClick={() => expandSearchBar(!isExpandMode)} />} value={searchQuery} onChange={searchQueryChange} onKeyPress={sendSearchQuery} />
        <Cross className={`cross-icon search-field-icon ${searchQuery.length > 0 ? '' : 'disabled'}`} onClick={clearSearch} />
      </OutsideClickHandler>
    </Wrapper>
  );
};

AlertsSearch.defaultProps = defaultProps;
export default AlertsSearch;
