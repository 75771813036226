import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'Store/InjectorsTypes';
import { initialState } from 'Slice/ProfileShareSlice';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state?.profileShare || initialState;
export const selectProfileShareLink = createSelector([selectDomain], (state) => state.link);
export const selectProfileLoading = createSelector([selectDomain], (state) => state.loading);
export const selectCopyModalOpen = createSelector([selectDomain], (state) => state.copyModalOpen);
