import InfoIconTwo from 'Components/common/assets/svgIcons/InfoIconTwo';
import React from 'react';

const InfoIconPlaceholder = function InfoIconPlaceholder() {
  return (
    <span className="icon-area">
      <InfoIconTwo />
    </span>
  );
};

export default InfoIconPlaceholder;
