import React from 'react';

const YoutubeVideoCrossIcon = function YoutubeVideoCrossIcon() {
  return (
    <svg width="33px" height="34px" viewBox="0 0 33 34" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Widgets" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Widget-Expand-Copy" transform="translate(-1167.000000, -650.000000)">
          <g id="Group-2" transform="translate(1167.000000, 650.500000)">
            <circle id="Oval" fill="#FFFFFF" cx="16.5" cy="16.5" r="16.5" />
            <g id="Group-3" transform="translate(16.636039, 16.636039) rotate(-45.000000) translate(-16.636039, -16.636039) translate(8.136039, 8.136039)" stroke="#424B50" strokeLinecap="square" strokeWidth="3">
              <line x1="0.274193548" y1="8.5" x2="16.7258065" y2="8.5" id="Line" />
              <line x1="0.274193548" y1="8.5" x2="16.7258065" y2="8.5" id="Line" transform="translate(8.500000, 8.500000) rotate(90.000000) translate(-8.500000, -8.500000) " />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default YoutubeVideoCrossIcon;
