import styled from 'styled-components';

const Wrapper = styled.div`
  overflow: hidden;
  .skeleton-box{
    height:5px;
  }
`;

const ProgressBarSkeleton = function ProgressBarSkeleton() {
  return (
    <Wrapper>
      <span className="skeleton-box w-100" />
    </Wrapper>
  );
};

export default ProgressBarSkeleton;
