import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import Empty from '../assets/svgIcons/Empty';
import {
  primaryFont, wMedium, maxWidth, retina, maxMobile, smMobile, maxTablet,
} from '../../../Utils/utilsStyle';

type Props = {
  title?: string;
  IconLeft?: object;
  IconRight?: object;
  disabled?: boolean;
  onButtonClick?: () => void;
  bgColor?: string,
  textColor?: string,
  outlineColor?: string,
  leftSeparator?: boolean,
  RightSeparator?: boolean,
  LeftSpace?: string,
  RightSpace?: string,
  disabledOutline?: boolean,
  innerSpace?: string,
  fontSize?: string,
  fontSizeSm?: string,
  leftSeparatorColor?: string,
  leftSeparatorClassName?: string,
  rightSeparatorClassName?: string;
  fontFamily?: string,
  buttonClassName?: string,
  borderRadius?: string
  loading?: boolean,
  PaddingSm?: string,
  PaddingXsm?: string,
  PaddingXxsm?: string,
  heightSm?: string,
  height?: string,
  borderWidth?: string,
  hoverFin?: boolean
  noTitle?: boolean,
  inCompanyCompare?: boolean,
} & typeof defaultProps;

const defaultProps = {
  title: '',
  hoverFin: false,
  IconLeft: <Empty />,
  IconRight: <Empty />,
  disabled: false,
  onButtonClick() { },
  bgColor: 'transparent',
  textColor: '#ffffff',
  outlineColor: 'transparent',
  leftSeparator: false,
  RightSeparator: false,
  LeftSpace: '0px',
  RightSpace: '0px',
  disabledOutline: false,
  innerSpace: '10px 15px 8px 15px',
  fontSize: '16px',
  fontSizeSm: '12px',
  leftSeparatorColor: '',
  leftSeparatorClassName: '',
  rightSeparatorClassName: '',
  fontFamily: primaryFont,
  buttonClassName: '',
  borderRadius: '8px',
  PaddingSm: '',
  PaddingXsm: '',
  PaddingXxsm: '',
  heightSm: '',
  height: '',
  borderWidth: '1.6px',
  loading: false,
  noTitle: false,
  sup: false,
  inCompanyCompare: false,
};

const Wrapper = styled.button < { height: string, heightSm: string, PaddingSm: string, PaddingXsm: string, PaddingXxsm: string, bgColor: string, textColor: string, outlineColor: string, LeftSpace: string, RightSpace: string, disabledOutline: boolean, innerSpace: string, fontSize: string, fontSizeSm: string, fontFamily: string, leftSeparatorColor: string, borderRadius: string, borderWidth: string } >`
  border-radius: ${(props) => props.borderRadius};
  background-color:${(props) => props.bgColor};
  border:none;
  outline:1px solid transparent;
  padding:${(props) => props.innerSpace};
  font-family:${(props) => props.fontFamily};
  color: ${(props) => props.textColor};
  font-weight: ${wMedium};
  border: ${(props) => props.borderWidth} solid ${(props) => props.outlineColor};
  font-size: ${(props) => props.fontSize};
  margin-left: ${(props) => props.LeftSpace};
  margin-right: ${(props) => props.RightSpace};
  height: ${(props) => props.height};
  white-space:nowrap;
 
  sup {
    font-size: 10px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      font-size: 8px;
    }
  }
  &.companyCompare {
    @media all and (max-width: ${maxWidth}), ${retina} {
      font-size: ${(props) => props.fontSizeSm};
      line-height: 20px;
      padding: ${(props) => props.PaddingSm};
      height: ${(props) => props.heightSm};
    }
  }
  &.isHoverFin {
    transition: 0.5s ease;
    @media all and (max-width: ${maxTablet}) {
      pointer-events: none;
    }
    &:hover {
      color: #1EC252;
      background-color: #DEF6E6;
      svg {
        g,g {
          fill:#1EC252;
        }
      }
    }
  }

  @media all and (max-width: ${maxWidth}) , ${retina} {
    font-size: ${(props) => props.fontSizeSm};
    line-height: 15px;
    padding: ${(props) => props.PaddingSm};
    height: ${(props) => props.heightSm};
  }
  @media all and (max-width: ${maxTablet}){
    padding: ${(props) => props.PaddingXsm};
  }
  @media all and (max-width: ${smMobile}){
    padding: ${(props) => props.PaddingXxsm};
  }
  &.disabledClass {
    opacity: 0.5;
    pointer-events: none;
  }
  &:focus, &:active, &:hover{
    ${(props) => (props.disabledOutline ? '' : 'outline:2px solid transparent')};
    @media all and (max-width: ${maxTablet}) { 
      outline: none !important;
    }
  }
  .ant-spin {
    display: flex;
  }
  .separator-right{
    width:1px;
    height: 22px;
    background-color: ${(props) => {
    if (props.leftSeparatorColor) return props.leftSeparatorColor;
    return props.textColor;
  }};
    display: inline-block;
    margin: 0 5px -5px 10px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      height: 16px;
      margin: 0 5px 0px 10px;
    }
  }
  .separator-left{
    width:1px;
    height: 22px;
    background-color: ${(props) => {
    if (props.leftSeparatorColor) return props.leftSeparatorColor;
    return props.textColor;
  }};
    display: inline-block;
    margin: 0 5px -5px 10px;
  }
  &.d-block{
    width:100%;
  }
  svg{
    vertical-align: middle;
    &:nth-of-type(1){
      @media all and (max-width: ${maxWidth}) , ${retina} {
        width: 18px;
        min-width: 18px;
      }
    }
    &.shareIcon-svg {
      @media all and (max-width: ${maxWidth}) , ${retina} {
        width: 12px;
        min-width: 12px;
      }
    }
    &:nth-of-type(2){
      margin-right:0px;
      margin-left: 3px;
    }
  }
  &.res-sm-md {
    @media all and (max-width: ${maxMobile}) {
      padding: 10px;
      font-size: 14px;
    }
    @media all and (max-width: ${smMobile}) {
      display: flex;
      flex-direction: column;
      min-height: 70px;
      font-size: 12px;
    }
  }
`;

const GlobalButton = function GlobalButton(props: Props) {
  const {
    title,
    IconLeft,
    IconRight,
    disabled,
    onButtonClick,
    bgColor,
    textColor,
    outlineColor,
    leftSeparator,
    RightSeparator,
    LeftSpace,
    RightSpace,
    disabledOutline,
    innerSpace,
    fontSize,
    fontSizeSm,
    leftSeparatorColor,
    fontFamily,
    buttonClassName,
    leftSeparatorClassName,
    rightSeparatorClassName,
    borderRadius,
    loading,
    PaddingSm,
    PaddingXsm,
    PaddingXxsm,
    heightSm,
    hoverFin,
    height,
    noTitle,
    sup,
    borderWidth,
    inCompanyCompare,
  } = props;

  return (
    <Wrapper
      borderWidth={borderWidth}
      disabled={disabled}
      onClick={onButtonClick}
      bgColor={bgColor}
      textColor={textColor}
      outlineColor={outlineColor}
      LeftSpace={LeftSpace}
      RightSpace={RightSpace}
      disabledOutline={disabledOutline}
      innerSpace={innerSpace}
      fontSize={fontSize}
      fontSizeSm={fontSizeSm}
      fontFamily={fontFamily}
      leftSeparatorColor={leftSeparatorColor}
      borderRadius={borderRadius}
      PaddingSm={PaddingSm}
      PaddingXsm={PaddingXsm}
      PaddingXxsm={PaddingXxsm}
      heightSm={heightSm}
      height={height}
      className={classNames(`d-flex align-items-center text-truncate ${buttonClassName}`, { disabledClass: disabled, isHoverFin: hoverFin, companyCompare: inCompanyCompare })}
      translate="no"
    >
      {loading
        ? (
          <Spin indicator={
            (
              <LoadingOutlined
                style={{
                  fontSize: 14,
                  color: textColor,
                  marginRight: 10,
                }}
                spin
              />
            )
          }
          />
        ) : null}
      {IconLeft}
      {leftSeparator ? <span className={`separator-left ${leftSeparatorClassName.length ? ` ${leftSeparatorClassName}` : ''}`}> </span> : ''}
      {noTitle ? '' : title}
      {sup ? <sup>TM</sup> : ''}
      {RightSeparator ? <span className={`separator-right ${rightSeparatorClassName.length ? ` ${rightSeparatorClassName}` : ''}`}> </span> : ''}
      {IconRight}
    </Wrapper>
  );
};

GlobalButton.defaultProps = defaultProps;
export default GlobalButton;
