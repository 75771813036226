import React from 'react';
import styled from 'styled-components';
import { maxMobile, maxWidth, retina } from 'Utils/utilsStyle';
import XiqSkeletonSvg from '../assets/svgIcons/XiqSkeletonSvg';

type Props = {
  height?: string,
  heightSm?: string,
  width?: string,
  logoSize?: string,
  diameter?: string,
  logo?: boolean,
  margin?: string,
  marginSm?: string,
  className?: string
  smWidth?: string
} & typeof defaultProps;

const defaultProps = {
  height: '200px',
  heightSm: '',
  width: '100%',
  logoSize: '100px',
  diameter: '8px',
  margin: '0px 5px 26px 5px',
  logo: false,
  marginSm: '0px',
  className: '',
  smWidth: '',
};

const Wrapper = styled.span < { height: string, heightSm?: string, width: string, smWidth: string, logoSize: string, diameter: string, margin: string, marginSm: string }>`
  height: ${(props) => props.height} !important;
  width: ${(props) => props.width};
  min-width: ${(props) => props.width};
  border-radius: ${(props) => props.diameter};
  margin: ${(props) => props.margin};
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    margin: ${(props) => props.marginSm};
    height: ${(props) => (props.heightSm ? props.heightSm : '')};
  }
  @media all and (max-width: ${maxMobile}) {
  width: ${(props) => props.smWidth} !important;
  min-width: ${(props) => props.smWidth} !important;
  }
  svg {
    width: ${(props) => props.logoSize};
    opacity: 0.3;
  }
`;

const SkeletonCustom = function SkeletonCustom(props: Props) {
  const {
    height, width, logoSize, diameter, logo, margin,
    marginSm, className, smWidth, heightSm,
  } = props;
  return (
    <Wrapper
      height={height}
      heightSm={heightSm}
      width={width}
      logoSize={logoSize}
      diameter={diameter}
      margin={margin}
      marginSm={marginSm}
      smWidth={smWidth}
      className={`skeleton-box ${className}`}
    >
      {logo && <XiqSkeletonSvg />}
    </Wrapper>
  );
};

SkeletonCustom.defaultProps = defaultProps;
export default SkeletonCustom;
