import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'Store/InjectorsTypes';
import { initialState } from 'Slice/CertificationSlice';

const selectDomain = (state: RootState) => state?.certification || initialState;

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading,
);

export const selectAiCertificationLoading = createSelector(
  [selectDomain],
  (state) => state.aiCertificationLoading,
);

export const selectData = createSelector(
  [selectDomain],
  (state) => state.certificationData,
);

export const selectAiCertificationData = createSelector(
  [selectDomain],
  (state) => state.aiCertificationData,
);

export const selectScrollToId = createSelector(
  [selectDomain],
  (state) => state.scrollToId,
);

export const selectIsScroll = createSelector(
  [selectDomain],
  (state) => state.isScroll,
);

export const selectScrollToPage = createSelector(
  [selectDomain],
  (state) => state.scrollToPage,
);

export const selectOpenDrawer = createSelector(
  [selectDomain],
  (state) => state.openDrawer,
);

export const selectOpenGilroyDrawer = createSelector(
  [selectDomain],
  (state) => state.gilroyDrawer,
);

export const selectPopupData = createSelector(
  [selectDomain],
  (state) => state.popupData,
);

export const selectIsCertificationCompleted = createSelector(
  [selectDomain],
  (state) => state.certificationData.percentage === 100
  || state.certificationData.completed,
);

export const selectIsAiCertificationCompleted = createSelector(
  [selectDomain],
  (state) => state.aiCertificationData.percentage === 100
  || state.aiCertificationData.completed,
);

export const selectFullName = createSelector(
  [selectDomain],
  (state) => state.certificationData.fullname,
);

export const selectNameLoading = createSelector(
  [selectDomain],
  (state) => state.nameLoading,
);

export const selectPDFLoading = createSelector(
  [selectDomain],
  (state) => state.certificateLoading,
);

export const selectPDFLink = createSelector(
  [selectDomain],
  (state) => state.certificatePDFLink,
);

export const selectCompletionDate = createSelector(
  [selectDomain],
  (state) => state.certificationData.certification_complete_date,
);

export const selectAiCompletionDate = createSelector(
  [selectDomain],
  (state) => state.aiCertificationData.certification_complete_date,
);

export const selectImgLink = createSelector(
  [selectDomain],
  (state) => state.certificateImgLink,
);

export const selectAiPDFLoading = createSelector(
  [selectDomain],
  (state) => state.aiCertificateLoading,
);

export const selectAiPDFLink = createSelector(
  [selectDomain],
  (state) => state.aiCertificatePDFLink,
);

export const selectAiImgLink = createSelector(
  [selectDomain],
  (state) => state.aiCertificateImgLink,
);

export const selectSearchPersonClicked = createSelector(
  [selectDomain],
  (state) => state.searchedPersonClicked,
);

export const selectSearchCompanyClicked = createSelector(
  [selectDomain],
  (state) => state.searchedCompanyClicked,
);

export const selectPulseCompanyClicked = createSelector(
  [selectDomain],
  (state) => state.pulseCompanyClicked,
);

export const selectShowPopup = createSelector(
  [selectDomain],
  (state) => state.showPopup,
);

export const selectActiveTask = createSelector(
  [selectDomain],
  (state) => state.activeTask,
);

export const selectHighlight = createSelector(
  [selectDomain],
  (state) => state.highlight,
);

export const selectActivetype = createSelector(
  [selectDomain],
  (state) => state.currentType,
);

export const selectShowtooltip = createSelector(
  [selectData],
  (certificationData) => certificationData.showTooltip,
);

export const selectIsHighlighted = createSelector(
  [selectDomain],
  (state) => state.isHighlighted,
);

export const selectTaskIndex = createSelector(
  [selectDomain],
  (state) => state.taskIndex,
);

export const selectModuleIndex = createSelector(
  [selectDomain],
  (state) => state.moduleIndex,
);

export const selectAiTaskIndex = createSelector(
  [selectDomain],
  (state) => state.aiTaskIndex,
);

export const selectAiModuleIndex = createSelector(
  [selectDomain],
  (state) => state.aiModuleIndex,
);

export const selectGroupToaster = createSelector(
  [selectDomain],
  (state) => state.certificationData.groupToaster === 1,
);

export const selectCompanyToaster = createSelector(
  [selectDomain],
  (state) => state.certificationData.companyToaster === 1,
);

export const selectPersonToaster = createSelector(
  [selectDomain],
  (state) => state.certificationData.personToaster === 1,
);

export const selectActiveModule = createSelector(
  [selectDomain],
  (state) => state.activeModule,
);

export const selectToasterFlag = createSelector(
  [selectDomain],
  (state) => state.toasterFlag,
);

export const selectShowAccordion = createSelector(
  [selectDomain],
  (state) => state.showAccordion,
);

export const selectCertificationPrompt = createSelector(
  [selectDomain],
  (state) => state.certificationPrompt,
);

export const selectIsCompletionPopupOpen = createSelector(
  [selectDomain],
  (state) => state.isCompletionPopupOpen,
);

export const selectIsChatLoading = createSelector(
  [selectDomain],
  (state) => state.chatLoading,
);
