/* eslint-disable @typescript-eslint/no-unsafe-return */
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'Store/InjectorsTypes';
import { initialState } from 'Slice/NotesSlice';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state?.notes || initialState;

export const selectPersonNotes = createSelector(
  [selectDomain],
  (state) => state.personNotes,
);
export const selectAddNoteLoading = createSelector(
  [selectDomain],
  (state) => state.addNoteLoading,
);
export const selectNoteAdded = createSelector(
  [selectDomain],
  (state) => state.noteAdded,
);
export const selectDeleteNoteLoading = createSelector(
  [selectDomain],
  (state) => state.deleteNoteLoading,
);
export const selectNoteDeleted = createSelector(
  [selectDomain],
  (state) => state.noteDeleted,
);
export const selectEditNoteLoading = createSelector(
  [selectDomain],
  (state) => state.editNoteLoading,
);
export const selectNoteEdited = createSelector(
  [selectDomain],
  (state) => state.noteEdited,
);
export const selectNotesDrawerOpen = createSelector(
  [selectDomain],
  (state) => state.notesDrawerOpen,
);
export const selectCurrentUser = createSelector(
  [selectDomain],
  (state) => state.currentUser,
);
export const selectAlertNotificationId = createSelector(
  [selectDomain],
  (state) => state.alertsNofiticationId,
);
