/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable consistent-return */
/**
 * From: https://dev.to/jorik/country-code-to-flag-emoji-a21
 */

import axios from '../Utils/Axios';

export function getFlagEmoji(countryCode: string) {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

/**
 * From: https://stackoverflow.com/a/37826698
 */
export function chunkedArray<T>(inputArray: T[], perChunk: number): T[][] {
  // var perChunk = 2 // items per chunk
  // var inputArray = ['a','b','c','d','e']

  const result = inputArray.reduce<T[][]>((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return result;
}

/**
 * Extract the value from an object by a key (or nested keys),
 * and return a default value if that value is missing
 */
export function extract(obj:
{ [key: string]: any }, key: string | string[], defaultValue: any): any {
  if (Array.isArray(key)) {
    if (key.length === 0) {
      throw 'This shouldn\'t happen';
    }
    if (key.length === 1) {
      // Extract this only key left
      return extract(obj, key[0], defaultValue);
    }
    if (key.length > 1) {
      // If current key doesn't exist, return defaultValue
      if (obj[key[0]] === undefined) {
        return defaultValue;
      }

      // Otherwise, recurse to next key
      return extract(obj[key[0]], key.slice(1), defaultValue);
    }
  } else {
    // If value is nullish, return defaultValue instead
    return (obj[key] === undefined || obj[key] === null) ? defaultValue : obj[key];
  }
}

export function existsInside<T>(array: T[], predicate: (value: T, index: number, obj: T[]) => unknown) {
  return array.findIndex(predicate) >= 0;
}

export async function translate(endpoint: string, text: string[], from: string, to: string): Promise<string[]> {
  const data = await axios.post(endpoint, {
    body: { data: text, from, to },
  });

  if (Array.isArray(data) && data.every((e) => typeof e === 'string')) {
    return data;
  }

  throw `Data returned from endpoint was not of type string[] (Endpoint: ${endpoint}), data: ${JSON.stringify(data)}`;
}

export async function update_translation(endpoint: string, originalText: string, text: string, from: string, to: string, siteName: string): Promise<boolean> {
  const res = await fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify({
      from,
      to,
      siteName,
      originalText,
      text,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await res.json();
  if (!('status' in data)) {
    throw `Invalid data returned from endpoint (Endpoint: ${endpoint}), data: ${JSON.stringify(data)}`;
  }

  if (data.status === 'Success') {
    return true;
  }

  return false;
}
